import { append as svgAppend, attr as svgAttr, classes as svgClasses, clear as svgClear, clone as svgClone, create as svgCreate, remove as svgRemove } from 'tiny-svg';
import BpmnRenderer, { BpmnRendererConfig } from 'bpmn-js/lib/draw/BpmnRenderer';
import { Shape, Element, Connection } from 'bpmn-js/lib/model/Types';
import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';

import TextRenderer from 'bpmn-js/lib/draw/TextRenderer';
import EventBus from 'diagram-js/lib/core/EventBus';
import Styles from 'diagram-js/lib/draw/Styles';
import Canvas from 'diagram-js/lib/core/Canvas';
import PathMap from 'bpmn-js/lib/draw/PathMap';
import DrawModule from 'bpmn-js/lib/draw';

import { GovDiagramDirective } from './diagram.directive';
import { Palette } from './palette.module';

type IEntry = Palette.IEntry;

interface ISVGAttr {
    strokeWidth?: string | number,
    height?: string | number,
    width?: string | number,
    rx?: string | number,
    ry?: string | number,
    transform?: string,
    stroke?: string,
    fill?: string
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode: SVGRectElement, parentNode: globalThis.Element, siblingNode?: globalThis.ChildNode) {
    parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}

// webpack:///node_modules/bpmn-js/lib/draw/BpmnRenderer.js
export class RendererHook extends BpmnRenderer {
    constructor(
        public diagram: GovDiagramDirective,
        public config: BpmnRendererConfig,
        public eventBus: EventBus,
        public styles: Styles,
        public pathMap: PathMap,
        public canvas: Canvas,
        public textRenderer: TextRenderer,
        public priority?: number
    ) {
        super(config, eventBus, styles, pathMap, canvas, textRenderer, priority);
        const { handlers } = this, _handlers = { ...handlers };

        // handlers['bpmn:Task'] = (parentGfx: any, element: any): any => {
        //     element = _handlers['bpmn:Task'](parentGfx, element);

        //     const rect = this.drawRect(parentGfx, {
        //         transform: 'translate(-20, -10)',
        //         width: 30, height: 20,
        //         stroke: '#cc0000',
        //         rx: 2, ry: 2
        //     });

        //     return svgAttr(element, {
        //         stroke: '#52B415',
        //         ry: 2, rx: 2,
        //     })
        // }
    }

    canRender(element: Element) {
        // isAny(element, ['bpmn:Task', 'bpmn:Event']) && !element.labelTarget
        return super.canRender(element);
    }

    getShapePath(shape: Shape) {
        if (is(shape, 'bpmn:Task')) {
            return getRoundRectPath(shape, 2);
        }

        return super.getShapePath(shape);
    }

    drawShape(parentNode: SVGElement, shape: Shape) {
        const { diagram: { entries: { entries } } } = this;
        const element = super.drawShape(parentNode, shape);
        const bussinessObject = getBusinessObject(shape);
        const type = bussinessObject?.type;

        const entry: IEntry = entries[type] || {};
        const { attr } = entry;

        if (attr) {
            svgAttr(element, attr);
        }

        return element;

        // const { diagram: { elementRegistry: ereg } } = this;
        // if (shape != ereg.get('SCAN_OK')) return element;

        // const { width, height } = shape;
        // const style = `width: ${width}px; height: ${height}px;`;
        // const overlayhtml = `<div class="height-overlay" style="${style}">`;

        // const { diagram: { overlays } } = this;
        // overlays.add(shape, {
        //     html: overlayhtml,
        //     position: {
        //         left: -5,
        //         top: -5,
        //     },
        // });

        // const { diagram: { moddle } } = this;
        // const businessObject = getBusinessObject(shape);
        // businessObject.conditionExpression = moddle.create('bpmn:FormalExpression', {
        //     body: '${ value > 100 }'
        // });

        // const { diagram: { modeling } } = this;
        // modeling.setColor([shape], {
        //     stroke: 'green',
        //     fill: '#ff00ff',
        // });

        // return element;
    }

    drawConnection(visuals: SVGElement, connection: Connection): SVGElement {
        return super.drawConnection(visuals, connection);
    }

    drawRect(parentGfx: SVGElement, attr: ISVGAttr = {}) {
        attr.strokeWidth = attr.strokeWidth || 2;
        attr.stroke = attr.stroke || '#000';
        attr.fill = attr.fill || '#fff';

        const rect = svgCreate('rect', attr);
        svgAppend(parentGfx, rect);
        return rect;
    }

    drawPath(parentGfx: SVGElement, d: string, attrs: ISVGAttr = {}): SVGPathElement {
        return (this as any)._drawPath(parentGfx, d, attrs);
    }

    static typeOf(shape: Shape): string {
        const stype = shape.type;
        if (is(shape, stype)) {
            return stype;
        }

        return '';
    }

    static Module = {
        ...DrawModule,
        bpmnRenderer: ['type', RendererHook],
        __init__: ['bpmnRenderer']
    };

    static $inject = [
        'govDiagram',
        ...BpmnRenderer.$inject,
    ];
}