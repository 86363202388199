<div class="h-100 w-100 m-0 p-0 d-flex flex-column align-items-stretch justify-content-start">
    <div mat-dialog-title class="d-flex flex-row flex-nowrap justify-content-end align-items-center">
        <label class="btn btn-sm" mat-dialog-close>
            <i class="fa fa-close fa-fw"></i>
        </label>
    </div>
    <mat-dialog-content class="mat-typography position-relative px-0 flex-fill">
        <div class="h-100 w-100 d-flex flex-row flex-nowrap justify-content-center align-items-center">
            <img style="width: auto; height: 100%;"
                src="{{'assets.about' | translate}}?version={{ app.version.server?.version }}" />
        </div>
    </mat-dialog-content>
</div>