<div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
    <div class="d-flex flex-row justify-content-between align-items-stretch flex-none">
        <div class="col-md-3">
            <gov-wscard [cardsum]="prjcountsuper"></gov-wscard>
        </div>
        <div class="col-md-3">
            <gov-wscard [cardsum]="supervisecount"></gov-wscard>
        </div>
        <div class="col-md-3">
            <gov-wscard [cardsum]="procount"></gov-wscard>
        </div>
        <div class="col-md-3">
            <gov-wscard [cardsum]="unfixprocount"></gov-wscard>
        </div>
    </div>
    <div id="reportsuperviseoption" echarts class="h-100 w-100 d-inline-flex" (chartClick)="onClickCharBar($event)"
        [options]="checkoption">
    </div>
</div>