import { forwardRef } from "@angular/core";
import * as _ from "lodash";

import { ProjectDetailComponent } from "../../../projectdetail/view/projectdetail.component";
import { MaplocateComponent } from "../../../maplocate/view/maplocate.component";
import { ResultsComponent } from "../../../results/view/results.component";
import { Prj as TPrj, Sys as TSys, Exec as TExec } from './types';
import { KeyOf } from "../../../utils/libs/types/mixins";
import { Property } from "../../../utils/libs/property";
import { Editor } from "../../../utils/libs/editor";
import { AppService } from "../app.service";
import { Lang } from "./lang";
import { Exec } from './exec';
import { Prj } from './prj';
import { Sys } from "./sys";

type IDatasetHeader = TSys.IDatasetHeader;
type AuditResult = Exec.AuditResult;

const AStatus = TExec.IResult.Audit.Status;
const EStatus = TExec.IResult.Exec.Status;
const Frequency = TSys.Frequency;
const WType = TPrj.IWorkitem.Type;
const VType = Editor.Value.Type;

export class Dict extends Lang {
    readonly monthcostdefault = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    readonly months = [
        {id:"01", name:'1月'}, 
        {id:"02", name:'2月'}, 
        {id:"03", name:'3月'}, 
        {id:"04", name:'4月'}, 
        {id:"05", name:'5月'}, 
        {id:"06", name:'6月'}, 
        {id:"07", name:'7月'}, 
        {id:"08", name:'8月'}, 
        {id:"09", name:'9月'}, 
        {id:"10", name:'10月'}, 
        {id:"11", name:'11月'}, 
        {id:"12", name:'12月'}        
    ];
    readonly rangeyearmin = Number.parseInt(this.trans('general.rangeyearmin'));
    readonly rangeyearmax = Number.parseInt(this.trans('general.rangeyearmax'));
    readonly useprepareproject = this.app.prepareproject;
    readonly priviledges = this.app.auth.me.priviledges;
    readonly currentYear = new Date().getFullYear();
    readonly rangeprjcountmax = 150;
    readonly rangewscountmax = 500;    

    readonly xfiles = [
        { format: "pdf", mime_type: "pdf", icon: `/assets/img/icons/pdf.png?version=${this.app.version.server?.version}` },
        { format: "doc", mime_type: "msword", icon: `/assets/img/icons/word.png?version=${this.app.version.server?.version}` },
        { format: "docx", mime_type: "vnd.openxmlformats-officedocument.wordprocessingml.document", icon: `/assets/img/icons/word.png?version=${this.app.version.server?.version}` },
        { format: "ppt", mime_type: "vnd.ms-powerpoint", icon: `/assets/img/icons/ppt.png?version=${this.app.version.server?.version}` },
        { format: "pptx", mime_type: "vnd.openxmlformats-officedocument.presentationml.presentation", icon: `/assets/img/icons/ppt.png?version=${this.app.version.server?.version}` },
        { format: "xls", mime_type: "vnd.ms-excel", icon: `/assets/img/icons/excel.png?version=${this.app.version.server?.version}` },
        { format: "xlsx", mime_type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet", icon: `/assets/img/icons/excel.png?version=${this.app.version.server?.version}` }
    ];

    readonly enums = {
        Frequency: {
            [Frequency[Frequency.randomly]]: this.trans('sysenums.randomly'),
            [Frequency[Frequency.daily]]: this.trans('sysenums.daily'),
            [Frequency[Frequency.weekly]]: this.trans('sysenums.weekly'),
            [Frequency[Frequency.monthly]]: this.trans('sysenums.monthly'),
            [Frequency[Frequency.quarterly]]: this.trans('sysenums.quarterly'),
            [Frequency[Frequency.halfyearly]]: this.trans('sysenums.halfyearly'),
            [Frequency[Frequency.yearly]]: this.trans('sysenums.yearly')
        },

        Audit: {
            Status: {
                [AStatus[AStatus.auditting]]: this.trans('sysenums.audit.auditting'),
                [AStatus[AStatus.callback]]: this.trans('sysenums.audit.callback'),
                [AStatus[AStatus.nopass]]: this.trans('sysenums.audit.nopass'),
                [AStatus[AStatus.pass]]: this.trans('sysenums.audit.pass'),
            }
        },

        Exec: {
            Status: {
                [EStatus[EStatus.exepre]]: this.trans('sysenums.exec.exepre'),
                [EStatus[EStatus.exeon]]: this.trans('sysenums.exec.exeon'),
                [EStatus[EStatus.exed]]: this.trans('sysenums.exec.exed'),
            }
        },

        Field: {
            Type: {
            }
        },

        Status: {
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.unset]]: this.trans('sysenums.unset'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.unknown]]: this.trans('sysenums.unknown'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.notstart]]: this.trans('sysenums.notstart'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.ongoing]]: this.trans('sysenums.ongoing'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.delaying]]: this.trans('sysenums.delaying'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.delayed]]: this.trans('sysenums.delayed'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.finished]]: this.trans('sysenums.finished'),
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.notused]]: this.trans('sysenums.notused')
        },

        StatusIcon: {
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.unknown]]: 'help_outline',
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.notstart]]: 'schedule',
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.ongoing]]: 'update',
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.delaying]]: 'warning',
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.delayed]]: 'error',
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.finished]]: 'check_circle_outline',
            [TPrj.IWorkitem.Status[TPrj.IWorkitem.Status.notused]]: 'block'
        },

        Type: {
            [TPrj.IWorkitem.Type[TPrj.IWorkitem.Type.normal]]: this.trans('sysenums.normal'),
            [TPrj.IWorkitem.Type[TPrj.IWorkitem.Type.verify]]: this.trans('sysenums.verify'),
            [TPrj.IWorkitem.Type[TPrj.IWorkitem.Type.supervise]]: this.trans('sysenums.supervise')
        },

        ConstrTypes: {
            [TPrj.ConstrType[TPrj.ConstrType.unknown]]: this.trans('sysenums.na'),
            [TPrj.ConstrType[TPrj.ConstrType.newcreate]]: this.trans('sysenums.newcreate'),
            [TPrj.ConstrType[TPrj.ConstrType.continous]]: this.trans('sysenums.continous'),
            [TPrj.ConstrType[TPrj.ConstrType.cancelled]]: this.trans('sysenums.cancelled'),
            [TPrj.ConstrType[TPrj.ConstrType.projectpre]]: this.trans('sysenums.projectpre'),
            [TPrj.ConstrType[TPrj.ConstrType.constrpre]]: this.trans('sysenums.constrpre'),
            [TPrj.ConstrType[TPrj.ConstrType.constring]]: this.trans('sysenums.constring'),
            [TPrj.ConstrType[TPrj.ConstrType.accepting]]: this.trans('sysenums.accepting'),
            [TPrj.ConstrType[TPrj.ConstrType.all]]: this.trans('sysenums.all')
        },

        Pool: {
            [TPrj.Pool[TPrj.Pool.input]]: this.trans('sysenums.pool.input'),
            [TPrj.Pool[TPrj.Pool.meditate]]: this.trans('sysenums.pool.meditate'),
            [TPrj.Pool[TPrj.Pool.candidate]]: this.trans('sysenums.pool.candidate'),
            [TPrj.Pool[TPrj.Pool.begun]]: this.trans('sysenums.pool.begun'),
            [TPrj.Pool[TPrj.Pool.accept]]: this.trans('sysenums.pool.accept')
        },

        PrjLevel: {
            PatchedProject: this.trans('sysenums.patchprj'),
            DetailProject: this.trans('sysenums.realprj')
        }
    }

    readonly FieldTypes = Sys.buildEnum(VType, null, [
        VType.catmask,
        VType.typemask,
        VType.onlycolumn,
        VType.notcolumn,
        VType.select,
        VType.added,
        VType.multi,
        VType.rowselect
    ]);

    readonly Frequencies = Sys.buildEnum(Frequency, this.enums.Frequency);

    readonly ExecStatus = Sys.buildEnum(EStatus, this.enums.Exec.Status);

    readonly AuditStatus = Sys.buildEnum(AStatus, this.enums.Audit.Status);

    readonly AllStatus = Sys.buildEnum(TPrj.IWorkitem.Status, this.enums.Status);

    readonly PrjConstrTypes = Sys.buildEnum(TPrj.ConstrType, this.enums.ConstrTypes);

    readonly PrjPool = Sys.buildEnum(TPrj.Pool, this.enums.Pool);

    readonly WSProperties = Sys.buildEnum(TPrj.IWorkitem.Type, this.enums.Type);

    readonly StatusIcons = Sys.buildEnum(TPrj.IWorkitem.Status, this.enums.StatusIcon);

    readonly Workitem = {
        Type: {
            [TPrj.IWorkitem.Type.normal]: 'sysenums.typenormal',
            [TPrj.IWorkitem.Type.verify]: 'sysenums.typeaudit',
            [TPrj.IWorkitem.Type.supervise]: 'sysenums.typesupvise'
        }
    }

    readonly ProjectHeaders = {
        ["select"]: { key: "select", type: VType.rowselect },
        ["id"]: { key: "id", title: 'project.id', readonly: true, type: VType.notcolumn },
        ["projectdetails"]: {
            key: "projectdetails", title: 'projectdetail.title',
            type: VType.command | VType.onlycolumn,
            source: forwardRef(() => ProjectDetailComponent)
        },
        ["plan.name"]: {
            key: "plan.name", title: 'project.plan', must: true,
            type: VType.option, source: "datasources.projectplan"
        },
        ["projecttype.name"]: {
            key: "projecttype.name", title: 'project.projecttype', must: true,
            type: VType.option, source: 'datasources.projecttype',// | VType.notcolumn
            dependfield: { key: 'ispatched' }, dependvalues: [(val: any, obj: any): boolean => (!val)],
            readonly: (app: AppService, project: Prj.Project): boolean => {
                return !!project.processlock;
            }
        },
        ["processlock"]: {
            key: "processlock", title: "project.processlock", must: true, type: VType.bool,
            readonly: (app: AppService, project: Prj.Project): boolean => {
                const { auth: { me: { priviledges: mep } } } = app;
                return !mep.plan?.project?.projectdetails?.projectprocesslock;
            }
        },
        ["name"]: { key: "name", title: 'project.name', must: true },

        ["ispatched"]: {
            key: "ispatched", title: 'project.ispatched',
            type: VType.bool //TODO  | VType.children,
        },
        ["parent.name"]: {
            key: "parent.name", title: 'project.parent',
            type: VType.option | VType.notcolumn, source: 'datasources.project',
            dependfield: { key: "ispatched" },
            dependvalues: [(val: any, obj: any): boolean => (!val)]
        },
        ["myproject"]: { key: "myproject", title: 'general.myproject', must: true, type: VType.bool | VType.onlycolumn },
        ["cancelled"]: { key: "cancelled", title: 'project.cancelled', must: true, type: VType.bool },

        ["changepool"]: {
            must: false,
            key: "nextpool",
            type: VType.enum | VType.notcolumn,
            title: 'projectplan.changepool',
            invisible(app: AppService, obj: Prj.Project): boolean {
                return !obj.nextpools;
            },
            source(app: AppService, obj: Prj.Project): Editor.IEnum | undefined {
                const { nextpools: nextcats } = obj;
                if ((nextcats?.length ?? 0) <= 0) return;

                const { dict: { PrjPool, ProjectHeaders } } = app;
                const id = _.sum(nextcats), props = Property.Of<{
                    [id: number]: Editor.IEnum
                }>(ProjectHeaders).values;

                return props[id] || (
                    props[id] = _.extend(_.map(nextcats, (cat) => {
                        return PrjPool.indexed[cat];
                    }), {
                        indexed: _.reduce(nextcats, (res, cat) => {
                            const item = PrjPool.indexed[cat];
                            res[item.value] = res[item.key] = item;
                            return res;
                        }, <typeof PrjPool.indexed>{})
                    }),

                    props[id].splice(0, 0, { key: '*', title: '', value: null }),

                    props[id].indexed['*'] = props[id][0],

                    props[id]
                )
            }
        },

        ["actor.name"]: {
            key: "actor.name", title: 'project.actor', must: true,
            type: VType.option | VType.notcolumn, source: 'datasources.actor'
        },
        ["inspector.name"]: {
            key: "inspector.name", title: 'project.inspector', must: true,
            type: VType.option | VType.notcolumn, source: 'datasources.inspector'
        },

        ["owncompany"]: { key: "owncompany", title: 'project.owncompany', must: true, type: VType.notcolumn | VType.text },
        ["dept[,].name"]: {
            key: "dept[,].name", title: 'project.owndept', must: true,
            type: VType.options, source: "datasources.dept"
        },
        ["budget.start"]: {
            key: "budget.start", title: 'budget.start',
            must: true,
            type: VType.date
        },
        ["budget.end"]: {
            key: "budget.end", title: 'budget.end',
            must: true,
            type: VType.date
        },
        ["budget.amount"]: {
            key: "budget.amount", title: 'budget.amount',
            must: true,
            type: VType.number
        },
        ["prjConsume"]: {
            key: "prjConsume", title: 'budget.consume',
            must: true,
            type: VType.number,
            readonly: true
        },
        ["budget.percent"]: {
            key: "budget.percent", title: 'budget.percent',
            type: VType.percent | VType.onlycolumn
        },
        // 年度计划投资 列表方式展示
        // 年度完成投资                
        // 完成投资占比（年度完成投资/年度计划投资）
        // 状态（新建、续建、完成）
        ["budgets"]: {
            key: "budgets", title: 'budgets.title',
            must: true,
            type: VType.list | VType.notcolumn, source: 'budgets-yearly'
        },
        ["pool"]: {
            key: "pool", title: "project.pool",
            type: VType.enum | VType.onlycolumn,
            source: this.PrjPool
        },
        ["constrstatus"]: {
            key: "constrstatus", title: "project.constrstatus",
            type: VType.enum | VType.onlycolumn,
            source: this.AllStatus
        },
        ["constrtypename"]: {
            key: "constrtypename", title: "project.constrtype",
            type: VType.text | VType.onlycolumn,
        },
        ["location"]: {
            key: "location", title: "project.location",
            must: true,
            type: VType.notcolumn
        },
        ["position[,].district"]: {
            key: "position[,].district", title: "project.position",
            type: VType.command | VType.notcolumn,
            readonly: true,
            source: forwardRef(() => MaplocateComponent)
        },
        ["description"]: { key: "description", title: "project.description", must: true, type: VType.notcolumn | VType.texts },
        ["memo"]: { key: "memo", title: "project.memo", type: VType.notcolumn | VType.text },
        ["isuniplan"]: { key: "isuniplan", title: 'project.isuniplan', must: true, type: VType.notcolumn | VType.bool },
        ["uniplan"]: { key: "uniplan", title: 'project.uniplan', must: true, type: VType.notcolumn | VType.text }
    }

    readonly prj = {
        projecttypedefine: <TSys.IDatasetDict>{
            title: 'tabs.projecttype',
            icon: "list_alt",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'projecttype.id', readonly: true, type: VType.notcolumn },
                { key: "name", title: 'projecttype.name', must: true },
                {
                    key: "workflow", title: 'projecttype.workflow', must: true,
                    type: VType.details | VType.onlycolumn
                },
                { key: "description", title: 'projecttype.description', type: VType.texts },
            ]
        },

        projecttype: <TSys.IDatasetDict>{
            title: 'tabs.projecttype',
            icon: "format_align_justify",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'projecttype.id', readonly: true, type: VType.notcolumn },
                { key: "name", title: 'projecttype.name', must: true },
                {
                    key: "stages[, ].name", title: 'projecttype.stages', source: "datasources.stage", must: true,
                    type: VType.details | VType.options
                },
                { key: "description", title: 'projecttype.description', type: VType.texts },
            ]
        },

        stage: <TSys.IDatasetDict>{
            title: 'tabs.stage',
            icon: "format_indent_increase",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'stage.id', readonly: true, type: VType.notcolumn },
                { key: "name", title: 'stage.name', must: true },
                {
                    key: "worksets[, ].name", title: 'stage.worksets',
                    type: VType.details | VType.options, source: "datasources.workset", must: true
                },
                { key: "description", title: 'stage.description', type: VType.texts }
            ]
        },

        workset: <TSys.IDatasetDict>{
            title: 'tabs.workset',
            icon: "format_list_numbered",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'workset.id', readonly: true, type: VType.notcolumn },
                { key: "name", title: 'workset.name', must: true },
                {
                    key: "workitems[, ].name", title: 'workset.workitems', must: true,
                    type: VType.details | VType.options, source: "datasources.workitem"
                },
                { key: "description", title: 'workset.description', type: VType.texts }
            ]
        },

        workitem: <TSys.IDatasetDict>{
            title: 'tabs.workitem',
            icon: "format_list_bulleted",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'workitem.id', readonly: true, type: VType.notcolumn },
                { key: "name", title: 'workitem.name', must: true },
                {
                    key: "type", title: 'workitem.type',
                    type: VType.enum, source: this.WSProperties, must: true
                },
                {
                    key: "checkpoints[,].title", title: 'workitem.checkpoints',
                    type: VType.details | VType.options, source: 'datasources.checkpoint', must: true
                },
                { key: "duration", title: 'workitem.duration', type: VType.number },
                { key: "occurancy", title: 'workitem.occurancy', type: VType.enum, source: this.Frequencies },
                {
                    key: "dept.name", title: 'workitem.dept',
                    type: VType.option, source: 'datasources.dept'
                },
                { key: "description", title: 'workitem.description', type: VType.texts | VType.notcolumn }
            ]
        },

        checkpoint: <TSys.IDatasetDict>{
            title: 'tabs.checkpoint',
            icon: "group",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'checkpoint.id', readonly: true, type: VType.notcolumn },
                { key: "title", title: 'checkpoint.title' },
                // { key: "must", title: 'checkpoint.must', type: VType.bool },
                { key: "type", title: 'checkpoint.type', type: VType.enum, source: this.FieldTypes },
                {
                    key: "typesource", title: 'checkpoint.typesource',
                    type: VType.json | VType.notcolumn,
                    dependvalues: [VType.enum, VType.option, VType.options],
                    dependfield: { key: "type" },
                },
                {
                    key: "dependfield.title", title: 'checkpoint.dependfield',
                    type: VType.option | VType.notcolumn,
                    source: "datasources.checkpoint"
                },
                {
                    key: "dependvalues", title: 'checkpoint.dependvalues',
                    type: VType.json | VType.notcolumn,
                    dependfield: { key: "dependfield.title" },
                    dependvalues: [(val: any, obj: any): boolean => (!!val)]
                },
                { key: "description", title: 'checkpoint.description', type: VType.texts | VType.notcolumn }
            ]
        },

        /*
        社会事业项目
        旅游开发项目
        城镇基础设施项目
        园区基础设施项目
        交通项目
        农业农村基础设施项目
        工业项目
        农业产业化项目 
        */
        projectplan: <TSys.IDatasetDict>{
            title: 'tabs.projectplan',
            icon: "ballot",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: 'projectplan.id', readonly: true, type: VType.notcolumn },
                { key: "name", title: 'projectplan.name', must: true },
                { key: "description", title: 'projectplan.description', type: VType.texts }
            ]
        },

        projectsummary: <TSys.IDatasetDict>{
            title: 'tabs.projectsummary',
            icon: "list_alt",
            filterdata: [
                this.priviledges.plan?.pilotproject ? { key: "pool", title: "tabs.meditateproject", value: TPrj.Pool.meditate } : undefined,
                this.priviledges.plan?.pilotproject ? { key: "pool", title: "tabs.candidateproject", value: TPrj.Pool.candidate } : undefined,
                this.priviledges.plan?.project ? { key: "pool", title: "tabs.begunproject", value: TPrj.Pool.begun } : undefined,
                this.priviledges?.plan?.project ? { key: "pool", title: "tabs.acceptproject", value: TPrj.Pool.accept } : undefined
                //this.priviledges.plan?.inputproject ? { key: "pool", title: "tabs.inputproject", value: TPrj.Pool.coll } : undefined
            ].filter(p => !!p)
        },

        project: <TSys.IDatasetDict>{
            title: 'tabs.project',
            icon: "list_alt",
            filterdata: [
                { key: "myproject", title: "general.myproject", value: true },
                { key: "formal", title: "general.formalproject", value: true },
                { key: "ispreproject", title: "general.preproject", value: true },
                { key: "all", title: "general.allproject", value: null, isdefault: true },
            ],
            headers: [
                ... (this.useprepareproject ? [] : [this.ProjectHeaders["select"]]),
                this.ProjectHeaders["id"],
                this.ProjectHeaders["projectdetails"],
                this.ProjectHeaders["plan.name"],
                this.ProjectHeaders["projecttype.name"],
                this.ProjectHeaders["processlock"],
                this.ProjectHeaders["name"],
                this.ProjectHeaders["myproject"],
                this.ProjectHeaders["cancelled"],
                ... (!this.useprepareproject ? [] : [
                    this.ProjectHeaders["changepool"]
                ]),
                this.ProjectHeaders["actor.name"],
                this.ProjectHeaders["inspector.name"],

                this.ProjectHeaders["owncompany"],
                this.ProjectHeaders["dept[,].name"],
                this.ProjectHeaders["budget.start"],
                this.ProjectHeaders["budget.end"],
                this.ProjectHeaders["budget.amount"],
                this.ProjectHeaders["prjConsume"],
                this.ProjectHeaders["budget.percent"],
                this.ProjectHeaders["budgets"],
                this.ProjectHeaders["pool"],
                this.ProjectHeaders["constrstatus"],
                this.ProjectHeaders["constrtypename"],
                this.ProjectHeaders["location"],
                this.ProjectHeaders["position[,].district"],
                this.ProjectHeaders["description"],
                this.ProjectHeaders["memo"],
                this.ProjectHeaders["isuniplan"],
                this.ProjectHeaders["uniplan"]
            ]
        },

        pilotproject: this.useprepareproject ? <TSys.IDatasetDict>{
            title: 'tabs.pilotproject',
            icon: "list_alt",
            filterdata: [
                { key: "myproject", title: "general.myproject", value: true },
                { key: "formal", title: "general.formalproject", value: true },
                { key: "ispreproject", title: "general.preproject", value: true },
                { key: "all", title: "general.allproject", value: null, isdefault: true },
            ],
            headers: [
                this.ProjectHeaders["id"],
                this.ProjectHeaders["projectdetails"],
                this.ProjectHeaders["plan.name"],
                this.ProjectHeaders["projecttype.name"],
                this.ProjectHeaders["processlock"],
                this.ProjectHeaders["name"],
                this.ProjectHeaders["myproject"],
                this.ProjectHeaders["cancelled"],
                this.ProjectHeaders["changepool"],
                this.ProjectHeaders["actor.name"],
                this.ProjectHeaders["inspector.name"],

                this.ProjectHeaders["owncompany"],
                this.ProjectHeaders["dept[,].name"],
                this.ProjectHeaders["budget.start"],
                this.ProjectHeaders["budget.end"],
                this.ProjectHeaders["budget.amount"],
                this.ProjectHeaders["prjConsume"],
                this.ProjectHeaders["budget.percent"],
                this.ProjectHeaders["budgets"],
                this.ProjectHeaders["pool"],
                this.ProjectHeaders["constrstatus"],
                this.ProjectHeaders["constrtypename"],
                this.ProjectHeaders["location"],
                this.ProjectHeaders["position[,].district"],
                this.ProjectHeaders["description"],
                this.ProjectHeaders["memo"],
                this.ProjectHeaders["isuniplan"],
                this.ProjectHeaders["uniplan"]
            ]
        } : undefined,

        inputproject: this.useprepareproject ? <TSys.IDatasetDict>{
            title: 'tabs.inputproject',
            icon: "list_alt",
            headers: [
                this.ProjectHeaders["select"],
                this.ProjectHeaders["id"],
                this.ProjectHeaders["plan.name"],
                this.ProjectHeaders["name"],
                this.ProjectHeaders["changepool"],
                this.ProjectHeaders["actor.name"],
                this.ProjectHeaders["inspector.name"],

                this.ProjectHeaders["owncompany"],
                this.ProjectHeaders["dept[,].name"],
                this.ProjectHeaders["budget.start"],
                this.ProjectHeaders["budget.end"],
                this.ProjectHeaders["budget.amount"],
                this.ProjectHeaders["budget.percent"],
                this.ProjectHeaders["budgets"],
                this.ProjectHeaders["location"],
                this.ProjectHeaders["position[,].district"],
                this.ProjectHeaders["description"],
                this.ProjectHeaders["memo"]
            ]
        } : undefined,
    }

    readonly org = {
        people: <TSys.IDatasetDict>{
            title: "tabs.people",
            icon: "group",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: "people.id", readonly: true, type: VType.notcolumn },
                { key: "name", title: "people.name", must: true },
                { key: "mobile", title: "people.mobile", type: VType.phone, must: true },
                { key: "email", title: "people.email", type: VType.email },
                {
                    key: "dept.name", title: "people.dept",
                    type: VType.option, source: 'datasources.dept'
                },
                {
                    key: "roles[, ].name", title: "people.roles",
                    type: VType.options, source: 'datasources.role'
                },
                { key: "disabled", title: "people.disabled", type: VType.bool },
                { key: "password", title: "people.password", must: true, type: VType.notcolumn | VType.password },
                { key: "description", title: "people.description", type: VType.texts | VType.notcolumn }
            ]
        },
        role: <TSys.IDatasetDict>{
            title: "tabs.role",
            icon: "assignment_ind",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: "role.id", readonly: true, type: VType.notcolumn },
                { key: "name", title: "role.name", must: true },
                // { [NOTE]: disable hirachy of role
                //     key: "parent.name", title: "role.parent",
                //     type: VType.option, source: 'datasources.role'
                // },
                { key: "description", title: "role.description", type: VType.texts | VType.notcolumn },
                {
                    key: "priviledges", children: "priviledges", title: "role.priviledges",
                    type: VType.tree | VType.notcolumn,
                    source: 'org.priviledge'
                }
            ]
        },
        dept: <TSys.IDatasetDict>{
            title: "tabs.dept",
            icon: "domain",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: "dept.id", readonly: true, type: VType.notcolumn },
                { key: "name", title: "dept.name", must: true },
                {
                    key: "parent.name", title: "dept.parent",
                    type: VType.option, source: 'datasources.dept'
                },
                { key: "location", title: "dept.location" },
                { key: "description", title: "dept.description", type: VType.texts | VType.notcolumn },
            ]
        },
        actor: <TSys.IDatasetDict>{
            title: "tabs.actor",
            icon: "account_circle",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: "actor.id", readonly: true, type: VType.notcolumn },
                { key: "name", title: "actor.name", must: true },
                {
                    key: "header.name", title: "actor.header", must: true,
                    type: VType.option, source: 'datasources.people'
                },
                {
                    key: "leaders[, ].name", title: "actor.leaders", must: true,
                    type: VType.options, source: 'datasources.people'
                },
                {
                    key: "actors[, ].name", title: "actor.actors", must: true,
                    type: VType.options, source: 'datasources.people'
                },
                {
                    key: "domains[, ].name", title: "actor.domains", must: true,
                    type: VType.options, source: 'datasources.people'
                },
                {
                    key: "sites[, ].name", title: "actor.sites", must: true,
                    type: VType.options, source: 'datasources.people'
                },
                {
                    key: "prjowner.name", title: "actor.prjowner", must: true,
                    type: VType.option, source: 'datasources.people'
                },
                { key: "description", title: "actor.description", type: VType.texts | VType.notcolumn },
            ]
        },
        inspector: <TSys.IDatasetDict>{
            title: "tabs.inspector",
            icon: "face",
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "id", title: "inspector.id", readonly: true, type: VType.notcolumn },
                { key: "name", title: "inspector.name", must: true },
                {
                    key: "kpileader.name", title: "inspector.kpileader", must: true,
                    type: VType.option, source: 'datasources.people'
                },
                {
                    key: "kpimembers[, ].name", title: "inspector.kpimembers", must: true,
                    type: VType.options, source: 'datasources.people'
                },
                {
                    key: "cdtorleader.name", title: "inspector.cdtorleader", must: true,
                    type: VType.option, source: 'datasources.people'
                },
                { key: "description", title: "inspector.description", type: VType.texts | VType.notcolumn },
            ]
        }
    }

    readonly auditheaders = {
        toaudit(dict: Dict, executing: boolean): IDatasetHeader {
            return {
                key: '', title: 'audit.toaudit', type: VType.label,
                invisible: (app: AppService, result: AuditResult): boolean => {
                    const { auditting } = AStatus, { status, workitem: { type } } = result, { auth: { me: { priviledges: mep } } } = app;
                    const priviledge = type == WType.supervise ? mep.supervise?.supervise?.superviseaudit : mep.audit?.audit?.executeaudit
                    return !(!executing && status == auditting && priviledge);
                }
            }
        },

        status(dict: Dict, executing: boolean): IDatasetHeader {
            return {
                key: "audit.status", title: 'audit.audit.status', type: VType.notcolumn | VType.enum, source: dict.AuditStatus,
                readonly: (app: AppService, result: AuditResult): boolean => {
                    const { auditting } = AStatus, { status, workitem: { type } } = result, { auth: { me: { priviledges: mep } } } = app;
                    const priviledge = type == WType.supervise ? mep.supervise?.supervise?.superviseaudit : mep.audit?.audit?.executeaudit
                    return !(!executing && status == auditting && priviledge);
                }
            }
        },

        comment(dict: Dict, executing: boolean): IDatasetHeader {
            return {
                key: "audit.comment", title: 'audit.audit.comment', type: VType.notcolumn | VType.texts,
                readonly: (app: AppService, result: AuditResult): boolean => {
                    const { auditting } = AStatus, { status, workitem: { type } } = result, { auth: { me: { priviledges: mep } } } = app;
                    const priviledge = type == WType.supervise ? mep.supervise?.supervise?.superviseaudit : mep.audit?.audit?.executeaudit
                    return !(!executing && status == auditting && priviledge);
                }
            }
        },

        callback(dict: Dict, executing: boolean): IDatasetHeader {
            return {
                key: "callback", title: 'audit.audit.callback', type: VType.notcolumn | VType.bool,
                invisible: (app: AppService, result: AuditResult): boolean => {
                    const { auditting } = AStatus, { status, people: { id: peopleid } = {} } = result;
                    return !(status == auditting && app.auth.me.id == peopleid);
                }
            }
        },

        torefine(dict: Dict, executing: boolean): IDatasetHeader {
            return {
                key: '', title: 'audit.torefine', type: VType.label,
                invisible: (app: AppService, result: AuditResult): boolean => {
                    const { nopass, callback } = AStatus, { status, people: { id: peopleid } = {} } = result;
                    return !((status == nopass || status == callback) && app.auth.me.id == peopleid);
                }
            }
        },

        value(dict: Dict, executing: boolean): IDatasetHeader {
            return {
                key: "value", title: '', type: VType.notcolumn | VType.editor,
                readonly: (app: AppService, result: AuditResult): boolean => {
                    const { nopass, callback } = AStatus, { status, people: { id: peopleid } = {} } = result;
                    return !(!status || ((status == nopass || status == callback) && app.auth.me.id == peopleid));
                }
            }
        }
    }

    readonly exec = {
        workitemexesummary: <TSys.IDatasetDict>{
            title: 'tabs.workitemexesummary',
            icon: "list_alt",
        },

        normalwssummary: <TSys.IDatasetDict>{
            title: 'tabs.normalwssummary',
            icon: "list_alt",
        },

        normalsw: <TSys.IDatasetDict>{
            title: 'tabs.normalsw',
            icon: "list_alt",
            headers: [
                { key: "id", title: 'normalsw.id', readonly: true, type: VType.notcolumn },
                { key: "records", title: 'normalsw.records', type: VType.command | VType.onlycolumn },
                { key: "project.name", title: 'normalsw.project', type: VType.onlycolumn },
                { key: "name", title: 'normalsw.name' },
                { key: "enddate", title: 'normalsw.enddate', type: VType.date },
                { key: "status", title: 'normalsw.status', type: VType.enum, source: this.AllStatus },
            ]
        },

        auditsummary: <TSys.IDatasetDict>{
            title: 'tabs.auditsummary',
            icon: "list_alt",
        },

        audit: <TSys.IDatasetDict>{
            title: 'tabs.audit',
            icon: "list_alt",
            headers: [
                { key: "id", title: 'audit.id', readonly: true, type: VType.notcolumn },
                { key: "records", title: 'audit.records', type: VType.command | VType.onlycolumn },
                { key: "status", title: 'audit.status', type: VType.enum, source: this.AllStatus },
                { key: "project.name", title: 'audit.project', type: VType.onlycolumn },
                { key: "stage.name", title: 'audit.stage', type: VType.onlycolumn },
                //{ key: "workset.name", title: 'audit.workset', type: VType.onlycolumn },
                { key: "name", title: 'audit.name' },
                { key: "type", title: 'audit.type', type: VType.enum, source: this.WSProperties },
                { key: "dept.name", title: 'audit.deptname' },
                { key: "enddate", title: 'audit.enddate', type: VType.date },
            ]
        },

        supervisesummary: <TSys.IDatasetDict>{
            title: 'tabs.supervisesummary',
            icon: "list_alt",
        },

        supervise: <TSys.IDatasetDict>{
            title: 'tabs.supervise',
            icon: "list_alt",
            headers: [
                { key: "id", title: 'audit.id', readonly: true, type: VType.notcolumn },
                { key: "records", title: 'supervise.records', type: VType.command | VType.onlycolumn },
                { key: "projectplan.name", title: 'audit.projectplan', type: VType.onlycolumn },
                { key: "project.name", title: 'audit.project' },
                { key: "name", title: 'supervise.name' }
            ]
        },

        superviseaudit: <TSys.IDatasetDict>{
            title: 'tabs.superviseaudit',
            icon: "list_alt",
            headers: [
                { key: "hists", title: "audit.audit.hists", type: VType.onlycolumn | VType.command },
                { key: "project.plan.name", title: 'audit.projectplan', type: VType.onlycolumn | VType.text },
                { key: "project.name", title: 'audit.project', type: VType.onlycolumn | VType.text },
                { key: "people.name", title: 'supervise.people', type: VType.onlycolumn | VType.text },
                { key: "date", title: 'supervise.date', type: VType.onlycolumn | VType.date },
                { key: "audit.status", title: 'audit.audit.status', type: VType.onlycolumn | VType.enum, source: this.AuditStatus },
                { key: "audit.people.name", title: 'audit.audit.people', type: VType.onlycolumn | VType.text },
                { key: "audit.date", title: 'audit.audit.date', type: VType.onlycolumn | VType.date },

                { key: "id", title: 'audit.id', type: VType.notcolumn, readonly: true },
                this.auditheaders.toaudit(this, false),
                this.auditheaders.status(this, false),
                this.auditheaders.comment(this, false),
                this.auditheaders.torefine(this, false),
                this.auditheaders.value(this, false)
            ]
        },

        executeaudit: <TSys.IDatasetDict>{
            title: 'tabs.executeaudit',
            icon: "list_alt",
            headers: [
                { key: "hists", title: "audit.audit.hists", type: VType.onlycolumn | VType.command },
                { key: "project.plan.name", title: 'audit.projectplan', type: VType.onlycolumn | VType.text },
                { key: "project.name", title: 'audit.project', type: VType.onlycolumn | VType.text },
                { key: "stage.name", title: 'stage.name', type: VType.onlycolumn | VType.text },
                { key: "workitem.name", title: 'workitem.name', type: VType.onlycolumn | VType.text },
                { key: "people.name", title: 'execute.people', type: VType.onlycolumn | VType.text },
                { key: "date", title: 'execute.date', type: VType.onlycolumn | VType.date },
                { key: "audit.status", title: 'audit.audit.status', type: VType.onlycolumn | VType.enum, source: this.AuditStatus },
                { key: "audit.people.name", title: 'audit.audit.people', type: VType.onlycolumn | VType.text },
                { key: "audit.date", title: 'audit.audit.date', type: VType.onlycolumn | VType.date },

                { key: "id", title: 'audit.id', type: VType.notcolumn, readonly: true },
                this.auditheaders.toaudit(this, false),
                this.auditheaders.status(this, false),
                this.auditheaders.comment(this, false),
                this.auditheaders.callback(this, false),
                this.auditheaders.torefine(this, false),
                this.auditheaders.value(this, false),
            ]
        },

        problem: <TSys.IDatasetDict>{
            title: 'tabs.problem',
            icon: "list_alt",
            headers: [
                { key: "id", title: 'problem.id', readonly: true, type: VType.notcolumn },
                { key: "records", title: 'supervise.detail', type: VType.command | VType.onlycolumn },
                { key: "project.name", title: 'problem.project', readonly: true },
                { key: "auditid", title: 'problem.auditid', readonly: true },
                { key: "problem", title: 'problem.problem', readonly: true },
                { key: "date", title: 'problem.date', readonly: true, type: VType.date },
                { key: "fixed", title: 'problem.fixed', type: VType.bool },
            ]
        },

        projectworkitems: <TSys.IDatasetDict>{
            title: 'tabs.projectworkitems',
            icon: "list_alt",
            headers: [
                {
                    key: "records",
                    title: 'projectworkitems.records',
                    type: VType.command | VType.onlycolumn,
                    source: forwardRef(() => ResultsComponent)
                },
                { key: "processpath", title: 'projectworkitems.processpath', readonly: true },
                { key: "name", title: 'projectworkitems.name', readonly: true },
                {
                    key: "type", title: 'projectworkitems.type',
                    type: VType.enum, source: this.WSProperties
                },
                { key: "enddate", title: 'projectworkitems.enddate', readonly: true, type: VType.date },
                { key: "status", title: 'projectworkitems.status', type: VType.enum, source: this.AllStatus },
            ]
        },

        summarypatchproject: <TSys.IDatasetDict>{
            title: 'tabs.summarypatchproject',
            icon: "visibility",
        },

        summaryongoingproject: <TSys.IDatasetDict>{
            title: 'tabs.summaryongoingproject',
            icon: "visibility",
            filterdata: [
                this.priviledges.plan?.pilotproject ? { key: "pool", title: "tabs.meditateproject", value: TPrj.Pool.meditate } : undefined,
                this.priviledges.plan?.pilotproject ? { key: "pool", title: "tabs.candidateproject", value: TPrj.Pool.candidate } : undefined,
                this.priviledges.plan?.project ? { key: "pool", title: "tabs.begunproject", value: TPrj.Pool.begun } : undefined,
                this.priviledges?.plan?.project ? { key: "pool", title: "tabs.acceptproject", value: TPrj.Pool.accept } : undefined
                //this.priviledges.plan?.inputproject ? { key: "pool", title: "tabs.inputproject", value: TPrj.Pool.coll } : undefined
            ].filter(p => !!p)
        },

        summaryprojectstage: <TSys.IDatasetDict>{
            title: 'tabs.summaryprojectstage',
            icon: "visibility",
        },

        summaryprojectworkitem: <TSys.IDatasetDict>{
            title: 'tabs.summaryprojectworkitem',
            icon: "view_list",
        },

        summarycancelproject: <TSys.IDatasetDict>{
            title: 'tabs.summarycancelproject',
            icon: "view_list",
        },

        sumworkitems: <TSys.IDatasetDict>{
            headers: [
                { key: "select", type: VType.rowselect },
                { key: "processpath", title: 'projectworkitems.processpath', readonly: true },
                { key: "name", title: 'projectworkitems.name', readonly: true },
                {
                    key: "type", title: 'projectworkitems.type',
                    type: VType.enum, source: this.WSProperties
                }
            ]
        }
    }

    readonly sys = {
        project: {
            name: 'general.title'
        },

        /*
        系统设置
        操作日志
        */

        systemconfig: <TSys.IDatasetDict>{
            title: 'tabs.settings',
            icon: "list_alt",
            headers: [
            ]
        },

        operationlog: <TSys.IDatasetDict>{
            title: 'tabs.operationlog',
            icon: "list_alt",
            headers: [
                { key: "operationtime", title: 'operationlog.operationtime', readonly: true, type: VType.onlycolumn },
                { key: "operationuser", title: 'operationlog.operationuser', type: VType.onlycolumn },
                { key: "operationitem", title: 'operationlog.operationitem', type: VType.onlycolumn },
                { key: "operationaction", title: 'operationlog.operationaction', type: VType.onlycolumn },
            ]
        },

        searchfilter: <TSys.IDatasetDict>{
            title: 'tabs.searchfilter',
            icon: "format_list_bulleted",
            headers: [
                { key: "id", title: 'searchfilter.id', readonly: true, type: VType.text },
                { key: "name", title: 'searchfilter.name', must: true, type: VType.text },
                { key: "description", title: 'searchfilter.description', type: VType.texts },
                { key: "nowyear", title: 'searchfilter.nowyear', type: VType.bool },
                { key: "start", title: 'searchfilter.start', type: VType.date, dependfield: { key: 'nowyear' }, dependvalues: [false, null, undefined] },
                { key: "end", title: 'searchfilter.end', type: VType.date, dependfield: { key: 'nowyear' }, dependvalues: [false, null, undefined] },
                { key: "plan.name", title: 'searchfilter.plan', type: VType.options, source: "datasources.projectplan" },
                { key: "dept.name", title: 'searchfilter.owndept', type: VType.options, source: "datasources.dept" },
                //{ key: "constrstatus", title: "searchfilter.constrstatus", type: VType.enums, source: AllStatus },
                //{ key: "constrtype", title: "searchfilter.constrtype", type: VType.enums, source: PrjConstrTypes },
                { key: "workitems", title: "searchfilter.workitems", children: "children", type: VType.tree, source: 'prj.projecttypes' }
            ]
        }
    }

    readonly prompt = {
        save: {
            content: {
                major: this.trans('general.saveconfirm')
            },
            caption: this.sys.project.name,
            context: {}
        },
        delete: {
            content: {
                major: this.trans('general.deleteconfirm')
            },
            caption: this.sys.project.name,
            context: {
                count: null as number
            }
        },
        reset_password: {
            content: {
                major: 'login.resetpwdhint',
                minor: 'login.resetpwdconfirm'
            },
            caption: 'login.resetpwdconfirm',
            context: {}
        },
        onetimelink_again: {
            content: {
                major: 'login.resetpwdlinkoverdue'
            },
            caption: 'login.resetpwdconfirm',
            context: {}
        },
        onetimelink_invalid: {
            content: {
                major: 'login.resetpwdlinkinvalid'
            },
            caption: 'login.resetpwdconfirm',
            context: {}
        }
    }

    readonly statuslist: Array<TSys.IStatusItem> = [
        {
            status: TPrj.IWorkitem.Status.unset,
            icon: this.getIcon(TPrj.IWorkitem.Status.unset),
            text: this.trans('sysenums.unset'),
            icontext: this.trans('sysenums.unsettext')
        },
        {
            status: TPrj.IWorkitem.Status.unknown,
            icon: this.getIcon(TPrj.IWorkitem.Status.unknown),
            text: this.trans('sysenums.unknown'),
            icontext: this.trans('sysenums.unknowntext')
        },
        {
            status: TPrj.IWorkitem.Status.notstart,
            icon: this.getIcon(TPrj.IWorkitem.Status.notstart),
            text: this.trans('sysenums.notstart'),
            icontext: this.trans('sysenums.notstarttext')
        },
        {
            status: TPrj.IWorkitem.Status.ongoing,
            icon: this.getIcon(TPrj.IWorkitem.Status.ongoing),
            text: this.trans('sysenums.ongoing'),
            icontext: this.trans('sysenums.ongoingtext')
        },
        {
            status: TPrj.IWorkitem.Status.delaying,
            icon: this.getIcon(TPrj.IWorkitem.Status.delaying),
            text: this.trans('sysenums.delaying'),
            style: 'color: red',
            icontext: this.trans('sysenums.delayingtext')
        },
        {
            status: TPrj.IWorkitem.Status.delayed,
            icon: this.getIcon(TPrj.IWorkitem.Status.delayed),
            text: this.trans('sysenums.delayed'),
            style: 'color: red',
            icontext: this.trans('sysenums.delayedtext')
        },
        {
            status: TPrj.IWorkitem.Status.finished,
            icon: this.getIcon(TPrj.IWorkitem.Status.finished),
            text: this.trans('sysenums.finished'),
            icontext: this.trans('sysenums.finishedtext')
        },
        {
            status: TPrj.IWorkitem.Status.notused,
            icon: this.getIcon(TPrj.IWorkitem.Status.notused),
            text: this.trans('sysenums.notused'),
            icontext: this.trans('sysenums.notusedtext')
        }
    ];

    constructor(app: AppService) {
        super(app);

        const { prj: { projectsummary: projectsummary }, exec: { summaryongoingproject: summaryongoingproject } } = this;
        if ((projectsummary.filterdata?.length ?? 0) <= 0) {
            delete projectsummary.filterdata;
        } else {
            projectsummary.filterdata[0].isdefault = true;
        }

        if ((summaryongoingproject.filterdata?.length ?? 0) <= 0) {
            delete summaryongoingproject.filterdata;
        } else {
            summaryongoingproject.filterdata[0].isdefault = true;
        }
    }

    getIcon(status: TPrj.IWorkitem.Status) {
        return this.StatusIcons.indexed[status]?.title;
    }

    getStatusItem(status: TPrj.IWorkitem.Status): TSys.IStatusItem {
        return this.statuslist.find(statusitem => statusitem.status == status);
    }

    getConstrTypeText(constrtype: TPrj.ConstrType): string {
        return this.PrjConstrTypes.indexed[constrtype]?.title;
    }

    getFormatObj(mime_type: string): { format: string, mime_type: string, icon: string } {
        return this.xfiles.find(p => p.mime_type == mime_type);
    }
}

export namespace Dict {
    export type Prompts = Dict['prompt'];
    export type PromptTypes = KeyOf<Prompts>;
}