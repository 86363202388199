import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { TemplateService } from '../../utils/view/template.directive';
import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./resetpwd.component.html",
    styleUrls: ['./resetpwd.component.scss'],
    providers: [
        TemplateService
    ]
})
export class ResetpwdComponent implements OnInit, AfterViewInit {
    constructor(
        public dialogRef: MatDialogRef<ResetpwdComponent>,
        public tpls: TemplateService,
        public auth: AuthService,
        public app: AppService
    ) {

    }

    ngOnInit() {
        this.auth.form.reset();
        this.auth.form.mobile = this.auth.me.mobile;
    }

    ngAfterViewInit() {
    }

    changePassword() {
        const async = this.auth.changePassword();
        if (_.isBoolean(async)) return async;

        const sub = async.subscribe(
            (value) => {
                sub.unsubscribe();
                if (_.isBoolean(value) && value) {
                    this.dialogRef.close(value);
                }
            }, (error) => {
                sub.unsubscribe();
            }
        )
    }

    static open(dialog: MatDialog): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
        }

        return dialog.open(ResetpwdComponent, config)
    }
}
