import { LegendComponentOption, SeriesOption, TitleComponentOption, YAXisComponentOption } from 'echarts';
import { Component, AfterViewInit, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { isNull, isUndefined } from 'lodash';
import { Subscription } from 'rxjs';

import { Prj as TPrj, Sys as TSys, Exec as TExec } from '../../../application/service/backface/types';
import { ProjectListComponent } from '../../../projectlist/view/projectlist.component';
import { ProblemListComponent } from '../../../problemlist/view/problemlist.component';
import { ResultsComponent } from '../../../results/view/results.component';
import { Backface } from '../../../application/service/backface/config';
import { AppService } from '../../../application/service/app.service';
import { Report } from '../../../application/service/backface/report';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { _JSON } from '../../../utils/libs/polyfill/json';
import { Property } from '../../../utils/libs/property';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'reportsupervise, [reportsupervise]',
    templateUrl: "./reportsupervise.component.html",
    styleUrls: ['./reportsupervise.component.scss']
})
export class ReportSuperviseComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of<ReportSuperviseComponent & {
        translatesub?: Subscription
    }>(this).values;

    readonly noinputtxt: boolean = true;
    
    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    readonly prjcountsuper: TSys.ICardSumItem = {
        title: 'report.refproject',
        year: this.app.dict.currentYear,
        count: 0,
    };

    readonly supervisecount: TSys.ICardSumItem = {
        title: 'report.supervisecount',
        year: this.app.dict.currentYear,
        count: 0,
    };

    readonly procount: TSys.ICardSumItem = {
        title: 'report.problemcount',
        year: this.app.dict.currentYear,
        count: 0,
    };

    readonly unfixprocount: TSys.ICardSumItem = {
        title: 'report.problemnotfixed',
        year: this.app.dict.currentYear,
        warning: true,
        count: 0
    };

    readonly checkoption = new Report.ReportTemplate().ReportTemplateMixedLineBar(this.app.report.supervisemixlinebarcolors, 1, 3);
    private dataMap: Map<string, object> = new Map<string, object>();

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    constructor(
        public app: AppService,
        public dialog: MatDialog,
        public router: ActivatedRoute,
        protected httpClient: HttpClient,
    ) {
        super(router, 'reportsupervise');
    }

    ngOnInit() {
        this.toolbar?.bindsource(this);

        const legendData = (this.checkoption.legend as LegendComponentOption).data;
        const title = this.checkoption.title as TitleComponentOption;
        const yAxis = this.checkoption.yAxis as YAXisComponentOption[];
        const series = this.checkoption.series as SeriesOption[];

        const { app: { lang: { report, general }, dict: { months, monthcostdefault } }, _props: props } = this;

        props.header = (
            (params) => {
                const { selectedYear } = this, { name, seriesName, value } = params, { year, unit } = general;
                return `${selectedYear}${year}/${name}/${seriesName}/${value}${unit}`
            }
        );

        title.text = report.supervisesummary;

        legendData.clear();
        legendData.add(report.refproject);
        legendData.add(report.problemnotfixed);
        legendData.add(report.supervisecount);
        legendData.add(report.problemcount);

        this.checkoption.xAxis[0]['data'] = months.reduce((res, v) => {
            res.push(v.name); return res;
        }, []);

        yAxis[0]['name'] = report.refproject;
        yAxis[1]['name'] = report.problemnotfixed;
        yAxis[2]['name'] = report.supervisecount;
        yAxis[3]['name'] = report.problemcount;

        yAxis[0]['max'] = 160;
        yAxis[1]['max'] = 200;
        yAxis[2]['max'] = 300;
        yAxis[3]['max'] = 200;

        series[0]['name'] = report.refproject;
        series[1]['name'] = report.problemnotfixed;
        series[2]['name'] = report.supervisecount;
        series[3]['name'] = report.problemcount;

        series[0]['id'] = ('project');
        series[1]['id'] = ('problemnotfixed');
        series[2]['id'] = ('supervisecount');
        series[3]['id'] = ('problemcount');

        series[0]['data'] = monthcostdefault;
        series[1]['data'] = monthcostdefault;
        series[2]['data'] = monthcostdefault;
        series[3]['data'] = monthcostdefault;
    }

    ngAfterViewInit(): void {
        this.GetSummaryData();
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter.ngOnDestroy();

        const { _props: props } = this;
        props.translatesub?.unsubscribe();
        delete props.translatesub;
    }

    get maxYear(): number {
        return this.app.dict.currentYear;
    }

    set selectedYear(val: number) {
        const { toolbarcontent } = this;
        if (toolbarcontent.selectedYear == val) return;

        toolbarcontent.selectedYear = val;
        this.saveToolbarContent();

        this.GetSummaryData();
    }

    get selectedYear(): number {
        const { toolbarcontent: { selectedYear }, app: { dict: { currentYear } } } = this;
        return selectedYear ?? currentYear;
    }

    header(params: {
        seriesName: string,
        name: string,
        value: string
    }): string {
        const { _props: { header } } = this;
        return header?.(params) ?? '';
    }

    GetSummaryData() {
        this.reporter.report("/report/supervise_per_month_by_project_attribute", (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            if (!isNull(res['count']) && !isUndefined(['count'])) {
                //update count card
                this.prjcountsuper.count = res['count'].project.nonpatched ? res['count'].project.nonpatched : 0;
                this.supervisecount.count = res['count'].count ? res['count'].count : 0;
                this.procount.count = res['count'].problem.count ? res['count'].problem.count : 0;
                this.unfixprocount.count = res['count'].problem.tofix ? res['count'].problem.tofix : 0;

                this.prjcountsuper.year = this.selectedYear;
                this.supervisecount.year = this.selectedYear;
                this.procount.year = this.selectedYear;
                this.unfixprocount.year = this.selectedYear;
            }

            if (!isNull(res['month']) && !isUndefined(res['month'])) {
                //update stage
                const series = this.checkoption.series as SeriesOption[];
                this.dataMap.clear();

                for (let index = 1; index <= 12; index++) {
                    var month = this.selectedYear + '-';
                    if (index < 10) month += '0';
                    month += index.toString();
                    var obj = res['month'].find(m => m.month == month);
                    if (isNull(obj) || isUndefined(obj)) {
                        series[0]['data'][index - 1] = 0;
                        series[1]['data'][index - 1] = 0;
                        series[2]['data'][index - 1] = 0;
                        series[3]['data'][index - 1] = 0;
                        continue;
                    };

                    series[0]['data'][index - 1] = obj['count'].project.nonpatched ? obj['count'].project.nonpatched : 0;
                    series[1]['data'][index - 1] = obj['count'].problem.tofix ? obj['count'].problem.tofix : 0;
                    series[2]['data'][index - 1] = obj['count'].count ? obj['count'].count : 0;
                    series[3]['data'][index - 1] = obj['count'].problem.count ? obj['count'].problem.count : 0;
                    if (series[0]['data'][index - 1] != 0 && this.dataMap) {
                        var key = index + 'project';
                        this.dataMap.set(key, obj['project']);
                    }
                }

                Report.RefreshEchart("reportsuperviseoption", this.checkoption);
            }
        }).query({
            "year": [this.selectedYear, this.selectedYear]
        });
    }

    onClickCharBar(params): void {
        if (params?.value <= 0) return;

        const title = this.checkoption.title as TitleComponentOption;
        const header = this.header(params);
        const sid = params?.seriesId;

        if (sid == 'project') {
            var idlist = [];
            if (this.dataMap) {
                var key = (params.dataIndex + 1) + params.seriesId;
                var obj = this.dataMap.get(key);
                if (obj) {
                    idlist = (obj as Array<Object>);
                }
            }

            ProjectListComponent.open(this.dialog, {
                prjidlist: idlist,
                title: title.text,
                header: header,
            })
        }

        if (sid == 'problemcount' || sid == 'problemnotfixed') {
            var month = params.dataIndex + 1;

            var start = this.selectedYear + "-";
            if (month < 10) start += "0";
            start += month.toString() + "-01 00:00:00";

            var end = this.selectedYear + "-";
            if ((month + 1) < 10) end += "0";
            if (month == 12) {
                end += month.toString() + "-31 23:59:59";
            } else {
                end += (month + 1).toString() + "-01 00:00:00";
            }

            ProblemListComponent.open(this.dialog, {
                title: title.text,
                header: header,
                prjidlist: null,
                date: [start, end],
                fixed: null,
                type: [sid == 'problemcount' ? 'newfound' : 'notfix']
            })
        }

        if (sid == 'supervisecount') {
            var month = params.dataIndex + 1;

            var start = this.selectedYear + "-";
            if (month < 10) start += "0";
            start += month.toString() + "-01 00:00:00";

            var end = this.selectedYear + "-";
            if ((month + 1) < 10) end += "0";
            if (month == 12) {
                end += month.toString() + "-31 23:59:59";
            } else {
                end += (month + 1).toString() + "-01 00:00:00";
            }

            this.reporter.report("/entity/results/retrieve", (res: TExec.IResult[] = []) => {
                const { app, dialog } = this;
                ResultsComponent.open(dialog, {
                    title: title.text + "/" + header,
                    object: this.app.prj.workitems.find(ws => ws.type == TPrj.IWorkitem.Type.supervise),
                    results: Report.buildResult(app, res)
                })
            }).query({
                "workitem": '77',
                "date": [start, end]
            });
        }
    }
}
