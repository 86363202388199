<div class="h-100 w-100 m-0 p-0 d-flex flex-column justify-content-start align-items-stretch">
  <div mat-dialog-title class="d-flex flex-row flex-nowrap justify-content-start align-items-center">
    <div class="flex-fill">{{ data?.object?.fullName }}</div>
    <label class="btn btn-sm" mat-dialog-close>
      <i class="fa fa-close fa-fw"></i>
    </label>
  </div>

  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

  <mat-dialog-content class="mat-typography position-relative px-0 flex-fill">
    <gov-table #govtablemodels class="overflow-hidden h-100" (onCommandTemplate)="
        $event.config.disableClose = false;
        $event.config.data.title = 'general.operate';
        $event.template = component
      " [datasets]="currentmodule"></gov-table>
  </mat-dialog-content>

  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
  <div mat-dialog-actions class="d-flex flex-row flex-nowrap justify-content-center align-items-stretch">
    <button mat-button [mat-dialog-close]="getSelected()" cdkFocusInitial>
      {{ "general.confirm" | translate }}
    </button>
  </div>
</div>