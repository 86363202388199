<div class="sidebar-wrapper flex-fill" style="overflow-x: hidden; overflow-y: auto">
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let navitem of app.mainnavs" class="{{ navitem.class }} nav-item">
      <a *ngIf="navitem.path!='systemset'" class="d-flex flex-row nav-link m-0 py-2 pl-3 pr-4"
        [routerLink]="[navitem.path]">
        <i class="material-icons">{{ navitem.icon }}</i>
        <p>{{ navitem.title | translate }}</p>
      </a>
      <a *ngIf="navitem.path=='systemset'" class="d-flex flex-row nav-link m-0 py-2 pl-3 pr-4" (click)="onSysInfo()">
        <i class="material-icons">{{ navitem.icon }}</i>
        <p>{{ navitem.title | translate }}</p>
      </a>
    </li>
  </ul>
</div>