<div class="h-100 w-100 m-0 p-0 d-flex flex-column align-items-stretch justify-content-start">
  <h2 mat-dialog-title>{{'general.maptag' | translate}}</h2>
  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

  <mat-dialog-content class="mat-typography position-relative px-0 flex-fill">
    <ngx-amap [zoom]="10" [scrollWheel]="true" [resizeEnable]="true" [center]="district?.center"
      (naReady)="amap = $event" mapStyle="amap://styles/whitesmoke" class="h-100 w-100">
      <amap-tool-bar></amap-tool-bar>
      <amap-polygon [path]="district?.boundaries" [strokeColor]="'#256edc'" [fillColor]="'#256edc'" [fillOpacity]="0.15"
        [strokeOpacity]="1" [strokeWeight]="2">
      </amap-polygon>
      <amap-info-window *ngIf="projectLocater" [offset]="infoWindowOffset"
        [position]="projectLocater?.position?.position" [content]="projectLocater?.position?.address"
        [isOpen]="projectLocater?.position" [isCustom]="false" style="padding: 2px">
        <div>{{ projectLocater?.position?.address }}</div>
      </amap-info-window>
    </ngx-amap>

    <div class="maplocate h-80 w-25 d-flex flex-column justify-content-between align-items-stretch">
      <input [hidden]="!projectLocater" id="tipinput" class="w-100" value="{{ inputVar }}"
        placeholder="{{'general.mapinhint' | translate}}" />
      <div [hidden]="!projectLocater?.locations" class="map-panel h-100 w-100" id="locationpanel"></div>
    </div>
  </mat-dialog-content>

  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="saveClick()">
      {{'general.save' | translate}}
    </button>
  </mat-dialog-actions>
</div>