import { isArray } from 'lodash';

import { DeepPartial } from './types/mixins';
import { _JSON } from './polyfill/json';
import { Property } from './property';
import { Extend } from './extends';
import { Unique } from './unique';

const _CollectionSymbol: symbol = Unique.symbol('COLLECTION');

function getCollectionBag(obj: any) {
    const props = Property.Of(obj) || {};
    return props[_CollectionSymbol] = props[_CollectionSymbol] ?? {};
}

function setall<T, TP, IA>(col: Collection<T, TP, IA>, items: T[]) {
    (items || []).forEach((item) => {
        item && (getCollectionBag(item).collection = col);
        Collection.Bound.Of<ICollection<T, TP, IA>>(item).bound?.(col, item);
    })
}

function rmall<T, TP, IA>(col: Collection<T, TP, IA>, items: T[]) {
    (items || []).forEach((item) => {
        item && (delete getCollectionBag(item).collection);
        Collection.Bound.Of<ICollection<T, TP, IA>>(item).unbound?.(col, item);
    })
}

export interface ICollection<T, TParent = T, IAttr = T> extends Array<T> {
    parent: TParent;

    init(parent: TParent, val?: T);
    init(parent: TParent, val?: T[]);

    where(attrs: DeepPartial<T> | ((item: T) => boolean)): T[];
    firstOf(attrs: DeepPartial<T> | ((item: T) => boolean)): T;
}

export class Collection<T, TParent = T, IAttr = T> extends Array<T> implements ICollection<T, TParent, IAttr> {
    constructor(
        public parent: TParent,
        val?: T | T[]
    ) {
        super();

        Extend.setProto(this, new.target.prototype);
        this.recreate(val);
    }

    _onAddRemoved({ removed, added }: { removed: T[]; added: T[]; }) {
        super._onAddRemoved?.({ added, removed });
        removed && rmall(this, removed);
        added && setall(this, added);
    }

    init(parent: TParent, val?: T);
    init(parent: TParent, val?: T[]);
    init(parent: TParent, val?: T | T[]) {
        this.parent = parent;
        this.recreate(val);
    }

    firstOf(attrs: DeepPartial<T> | ((item: T) => boolean)): T {
        return super.firstOf(attrs);
    }

    where(attrs: DeepPartial<T> | ((item: T) => boolean)): T[] {
        return super.where(attrs);
    }

    createnew(val: IAttr | T, at?: number): T {
        return super.create(this.build(val || {} as IAttr), at);
    }

    create(val: IAttr | T, at?: number): T;
    create(val: (IAttr | T)[], at?: number): T[];
    create(val: IAttr | T | (IAttr | T)[], at?: number): T | T[];
    create(val: IAttr | T | (IAttr | T)[], at?: number): T | T[] {
        return super.create(this.build(val || {} as IAttr), at);
    }

    recreate(val?: IAttr | T): T;
    recreate(val?: (IAttr | T)[]): T[];
    recreate(val?: IAttr | T | (IAttr | T)[]): T | T[];
    recreate(val?: IAttr | T | (IAttr | T)[]): T | T[] {
        return super.recreate(val && this.build(val) || []);
    }

    build(val?: IAttr | T): T;
    build(val?: (IAttr | T)[]): T[];
    build(val?: IAttr | T | (IAttr | T)[]): T | T[];
    build(val: IAttr | T | (IAttr | T)[]): T | T[] {
        if (!val) return;

        return isArray(val) ? val.map(
            (d) => this.construct(
                d || {} as IAttr
            )
        ) || [] : this.construct(
            val || {} as IAttr
        );
    }

    construct(val: IAttr | T): T {
        return val as T;
    }

    static get [Symbol.species]() {
        return Array;
    }
}

export namespace Collection {
    export type All<T extends ICollection<any, any, any>> = T extends ICollection<infer V, infer P, infer A> ? [V, P, A] : never;
    export type Parent<T extends ICollection<any, any, any>> = T extends ICollection<any, infer P, any> ? P : never;
    export type Value<T extends ICollection<any, any, any>> = T extends ICollection<infer V, any, any> ? V : never;
    export type Attr<T extends ICollection<any, any, any>> = T extends ICollection<any, any, infer A> ? A : never;

    export function Of<
        T extends ICollection<TV, TP, IA>,
        TP = Parent<T>,
        TV = Value<T>,
        IA = Attr<T>
    >(item: TV): T {
        return item && getCollectionBag(item).collection;
    }

    export function is<
        T extends ICollection<TV, TP, IA>,
        TP = Parent<T>,
        TV = Value<T>,
        IA = Attr<T>
    >(item: any): item is ICollection<TV, TP, IA> {
        return item instanceof Collection;
    }

    export namespace Parent {
        export function Of<
            T extends ICollection<TV, TP, IA>,
            TP = Parent<T>,
            TV = Value<T>,
            IA = Attr<T>
        >(item: TV): TP {
            return item && getCollectionBag(item).collection?.parent;
        }
    }

    export namespace Bound {
        export function Of<
            T extends ICollection<TV, TP, IA>,
            TP = Parent<T>,
            TV = Value<T>,
            IA = Attr<T>
        >(item: TV) {
            const bindBag = item && getCollectionBag(item);
            if (!bindBag) return null;

            const hook: {
                unbound?: (coll: T, item: TV) => TV,
                bound?: (coll: T, item: TV) => TV,
            } = (bindBag.onBind = bindBag.onBind || {
                unbound: null,
                bound: null,
            })

            return hook;
        }
    }

}
