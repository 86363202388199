
/*
// get the value of T.K
    type Lookup<T, K extends keyof any, Else = never> = K extends keyof T ? T[K] : Else;

    type Last<T extends any[]> = ((...t: T) => void) extends ((x: any, ...u: infer U) => void) ? U : never;

    type Func1 = (arg: any) => any;

    type ArgType<F, Else = never> = F extends (arg: infer A) => any ? A : Else;

    type AsChain<F extends [Func1, ...Func1[]], G extends Func1[] = Last<F>> = { [K in keyof F]: (arg: ArgType<F[K]>) => ArgType<Lookup<G, K, any>, any> };

    type LastIndexOf<T extends any[]> = ((...x: T) => void) extends ((y: any, ...z: infer U) => void) ? U['length'] : never


    declare function flow<F extends [Func1, ...Func1[]]>(
        ...f: F //& AsChain<F>
    ): (arg: ArgType<F[0]>) => ReturnType<F[LastIndexOf<F>]>;

    const stringToString = flow(
        (x: string) => x.length,
        (y: number) => y + "!"
    );

    const str = stringToString("hey"); // it's a string

    const tooFewParams = flow(); // error

    const badChain = flow(
        (x: number) => "string",
        (y: string) => false,
        (z: number) => class { }
    ); // error, boolean not assignable to number
*/

/**
    type λ<Exp extends Func, Vars extends unknown[]> = (Exp & {
        variables: Vars;
    })["expression"];

    type Func = {
        variables: Func[];
        expression: unknown;
    }

    type Var<F extends Func, X extends number> = F["variables"][X];

    interface Not extends Func {
        expression: λ<Var<this, 0>, [False, True]>
    }

    interface And extends Func {
        expression: λ<Var<this, 0>, [Var<this, 1>, Var<this, 0>]>
    }

    interface True extends Func {
        expression: Var<this, 0>;
    }

    interface False extends Func {
        expression: Var<this, 1>;
    }

    type Test1 = λ<Not, [True]>;        // False
    type Test2 = λ<And, [True, False]>; // False
    type Test3 = λ<And, [True, True]>;  // True
    type Test4 = λ<And, [False, True]>; // False
    type Test5 = λ<And, [True, True]>;  // True
 */

import { Class, KeyOf, TypeParam, TYPECF, TYPECFO, Valid, TypeReturn } from './types/mixins';
import { Tuple } from './types/tuple';
import { __extends } from 'tslib';
import { forEach } from 'lodash';

const Tools = (function () {
    var set__proto__ = function <TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB): TD {
        set__proto__ = (
            Object.setPrototypeOf as (<TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB) => TD)
        ) || (
                { __proto__: [] } instanceof Array && function <TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB): TD {
                    (driven as any).__proto__ = base;
                    return driven;
                }
            ) || (
                function <TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB): TD {
                    for (var key in base) {
                        if (base.hasOwnProperty(key)) {
                            (driven as any)[key] = base[key];
                        }
                    }

                    return driven;
                }
            );

        return set__proto__(driven, base);
    };


    var get__proto__ = function <TD extends TYPECFO>(cls_obj: TD): TYPECFO {
        get__proto__ = (
            Object.getPrototypeOf as (<TD extends TYPECFO>(cls_obj: TD) => TYPECFO)
        ) || (
                { __proto__: [] } instanceof Array && function <TD extends TYPECFO>(cls_obj: TD): TYPECFO {
                    return (<any>cls_obj).__proto__;
                }
            ) || (
                function <TD extends TYPECFO>(cls_obj: TD): TYPECFO {
                    return (<any>cls_obj).__proto__;
                }
            )

        return get__proto__(cls_obj);
    };


    var set_base = function <TD extends TYPECF, TB extends TYPECF>(driven: TD, base: TB): TD {
        function clsBase() { this.constructor = driven; }
        set__proto__(driven, base);

        const prototype = (
            base === null ? Object.create(base) : (clsBase.prototype = base.prototype, new clsBase())
        );

        Object.defineProperties(prototype, Object.getOwnPropertyDescriptors(driven.prototype));
        return driven.prototype = prototype, driven;
    }

    return {
        setProto: function <TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB): TD {
            return set__proto__(driven, base)
        },
        getProto: function <TD extends TYPECFO>(cls_obj: TD): TYPECFO {
            return get__proto__(cls_obj);
        },
        setBase: function <TD extends TYPECF, TB extends TYPECF>(driven: TD, base: TB): TD {
            return set_base(driven, base)
        }
    }
})();




namespace internal {
    function getOwnPropertyDescriptors<T>(o: T, remove: {
        [k in "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" | "all"]?: string[];
    } = {
            function: [
                'ctorParameters',
                'arguments',
                'length',
                'caller',
                'name',
            ],
            all: [
                'constructor',
                'prototype',
                '__proto__',
            ]
        }
    ): { [P in keyof T]?: TypedPropertyDescriptor<T[P]> } & { [x: string]: PropertyDescriptor } {
        let props: { [P in keyof T]?: TypedPropertyDescriptor<T[P]> } & { [x: string]: PropertyDescriptor } = Object.getOwnPropertyDescriptors(o) || {};

        [...(remove[typeof o] || []), ...(remove['all'] || [])].forEach((k) => {
            delete props[k];
        });

        return props;
    }

    function clone<T>(o: T, init?: Function): T {
        let ret = (typeof o == 'function' ? function (...args: any[]) {
            init?.apply(this, args);
            o.apply(this, args);
        } : {}) as T;

        return Object.defineProperties(ret, getOwnPropertyDescriptors(o, {}));
    }

    function assign<TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB, ownonly: boolean): TD {
        if (!driven) return driven;

        for (var p in base) {
            if (!ownonly || base.hasOwnProperty(p)) {
                (<any>driven)[p] = base[p];
            }
        }

        return driven;
    }

    function createPrototype<TD extends TYPECF, TB extends TYPECFO>(base: TB, driven_constructor?: TD) {
        if (typeof base == 'function') {
            if (Object.create) {
                base = Object.create(base.prototype);
            } else {
                let ctor = function () { }
                setPrototype(ctor, base.prototype);
                base = new ctor();
            }
        }

        if (driven_constructor) {
            base.constructor = driven_constructor;
        }

        return base;
    }

    export function setPrototype<TD extends TYPECF>(cf: TD, prototype: Object): TD {
        try {
            cf.prototype = prototype;
            return cf;
        } catch (e) {
        }

        let pt: PropertyDescriptor = Object.getOwnPropertyDescriptor(cf, 'prototype');

        if (pt) {
            try {
                if (pt.get) {
                    pt.get = function () {
                        return prototype;
                    }
                } else {
                    pt.value = prototype;
                }

                return Object.defineProperty(cf, 'prototype', pt);
            } catch (e) {
            }
        }

        try {
            Object.defineProperties(cf.prototype, Object.getOwnPropertyDescriptors(prototype));
            return cf;
        } catch (e) {

        }

        assign(cf.prototype, prototype, false);
        return cf;
    }

    export function getPrototype<TD extends TYPECF>(cf: TD): Object {
        return cf.prototype;
    }




    /**
     * function or class type structure:
     *  function
     *   |    |->prototype (object)
     *   |          |->__proto__ (object)
     *   |                 |->__proto__ (object)
     *   |                        |-> .... (object)
     *   |                              |-> (Object | Function | Array | ...).prototye
     *   |->__proto__ (function | object) 
     * 
     *  none function
     *   |->__proto__(object)
     *          |->__proto__ (object)
     *                 |->__proto__ (object)
     *                        |-> .... (object)
     *                              |-> (Object | Function | Array | ...).prototye
     */
    function getProtoOf<TD extends TYPECFO>(o: TD, is_prototype_chain: boolean): TYPECFO {
        if (is_prototype_chain && typeof o === 'function') {
            return getPrototype(o as TYPECF);
        }

        return Tools.getProto(o);
    }

    function setProtoOf<TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB, is_prototype_chain: boolean): TD {
        if (!driven) return driven;

        if (is_prototype_chain && typeof driven == 'function') {
            // has assured that the driven is an class or function.
            return setPrototype(driven as TYPECF, createPrototype(base, driven as TYPECF)) as TD;
        }

        let driven_cls = Tools.getProto(driven.constructor) as TYPECF;
        return Tools.setProto(driven, is_prototype_chain ? createPrototype(base, driven_cls) : base);
    };

    /**
     *                                               O(Object)
     *                                                ↑  ↑  ↑
     *                                                |  |  |
     *                   F(FunctionBase).__proto__----┘  |  |
     *                     ↑  ↑  ↑  ↑                    |  |
     *                     |  |  |  |                    |  |
     * Function.__proto__--┘  |  |  |                    |  |
     *                        |  |  |                    |  |
     * Function.prototype-----┘  |  |                    |  |
     *                           |  |                    |  |
     * Array.__proto__-----------┘  |                    |  |
     *                              |                    |  |
     * Object.__proto__-------------┘                    |  |
     *                                                   |  |
     *                         O(Array<T>).__proto__-----┘  |
     *                              ↑                       |
     * Array.prototype--------------┘                       |
     *                                                      |
     * Object.prototype-------------------------------------┘
     */
    const TermPrototypes = [
        Object.prototype,
        null,
    ];

    const TermProtos = [
        getProtoOf(Function, false),
        Object.prototype,
        null,
    ]

    function Terms(is_prototype_chain: boolean): any[] {
        return is_prototype_chain ? TermPrototypes : TermProtos;
    }

    function getProtoRootOf<TD extends TYPECFO>(target: TD, recreate: boolean, is_prototype_chain: boolean): {
        is_prototype: boolean,
        recreated: boolean,
        chain: any[],
        root: any
    } {
        var pre: any = null, cur: any = target;
        var targets = [];

        while (!Terms(is_prototype_chain).includes(cur)) {
            var proto = getProtoOf(cur, is_prototype_chain);

            if (recreate) {
                cur = clone(cur);
                pre && setProtoOf(pre, cur, is_prototype_chain);
            }

            targets.push(pre = cur);
            cur = proto;
        };

        return {
            root: cur,
            chain: targets,
            recreated: recreate,
            is_prototype: is_prototype_chain
        }
    }

    function setProtoRootOf<TD extends TYPECFO, TB extends TYPECFO>(target: TD, data: TB, recreate: boolean, is_prototype_chain: boolean): TD {
        let { chain } = getProtoRootOf(target, recreate, is_prototype_chain);

        setProtoOf(chain[chain.length - 1], data, is_prototype_chain);

        // should not return target directly for may being recreated
        return chain[0];
    }

    export function extend<TD extends TYPECFO, TB extends TYPECFO>(driven: TD, base: TB, recreate: boolean): TD {
        if (typeof driven === 'function') {
            let prototype = setProtoRootOf(getPrototype(driven as TYPECF), base, recreate, true);
            if (!recreate && typeof base !== 'function') return driven;

            // need to setup the call chain.
            let ncls = clone(driven, function (...args: any[]) {
                typeof base === 'function' && (base as Function).apply(this, args);
                (driven as Function).apply(this, args);
            });

            setPrototype(ncls as TYPECF, prototype);
            if (typeof base !== 'function') {
                return <any>ncls;
            }

            let proto = setProtoRootOf(Tools.getProto(ncls), base, recreate, false);
            if (!recreate) return <any>ncls;

            return Tools.setProto(ncls, proto);
        }

        return setProtoRootOf(driven, base, recreate, false);
    }

    export function extendReadonly<TD extends TYPECFO, TB extends TYPECFO>(target: TD, data: TB, recreate: boolean): TD {
        // Clear the properties writable, we wanto keep other members rather than OwnProperties
        var props = getOwnPropertyDescriptors(data);
        for (var k in props) {
            let prop = props[k];
            delete prop.writable;
            delete prop.set;
        }

        Object.defineProperties(data, props);
        return extend(target, data, recreate);
    }

    export function extendMixProps<T extends TYPECFO>(
        base: T, is_prototype_chain: boolean,
        out?: {
            [x: string]: PropertyDescriptor
        }
    ): {
        [x: string]: PropertyDescriptor
    } {
        out = out || {};

        let { chain } = getProtoRootOf(is_prototype_chain && typeof base === 'function' ? getPrototype(base as TYPECF) : base, false, is_prototype_chain);

        chain.reverse().forEach((item) => {
            forEach(getOwnPropertyDescriptors(item), (val, key) => {
                try {
                    out[key] = val;
                } catch (e) { }
            });
        });

        return out;
    }
}

/**
   Get the merged type, including from return type of function
 */
type mergetype = MergeValueTypes<[string, Object, () => { foo: string } | { bar: number }, { baz: boolean }]>;
export type MergeValueTypes<T extends TYPECFO[]> = (_MergeValueTypes<T>);
type _MergeValueTypes<T extends TYPECFO[]> = (
    {
        [K in KeyOf<T>]: (x: TypeReturn<T[K]>) => void
    } extends {
        [K in KeyOf<T>]: (x: infer V) => void
    } ? V : never
);

/**
   Get the first/last/longer none empty arguments of function as the final arguments, 
   empty argument will be returned if all functions' argument are empty.
 */
type bestargs0 = (...args: BestArgs<[]>) => any;
type bestargs1 = (...args: BestArgs<[string]>) => any;
type bestargs2 = (...args: BestArgs<[string, { new(): void }]>) => any;
type bestargsn = (...args: BestArgs<[string, { new(): void }, { new(a: number, b: Object): void }, () => void, { new(a: number, b: string): void }, (a: number, b: string, c: Object) => void, (a: number) => void], BestArgs.Option.First>) => any;
export type BestArgs<T extends TYPECFO[], Opt extends BestArgs.Option = BestArgs.Option.Longer> = (_BestArgs<Tuple.Length<T>, Opt, T>);
type _BestArgs<
    N extends number,
    Opt extends BestArgs.Option,
    T extends TYPECFO[],
    _PL extends any[] = [],
    _PC extends any[] = TypeParam<Tuple.First<T, []>, []>,
    _TR extends TYPECFO[] = Tuple.RemoveFirst<T, []>,

    RES_FST = {
        stop: _PC;
        next: _BestArgs<N, Opt, _TR, _PL>;
    }[N extends Valid.Index ? (Tuple.IsEmpty<_PC> extends false ? 'stop' : (Tuple.IsEmpty<T> extends true ? 'stop' : 'next')) : 'stop'],

    RES_LST = {
        stop: Tuple.IsEmpty<_PC> extends false ? _PC : _PL;
        next: _BestArgs<N, Opt, _TR, Tuple.IsEmpty<_PC> extends false ? _PC : _PL>;
    }[N extends Valid.Index ? (Tuple.IsEmpty<T> extends true ? 'stop' : 'next') : 'stop'],

    RES_MAX = {
        stop: Tuple.Longer<_PL, _PC>;
        next: _BestArgs<N, Opt, _TR, Tuple.Longer<_PL, _PC>>
    }[N extends Valid.Index ? (Tuple.IsEmpty<T> extends true ? 'stop' : 'next') : 'stop']

    > = Opt extends BestArgs.Option.First ? RES_FST : (Opt extends BestArgs.Option.Last ? RES_LST : RES_MAX);

export namespace BestArgs {
    export enum Option {
        Longer = 1,
        First = 2,
        Last = 3,
    }
}

const global = { Object }

export namespace Extend {

    type MixTypes<
        Bases extends TYPECFO[],
        _TInstance extends Object = MergeValueTypes<Bases>,
        _TArgs extends any[] = BestArgs<Bases>,
        _TClass extends Class = {
            new(...args: _TArgs): _TInstance;
            prototype: _TInstance;
        }
        > = {
            args: _TArgs,
            rets: _TInstance,
            cls: _TClass
        };

    export function Mix<Bases extends TYPECFO[]>(...bases: Bases): MixTypes<Bases>['cls'] {
        let prototypes: { [x: string]: PropertyDescriptor } = {};
        let protos: { [x: string]: PropertyDescriptor } = {};
        let ctors: Class[] = [];

        (bases || []).reverse().forEach(base => {
            // mix prototype chain.
            prototypes = internal.extendMixProps(base, true, prototypes);

            // mix __proto__ chain.
            if (typeof base === 'function') {
                protos = internal.extendMixProps(base, false, protos);
                ctors.push(base as Class);
            }
        });

        // create new class to inherit mixed prototype and __proto__.
        let mixclass = class {
            constructor(...args: MixTypes<Bases>['args']) {
                ctors.forEach((ctor) => {
                    ctor.apply(this, args);
                }, this)
            }
        }

        internal.setPrototype(mixclass, global.Object.defineProperties({}, prototypes));
        global.Object.defineProperties(mixclass, protos);
        return mixclass as any as (MixTypes<Bases>['cls']);
    }

    type ExtendTypes<
        Drivern extends TYPECF, Bases extends TYPECFO[],
        _Bases extends TYPECFO[] = Tuple.Headpend<Drivern, Bases>
        > = MixTypes<_Bases>

    export function extend<Drivern extends TYPECF, Bases extends TYPECFO[]>(driven: Drivern, bases: Bases, recreate?: boolean): ExtendTypes<Drivern, Bases>['cls'] {
        let base: TYPECFO = null;

        bases.forEach((b) => {
            base = (base && internal.extend(base, b, recreate)) || base;
        })

        return internal.extend(driven, base, recreate) as any as (ExtendTypes<Drivern, Bases>['cls']);
    }

    export function Interface<T>(): {
        prototype: T;
        new(): T;
    } {
        return class {
            constructor() { }
        } as ({
            prototype: T;
            new(): T;
        });
    }

    export function Object<T extends Object>(obj: T) {
        return internal.extend(class { } as ({
            prototype: T;
            new(): T;
        }), obj, false);
    }

    export function Readonly<T extends Object>(obj: T) {
        return internal.extendReadonly(class { } as ({
            prototype: Readonly<T>;
            new(): Readonly<T>;
        }), obj, false);
    }

    export function IReadonly<T extends Object>() {
        return class {
            constructor(data: T) {
                internal.extendReadonly(this, data, false);
            }
        } as ({
            new(data: T): Readonly<T>;
            prototype: Readonly<T>;
        });
    }

    export function getPrototype<TD extends TYPECF>(cf: TD): Object {
        return internal.getPrototype(cf);
    }

    export function setPrototype<TD extends TYPECF>(cf: TD, prototype: Object): TD {
        return internal.setPrototype(cf, prototype);
    }

    export function getProto<TD extends TYPECFO>(driven: TD): TYPECFO {
        return Tools.getProto(driven);
    }

    export function setProto<TD extends TYPECFO, TB extends TYPECFO>(driven: TD, proto: TB): TD {
        return Tools.setProto(driven, proto);
    }

    export function getClass<TC, TO>(obj: TO): TC {
        return (obj as any).constructor as TC;
    }

    export function setBase<TD extends TYPECF, TB extends TYPECF>(driven: TD, base: TB): TD {
        return Tools.setBase(driven, base);
    }
}