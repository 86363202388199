<div class="home w-100 h-100">
  <div class="position-relative w-100 h-100">
    <ngx-amap [zoom]="10" [resizeEnable]="true" (naReady)="onMapReady($event)" class="demo-map-lg h-100 w-100"
      [center]="city_position" mapStyle="amap://styles/whitesmoke"
      mapStyle="amap://styles/01393e9fec4d62c7c362b74355e55adf">
      <amap-tool-bar></amap-tool-bar>
      <amap-polygon [path]="polygonArr" [strokeColor]="'#256edc'" [strokeOpacity]="1" [strokeWeight]="2"
        [fillColor]="'#256edc'" [fillOpacity]="0.15">
      </amap-polygon>

      <amap-marker-clusterer #currentMarkerCluster="marker-clusterer" [gridSize]="80" [styles]="customStyles">
        <amap-marker *ngFor="let markerproject of filteredprojects; index as i" [inCluster]="true" [position]="
            markerproject.positionXY ? markerproject.positionXY : markerNull
          " [hidden]="markerproject.positionXY ? false : true" [offset]="{ x: -15, y: -15 }" [icon]="mark_icon"
          [title]="markerproject.name" [label]="{ content: markerproject.name, direction: 'top' }"
          (naClick)="markClick(currentMarkerCluster, i, markerproject)">
        </amap-marker>
      </amap-marker-clusterer>
    </ngx-amap>

    <div class="map-panel h-5 w-30 m-0 d-flex flex-row align-items-stretch">
      <div class="flex-fill">
        <input matInput class="w-100 h-100 bg-transparent border-0" style="color: black;" (input)="bInputing=true"
          placeholder="{{'general.searchprj' | translate}}" [matAutocomplete]="autoPanel" [(ngModel)]="searchtext" />
      </div>
      <div class="flex-none">
        <select class="w-100 h-100 bg-transparent border-0 text-center" style="width: 100px; color: black;"
          [(ngModel)]="selectedYear">
          <option style="color: initial;" *ngFor="
              let year of app._.range(
                app.dict.rangeyearmin,
                app.dict.rangeyearmax + 1
              )
            " value="{{ year }}">
            {{ year }}{{'general.year' | translate}}
          </option>
        </select>
      </div>
      <mat-autocomplete #autoPanel="matAutocomplete"
        (optionSelected)="onItemSeleted($event.option.value);bInputing=false">
        <ng-container *ngFor="let opt of filteredprojects">
          <div *ngIf="bInputing && CheckSimilar(opt)" class="d-flex flex-row align-items-center">
            <mat-option [style.color]="opt.position ? '' : 'red'" [value]="opt">
              <p class="m-0 flex-fill text-truncate">
                {{opt.position?'':'general.mapnotset' | translate}}{{opt.name}}
              </p>
            </mat-option>
            <button class="flex-none" *ngIf="opt.position" mat-icon-button
              matTooltip="{{ 'general.mapcenter' | translate }}" (click)="locateOnMap(currentMarkerCluster, opt)">
              <i class="material-icons">place</i>
            </button>
          </div>
        </ng-container>
      </mat-autocomplete>
    </div>

    <div class="map-panel h-35 w-60 m-0 d-flex flex-row align-items-center" style="position: absolute;left: 50%;transform: translate(-50%, -100%);top:unset;right: unset;border: 1px;">
      <gov-prjsumcard *ngFor="let poolpie of poolpies; index as i"  class="h-100 col-md-4" [projectsum]="poolpie"></gov-prjsumcard>
    </div>
   
  </div>