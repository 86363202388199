<div class="h-100 w-100 m-0 p-0 d-flex flex-column align-items-stretch justify-content-start">
  <mat-dialog-content class="mat-typography overflow-hidden position-relative px-0 flex-fill">
    <ngb-carousel [activeId]="'slide-ngb-slide-' + pics?.indexOf(current)"
      (slide)="carousel['$current'] = $event.current" #carousel="ngbCarousel" class="h-100 w-100" *ngIf="pics">
      <ng-template ngbSlide [id]="'slide-ngb-slide-' + index" *ngFor="let pic of pics; index as index">
        <div class="h-100 w-100" [ngSwitch]="pic.content_type">
          <video async controls *ngSwitchCase="'video'" class="mat-card-image border w-100 h-100 m-0 p-0" [stopif]="
              (carousel['$current'] || carousel.activeId) !=
              'slide-ngb-slide-' + index
            ">
            <source [src]="pic.url | safe: 'url'" type="{{ pic.content_type + '/' + pic.mime_type }}" />
            Your browser does not support HTML5 video.
          </video>

          <img async *ngSwitchDefault [src]="pic.url | safe: 'url'" [style.object-fit]="'contain'"
            [style.background-position]="'center'" [style.background-repeat]="'no-repeat'"
            class="mat-card-image border w-100 h-100 m-0 p-0" />
        </div>
        <div class="carousel-caption" *ngIf="pic.comment">
          <h3>{{ pic.comment }}</h3>
        </div>
      </ng-template>
    </ngb-carousel>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ "general.close" | translate }}
    </button>
  </mat-dialog-actions>
</div>