import { AppRoutingModule } from "./module/app.routing.module";

import { SidebarComponent } from "./view/sidebar/sidebar.component";
import { FooterComponent } from "./view/footer/footer.component";
import { NavbarComponent } from "./view/navbar/navbar.component";
import { PromptComponent } from "./view/prompt/prompt.component";
import { FrameComponent } from "./view/frame/frame.component";
import { AppComponent } from "./view/app.component";

import { RqOverlayDirective } from "./service/http.service";

import { VersioinService } from "./service/version.service";
import { AuthService } from "./service/auth.service";
import { HttpService } from "./service/http.service";
import { AppService } from "./service/app.service";
import { SysService } from "./service/sys.service";
import { MsgService } from "./service/msg.service";
import { AlertService } from "./service/alert.service";
import { Backface } from "./service/backface/config";

export const Application = {
    Components: [
        AppComponent,

        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        FrameComponent,

        PromptComponent,

        RqOverlayDirective
    ],

    Services: [
        VersioinService,
        HttpService,
        AuthService,
        SysService,
        AppService,
        MsgService,
        AlertService,
        Backface.AsyncReport
    ],

    Modules: [
        AppRoutingModule
    ]
}