import { Component, OnInit, AfterViewInit, ViewChild, Inject, ComponentRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Prj as TPrj, Sys } from "../../../application/service/backface/types";
import { GovTableComponent } from "../gov.table.component/gov.table.component";
import { AppService } from "../../../application/service/app.service";
import { Property } from "../../libs/property";

@Component({
    selector: "gov-wssumitem, [gov-wssumitem]",
    templateUrl: "./gov.wssumitem.component.html",
    styleUrls: ["./gov.wssumitem.component.scss"]
})
export class GovWSSumitemComponent implements OnInit, AfterViewInit {
    private _props = Property.Of(this).values;

    readonly currentmodule: Sys.IDatasetModule = {
        dict: this.app.dict.exec.sumworkitems,
        headers: this.app.dict.exec.sumworkitems.headers,
        rows: this.app.prj.workitems.filter(
            p => (p.type == TPrj.IWorkitem.Type.normal || p.type == TPrj.IWorkitem.Type.verify)
        ).reverse(),
        key: 'wssumitem',
    };

    readonly component: ComponentRef<any> = null;

    @ViewChild("govtablemodels", { static: true })
    set govtablemodels(val: GovTableComponent) {
        const { _props: props } = this;
        props.govtablemodels = val;
    }

    get govtablemodels(): GovTableComponent {
        const { _props: props } = this;
        return props.govtablemodels;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectedWorkItems: Array<string>,
            object?: { fullName?: string }
        },
        public app: AppService
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.data.selectedWorkItems.forEach(wsid => {
            var temp = this.app.prj.workitems.find(ws => ws.id == wsid);
            temp ? this.govtablemodels?.selection?.select(temp) : temp;
        });
    }

    getSelected() {
        return this.govtablemodels?.selection?.selected;
    }

    static open(dialog: MatDialog, data: {} = {}): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: false,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '80vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
            data: {
                ...data
            }
        }

        return dialog.open(GovWSSumitemComponent, config);
    }

}