import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { extend } from "lodash";
import * as _ from 'lodash';

import { GovDiagramDirective } from "../diagram.directive/diagram.directive";
import { AppService } from '../../../application/service/app.service';
import { Sys } from '../../../application/service/backface/types';
import { TemplateService } from '../template.directive';
import { GovEditor } from '../model/form.editting';
import { Property } from '../../libs/property';

type Imported = GovDiagramDirective.Imported;
type Destroied = GovDiagramDirective.Destroied;

@Component({
    selector: "gov-editor, [gov-editor]",
    templateUrl: "./gov.editor.component.html",
    styleUrls: ['./gov.editor.component.scss'],
    exportAs: 'GovEditor'
})
export class GovEditorComponent {
    private _props = Property.Of<GovEditorComponent & {
        edittingsub: Subscription,
    }>(this).values;

    @Input('gov-editor')
    get _modules() {
        return this.modules;
    }

    set _modules(val: Sys.IDatasetModules) {
        this.modules = val;
    }

    @Input('modules')
    set modules(val: Sys.IDatasetModules) {
        const modules = (this._props.modules = extend([], { currentIndex: 0 }));
        val.forEach(v => {
            if (!_.has(v, 'accessable') || v.accessable) {
                modules.add(v);
            }
        });
    }

    get modules(): Sys.IDatasetModules {
        return this._props.modules;
    }

    @Input('toeditting')
    set toeditting(val: GovEditor.ToEditting) {
        this._props.toeditting = val;
    }

    get toeditting() {
        return this._props.toeditting;
    }

    @Output('onDiagramImported')
    get onDiagramImported(): EventEmitter<{
        entity: any
    } & Imported> {
        const { _props: props } = this;

        return props.onDiagramImported || (
            props.onDiagramImported = new EventEmitter()
        )
    }

    @Output('onDiagramDestroied')
    get onDiagramDestroied(): EventEmitter<{
        entity: any
    } & Destroied> {
        const { _props: props } = this;

        return props.onDiagramDestroied || (
            props.onDiagramDestroied = new EventEmitter()
        )
    }

    @Output('onCommandTemplate')
    get onCommandTemplate(): EventEmitter<GovEditor.CommandConfig> {
        const { _props: props } = this;

        return props.onCommandTemplate = props.onCommandTemplate || new EventEmitter();
    }

    @Output('onFilterTemplate')
    get onFilterTemplate(): EventEmitter<GovEditor.IFilterData> {
        const { _props: props } = this;

        return props.onFilterTemplate = props.onFilterTemplate || new EventEmitter();
    }

    onFilter(data: GovEditor.IFilterData) {
        this.onFilterTemplate.emit(data);
    }

    @Output('onCurrentModule')
    get onCurrentModule(): EventEmitter<Sys.IDatasetModule> {
        const { _props: props } = this;

        return props.onCurrentModule = props.onCurrentModule || new EventEmitter();
    }

    @Output('onWorkItemDetailsTemplate')
    get onWorkItemDetailsTemplate(): EventEmitter<GovEditor.IWorkItemSumData> {
        const { _props: props } = this;

        return props.onWorkItemDetailsTemplate = props.onWorkItemDetailsTemplate || new EventEmitter();
    }

    onWorkItemDetails(data: GovEditor.IWorkItemSumData) {
        this.onWorkItemDetailsTemplate.emit(data);
    }

    get currentmodule(): Sys.IDatasetModule {
        const { modules, _props: props } = this;
        const cur = modules?.[modules?.currentIndex || 0];

        if (props.currentmodule != cur) {
            props.currentmodule = cur;
            this.onCurrentModule.emit(cur);
        }

        return cur;
    }

    get editting(): GovEditor.IEditting {
        const { _props: props } = this;

        if (!props.edittingsub && props.editting != GovEditor.Editting.get(this.currentmodule)) {
            props.edittingsub = timer().subscribe(() => {
                props.editting = GovEditor.Editting.get(this.currentmodule)
                props.edittingsub.unsubscribe();
                props.edittingsub = null;
            })
        }

        return props.editting;
    }

    get noform(): boolean {
        return this.currentmodule?.noform;// || !this.editting?.form;
    }

    constructor(
        public fieldtpls: TemplateService,
        public app: AppService,
        public ngzone: NgZone
    ) {
    }
}
