import { Component, OnInit } from '@angular/core';

import { AlertService } from '../service/alert.service';

@Component({
    selector: 'application, [application]',
    templateUrl: "./app.component.html",
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        public alerts: AlertService
    ) {
    }

    ngOnInit() {
    }
}
