import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppService } from '../../service/app.service';
import { SystemsetComponent } from 'src/app/systemset';

@Component({
    selector: 'app-sidebar, [app-sidebar]',
    templateUrl: "./sidebar.component.html",
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    constructor(
        public app: AppService,
        public dialog: MatDialog
    ) {

    }

    ngOnInit() {
    }

    onSysInfo() {
        SystemsetComponent.open(this.dialog);
    }

}
