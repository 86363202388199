<div class="h-100 w-100 m-0 p-0 d-flex flex-column align-items-stretch justify-content-start">
  <div mat-dialog-title class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
    <div class="flex-fill">{{ data?.title | translate }}【{{ data?.header | translate }}】</div>
    <label class="btn btn-sm" mat-dialog-close>
      <i class="fa fa-close fa-fw"></i>
    </label>
  </div>

  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

  <mat-dialog-content class="mat-typography position-relative px-0 flex-fill">
    <gov-table class="w-100" [datasets]="currentmodule"></gov-table>
  </mat-dialog-content>
</div>