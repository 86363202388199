import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { extend, isNull, isUndefined } from 'lodash';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { GovEditorComponent } from '../../utils/view/gov.editor.component/gov.editor.component';
import { Exec as TExec, Sys as TSys } from '../../application/service/backface/types';
import { TemplateService } from '../../utils/view/template.directive';
import { SysService } from '../../application/service/sys.service';
import { AppService } from '../../application/service/app.service';
import { Report } from '../../application/service/backface/report';
import { AuditComponent } from '../../audit/view/audit.component';
import { Property } from '../../utils/libs/property';

@Component({
    templateUrl: "./supervise.component.html",
    styleUrls: ['./supervise.component.scss'],
    providers: [TemplateService]
})
export class SuperviseComponent extends AuditComponent implements OnInit, OnDestroy {
    private __props = Property.Of<SuperviseComponent>(this).values;

    @ViewChild(GovEditorComponent, { static: false })
    get goveditor(): GovEditorComponent {
        return this.__props.goveditor;
    }

    set goveditor(val: GovEditorComponent) {
        this.__props.goveditor = val;
    }

    get execute(): TSys.IDatasetModule {
        const { __props: props } = this;
        return props.execute = props.execute || {
            ...this.app.datasources.supervise,
            rows: []
        }
    }

    get audit(): TSys.IDatasetModule {
        const { __props: props } = this;
        return props.audit = props.audit || {
            ...this.app.datasources.superviseaudit,
            rows: []
        }
    }

    get modules(): TSys.IDatasetModules {
        const { __props: props, app: { datasources: { supervisesummary } }, execute, audit, problem } = this;

        return props.modules || (
            props.modules = extend([
                supervisesummary,
                execute,
                audit,
                problem,
            ], {
                currentIndex: 0
            })
        )
    }

    constructor(
        public app: AppService,
        public sys: SysService,
        protected httpClient: HttpClient,
    ) {
        super(app, sys, httpClient);
        this.editting = this.editting.bind(this);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    loadWorkitems(): void {
        const { app, execute } = this
        const rows = (execute.rows || (
            execute.rows = []
        ));

        this.report("/report/project_todo_supervise", (res: Report.IProjectWorkitems) => {
            if (isNull(res) || isUndefined(res)) return;
            Report.buildWorkitems(app, res, rows);
        }).query();
    }

    loadAudits(): void {
        const { app, audit } = this
        const rows = (audit.rows || (
            audit.rows = []
        ));

        this.report("/entity/results/retrieve", (res: TExec.IAuditResult[] = []) => {
            Report.buildAuditResults(app, res, rows);
        }).query({
            workitem: {
                type: ['supervise']
            },

            audit: [
                "auditting",
                "callback",
                "nopass"
            ]
        });
    }

    initResult(result: TExec.IAuditResult): TExec.IAuditResult {
        return _.extend(result, {
            audit: {},
            value: {}
        });
    }
}
