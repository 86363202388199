import DirectEditing from 'diagram-js-direct-editing/lib/DirectEditing';
import { Shape, Element, Moddle, } from "bpmn-js/lib/model/Types";
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import DirectEditingModule from 'diagram-js-direct-editing';
import EventBus from 'diagram-js/lib/core/EventBus.js';
import Canvas from 'diagram-js/lib/core/Canvas';

import { GovDiagramDirective } from './diagram.directive';

export class EdittingHook extends DirectEditing {
    constructor(
        public diagram: GovDiagramDirective,
        public eventbus: EventBus,
        public canvas: Canvas
    ) {
        super(eventbus, canvas)
    }

    activate(shape: Shape) {
        const { diagram: { entries: { entries }, readonly } } = this;
        const entry = entries[getBusinessObject(shape)?.type];
        if (readonly || (entry && !(entry.editable ?? true))) return;

        return super.activate(shape);
    }

    static Module = {
        ...DirectEditingModule,
        directEditing: ['type', EdittingHook],
        __init__: ['directEditing'],
    }

    static $inject = [
        'govDiagram',
        ...DirectEditing.$inject
    ];
}
