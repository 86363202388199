import { Component, OnInit, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { TemplateService } from '../../utils/view/template.directive';
import { AppService } from "../../application/service/app.service";

@Component({
    selector: 'processhelp.component',
    templateUrl: "./processhelp.component.html",
    styleUrls: ['./processhelp.component.scss'],
    providers: [
        TemplateService
    ]
})
export class ProcesshelpComponent implements AfterViewInit, OnInit {
    constructor(public app: AppService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
    }
    
    static open(dialog: MatDialog): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '80vh',
            minHeight: '50vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            height: '80vh',
            width: '50vw'
        }

        return dialog.open(ProcesshelpComponent, config);
    }
}
