import { Component, AfterViewInit, OnInit } from '@angular/core';

import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./summary.component.html",
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, AfterViewInit {
    constructor(
        public app: AppService
    ) {

    }
    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }
}
