import { isArray, isNull, isUndefined } from 'lodash';
import * as _ from 'lodash';

import { Class } from '../types/mixins';
import { Property } from '../property';
import { Unique } from '../unique';

const _JSONSymbol: symbol = Unique.symbol('JSONKEYS');
const _JSONHANDLESymbol: symbol = Unique.symbol('JSONHANDLES');
const _JsonHandleBag = Property.inheritBag<_JSON.JsonHandle>(_JSONHANDLESymbol);
const _JsonBag = Property.inheritBag<_JSON.JsonKeys>(_JSONSymbol, (parent?: _JSON.JsonKeys) => {
    if (!parent) return _.extend<_JSON.JsonKeys>([], { indexed: {} });

    const clone = (keys: _JSON.JsonKeys): _JSON.JsonKeys => {
        return _.extend(_.cloneDeep(keys), {
            indexed: _.reduce(keys.indexed, (_indexed, val, key) => {
                _indexed[key] = _.isBoolean(val) ? val : clone(val);
                return _indexed;
            }, {} as _JSON.JsonKeys['indexed'])
        });
    }

    return clone(parent);
});

export { };

declare global {
    interface JSON {
        Key(keys?: (string) | ((string)[])): PropertyDecorator;
    }
}

function _KeysOf<TObj extends Object | Class = any>(obj: TObj): _JSON.JsonKeys {
    return _JsonBag.Of(typeof obj === 'function' ? obj.prototype : obj, false);
}

function _JsonOf<TObj extends Object = any>(obj: TObj, res?: { [P: string]: _JSON.Type }, subkeys?: _JSON.JsonKeys): undefined | _JSON.Type {
    if (isNull(obj) || isUndefined(obj)) {
        return res || obj;
    }

    if (this != obj && !subkeys && obj?.['toJSON']) {
        return obj?.['toJSON'](res);
    }

    const keys = _KeysOf(obj);
    if (!keys || keys.length <= 0) {
        return obj as any;
    }

    const handles = _JSON.HandlesOf(obj, false).handles;
    return keys.reduce((res, key) => {
        if (subkeys && !subkeys.indexed[key]) return res;

        const _handle = handles?.[key];
        const _subkeys = keys.indexed[key];
        const _val = (_handle ? _handle(obj) : (
            _JsonOf(obj[key], {}, (
                _.isArray(_subkeys) ? _subkeys : undefined
            ))
        ));

        _val !== undefined && (res[key] = _val);

        return res;
    }, res || {});
}

function _polyfill(_JSON: JSON) {
    if (!_JSON || _JSON.Key) {
        return;
    }

    if (!_JSON.Key) {
        Object.defineProperty(_JSON, 'Key', {
            enumerable: false,
            configurable: true,
            value: function (this: JSON, subkeys?: (string) | ((string)[])): PropertyDecorator {
                subkeys = isArray(subkeys) ? subkeys : (
                    subkeys ? [subkeys] : undefined
                );

                const _subkeys = subkeys?.reduce((r, k) => (
                    r.push(k), r.indexed[k] = true, r
                ), _JsonBag.creator());

                // generate the property decorator function
                return function (prototype: Object, propertyKey: string): void {
                    // attach the JSON keys bag to the target.
                    const jsonbag = _JsonBag.Of(prototype, true), indexed = jsonbag.indexed;
                    !_.includes(jsonbag, propertyKey) && jsonbag.push(propertyKey)
                    indexed[propertyKey] = _subkeys ?? true;

                    // attach the toJSON method to the target which will be called by JSON.strinify with instance of target.
                    if (!prototype['toJSON']) {
                        Object.defineProperty(prototype, 'toJSON', {
                            writable: true, enumerable: false, configurable: true,
                            value: function (this: any, res: { [P: string]: _JSON.Type }) {
                                return _JsonOf.call(this, this, res);
                            }
                        })
                    }
                }

            }
        })
    }
}

_polyfill(JSON);

export namespace _JSON {
    export function polyfill(wnd: Window) {
        wnd && _polyfill(wnd['JSON']);
    }

    export type Object = {
        [P: string]: null | string | number | boolean | Object | Array
    }

    export type Array = (null | string | number | boolean | Object | Array)[];

    export type Type = null | string | number | boolean | Object | Array;

    export interface toJSON {
        (obj: object): undefined | Type;
    }

    export interface JsonHandle {
        [P: string]: toJSON
    }

    export type JsonKeys = (string[] & {
        indexed: {
            [k: string]: JsonKeys | true
        }
    })

    export function KeysOf<TObj extends object | Class = any>(obj: TObj): JsonKeys {
        return _KeysOf(obj);
    }

    export function JsonOf(obj: any, res?: { [P: string]: Type }): undefined | Type {
        return _JsonOf(obj, res);
    }

    export function HandlesOf(obj: object, forceown: boolean): {
        to(key: string): undefined | Type
        handle(key: string): toJSON
        handles: JsonHandle
    } {
        const handles = _JsonHandleBag.Of(obj, forceown);

        return {
            to(key: string): undefined | Type {
                return handles?.[key]?.(obj);
            },
            handle(key: string): toJSON {
                return handles?.[key];
            },
            get handles(): {
                [P: string]: toJSON
            } {
                return handles;
            }
        }
    }
}