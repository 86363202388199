import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ResetpwdComponent } from 'src/app/resetpwd/view/resetpwd.component';
import { UserinfoComponent } from 'src/app/userinfo/view/userinfo.component';
import { AuthService } from '../../service/auth.service';
import { AppService } from '../../service/app.service';
import { SysService } from '../../service/sys.service';

@Component({
    selector: 'app-navbar, [app-navbar]',
    templateUrl: "./navbar.component.html",
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    constructor(
        public auth: AuthService,
        public sys: SysService,
        public app: AppService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }

    resetPassword() {
        ResetpwdComponent.open(this.dialog)
    }

    userInfo() {
        UserinfoComponent.open(this.dialog)
    }
}
