import { Component, Directive, EventEmitter, Input, OnInit, Output, resolveForwardRef, TemplateRef } from "@angular/core";
import { isArray, isBoolean, isFunction, isString } from "lodash";
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog, } from "@angular/material/dialog";
import { ComponentType } from '@angular/cdk/portal';
import { JsonEditorOptions } from "ang-jsoneditor";
import { Subscription, timer } from "rxjs";
import * as _ from "lodash";

import { XFilesComponent } from "../../../results/view/files.component";
import { AuthService } from "../../../application/service/auth.service";
import { AppService } from "../../../application/service/app.service";
import { SysService } from "../../../application/service/sys.service";
import { PicsComponent } from "../../../results/view/pics.component";
import { Exec } from "../../../application/service/backface/exec";
import { Dict } from "../../../application/service/backface/dict";
import { Sys } from "../../../application/service/backface/types";
import { TemplateService } from "../template.directive";
import { FieldProperty } from "../model/field.property";
import { Unreadonly } from "../../libs/types/mixins";
import { GovEditor } from "../model/form.editting";
import { Property } from "../../libs/property";
import { Editor } from "../../libs/editor";

enum SelectStatus {
    indeterminate = 0.5,
    nonselected = 0,
    selected = 1,
}

type StatusMap<T extends object> = (
    WeakMap<T, [refs: number, parent: T]>
)
const _EmptyDataSet = { rows: [], headers: [], key: '' };

@Directive({
    selector: '[gov-nested-tree]',
    exportAs: "GovNestedTree",
}) export class GovNestedTreeDirective<TNode extends object = any> {
    private _props = Property.Of<
        Unreadonly<GovNestedTreeDirective> & {
            statusmap: StatusMap<TNode>
        }
    >(this).values;

    readonly indeterminate = SelectStatus.indeterminate;
    readonly nonselected = SelectStatus.nonselected;
    readonly selected = SelectStatus.selected;

    @Input('gov-nested-tree')
    set setting(val: {
        source: TNode | TNode[],
        header: Editor.IField,
        leafonly: boolean,
        data: TNode[]
    }) {
        const { _props: props, setting: { header, source, data } = {} } = this;
        if (val?.header != header) {
            props.control = props.dataSource = props.whenChecker = props.statusmap = null;
        }

        if (val?.source != source) {
            props.dataSource = props.statusmap = null;
        }

        if (val?.data != data) {
            props.statusmap = null;
        }

        props.setting = val;
    };

    get setting(): {
        source: TNode | TNode[],
        header: Editor.IField,
        leafonly: boolean,
        data: TNode[]
    } {
        return this._props.setting;
    }

    get control(): NestedTreeControl<TNode> {
        const { _props: props, setting: { header: { children } = {} } = {} } = this;
        return props.control = props.control || (children && (
            new NestedTreeControl<TNode>(node => node[children])
        ));
    }

    get dataSource(): MatTreeNestedDataSource<TNode> {
        const { _props: props, setting: { header: { children } = {}, source } = {} } = this;

        return props.dataSource = props.dataSource || (
            props.dataSource = new MatTreeNestedDataSource<TNode>(),
            props.dataSource.data = isArray(source) ? source : (source && children ? source[children] : []),
            props.dataSource
        );
    }

    get whenChecker(): (idx: number, node: TNode) => boolean {
        const { _props: props, setting: { header: { children } = {} } = {} } = this;

        return props.whenChecker = props.whenChecker || (children && (
            (_: number, node: TNode) => !!node[children] && node[children]?.length > 0
        ));
    }

    constructor(
    ) {
    }

    select(node: TNode) {
        this.update(node, true);
    }

    deselect(node: TNode) {
        this.update(node, false);
    }

    isselected(node): boolean {
        return this.status(node) == SelectStatus.selected;
    }

    isnonselected(node): boolean {
        return this.status(node) == SelectStatus.nonselected;
    }

    isindeterminate(node): boolean {
        return this.status(node) == SelectStatus.indeterminate;
    }

    status(node: TNode): SelectStatus {
        const { setting: { header: { children } = {} } = {} } = this;
        if (!children) return SelectStatus.nonselected;

        // update the status map firstly;
        const statusmap = this.update();
        const slen = statusmap?.get(node)?.[0] ?? 0;
        const clen = node[children]?.length ?? 0;

        if (clen <= 0) {
            if (slen > 0) return SelectStatus.selected;
            return SelectStatus.nonselected;
        }

        if (slen == 0) return SelectStatus.nonselected;
        if (clen != slen) return SelectStatus.indeterminate;
        return SelectStatus.selected;
    }

    update(node?: TNode, selected?: boolean): StatusMap<TNode> {
        const { _props: props, setting: { header: { children } = {}, source, data } = {} } = this;

        const build = (node: TNode | TNode[], parent?: TNode, statusmap?: StatusMap<TNode>): [refs: number, map: StatusMap<TNode>] => {
            if (!node) return null;

            statusmap = statusmap || new WeakMap();

            if (!isArray(node)) {
                const clen = node[children]?.length ?? 0;
                let selcount = 0;

                if (clen <= 0) {
                    // leaf node
                    selcount = data.has(node) ? SelectStatus.selected : SelectStatus.nonselected;
                } else {
                    // non-leaf node
                    selcount = build(node[children], node, statusmap)?.[0] ?? 0;
                }

                statusmap.set(node, [selcount, parent]);
                return [(selcount <= 0 ? SelectStatus.nonselected : (selcount > 0 && selcount < clen ? SelectStatus.indeterminate : SelectStatus.selected)), statusmap];
            }

            let refval = _.reduce(node, (refs, n): number => {
                return refs + build(n, parent, statusmap)?.[0] ?? 0;
            }, 0);

            return [refval, statusmap];
        }

        const statusmap: StatusMap<TNode> = (props.statusmap = props.statusmap || (children && source && data && (
            build(isArray(source) ? source : (source && children ? source[children] : null))?.[1]
        )))

        if (!node) {
            return statusmap;
        }

        const targetcode = (selected = !!selected) ? SelectStatus.selected : SelectStatus.nonselected;
        if (targetcode == this.status(node)) return statusmap;

        // mark and collect decendents
        const update = (node: TNode | TNode[], starting: boolean = true, colls?: TNode[]): TNode[] => {
            colls = (colls || []);

            if (starting) {
                // start point
                if (isArray(node)) {
                    console.assert(!isArray(node));
                    return null;
                }

                const clen = node[children]?.length ?? 0, nbag = statusmap.get(node);
                for (let _node = node, delta = ((selected ? Math.max(clen, 1) : 0) - nbag[0]); _node;) {
                    const statusbag = statusmap.get(_node);
                    const prestatus = this.status(_node);
                    statusbag[0] += delta;

                    const pststatus = this.status(_node);
                    if (prestatus == pststatus) break;

                    delta = pststatus - prestatus;
                    _node = statusbag[1];
                }

                if (clen <= 0) return colls.push(node), colls;
                return update(node[children], false, colls);
            }

            if (!isArray(node)) {
                const clen = node[children]?.length ?? 0;
                const nbag = statusmap.get(node);

                if (clen <= 0) {
                    nbag[0] = targetcode;
                    return colls.push(node), colls;
                }

                nbag[0] = selected ? clen : 0;
                return update(node[children], false, colls);
            }

            return _.transform(node, (colls, n) => {
                if (this.status(n) == targetcode) return;
                update(n, false, colls);
            }, colls);
        }

        const colls = update(node) || [];
        if (selected) {
            data.create(colls)
        } else {
            data.destroy(colls);
        }

        return statusmap;
    }
};

@Component({
    selector: "gov-form, [gov-form]",
    templateUrl: "./gov.form.component.html",
    styleUrls: ["./gov.form.component.scss"],
    providers: [TemplateService],
    exportAs: 'GovForm'
})
export class GovFormComponent extends FieldProperty implements OnInit {
    private _props = Property.Of<GovFormComponent & {
        edittingsub: Subscription
    }>(this).values;


    readonly JSON = JSON;
    mindate = new Date(this.app.dict.rangeyearmin, 0, 1);
    maxdate = new Date(this.app.dict.rangeyearmax, 11, 31);
    nowdate = new Date();

    @Input('gov-form')
    get govform(): Sys.IDatasetModule {
        return this.datasets;
    }

    set govform(val: Sys.IDatasetModule) {
        this.datasets = val;
    }

    @Input('datasets')
    get datasets(): Sys.IDatasetModule {
        const { _props: props } = this;
        return props.datasets = props.datasets || _EmptyDataSet;
    }

    set datasets(val: Sys.IDatasetModule) {
        this._props.datasets = val;
    }

    @Input("readonly")
    get readonly(): boolean {
        const { _props: props } = this;
        return props.readonly;
    }

    set readonly(val: boolean) {
        this._props.readonly = val;
    }

    @Output('onCommandTemplate')
    get onCommandTemplate(): EventEmitter<GovEditor.CommandConfig> {
        const { _props: props } = this;

        return props.onCommandTemplate = props.onCommandTemplate || new EventEmitter();
    }

    get jsoneditoroptions(): JsonEditorOptions {
        const { _props: props } = this;

        let options: JsonEditorOptions = null;

        return props.jsoneditoroptions = props.jsoneditoroptions || (
            options = new JsonEditorOptions(),
            options.modes = ['code', 'text', 'tree', 'view'],
            //options.schema = schema,
            options.statusBar = false,
            options.mode = 'code',
            options
        )
    };

    get editting(): GovEditor.IEditting {
        const { _props: props } = this;

        if (!props.edittingsub && props.editting != GovEditor.Editting.get(this.datasets)) {
            props.edittingsub = timer().subscribe(() => {
                props.editting = GovEditor.Editting.get(this.datasets);
                props.edittingsub.unsubscribe();
                props.edittingsub = null;
            })
        }

        return props.editting;
    }

    get forms(): GovEditor.IForm[] {
        const { _props: props, editting } = this;

        if (props.forms != editting?.form && props.forms?.[0] != editting?.form) {
            props.forms = isArray(editting?.form) ? editting?.form : (editting?.form && [editting?.form] || []);
        }

        return props.forms;
    }

    constructor(
        public sys: SysService,
        public app: AppService,
        public auth: AuthService,
        public dialog: MatDialog,
        public fieldtpls: TemplateService
    ) {
        super(app);
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
    }

    stopevent(event: Event) {
        event?.stopImmediatePropagation?.();
        event?.stopPropagation?.();
    }

    isReadonly(header: Editor.IField, headers: Editor.IFields, data: Editor.Editor<any>): boolean {
        if (this.readonly || (isBoolean(header.readonly) && header.readonly)) return true;
        if (isFunction(header.readonly) && header.readonly(this.app, data)) return true;
        if (!(data instanceof Editor.Editor)) return false;

        return data.$readonly_(this.getProperty(header, headers)?.pathProp.paths[0]);
    }

    isVisible(header: Editor.IField, headers: Editor.IFields, editor: Editor.Editor<any>): boolean {
        if (isFunction(header.invisible) && header.invisible(this.app, editor)) return false;
        if (isBoolean(header.invisible) && header.invisible) return false;

        if (header?.dependvalues?.length > 0) {
            const targetheaderkey = header?.dependfield?.key, dependvalues = header?.dependvalues;
            const targetheader = headers.find(header => header.key == targetheaderkey);
            const targetfield = this.getCellField(editor, targetheader, headers);
            if (!targetfield) return true;

            const targetvalue = targetfield?.data;
            const matchindex = dependvalues.findIndex(v => {
                switch (typeof v) {
                    case 'function': {
                        return v(targetvalue, editor);
                    }

                    default: {
                        return v == targetvalue;
                    }
                }
            })

            return matchindex >= 0;
        }

        return true;
    }

    isSelected(values: any[], opt: any) {
        return values?.find(v => (v?.['$base_'] == opt) || v == opt);
    }

    isSection(form: GovEditor.IForm): form is GovEditor.ISection {
        return GovEditor.isSection(form);
    }

    noactionbar(form: GovEditor.IForm): boolean {
        if (GovEditor.isSection(form)) return form.noactionbar;
        return !form.sections.find(s => !s?.noactionbar);
    }

    $changed_(form: GovEditor.IForm): boolean {
        if (GovEditor.isSection(form)) return form.editor?.$changed_?.();
        return !!form.sections.find(s => s?.editor?.$changed_?.());
    }

    $save_(form: GovEditor.IForm) {
        if (GovEditor.isSection(form)) return form.editor?.$save_?.();
        form.sections.forEach(s => s?.editor?.$save_?.());
    }

    $cancel_(form: GovEditor.IForm) {
        if (GovEditor.isSection(form)) return form.editor?.$cancel_?.();
        form.sections.forEach(s => s?.editor?.$cancel_?.());
    }

    getFieldTemplate(header: Editor.IField): string {
        const { app: { editor } } = this, { Value: { Type: FType } } = editor;

        if (editor.Value.isFieldType(header, FType.list)) {
            return isString(header.source) ? header.source : FType[FType.list];
        }

        return FType[editor.Value.getFieldType(header, 'multi') || FType.text];
    }

    onCommand(header: Sys.IDatasetHeader, editor: Editor.Editor<any>) {
        header.source = resolveForwardRef(header.source);

        const config: GovEditor.CommandConfig = {
            template: header.source as ComponentType<any> | TemplateRef<any>,
            config: {
                autoFocus: true,
                hasBackdrop: true,
                disableClose: true,
                restoreFocus: true,
                maxHeight: '90vh',
                minHeight: '90vh',
                maxWidth: '80vw',
                minWidth: '80vw',
                height: '90vh',
                width: '80vw',
                data: {
                    object: editor,
                    header: header,
                    dataset: this.datasets
                },
            }
        }

        this.onCommandTemplate.emit(config);
        if (!config.template) return;

        if (_.isFunction(config.template['open'])) {
            config.template['open'](this.dialog, config.config.data);
            return;
        }

        // the source of the header should be a component type or a templateref, [TODO] check it.
        this.dialog.open(config.template, config.config);
    }

    openPics(pics: Exec.Pic[], current: Exec.Pic) {
        this.sys.openDialog(PicsComponent, {
            data: {
                pics, current
            }
        })
    }

    openXFiles(files: Exec.XFile[], current: Exec.XFile) {
        this.sys.openDialog(XFilesComponent, {
            data: {
                files, current
            }
        })
    }
}