<ng-content></ng-content>
<ng-container
  *ngFor="let area of displayedPanels; index as index; last as last"
>
  <gov-splitter-bar
    *ngIf="last === false"
    [order]="index * 2 + 1"
    [direction]="direction"
    [width]="splitterBarWidth"
    (mousedown)="startDragging($event, index * 2 + 1, index + 1)"
  ></gov-splitter-bar>
</ng-container>
