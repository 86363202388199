import { Injectable, NgZone, OnDestroy, Type } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { share } from "rxjs/operators";
import { forEach } from "lodash";
import * as _ from "lodash";

import { Org as TOrg, Prj as TPrj, Exec as TExec } from './types';
import { Unreadonly } from "../../../utils/libs/types/mixins";
import { Property } from "../../../utils/libs/property";
import { Editor } from "../../../utils/libs/editor";
import { Unique } from "../../../utils/libs/unique";
import { Async } from "../../../utils/libs/async";

interface IEntities {
    [K: string]: { // entity name vs the request payload
        [P: string]: any
    }
}

interface IDefinitions {
    [K: string]: Editor.TEntity // entity name vs the request parameters
}

interface ISection {
    name: string; // section name
    definitions: IDefinitions;
    entities: IEntities
}

const _EntityInits: (() => void)[] = [];
function EntityInitialize() {
    if (_EntityInits["initialized"]) return;

    _EntityInits.forEach((init) => init());
    _EntityInits["initialized"] = true;
}

const Setions = {
    org: {
        name: "org",
        definitions: {
            priviledge: {},
            dept: {},
            peoples: {},
            role: {},
            actors: {},
            inspectors: {}
        },
        entities: {
            priviledge: {},
            dept: {},
            peoples: {},
            role: {},
            actors: {},
            inspectors: {}
        }
    },
    prj: {
        name: "prj",
        definitions: {
            checkpoints: {},
            workitems: {},
            worksets: {},
            stages: {},
            projecttypes: {},
            plans: {},
            project: {}
        },
        entities: {
            checkpoints: {},
            workitems: {},
            worksets: {},
            stages: {},
            projecttypes: {},
            plans: {},
            project: {}
        }
    },
    exec: {
        name: "exec",
        definitions: {
            results: {}
        },
        entities: {
            results: {}
        }
    }
}

namespace Net {

    interface IParam {
        headers?: HttpHeaders,
        url: string,
    }

    class Accessor<IValue = any> {
        private $props = Property.Of<{
            cache: Observable<IValue>,
            definition: Editor.TEntity,
            httpClient: HttpClient,
            entity: string,
            values: any,
            url: string,
        }>(this).values;

        constructor(
            httpClient: HttpClient,
            definition: Editor.TEntity,
            entity: string,
        ) {
            const { $props: props } = this;

            props.url = `/entity/${entity}/retrieve`;
            props.definition = definition;
            props.httpClient = httpClient;
            props.entity = entity;
        }

        refetch() {
            const { $props: props } = this;
            const definition = props.definition;

            definition.loaded = false;
            delete props.values;
        }

        get(body: any) {
            return this.bysync(body);
        }

        private bysync(body: any) {
            const { $props: props } = this;
            if (props.values) return props.values;

            const definition = props.definition;
            const request = new XMLHttpRequest();
            request.open('POST', props.url, false);

            request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            request.setRequestHeader('Accept', 'application/json');
            request.send(body);

            definition.loaded = true;
            return (props.values = request.httpResponse?.body);
        }

        private byasync(body: any) {
            const { $props: props } = this;

            if (!props.cache) {
                props.cache = new Async.Cache<IValue, IParam>({
                    query: (p: IParam): Observable<IValue> => {
                        return props.httpClient.post<IValue>(p.url, body);
                    }, param: { url: props.url }
                }, 1, false);
            }

            return props.cache;
        }
    }

    export class Handler implements Editor.TSettingHandler {
        private $props = Property.Of<{
            httpClient: HttpClient,
            definitions: IDefinitions,
            entities: IEntities,
            section: string,
            accessors: {},
            settings: {},
        }>(this).values;

        constructor(
            protected app: any,
            protected httpClient: HttpClient,
            protected section?: ISection,
        ) {
            const { $props: props } = this;
            props.httpClient = httpClient;
            props.accessors = {};
            props.settings = {};
            if (!section) return;

            props.section = section.name;
            props.entities = section.entities;
            props.definitions = section.definitions;

            forEach(props.entities, (body, key) => {
                Object.defineProperty(this, key, {
                    enumerable: false, configurable: true,
                    get: function (this: Handler) {
                        EntityInitialize();

                        const definition = section.definitions[key];

                        props.settings[key] = (props.settings[key] || (props.accessors[key] = (props.accessors[key] || (
                            props.accessors[key] || new Accessor<any>(
                                props.httpClient, definition, definition?.entity || key
                            )
                        ))))

                        if (props.settings[key] instanceof Accessor) {
                            return props.settings[key].get(body);
                        }

                        return props.settings[key];
                    },
                    set: function (this: Handler, val: any) {
                        props.settings[key] = val;
                    }
                })
            })
        }

        $mayRefetch(key?: string | number | symbol) {
            const { $props: { definitions, settings, accessors } } = this;
            if (!definitions[key as any]?.["refetch"]) return;
            accessors[key]?.refetch?.();
            delete settings[key];
        }

        $mayfail(key?: string | number | symbol) {
            this.$mayRefetch(key);

            const { $props: { settings, entities } } = this;
            if (!entities.hasOwnProperty(key)) return false;
            if (!settings.hasOwnProperty(key)) return true;
            return settings[key] instanceof Accessor;
        }

        $create?(val: any, entity: Editor.TEntity): boolean | Observable<any> {
            EntityInitialize();

            return entity ? this.httpClient.post(
                `/entity/${entity.entity}/create`,
                val
            ) : true;
        }

        $update?(val: any, entity: Editor.TEntity): boolean | Observable<any> {
            EntityInitialize();

            return entity ? this.httpClient.post(
                `/entity/${entity.entity}/update`,
                val
            ) : true;
        }

        $delete?(val: any, entity: Editor.TEntity): boolean | Observable<any> {
            EntityInitialize();

            return entity ? this.httpClient.post(
                `/entity/${entity.entity}/delete`,
                val
            ).pipe(
                share()
            ) : true;
        }

        $entity?(val: any): Editor.TEntity;
        $entity?(val: any, property: string | number | symbol): Editor.TEntity
        $entity?(val: any, property?: string | number | symbol): Editor.TEntity {
            return Backface.Of(val, property);
        }
    };
}

export class Backface {
    private static Handler = Net.Handler;
    private $props = Property.Of<Unreadonly<Backface> & {
        _queries: {
            [report: string]: Async.Cache<any, object>
        }
    }>(this).values;

    get org(): TOrg.IOrg & Editor.TSettingHandler {
        return (this.$props.org = this.$props.org || new Backface.Handler(
            this.app, this.httpClient, Setions.org
        ));
    }

    get prj(): TPrj.IPrj & Editor.TSettingHandler {
        return (this.$props.prj = this.$props.prj || new Backface.Handler(
            this.app, this.httpClient, Setions.prj
        ));
    }

    get exec(): TExec.IExec & Editor.TSettingHandler {
        return (this.$props.exec = this.$props.exec || new Backface.Handler(
            this.app, this.httpClient, Setions.exec
        ));
    }

    get cud(): Editor.TSettingHandler {
        return (this.$props.cud = this.$props.cud || new Backface.Handler(
            this.app, this.httpClient
        ));
    }

    report<
        Value extends any = any,
        Body extends object = object
    >(report: string, querynow: boolean = false): Async.Cache<Value, Body> {
        const queries = (this.$props._queries = this.$props._queries || {});

        const query = (queries[report] = queries[report] || (
            new Async.Cache<Value, Body>({
                query: (p: Body): Observable<any> => {
                    return this.httpClient.post(
                        `${report}`,
                        p || {}
                    );
                }
            }, 1, querynow)
        ));

        return query as Async.Cache<Value, Body>;
    }

    constructor(
        private httpClient: HttpClient,
        private app: any
    ) {
    }
}

export namespace Backface {
    @Injectable(
    ) export class AsyncReport implements OnDestroy {
        private $props = Property.Of<{
            reporters: {
                [report: string]: {
                    handler: { query: (p?: object) => void },
                    cache: Async.Cache<any, object>,
                    subscription: Subscription
                }
            }
        }>(this).values;

        constructor(
            protected httpClient: HttpClient,
        ) {
            const { $props: props } = this;
            props.reporters = {};
        }

        ngOnDestroy(): void {
            const { $props: props } = this;
            _.forEach(props.reporters, (slot) => {
                slot?.subscription?.unsubscribe();
                slot?.cache?.finish();
            });

            props.reporters = {};
        }

        report(report: string, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void): {
            query: (p?: object) => void;
        } {
            const { $props: { reporters }, httpClient } = this;

            if (!reporters[report]) {
                const slot: {
                    handler: { query: (p?: object) => void },
                    cache: Async.Cache<any, object>,
                    subscription: Subscription
                } = {
                    subscription: null,
                    cache: new Async.Cache<any, Object>({
                        query: (p: Body): Observable<any> => {
                            return httpClient.post(
                                `${report}`,
                                p || {}
                            );
                        }
                    }, 1, false),
                    handler: {
                        query: (p?: object): void => {
                            if (!slot.subscription) {
                                slot.subscription = slot.cache.subscribe(
                                    next ? ((_value: any): void => {
                                        Promise.resolve().then(() => { next(_value) });
                                    }) : undefined,

                                    (_error: any): void => {
                                        error && Promise.resolve().then(() => { error(_error) });

                                        // restore the subscription for next round query
                                        slot.subscription?.unsubscribe();
                                        slot.subscription = null;
                                    },

                                    complete ? ((): void => {
                                        Promise.resolve().then(() => { complete() })

                                        // restore the subscription for next round query
                                        slot.subscription?.unsubscribe();
                                        slot.subscription = null;
                                    }) : undefined
                                )
                            }

                            slot.cache.query(p);
                        }
                    }
                }

                reporters[report] = slot;
            }

            return reporters[report].handler;
        }
    }
}

export namespace Backface {
    const _HOSTSymbol: symbol = Unique.symbol('HOSTKEYS');
    const _ENTITYSymbol: symbol = Unique.symbol('ENTITYKEYS');
    const _ENTITYBag = Property.inheritBag<Editor.TEntity>(_ENTITYSymbol);
    const _HOSTBag = Property.inheritBag<{
        [P in string | number | symbol]: Editor.TEntity
    }>(_HOSTSymbol);

    export function Entity<T>(type: (() => Type<T>), section: string | [section: string, refetch: boolean] | [section: string, entity: string] | [section: string, entity: string, refetch: boolean], payload?: any): PropertyDecorator {
        const Refetch: boolean = Array.isArray(section) ? (_.isBoolean(section[1]) ? section[1] : !!section[2]) : false;
        const Entity: string = Array.isArray(section) ? (_.isString(section[1]) ? section[1] : null) : null;
        const Section: string = Array.isArray(section) ? section[0] : section;

        // generate the property decorator function
        return function (prototype: Object, property: string): void {
            const _section = Setions[Section] = Setions[Section] || {
                name: Section,
                entities: {},
            }

            payload = payload || _section.entities[property] || {};
            _section.entities[property] = payload;

            _EntityInits.push(() => {
                const thisbag = _ENTITYBag.Of(type().prototype, true);
                const hostbag = _HOSTBag.Of(prototype, true);

                hostbag[property] = thisbag;
                thisbag.entity = (Entity || property);
                thisbag.refetch = Refetch;
                thisbag.section = Section;
                thisbag.payload = payload;
                thisbag.loaded = false;
                thisbag.cls = type();

                _section.definitions[property] = thisbag;
            })
        }
    }

    export function Of<T>(obj: T): Editor.TEntity;
    export function Of<T>(obj: T, property: string | number | symbol): Editor.TEntity;
    export function Of<T>(obj: T, property?: string | number | symbol): Editor.TEntity {
        EntityInitialize();

        if (property) {
            const bag = _HOSTBag.Of(obj, false);
            if (!bag) return null;

            if (bag?.[property as string]) {
                return bag?.[property as string];
            }

            if (_.isString(property) && property[property.length - 1] == 's') {
                return bag?.[property.substring(0, property.length - 1)];
            }

            return null;
        }

        return _ENTITYBag.Of(obj, false);
    }
}