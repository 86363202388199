<router-outlet></router-outlet>

<div *rqOverlay class="overlayContainer">
  <div class="overlayContent">
    <div class="loadingcontainer animation-2">
      <div class="shape shape1"></div>
      <div class="shape shape2"></div>
      <div class="shape shape3"></div>
      <div class="shape shape4"></div>
    </div>
  </div>
</div>

<div
  class="app-alerts white-board app-alerts-container"
  *ngIf="!alerts.isEmpty"
>
  <ngb-alert
    #ngbalert="ngbAlert"
    [animation]="true"
    [type]="alert.type"
    [dismissible]="true"
    (closed)="alerts.close(alert)"
    *ngFor="let alert of alerts"
    [timer]="alert.dismiss"
    (finish)="ngbalert.close()"
  >
    {{ alert.errkey | translate }}
  </ngb-alert>
</div>
