import { Component, AfterViewInit, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { isArray, isNull, isUndefined } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { O } from 'ts-toolbelt';
import * as _ from 'lodash';

import { ProjectListComponent } from '../../../../app/projectlist/view/projectlist.component';
import { Backface } from '../../../../app/application/service/backface/config';
import { GovEditor } from "../../../../app/utils/view/model/form.editting";
import { Prj, Sys } from '../../../application/service/backface/types';
import { AppService } from '../../../application/service/app.service';
import { Report } from '../../../application/service/backface/report';
import { Property } from '../../../../app/utils/libs/property';
import { _JSON } from '../../../utils/libs/polyfill/json';
import { SeriesOption } from 'echarts';

@Component({
    selector: 'reportgeneral, [reportgeneral]',
    templateUrl: "./reportgeneral.component.html",
    styleUrls: ['./reportgeneral.component.scss']
})
export class ReportGeneralComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of<ReportGeneralComponent & {
        onFilterTemplate: EventEmitter<GovEditor.IFilterData>
    }>(this).values;

    readonly noinputtxt: boolean = true;

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    get allprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.allprjsum;
    }

    get newprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.newprjsum;
    }

    get continueprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.continueprjsum;
    }

    get ongoingprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.ongoingprjsum;
    }

    get delayingprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.delayingprjsum;
    }

    get delayedprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.delayedprjsum;
    }

    get finishedprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.finishedprjsum;
    }

    get constrpreprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.constrpreprjsum;
    }

    get constringprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.constringprjsum;
    }

    get acceptingprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.acceptingprjsum;
    }

    get projectpreprjsum(): Sys.IPrjBudgetSumItem {
        return this._props.projectpreprjsum;
    }

    readonly yearmonthcostchartParam: Report.IChartParam = {
        charttype: Report.eChartType.line,
        chartid: "yearmonthcostOption",
        option: new Report.ReportTemplate().ReportTemplateChartStackLine,
        reqURL: "/report/costconsume_per_month_by_project_attribute",
        legends: this.app.report.yearcostinvest,
        xAxisItems: this.app.dict.months,
        chartCfg: this.app.report.monthChartConfig,
        legtag: "month",
        dataMap: new Map<string, object>()
    }

    readonly planchartBarParam: Report.IChartParam = {
        charttype: Report.eChartType.bar,
        chartid: "planOption",
        option: new Report.ReportTemplate().ReportTemplateChartBar,
        reqURL: "/report/project_count_per_plan_by_project_attribute",
        legends: this.app.report.statuslist,
        xAxisItems: this.app.prj.plans,
        chartCfg: this.app.report.statusChartConfig,
        legtag: "status",
        dataMap: new Map<string, object>()
    }

    readonly costchartBarParam: Report.IChartParam = {
        charttype: Report.eChartType.bar,
        chartid: "costOption",
        option: new Report.ReportTemplate().ReportTemplateChartBar,
        reqURL: "/report/project_count_per_plan_total_amount_segment_by_project_attribute",
        legends: this.app.report.costRange,
        xAxisItems: this.app.prj.plans,
        chartCfg: this.app.report.plancostChartConfig,
        legtag: "segment",
        dataMap: new Map<string, object>()
    }

    readonly percentchartBarParam: Report.IChartParam = {
        charttype: Report.eChartType.bar,
        chartid: "percentOption",
        option: new Report.ReportTemplate().ReportTemplateChartBar,
        reqURL: "/report/project_count_per_plan_total_consume_percent_segment_by_project_attribute",
        legends: this.app.report.percentRange,
        xAxisItems: this.app.prj.plans,
        chartCfg: this.app.report.plancostpercentChartConfig,
        legtag: "segment",
        dataMap: new Map<string, object>()
    }

    readonly yearcostchartBarParam: Report.IChartParam = {
        charttype: Report.eChartType.bar,
        chartid: "yearcostOption",
        option: new Report.ReportTemplate().ReportTemplateChartBar,
        reqURL: "/report/project_count_per_plan_years_amount_segment_by_project_attribute",
        legends: this.app.report.costRange,
        xAxisItems: this.app.prj.plans,
        chartCfg: this.app.report.yearplancostChartConfig,
        legtag: "segment",
        dataMap: new Map<string, object>()
    }

    readonly yearpercentchartBarParam: Report.IChartParam = {
        charttype: Report.eChartType.bar,
        chartid: "yearpercentOption",
        option: new Report.ReportTemplate().ReportTemplateChartBar,
        reqURL: "/report/project_count_per_plan_years_consume_percent_segment_by_project_attribute",
        legends: this.app.report.percentRange,
        xAxisItems: this.app.prj.plans,
        chartCfg: this.app.report.yearplancostpercentChartConfig,
        legtag: "segment",
        dataMap: new Map<string, object>()
    }

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    @Input('module')
    // @ts-ignore
    module: Sys.IDatasetModule;

    @Output('onFilterTemplate')
    get onFilterTemplate(): EventEmitter<GovEditor.IFilterData> {
        const { _props: props } = this;

        return props.onFilterTemplate = props.onFilterTemplate || new EventEmitter(true);
    }

    onClickCharBar(params, chartBarParam): void {
        if (params?.value <= 0) return;

        var chartParam = chartBarParam as Report.IChartParam;
        var title = chartParam?.chartCfg?.title;

        const { app: { lang: { general: { year = '', unit = '' } = {} } = {} }, selectedYear } = this;
        var header = `${selectedYear}${year}/${params.name}/${params.seriesName}/${params.value}${unit}`;

        var idlist = [];
        if (chartParam?.dataMap) {
            var key = params.name + params.seriesId + params.seriesName;
            var obj = chartParam?.dataMap.get(key);
            if (obj) {
                idlist = (obj as Array<Object>);
            }
        }

        ProjectListComponent.open(this.dialog, {
            prjidlist: idlist,
            header: header,
            title: title,
        })
    }

    set selectedYear(val: number) {
        const { toolbarcontent } = this;
        if (toolbarcontent.selectedYear == val) return;

        toolbarcontent.selectedYear = val;
        this.saveToolbarContent();

        //TODO get data from backend service
        this.GetSummaryData();
        this.GetPlanChartBarData(this.planchartBarParam);
        this.GetChartBarData(this.costchartBarParam);
        this.GetChartBarData(this.percentchartBarParam);
        this.GetChartBarData(this.yearcostchartBarParam);
        this.GetChartBarData(this.yearpercentchartBarParam);
        this.GetYearMonthCostData(this.yearmonthcostchartParam);
    }

    get selectedYear(): number {
        const { toolbarcontent: { selectedYear }, app: { dict: { currentYear } } } = this;
        return selectedYear ?? currentYear;
    }

    dosearch(): void {
        //TODO get data from backend service
        this.GetSummaryData();
        this.GetPlanChartBarData(this.planchartBarParam);
        this.GetChartBarData(this.costchartBarParam);
        this.GetChartBarData(this.percentchartBarParam);
        this.GetChartBarData(this.yearcostchartBarParam);
        this.GetChartBarData(this.yearpercentchartBarParam);
        this.GetYearMonthCostData(this.yearmonthcostchartParam);
    }

    constructor(
        public app: AppService,
        public dialog: MatDialog,
        public router: ActivatedRoute,
        protected httpClient: HttpClient,
    ) {
        super(router);
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);

        const { app: { report: { planbarcolors, costbarcolors, percentbarcolors }, dict: { monthcostdefault } } } = this;
        this.planchartBarParam.option.color = planbarcolors;
        this.costchartBarParam.option.color = costbarcolors;
        this.percentchartBarParam.option.color = percentbarcolors;
        this.yearcostchartBarParam.option.color = costbarcolors;
        this.yearpercentchartBarParam.option.color = percentbarcolors;

        Report.InitChartParam(this.yearmonthcostchartParam);
        Report.InitChartParam(this.planchartBarParam);
        Report.InitChartParam(this.costchartBarParam);
        Report.InitChartParam(this.percentchartBarParam);
        Report.InitChartParam(this.yearcostchartBarParam);
        Report.InitChartParam(this.yearpercentchartBarParam);

        this.yearmonthcostchartParam.option.series[0].data = monthcostdefault;
        this.yearmonthcostchartParam.option.series[1].data = monthcostdefault;
    }

    ngAfterViewInit(): void {
        this.GetSummaryData();
        this.GetPlanChartBarData(this.planchartBarParam);
        this.GetChartBarData(this.costchartBarParam);
        this.GetChartBarData(this.percentchartBarParam);
        this.GetChartBarData(this.yearcostchartBarParam);
        this.GetChartBarData(this.yearpercentchartBarParam);
        this.GetYearMonthCostData(this.yearmonthcostchartParam);
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter.ngOnDestroy();
    }

    GetSummaryData(): void {
        const { _props: props, selectedYear } = this;

        const prjsums: {
            [P in O.SelectKeys<ReportGeneralComponent, Sys.IPrjBudgetSumItem>]?: [constrtype?: Prj.ConstrType, constrstatus?: Prj.IWorkitem.Status, warning?: boolean]
        } = {
            allprjsum: [Prj.ConstrType.all, null, false],
            newprjsum: [Prj.ConstrType.newcreate, null, false],
            continueprjsum: [Prj.ConstrType.continous, null, false],
            constrpreprjsum: [Prj.ConstrType.constrpre, null, false],
            constringprjsum: [Prj.ConstrType.constring, null, false],
            acceptingprjsum: [Prj.ConstrType.accepting, null, false],
            projectpreprjsum: [Prj.ConstrType.projectpre, null, false],
            ongoingprjsum: [null, Prj.IWorkitem.Status.ongoing, false],
            delayingprjsum: [null, Prj.IWorkitem.Status.delaying, true],
            delayedprjsum: [null, Prj.IWorkitem.Status.delayed, true],
            finishedprjsum: [null, Prj.IWorkitem.Status.finished, false]
        }

        this.reporter.report("/report/project_count_budget_by_project_attribute", (res) => {

            for (const sumkey in prjsums) {
                const sumconstrtype = prjsums[<keyof typeof prjsums>sumkey][0];
                const sumconstrstatus = prjsums[<keyof typeof prjsums>sumkey][1];
                const sumwarning = !!prjsums[<keyof typeof prjsums>sumkey][2];
                const sumname = Prj.ConstrType[sumconstrtype] ?? Prj.IWorkitem.Status[sumconstrstatus];

                const sumslot = res?.[sumname];
                const sumprojects = sumslot?.project;
                const sumcount = sumslot?.count?.project;

                props[sumkey] = <Sys.IPrjBudgetSumItem>_.extend({
                    year: selectedYear,
                    warning: sumwarning,
                    constrtype: sumconstrtype,
                    constrstatus: sumconstrstatus,
                    count: (sumcount?.nonpatched ?? 0),
                    totalamount: (sumslot?.totalamount ?? 0),
                    yearamount: (sumslot?.yearsamount ?? 0),
                    yearconsume: (sumslot?.yearsconsume ?? 0),
                    prjlist: sumprojects
                })
            }

        }).query({
            year: [this.selectedYear, this.selectedYear],
            pool: [Prj.Pool[this.searcher['pool']]]
        });
    }

    GetPlanChartBarData(param: Report.IChartParam): void {
        if (isNull(param) || isUndefined(param)) return;

        this.reporter.report(param.reqURL, (res) => {
            if (isNull(res['plan']) || isUndefined(res['plan'])) { return; }
            var objs: Array<Object> = res['plan'], j = 0;

            param.xAxisItems.forEach(element => {
                var rst = objs.find(obj => obj['id'] == element.id);
                if (isNull(rst) || isUndefined(rst)) { j++; return; }

                var i = 0;
                param.legends.forEach(legend => {
                    var legval = rst[legend.id];
                    param.option.series[i].data[j] = (isNull(legval) || isUndefined(legval)) ? 0 : legval.count.project.nonpatched;

                    if (param.option.series[i].data[j] != 0 && param.dataMap) {
                        var key = element.name + legend.id + legend.name;
                        param.dataMap.set(key, legval['project']);
                    }
                    i++;
                });
                j++;
            });

            Report.RefreshEchart(param.chartid, param.option);
        }).query({
            year: [this.selectedYear, this.selectedYear],
            pool: [Prj.Pool[this.searcher['pool']]]
        });
    }

    GetChartBarData(param: Report.IChartParam): void {
        if (isNull(param) || isUndefined(param)) return;

        this.reporter.report(param.reqURL, (res) => {
            if (isNull(res['plan']) || isUndefined(res['plan'])) { return; }
            var objs: Array<Object> = res['plan'], j = 0;

            param.xAxisItems.forEach(element => {
                var rst = objs.find(obj => obj['id'] == element.id);
                if (isNull(rst) || isUndefined(rst)) { j++; return; }
                if (isNull(rst[param.legtag]) || isUndefined(rst[param.legtag])) { j++; return; }

                var vals = rst[param.legtag];
                var i = 0;
                param.legends.forEach(legend => {
                    var legval = isArray(vals) ? vals.find(val => val['type'] == legend.id) : vals[legend.id];
                    param.option.series[i].data[j] = (isNull(legval) || isUndefined(legval)) ? 0 : legval.count.project.nonpatched;
                    if (param.option.series[i].data[j] != 0 && param.dataMap) {
                        var key = element.name + legend.id + legend.name;
                        param.dataMap.set(key, legval['project']);
                    }
                    i++;
                });
                j++;
            });

            Report.RefreshEchart(param.chartid, param.option);
        }).query({
            year: [this.selectedYear, this.selectedYear],
            pool: [Prj.Pool[this.searcher['pool']]]
        });
    }

    GetYearMonthCostData(param: Report.IChartParam): void {
        if (isNull(param) || isUndefined(param)) return;

        this.reporter.report(param.reqURL, (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            if (!isNull(res['month']) && !isUndefined(res['month'])) {
                //update stage
                const series = param.option.series as SeriesOption[];
                param.dataMap.clear();

                for (let index = 1; index <= 12; index++) {
                    var month = this.selectedYear + '-';
                    if (index < 10) month += '0';
                    month += index.toString();
                    var obj = res['month'].find(m => m.month == month);
                    if (isNull(obj) || isUndefined(obj)) {
                        series[0]['data'][index - 1] = 0;
                        series[1]['data'][index - 1] = 0;
                        continue;
                    };

                    series[0]['data'][index - 1] = obj['consume'].yearlyinvested ? obj['consume'].yearlyinvested : 0;
                    series[1]['data'][index - 1] = obj['consume'].yearlyinvested ? obj['consume'].yearlyassetinvested : 0;
                    if (series[0]['data'][index - 1] != 0 && param.dataMap) {

                    }
                }
                Report.RefreshEchart(param.chartid, param.option);
            }            
        }).query({
            year: [this.selectedYear, this.selectedYear],
            pool: [Prj.Pool[this.searcher['pool']]]
        });
    }
}
