import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';

import { Sys as TSys } from '../../application/service/backface/types';
import { TemplateService } from '../../utils/view/template.directive';
import { AppService } from '../../application/service/app.service';
import { Property } from '../../utils/libs/property';

@Component({
    templateUrl: "./projectlist.component.html",
    styleUrls: ['./projectlist.component.scss'],
    providers: [TemplateService]
})
export class ProjectListComponent implements OnInit, AfterViewInit {
    private _props = Property.Of(this).values;

    get currentmodule(): TSys.IDatasetModule {
        const { app: { dict: { prj: { project, project: { headers } } } } } = this;
        const { _props: props, data: { prjidlist: ids } = {} } = this;
        const { app: { prj: { project: projects } } } = this;

        return (props.currentmodule = (props.currentmodule || {
            rows: ids?.map(({ id, pool }) => projects.firstOf({ id, pool })) ?? [],
            headers: headers.filter(h => h.key == "projectdetails" || h.key == "name"),
            dict: project,
            key: 'projectlist',
        }));
    }

    constructor(
        public app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string,
            header: string,
            prjidlist: TSys.IPrjBudgetSumItem['prjlist']
        }
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
    }

    static open(dialog: MatDialog, data: {} = {}): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '80vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
            data: {
                ...data
            }
        }

        return dialog.open(ProjectListComponent, config);
    }
}
