import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Input, Inject, OnDestroy, Optional } from "@angular/core";
import { isNull, isUndefined, property } from "lodash";
import { JsonEditorOptions } from "ang-jsoneditor";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";

import { Sys as TSys, Exec as TExec, Prj as TPrj } from "../../application/service/backface/types";
import { GovTableComponent } from "../../utils/view/gov.table.component/gov.table.component";
import { TemplateService } from "../../utils/view/template.directive";
import { FieldProperty } from "../../utils/view/model/field.property";
import { Backface } from "../../application/service/backface/config";
import { Report } from "src/app/application/service/backface/report";
import { AppService } from "../../application/service/app.service";
import { SysService } from "../../application/service/sys.service";
import { Exec } from "../../application/service/backface/exec";
import { Prj } from "../../application/service/backface/prj";
import { Property } from "../../utils/libs/property";
import { Editor } from "../../utils/libs/editor";
import { XFilesComponent } from "./files.component";
import { PicsComponent } from "./pics.component";

export type ResultData = {
    title: string,
    from?: GovTableComponent,
    header?: TSys.IDatasetHeader,
    dataset?: TSys.IDatasetModule,
    object?: Prj.ProjWorkitem | Exec.Problem | Exec.AuditResult,
    results?: Exec.Result[]
};

const hints = {
    project: ["project.plan.name", "project.name"],
    workitem: ["projecttype.name", "stage.name", "workset.name", "name"],
}

@Component({
    selector: "gov-results, [gov-results]",
    templateUrl: "./results.component.html",
    styleUrls: ['./results.component.scss'],
    providers: [TemplateService]
})
export class ResultsComponent extends FieldProperty implements OnDestroy {
    private _props = Property.Of<{
        _asyncreport: Backface.AsyncReport;
    } & ResultsComponent>(this).values;

    get property() {
        return property
    }

    get projecthints(): string[] {
        return hints.project;
    }

    get workitemhints(): string[] {
        return hints.workitem;
    }

    get results(): Exec.Result[] {
        const { _props: props } = this;

        props.results = (
            props.results ||
            this.data?.results ||
            this.loadResults()
        );

        return props.results;
    }

    get workitem(): Prj.ProjWorkitem {
        const { data: { object } } = this;
        if (object instanceof Exec.Problem) return object.workitem;
        if (object instanceof Prj.Workitem) return object;
        return object?.workitem;
    }

    get project(): Prj.Project {
        const { data: { object } } = this;
        if (object instanceof Exec.Problem) return object.project;
        if (object instanceof Prj.Workitem) return object.project;
        return object?.project;
    }

    get headers(): Editor.IFields {
        return this.workitem?.checkpoints;
    }

    get isaudit(): boolean {
        return this.data.object instanceof Exec.AuditResult;
    }

    get issuperviseaudit(): boolean {
        const { data: { object } } = this;
        return object instanceof Exec.AuditResult && (object.workitem.type == TPrj.IWorkitem.Type.supervise);
    }

    get isexecuteaudit(): boolean {
        const { data: { object } } = this;
        return object instanceof Exec.AuditResult && (object.workitem.type != TPrj.IWorkitem.Type.supervise);
    }

    get jsoneditoroptions(): JsonEditorOptions {
        const { _props: props } = this;

        let options: JsonEditorOptions = null;
        return props.jsoneditoroptions = props.jsoneditoroptions || (
            options = new JsonEditorOptions(),
            options.modes = ['code', 'text', 'tree', 'view'],
            //options.schema = schema,
            options.statusBar = false,
            options.mode = 'code',
            options
        )
    };

    @Input('resultdata')
    get resultdata(): ResultData {
        return this.data;
    }

    set resultdata(val: ResultData) {
        this.data = val;
    }

    constructor(
        public sys: SysService,
        public app: AppService,
        public fieldtpls: TemplateService,
        protected httpClient: HttpClient,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public data: ResultData
    ) {
        super(app);

        const { _props: props } = this;
        props._asyncreport = new Backface.AsyncReport(httpClient);
    }

    ngOnDestroy(): void {
        const { _props: { _asyncreport } } = this;
        _asyncreport.ngOnDestroy();
    }

    loadResults(): Exec.Result[] {
        const { data: { object } } = this;
        if (!object) return [];

        let param: {} = null;
        const host: {
            project?: Prj.Project;
            workitem?: Prj.ProjWorkitem;
        } = {};

        if (object instanceof Prj.Workitem) {
            const {
                id: workitemid,
                workset: { id: worksetid } = {},
                stage: { id: stageid } = {},
                projecttype: { id: projecttypeid } = {},
                project: { id: projectid } = {}
            } = object;

            param = {
                project: projectid,
                projecttype: projecttypeid,
                stage: stageid,
                workset: worksetid,
                workitem: workitemid
            }

            host.workitem = object;
            object.project && (host.project = object.project);
        } else if (object instanceof Exec.Problem) {
            param = { id: object.parent.id }
            object.workitem && (host.workitem = object.workitem);
            object.project && (host.project = object.project);
        } else {
            param = { audit: "hist", id: object.id }
            object.workitem && (host.workitem = object.workitem);
            object.project && (host.project = object.project);
        }

        const results = [];
        const { _props: { _asyncreport }, isaudit, app } = this;
        _asyncreport.report("/entity/results/retrieve", (res: TExec.IResult[] = []) => {
            if (isaudit) {
                Report.buildAuditResults(app, res, results, host)
            } else {
                Report.buildResult(app, res, results, host);
            }
        }).query(param)

        return results;
    }

    isProjectedResult() {
        return (isNull(this.data?.results) || isUndefined(this.data?.results));
    }

    isVisible(header: Editor.IField, editor: Editor.Editor<any>): boolean {
        if (header?.key == 'yearlyinvested' && this.app.consumeByProject) {
            return false;
        }

        const fieldvalue = this.getCellField(editor, header, this.headers).data;
        if (_.isArray(fieldvalue) && fieldvalue.isEmpty) {
            return false;
        }

        if (header?.dependvalues?.length > 0) {
            const targetheaderkey = header?.dependfield?.key, dependvalues = header?.dependvalues;
            const targetheader = this.headers.find(header => header.key == targetheaderkey);
            const targetfield = this.getCellField(editor, targetheader, this.headers);
            const targetvalue = targetfield?.data;

            const matchindex = dependvalues.findIndex(v => {
                switch (typeof v) {
                    case 'function': {
                        return v(targetvalue, editor);
                    }

                    default: {
                        return v == targetvalue;
                    }
                }
            })

            return matchindex >= 0;
        }

        return true;
    }

    openPics(pics: Exec.Pic[], current: Exec.Pic) {
        this.sys.openDialog(PicsComponent, {
            data: {
                pics, current
            }
        })
    }

    openXFiles(files: Exec.XFile[], current: Exec.XFile) {
        this.sys.openDialog(XFilesComponent, {
            data: {
                files, current
            }
        })
    }

    static open(dialog: MatDialog, data: {} = {}): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '80vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
            data: {
                ...data
            }
        }

        return dialog.open(ResultsComponent, config);
    }
}