import { Component, Input, ViewChild, DoCheck, Output, EventEmitter, OnDestroy, NgZone, TemplateRef, resolveForwardRef, Inject, LOCALE_ID, Injector } from "@angular/core";
import { castArray, extend, isEmpty, isNull, isString, isUndefined } from "lodash";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { ActivatedRoute } from "@angular/router";
import { MatSort } from '@angular/material/sort';
import { DatePipe } from "@angular/common";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import * as _ from "lodash";

import { ProcesshelpComponent } from "../../../processhelp/view/processhelp.component";
import { GovDiagramDirective } from "../diagram.directive/diagram.directive";
import { AppService } from "../../../application/service/app.service";
import { SysService } from "../../../application/service/sys.service";
import { Sys } from "../../../application/service/backface/types";
import { FieldProperty } from "../model/field.property";
import { TemplateService } from "../template.directive";
import { Unreadonly } from "../../libs/types/mixins";
import { Collection } from "../../libs/collection";
import { GovEditor } from "../model/form.editting";
import { Property } from "../../libs/property";
import { Differ } from "../../libs/differ";
import { Editor } from "../../libs/editor";
import { Unique } from "../../libs/unique";

type Imported = GovDiagramDirective.Imported;
type Destroied = GovDiagramDirective.Destroied;

const _ExpandColumnSymbol: string = Unique.symbol('ExpandPanel').toString();
const _EmptyDataSet = { rows: [], headers: [], key: '' };

function toeditting(this: GovTableComponent, obj: object, def?: ((obj: object) => GovEditor.IEditting)): GovEditor.IEditting {
    return {
        form: {
            editor: obj as Editor.Editor<any>,
            headers: this.datasets.headers
        },
        source: obj,
    }
}

@Component({
    selector: "gov-table, [gov-table]",
    templateUrl: "./gov.table.component.html",
    styleUrls: ["./gov.table.component.scss"],
    providers: [TemplateService],
    exportAs: "GovTable",
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class GovTableComponent extends GovEditor.ToolBar implements OnDestroy {
    private _props = Property.Of<Unreadonly<GovTableComponent> & {
        _toeditting: ((obj: object) => GovEditor.IEditting),
        _headerids: string[],
        differ: Differ<{
            datasource: Differ.Event<any>
        }>
    }>(this).values;

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    @Input('gov-table')
    get govtable(): Sys.IDatasetModule {
        return this.datasets;
    }

    set govtable(val: Sys.IDatasetModule) {
        this.datasets = val;
    }

    @Input('datasets')
    get datasets(): Sys.IDatasetModule {
        const { _props: props } = this;
        return props.datasets = props.datasets || _EmptyDataSet;
    }

    set datasets(val: Sys.IDatasetModule) {
        const { editor } = this.app, { _props: props, matDataSource } = this;
        val = val || _EmptyDataSet;

        if (props.datasets == val) {
            return;
        }

        props.datasets = val;

        props.expandable = !!this.headers?.find(
            h => editor.Value.isFieldType(h, editor.Value.Type.details)
        );

        props.creatable = props.deletable = !!this.headers?.find(
            h => editor.Value.isFieldType(h, editor.Value.Type.rowselect)
        )

        if (!(props.editable = (_.has(val, 'cudable') ? !!val.cudable : true))) {
            props.creatable = props.deletable = false;
        }

        if (_.has(val, 'creatable') && !!!val.creatable) {
            props.creatable = false;
        }

        if (_.has(val, 'deletable') && !!!val.deletable) {
            props.deletable = false;
        }

        const { deletable } = this;
        props.headerids = this.headers?.filter(
            h => (
                !editor.Value.isFieldType(h, editor.Value.Type.notcolumn) &&
                !editor.Value.isFieldType(h, editor.Value.Type.label) &&
                (deletable || (h.key != 'select'))
            )
        ).map(h => h.key) || [];

        const renderdatasource = ({ removed, added }: {
            removed: any[],
            added: any[],
        }) => {
            if (val.loading) return;

            matDataSource.data = val?.rows;
            this.table?.renderRows();

            if (removed?.length > 0) {
                const _editting = GovEditor.Editting.get(val);

                if (removed.has(_editting?.primary)) {
                    if (!val?.rows?.find((r) => r == _editting.source)) {
                        GovEditor.Editting.remove(val);
                    }
                }
            }
        }

        props.differ.extend({
            datasource: val?.rows && Differ.Event.create(
                val.rows.onAddRemoved, renderdatasource, this
            )
        })

        renderdatasource({ removed: null, added: null });
        this.module = val;
    }

    @Input('headerids')
    set headerids(val: string[]) {
        const { _props: props, deletable } = this;
        props._headerids = val;

        if (!deletable) {
            props._headerids.remove('select');
        }
    }

    get headerids(): string[] {
        const { _props: props } = this;
        return props._headerids || props.headerids;
    }

    @Input('paginator')
    set paginator(val: MatPaginator) {
        const { _props: props, _props: { paginator } } = this;
        if (val == paginator) return;
        props.paginator = val;

        const { matDataSource, nopaginator, defaultpaginator } = this;
        matDataSource.paginator = nopaginator ? undefined : val || defaultpaginator;
    }

    get paginator(): MatPaginator {
        const { _props: { paginator } } = this;
        return paginator;
    }

    @Input('nopaginator')
    set nopaginator(val: boolean) {
        const { _props: props, _props: { nopaginator } } = this;
        if (val == nopaginator) return;
        props.nopaginator = val;

        const { matDataSource, defaultpaginator, paginator } = this;
        matDataSource.paginator = val ? undefined : paginator || defaultpaginator;
    }

    get nopaginator(): boolean {
        const { _props: { nopaginator } } = this;
        return !!nopaginator;
    }

    @ViewChild(MatPaginator, { static: false })
    set defaultpaginator(val: MatPaginator) {
        const { _props: props, _props: { defaultpaginator } } = this;
        if (val == defaultpaginator) return;
        props.defaultpaginator = val;

        const { matDataSource, nopaginator, paginator } = this;
        matDataSource.paginator = nopaginator ? undefined : paginator || val;
    }

    get defaultpaginator(): MatPaginator {
        const { _props: { defaultpaginator } } = this;
        return defaultpaginator;
    }

    @Input('sort')
    get sort(): MatSort {
        return this.matDataSource.sort;
    }

    set sort(val: MatSort) {
        this.matDataSource.sort = val;
    }

    @ViewChild(MatSort, { static: false })
    set viewsort(val: MatSort) {
        this.sort = val;
    }

    @Input('editting')
    set editting(val: GovEditor.IEditting) {
        const { datasets, oneditting } = this;
        if (!datasets) return;

        const editting = GovEditor.Editting.get(datasets)
        const boundeditting = GovEditor.Editting.getBound(datasets).editting;
        if (editting && boundeditting.isSame(val)) return;

        if (isEmpty(val)) {
            // sub ask the current editting.
            val.handled = boundeditting.handled || boundeditting;
            return;
        }

        oneditting.emit(val);
        GovEditor.Editting.set(datasets, boundeditting.reset(val));

        if (!val.primary && !val.handled) {
            // the editting is coming from downside and not yet update by upside
            val.handled = boundeditting.handled || boundeditting;
        }
    }

    get editting(): GovEditor.IEditting {
        const { datasets } = this;
        if (!datasets) return;

        const { _props: { _toeditting }, oneditting } = this;
        const boundeditting = GovEditor.Editting.getBound(datasets).editting;

        if (!GovEditor.Editting.get(datasets)) {
            // not yet have a valid editting object, try to aquire from outside firstly.
            const tmpeditting = {} as GovEditor.IEditting;
            oneditting.emit(tmpeditting);

            if (tmpeditting.handled) {
                return GovEditor.Editting.set(datasets, boundeditting.reset(tmpeditting));
            }

            // nobody provides valid editting, create by self.
            const data = datasets.rows?.[0];
            const _editting = this.toeditting(data, _toeditting);
            this.editting = (_editting.primary = data, _editting);
        }

        return GovEditor.Editting.get(datasets);
    }

    @Output('editting')
    get oneditting(): EventEmitter<GovEditor.IEditting> {
        const { _props: props } = this;
        return props.oneditting = props.oneditting || new EventEmitter();
    }

    @Input('toeditting')
    set toeditting(val: GovEditor.ToEditting) {
        const { _props: props } = this;
        props.toeditting = val;
    }

    get toeditting(): GovEditor.ToEditting {
        const { _props: props } = this;
        return props.toeditting = props.toeditting || props._toeditting;
    }

    @Input('expandheader')
    get expandheader(): Editor.IField {
        return GovEditor.Editting.getBound(this.datasets).expandheader;
    }

    set expandheader(val: Editor.IField) {
        GovEditor.Editting.getBound(this.datasets).expandheader = val;
        this.onexpandheader.emit(val);
    }

    @Output('expandheader')
    get onexpandheader(): EventEmitter<Editor.IField> {
        const { _props: props } = this;
        return props.onexpandheader = props.onexpandheader || new EventEmitter();
    }

    @ViewChild(MatTable, { static: false })
    set table(val: MatTable<any>) {
        const { _props: props } = this;
        props.table = val;
    }

    get table(): MatTable<any> {
        const { _props: props } = this;
        return props.table;
    }

    @Output('onCommandTemplate')
    get onCommandTemplate(): EventEmitter<GovEditor.CommandConfig> {
        const { _props: props } = this;

        return props.onCommandTemplate = props.onCommandTemplate || new EventEmitter();
    }

    @Output('onWorkItemDetailsTemplate')
    get onWorkItemDetailsTemplate(): EventEmitter<GovEditor.IWorkItemSumData> {
        const { _props: props } = this;

        return props.onWorkItemDetailsTemplate = props.onWorkItemDetailsTemplate || new EventEmitter();
    }

    onWorkItemDetails(data: GovEditor.IWorkItemSumData) {
        this.onWorkItemDetailsTemplate.emit(data);
    }

    @Output('onDiagramImported')
    get diagramImported(): EventEmitter<{
        entity: any
    } & Imported> {
        const { _props: props } = this;

        return props.diagramImported || (
            props.diagramImported = new EventEmitter()
        )
    }

    onDiagramImported(entity: any, event: Imported) {
        this.diagramImported.emit({
            ...event, entity
        })
    }

    @Output('onDiagramDestroied')
    get diagramDestroied(): EventEmitter<{
        entity: any
    } & Destroied> {
        const { _props: props } = this;

        return props.diagramDestroied || (
            props.diagramDestroied = new EventEmitter()
        )
    }

    onDiagramDestroied(entity: any, event: Destroied) {
        this.diagramDestroied.emit({
            ...event, entity
        })
    }

    get fielder(): FieldProperty {
        const { _props: props, app } = this;
        return props.fielder || (
            props.fielder = new FieldProperty(app)
        );
    }

    get expandable(): boolean {
        const { _props: props } = this;
        return props.expandable;
    }

    get creatable(): boolean {
        const { _props: { creatable } } = this;
        return creatable;
    }

    get deletable(): boolean {
        const { _props: { deletable } } = this;
        return deletable;
    }

    get editable(): boolean {
        const { _props: { editable } } = this;
        return editable;
    }

    get expandColumn() {
        return _ExpandColumnSymbol;
    }

    get matDataSource(): MatTableDataSource<object> {
        const { _props: props, fielder } = this;

        return props.matDataSource = props.matDataSource || (
            props.matDataSource = new MatTableDataSource(),
            props.matDataSource.filterPredicate = this.filter.bind(this),
            props.matDataSource.paginator = this.nopaginator ? undefined : this.paginator || this.defaultpaginator,
            props.matDataSource.sortingDataAccessor = ((data: object, sortHeaderId: string) => {
                return fielder.getCellText(data, sortHeaderId, this.headers) as string | number;
            }),
            props.matDataSource
        )
    }

    get headers(): Editor.IFields {
        return this.datasets.headers;
    }

    get selection(): SelectionModel<object> {
        return this.datasets && (this.datasets.selection = this.datasets.selection || new SelectionModel(true, []));
    }

    get isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.matDataSource.data?.length;
        return numSelected === numRows;
    }

    get create(): () => void {
        if (!this.creatable) {
            return null;
        }

        return () => {
            const { datasets, sys, _props: { _toeditting } } = this;
            if (!datasets) return;

            const tocreate = () => {
                const data = datasets.rows.createnew({}, 0);
                const editting = this.toeditting(data, _toeditting);
                this.editting = (editting.primary = data, editting);
            }

            const _editting = GovEditor.Editting.get(datasets);
            if (castArray(_editting?.form)?.find(form => (
                GovEditor.isSection(form) ?
                    form?.editor?.$changed_?.() :
                    form.sections.find((s) => (
                        s?.editor?.$changed_?.()
                    ))
            ))) {
                // prompt whether need to save the changes.
                sys.prompt('save', null, () => {
                    castArray(_editting?.form)?.forEach(
                        form => (
                            GovEditor.isSection(form) ?
                                form?.editor?.$cancel_?.() :
                                form.sections.forEach(s => (
                                    s?.editor?.$cancel_?.()
                                ))
                        )
                    );

                    tocreate();
                });
            } else {
                tocreate();
            }
        }
    }

    get delete(): () => void {
        if (!this.deletable) {
            return null;
        }

        return () => {
            const { datasets, sys } = this;
            if (!datasets) return;

            const todelete = () => {
                const ret = datasets.rows?.destroy(datasets.selection?.selected);
                if (ret instanceof Observable) {
                    const sub = ret.pipe(finalize(() => {
                        sub?.unsubscribe();
                    })).subscribe((value: any): void => {
                        datasets.selection?.clear();
                    }, (error: any): void => {
                    })
                } else {
                    datasets.selection?.clear();
                }
            }

            sys.prompt('delete', todelete, null, {
                count: datasets.selection?.selected?.length
            });
        }
    }

    get monopage(): boolean {
        return !!this.datasets?.monopage;
    }

    get monorow(): object {
        return this.datasets?.monorow;
    }

    pageSize: number[] & { current: number } = extend([
        5, 10, 25, 50, 100, 200
    ], { current: 2 })

    constructor(
        public ngzone: NgZone,
        public app: AppService,
        public sys: SysService,
        public dialog: MatDialog,
        public injector: Injector,
        public fieldtpls: TemplateService,
        public router: ActivatedRoute,
        @Inject(LOCALE_ID) private locale: string
    ) {
        super(router);

        const { _props: props } = this;
        props._toeditting = toeditting.bind(this);
        props.differ = Differ.create();
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
    }

    ngOnDestroy() {
        const { _props: props } = this;
        props.differ.clear();
    }

    onrowselect(row: object, headers?: Editor.IFields) {
        const { datasets, sys, _props: { _toeditting } } = this;
        const _editting = GovEditor.Editting.get(datasets);
        const editor = row as Editor.Editor<any>;
        if (_editting.source == editor) return;

        const toexchange = () => {
            if (!headers) {
                // from host table.
                const _editting = this.toeditting(editor, _toeditting);
                this.editting = (_editting.primary = editor, _editting);
            } else {
                // from sub table.
                this.editting = { form: { editor: editor, headers: headers }, source: editor }
            }
        }

        if (castArray(_editting?.form)?.find(form => (
            GovEditor.isSection(form) ?
                form?.editor?.$changed_?.() :
                form.sections?.find(s => (
                    s?.editor?.$changed_?.()
                ))
        ))) {
            sys.prompt('save', null, () => {
                castArray(_editting?.form)?.forEach(
                    form => (
                        GovEditor.isSection(form) ?
                            form?.editor?.$cancel_?.() :
                            form?.sections?.forEach(s => (
                                s?.editor?.$cancel_?.()
                            ))
                    )
                );

                toexchange();
            })
        } else {
            toexchange();
        }
    }

    onsubeditting(val: GovEditor.IEditting) {
        console.assert(!!val);

        const { primary } = val;
        const hasprimary = val.hasOwnProperty('primary');

        // remove primary at first.
        delete val.primary;

        // try to update the editting.
        this.editting = val;

        // restore primary if have.
        if (hasprimary) {
            val.primary = primary;
        }
    }

    getSummaryItem(rowdata: object, headerId: Editor.IField | string, headers: Editor.IFields): Sys.IStageSumItem {
        return (<Sys.IStageSumItem>rowdata[isString(headerId) ? headerId : headerId?.key]);
    }

    createDatasource(host: any, form: Sys.IDatasetModule, data: any[] | Collection<any, any, any>) {
        let source;

        return host.$$source = host.$$source || (
            source = { ...form, rows: data },
            GovEditor.Editting.remove(source),
            GovEditor.Editting.removeBound(source),
            source
        );
    }

    stopevent(event: Event) {
        event?.stopImmediatePropagation?.();
        event?.stopPropagation?.();
        event?.preventDefault?.()
    }

    stopPropagation(event: Event) {
        event?.stopPropagation?.();
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected ?
            this.selection.clear() :
            this.matDataSource.data?.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected ? 'select' : 'deselect'} all`;
        }

        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    dosearch() {
        const { matDataSource, searcher = '' } = this;
        matDataSource.filter = (searcher || '') as any;
        matDataSource.paginator?.firstPage();
    }

    filter(rowdata: object, filter: string | object): boolean {
        const _filter = _.isString(filter) ? JSON.parse(filter) : filter;
        if (!_filter) return true;

        for (let key in _filter) {
            const rval = rowdata[key], fval = _filter[key];
            if (key != "*" && fval != null && rval != fval) {
                return false;
            }
        }

        const searchtxt = _filter["*"];
        if (!searchtxt) return true;

        const { fielder } = this;
        return this.headerids.findIndex(id => {
            // TODO: getCellText
            // text number percent date bool
            let val = fielder.getCellText(rowdata, id, this.headers);
            const type = this.app.editor.Value.getFieldType(fielder.getProperty(id, this.headers)?.header, 'typemask');

            switch (type) {
                case Editor.Value.Type.bool:
                    {
                        val = val ? "是" : "否";
                    }
                    break;
                case Editor.Value.Type.percent:
                    {
                        val = val + '%';
                    }
                    break;
                case Editor.Value.Type.date:
                    {
                        val = new DatePipe(this.locale).transform(val, this.app.sys.dateFormat);
                    }
                    break;

                case Editor.Value.Type.enum:
                    {
                        let cellfield = fielder.getCellField(rowdata, id, this.headers);
                        val = fielder.getOptionSourceRows(rowdata, id, this.headers).data['indexed'][cellfield?.data]?.title;
                    }
                    break;
            }

            return (isString(val) ? val : String(val)).includes(searchtxt);
        }) >= 0;
    }

    onCommand(header: Sys.IDatasetHeader, editor: Editor.Editor<any>) {
        header.source = resolveForwardRef(header.source);

        const config: GovEditor.CommandConfig = {
            template: header.source as ComponentType<any> | TemplateRef<any>,
            config: {
                autoFocus: true,
                hasBackdrop: true,
                disableClose: true,
                restoreFocus: true,
                maxHeight: '90vh',
                minHeight: '90vh',
                maxWidth: '80vw',
                minWidth: '80vw',
                height: '90vh',
                width: '80vw',
                data: {
                    from: this,
                    object: editor,
                    header: header,
                    title: header?.title,
                    dataset: this.datasets,
                    dialog: this.dialog
                },
            }
        }

        this.onCommandTemplate.emit(config);
        if (!config.template) return;

        if (_.isFunction(config.template['open'])) {
            config.template['open'](this.dialog, config.config.data, this.injector);
            return;
        }

        // the source of the header should be a component type or a templateref, [TODO] check it.
        this.dialog.open(config.template, config.config);
    }

    getEnumStyle(cellField: any, header: Editor.IField): string {
        const { app: { dict, dict: { AllStatus } } } = this;
        if (!header || header.source != AllStatus) return "";
        return dict.getStatusItem(cellField?.data)?.style;
    }

    showProcessHelp() {
        ProcesshelpComponent.open(this.dialog);
    }
}

export namespace GovTableComponent {
    export type ToEditting = ((obj: object) => GovEditor.IEditting);
}