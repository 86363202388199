import { Component, AfterViewInit, OnInit, Input, OnDestroy, Inject, LOCALE_ID, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { concat, isNull, isUndefined, keys } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';

import { GovAdvfilterComponent } from '../../../utils/view/gov.advfilter.component/gov.advfilter.component';
import { Sys as TSys, Prj as TPrj } from '../../../application/service/backface/types';
import { ExcelService } from '../../../application/service/excel.service';
import { TemplateService } from '../../../utils/view/template.directive';
import { FieldProperty } from '../../../utils/view/model/field.property';
import { Backface } from '../../../application/service/backface/config';
import { Report } from '../../../application/service/backface/report';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Prj } from '../../../application/service/backface/prj';
import { Unreadonly } from '../../../utils/libs/types/mixins';
import { Property } from '../../../utils/libs/property';
import { Editor } from '../../../utils/libs/editor';
import { ActivatedRoute } from '@angular/router';

type FootCellType = { key: string, title: string, col: number, row: number };
type StatusCacheType = { id: string, isstage: boolean, status: TSys.IStatusItem };
type PrjSumCountType = {
    refexed: number, refexeon: number, refpre: number,
    refall: number, notused: number, prjsumall: number
};

@Component({
    selector: "sumprojectworkitem, [sumprojectworkitem]",
    templateUrl: "./sumprojectworkitem.component.html",
    styleUrls: ['./sumprojectworkitem.component.scss'],
    providers: [TemplateService]
})
export class SumProjectWorkitemComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit, ExcelService.ISummary {
    // props to maintain local parameters
    private _props = Property.Of<Unreadonly<SumProjectWorkitemComponent> & {
        _searchFilters: Prj.SearchFiltersHost;
    }>(this).values;

    readonly noinputtxt: boolean = true;
    
    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    get fielder(): FieldProperty {
        const { _props: props, app } = this;
        return props.fielder || (
            props.fielder = new FieldProperty(app)
        )
    }

    /* ***************************************** */
    /* Local cache for data from backend service */
    // summary count in header
    prjcount: number = 0;
    totalbudget: number = 0;
    yearbudget: number = 0;
    yearconsume: number = 0;

    // each project summary
    projectwscnt: Map<string, { prjstatus: StatusCacheType[], prjstatuscnt: PrjSumCountType }[]> = new Map();

    // foot summary
    selectedwscnt: { id: string, isstage: boolean, wssum: PrjSumCountType }[] = [];
    sumallcount: PrjSumCountType = {
        refexed: 0, refexeon: 0, refpre: 0,
        refall: 0, notused: 0, prjsumall: 0
    };

    // const pararmeter
    STAGE: string = "stage";
    HEADER: string = "header";
    FOOT: string = "foot";
    SUMLEFT: string = "sum1";
    SUMRIGHT: string = "sum2";
    SUMKEYS: string[] = keys(this.sumallcount).map(key => key);

    /* Local cache for data from backend service */
    /* ***************************************** */

    /* ***************************************** */
    /* Summary header fields parameter  */
    /* ***************************************** */
    sumdisplayedColumns: string[] = [];
    sum2displayedColumns: string[] = [];

    get sumFields_init(): Editor.IFieldCols {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields_init || (props.sumFields_init = [
            {
                key: "total.key",
                title: 'general.total',
                col: 1
            },
            {
                key: "total.value",
                get title() {
                    const { prjcount } = _this;
                    return `${prjcount}${lang.general.unit}`;
                },
                col: 2
            },
            {
                key: "amount.key",
                title: 'budget.amount',
                col: 1
            },
            {
                key: "amount.value",
                get title() {
                    const { totalbudget, locale } = _this;
                    return formatNumber(totalbudget, locale, "1.0-0")
                },
                col: 2
            },
            {
                key: "yearamount.key",
                get title() {
                    const { selectedYearsName } = _this;
                    return `${selectedYearsName}${lang.budget.amount}`
                },
                col: 1
            },
            {
                key: "yearamount.value",
                get title() {
                    const { yearbudget, locale } = _this;
                    return formatNumber(yearbudget, locale, "1.0-0");
                },
                col: 2
            },
            {
                key: "yearconsume.key",
                get title() {
                    const { selectedYearsName } = _this;
                    return `${selectedYearsName}${lang.budget.consume}`;
                },
                col: 1
            },
            {
                key: "yearconsume.value",
                get title() {
                    const { yearconsume, locale } = _this;
                    return formatNumber(yearconsume, locale, "1.0-0");
                },
                col: 2
            },
            {
                key: "budgetunit",
                title: `${lang.budget.unit}：${lang.general.bunit}`,
                col: 1
            },
            {
                key: "workitemtitle",
                title: lang.summary.workitemtitle,
                col: 1 // would change dynamiclly by workitem selected
            },
            {
                key: "workitemsummary",
                title: lang.summary.workitemsummary,
                col: 6
            }
        ]);
    }

    sumFields2_init = <Editor.IFieldCols>[
        {
            key: "projectinfo",
            title: this.app.lang.summary.projectinfo,
            col: 1  //cols depends on project parameters selection
        }
    ];

    sumFields = <Editor.IFieldCols>[];//this.sumFields_init;
    sumFields2 = <Editor.IFieldCols>[];//this.sumFields2_init;

    /* ***************************************** */
    /* Summary header fields parameter  */
    /* ***************************************** */

    /* ***************************************** */
    /* Main Table Header & Data Zone parameter  */
    /* ***************************************** */

    displayedColumns: string[] = [];
    dataSource: MatTableDataSource<Object> = new MatTableDataSource<Object>();
    dataList: Object[] = [];

    colFields_init = <Editor.IFieldCols>[
        {
            key: "id",
            title: this.app.lang.project.id,
            col: 1
        },
        {
            key: "name",
            title: this.app.lang.project.name,
            col: 1
        },
        {
            key: "owncompany",
            title: this.app.lang.project.owncompany,
            col: 1
        },
        {
            key: "location",
            title: this.app.lang.project.location,
            col: 1,
            row: 2
        },
        {
            key: "description",
            title: this.app.lang.project.description,
            cellcls: 'largewidth',
            col: 1
        },
        {
            key: "budgetstartend",
            title: this.app.lang.budget.startend,
            col: 1
        },
        {
            key: "constrtype",
            title: this.app.lang.project.constrtype,
            col: 1
        },
        {
            key: "budgetamount",
            title: this.app.lang.budget.amount,
            col: 1
        },
        {
            key: "budgetyearamount",
            title: this.app.lang.general.annual + this.app.lang.budget.amount,
            col: 1
        },
        {
            key: "budgetyeartarget",
            title: this.app.lang.general.annual + this.app.lang.budget.target,
            cellcls: 'largewidth',
            col: 1
        },
        {
            key: "owndept",
            title: this.app.lang.project.owndept,
            col: 1
        },
        {
            key: "memo",
            title: this.app.lang.project.memo,
            col: 1
        },
        {
            key: "actorprjowner",
            title: this.app.lang.actor.prjowner,
            col: 1
        }
    ];

    colFields = <Editor.IFieldCols>[];//this.colFields_init;
    /* ***************************************** */
    /* Main Table Header & Data Zone parameter  */
    /* ***************************************** */

    /* ***************************************** */
    /* Summary foot fields parameter  */
    /* ***************************************** */
    sumcells1: FootCellType[] = [
        { key: this.FOOT + "refedworkitem", title: this.app.lang.summary.refedworkitem, col: 2, row: 4 },
        { key: this.FOOT + "sumnotused", title: this.app.lang.summary.sumnotused, col: 2, row: 1 },
        { key: this.FOOT + "workitemsummary", title: this.app.lang.summary.workitemsummary, col: 2, row: 1 }
    ];

    sumcells2: FootCellType[] = [
        { key: this.FOOT + "exed", title: this.app.lang.summary.exed + this.app.lang.summary.subsum, col: 2, row: 1 },
        { key: this.FOOT + "exeon", title: this.app.lang.summary.exeon + this.app.lang.summary.subsum, col: 2, row: 1 },
        { key: this.FOOT + "exepre", title: this.app.lang.summary.exepre + this.app.lang.summary.subsum, col: 2, row: 1 },
        { key: this.FOOT + "refedworkitemsumall", title: this.app.lang.summary.sumall, col: 2, row: 1 },
        { key: this.FOOT + "sumnotusedsumall", title: this.app.lang.summary.sumall, col: 2, row: 1 },
        { key: this.FOOT + "workitemsummarysumall", title: this.app.lang.summary.sumall, col: 2, row: 1 }
    ];

    sumcells3: FootCellType[] = [
        { key: this.FOOT + "exedcnt", title: "0", col: 2, row: 1 },
        { key: this.FOOT + "exeoncnt", title: "0", col: 2, row: 1 },
        { key: this.FOOT + "exeprecnt", title: "0", col: 2, row: 1 },
        { key: this.FOOT + "refedworkitemsumallcnt", title: "0", col: 2, row: 1 },
        { key: this.FOOT + "sumnotusedsumallcnt", title: "0", col: 2, row: 1 },
        { key: this.FOOT + "workitemsummarysumallcnt", title: "0", col: 2, row: 1 }
    ];

    sumcells4: FootCellType[] = [
        { key: this.FOOT + "exedper", title: this.app.lang.summary.peronused, col: 2, row: 1 },
        { key: this.FOOT + "exeonper", title: this.app.lang.summary.peronused, col: 2, row: 1 },
        { key: this.FOOT + "exepreper", title: this.app.lang.summary.peronused, col: 2, row: 1 },
        { key: this.FOOT + "refedworkitemsumallper", title: this.app.lang.summary.peronall, col: 2, row: 1 },
        { key: this.FOOT + "sumnotusedsumallper", title: this.app.lang.summary.peronall, col: 2, row: 1 },
        { key: this.FOOT + "workitemsummarysumallper", title: "/", col: 2, row: 1 }
    ];

    sumcells5: FootCellType[] = [
        { key: this.FOOT + "exedpercnt", title: "0.00%", col: 2, row: 1 },
        { key: this.FOOT + "exeonpercnt", title: "0.00%", col: 2, row: 1 },
        { key: this.FOOT + "exeprepercnt", title: "0.00%", col: 2, row: 1 },
        { key: this.FOOT + "refedworkitemsumallpercnt", title: "0.00%", col: 2, row: 1 },
        { key: this.FOOT + "sumnotusedsumallpercnt", title: "0.00%", col: 2, row: 1 },
        { key: this.FOOT + "workitemsummarysumallpercnt", title: "/", col: 2, row: 1 }
    ];

    sumcells6: FootCellType[] = [
        { key: this.FOOT + "workitemcatsum", title: this.app.lang.summary.workitemcatsum, col: 2, row: 6 },
    ]

    sumcells: { key: string, col: number, items: FootCellType[] }[] = [
        { key: this.FOOT + "sumcol1", col: 1, items: this.sumcells1 },
        { key: this.FOOT + "sumcol2", col: 1, items: this.sumcells2 },
        { key: this.FOOT + "sumcol3", col: 1, items: this.sumcells3 },
        { key: this.FOOT + "sumcol4", col: 1, items: this.sumcells4 },
        { key: this.FOOT + "sumcol5", col: 1, items: this.sumcells5 },
        { key: this.FOOT + "sumcol6", col: 1, items: this.sumcells6 }
    ]

    /* ***************************************** */
    /* Summary foot fields parameter  */
    /* ***************************************** */

    /* ***************************************** */
    /* Search filter parameter  */
    /* ***************************************** */
    static CURLOCALFILTERID: string = "sumprojectworkitem";
    get searchFilter(): Prj.SearchFilter {
        const { _props: { searchFilter } } = this;
        if (searchFilter) return searchFilter;

        // the search filter not yet loaded or loading, try to load from backend
        const { app, searchFilters, _props: props } = this;
        const curFilterID = localStorage[SumProjectWorkitemComponent.CURLOCALFILTERID];
        return this.searchFilter = searchFilters.find(f => f.id == curFilterID) || app.defaultfiler;
    }

    set searchFilter(val: Prj.SearchFilter) {
        this._props.searchFilter = val;
        localStorage[SumProjectWorkitemComponent.CURLOCALFILTERID] = val?.id;

        //TODO get data from backend service
        this.GetWorkItemStatus();
    }


    get searchFilters(): Prj.SearchFilters {
        const { _props: props, app } = this;
        return (props._searchFilters || (
            props._searchFilters = new Prj.SearchFiltersHost(app)
        )).searchFilters;
    }

    /* ***************************************** */
    /* Search filter parameter  */
    /* ***************************************** */

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        public app: AppService,
        public dialog: MatDialog,
        public router: ActivatedRoute,
        public templates: TemplateService,
        protected httpClient: HttpClient,
    ) {
        super(router, 'sumprojectworkitem');
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter.ngOnDestroy();
    }

    initSummaryData(): void {
        this.prjcount = 0;
        this.totalbudget = 0;
        this.yearbudget = 0;
        this.yearconsume = 0;

        this.projectwscnt.clear();
        this.dataList.clear();

        this.selectedwscnt.clear();
        this.sumallcount = {
            refexed: 0, refexeon: 0, refpre: 0,
            refall: 0, notused: 0, prjsumall: 0
        }
    }

    applyFilter(): void {
        let rst: Prj.SearchFilter = this.searchFilter;

        this.sumFields = this.sumFields_init.concat();
        this.sumFields2 = this.sumFields2_init.concat();
        this.colFields = this.colFields_init.concat();

        this.sumFields[9].col = rst?.workitemcount + rst?.stagecount;
        this.sumFields2[0].col = this.colFields_init.length;

        for (let i = 0; i < this.sumcells.length; i++) {
            if ((i + 1) == this.sumcells.length) {
                this.sumcells[i].col = this.colFields_init.length - (Math.round(this.colFields_init.length / this.sumcells.length) * i);
                continue;
            }
            this.sumcells[i].col = Math.round(this.colFields_init.length / this.sumcells.length);
        }

        rst?.searchelements?.forEach(se => {
            this.sumFields2.push(
                {
                    key: this.STAGE + se.ids[0],
                    title: se.name,
                    col: se.children.length + 1
                }
            );

            this.colFields.push(
                {
                    key: se.ids[0],
                    title: this.app.lang.summary.curstagestatus,
                    col: 1
                }
            );

            this.selectedwscnt.push(
                {
                    id: se.ids[0],
                    isstage: true,
                    wssum: {
                        refexed: 0, refexeon: 0, refpre: 0,
                        refall: 0, notused: 0, prjsumall: 0
                    }
                }
            );

            se.children.forEach(ws => {
                this.colFields.push(
                    {
                        key: ws.id,
                        title: ws.name,
                        col: 1
                    }
                );
                this.selectedwscnt.push(
                    {
                        id: ws.id,
                        isstage: false,
                        wssum: {
                            refexed: 0, refexeon: 0, refpre: 0,
                            refall: 0, notused: 0, prjsumall: 0
                        }
                    }
                );
            });
        });

        this.sumFields2.push(
            {
                key: 'summary.refedworkitem',
                title: this.app.lang.summary.refedworkitem,
                col: 4
            },
            {
                key: 'summary.sumnotused',
                title: this.app.lang.summary.sumnotused,
                col: 1
            },
            {
                key: 'summary.workitemall',
                title: this.app.lang.summary.workitemall,
                col: 1
            }
        );

        this.colFields.push(
            {
                key: this.SUMKEYS[0],
                title: this.app.lang.summary.exed,
                col: 1
            },
            {
                key: this.SUMKEYS[1],
                title: this.app.lang.summary.exeon,
                col: 1
            },
            {
                key: this.SUMKEYS[2],
                title: this.app.lang.summary.exepre,
                col: 1
            },
            {
                key: this.SUMKEYS[3],
                title: this.app.lang.summary.allcount,
                col: 1
            },
            {
                key: this.SUMKEYS[4],
                title: this.app.lang.summary.allcount,
                col: 1
            },
            {
                key: this.SUMKEYS[5],
                title: this.app.lang.summary.allcount,
                col: 1
            }
        );
    }

    applySumData(): void {
        this.sumallcount.refall = this.sumallcount.refexed + this.sumallcount.refexeon + this.sumallcount.refpre;
        this.sumallcount.prjsumall = this.sumallcount.refall + this.sumallcount.notused;

        for (let i = 0; i < this.SUMKEYS.length; i++) {
            this.sumcells[2].items[i].title = this.sumallcount[this.SUMKEYS[i]];

            let numerator: number = this.sumallcount[this.SUMKEYS[i]];
            let denominator: number = this.sumallcount.prjsumall;

            if (this.SUMKEYS[i] == "prjsumall") {
                continue;
            }

            this.sumcells[4].items[i].title = (denominator == 0) ? "0.00%" : (numerator * 100 / denominator).toFixed(2) + "%";
        }

        this.selectedwscnt.forEach(ws => {
            ws.wssum.refall = ws.wssum.refexed + ws.wssum.refexeon + ws.wssum.refpre;
            ws.wssum.prjsumall = ws.wssum.notused + ws.wssum.refall;
        });
    }

    applyTable(): void {
        this.sumdisplayedColumns = this.sumFields.map(p => p.key);
        this.sum2displayedColumns = this.sumFields2.map(p => p.key);
        this.displayedColumns = this.colFields.map(p => p.key);
        this.dataSource.data = this.dataList;
    }

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    // set selectedYear(val: number) {
    //     const { toolbarcontent } = this;
    //     if (toolbarcontent.selectedYear == val) return;

    //     toolbarcontent.selectedYear = val;
    //     this.saveToolbarContent();

    //     //TODO get data from backend service
    //     this.GetWorkItemStatus();
    // }

    // get selectedYear(): number {
    //     const { toolbarcontent: { selectedYear }, app: { dict: { currentYear } } } = this;
    //     return selectedYear ?? currentYear;
    // }

    get selectedYears(): number[] {
        return this.searchFilter?.selectedYears;
    }

    get selectedYearsName(): string {
        var years = this.app.lang.general.all + this.app.lang.general.annual;
        if (this.selectedYears && this.selectedYears.length > 0) {
            years = this.selectedYears[0];
            years += (this.selectedYears.length == 2 && this.selectedYears[0] != this.selectedYears[1]) ? "~" : "";
            years += (this.selectedYears.length == 2 && this.selectedYears[0] != this.selectedYears[1]) ? this.selectedYears[1] : "";
            years += this.app.lang.general.annual;
        }
        return years;
    }

    get exportas(): (val: string) => void {
        return (val: string) => {
            var footcollist: Array<ExcelService.IFooterColCells> = [];
            this.sumcells.forEach(sumcell => {
                footcollist.push({
                    col: sumcell.col,
                    rows: sumcell.items.reduce((res, v) => {
                        res.push({ row: v.row, value: v.title });
                        return res
                    }, [])
                });
            });

            this.selectedwscnt.forEach(wscnt => {
                footcollist.push({
                    col: 1,
                    rows: this.SUMKEYS.reduce((res, v) => {
                        res.push({ row: 1, value: wscnt.wssum[v] });
                        return res
                    }, [])
                });
            });

            this.SUMKEYS.forEach(key => {
                footcollist.push({
                    col: 1,
                    rows: [{ row: this.SUMKEYS.length, value: this.sumallcount[key] }]
                });
            });

            const { searchFilter: { name = '' } = {}, app: { lang: { general: { orgname = '' } = {}, summary: { workitemprjtable = '' } = {} } } } = this;
            ExcelService.generateExcel(`${orgname}${workitemprjtable}(${name})`, this as ExcelService.ISummary, footcollist);
        };
    }

    GetWorkItemStatus(): void {
        // Apply Filter Here
        var param = {
            //"year": [this.selectedYear, this.selectedYear],
            //"constrstatus": { type: ["unknown", "notstart", "ongoing", "delaying"] },
            //"plan": [{id:"1"}],
            //"constrtype":[ "constrpre" , "constring" ,"accepting"]
        };

        param = this.searchFilter.searchfilterparam || {};

        // Clear previous data set
        this.initSummaryData();
        this.applyFilter();

        const { app: { prj: { project: projects }, dict, dict: { AllStatus, statuslist } } } = this;

        this.reporter.report("/report/project_workitem_status", (res: Report.IProjectWorkitems) => {
            // Check result validation
            if (isNull(res) || isUndefined(res)) { return; }

            // Check each project workitem status
            res.forEach(prj => {
                const id = prj["id"];
                if (isNull(id) || isUndefined(id)) { return; }

                // Check valid project
                const project: Prj.Project = projects.firstOf({ id, pool: TPrj.Pool[prj.pool] });
                if (isNull(project) || isUndefined(project)) { return; }

                const sumproject: Prj.SumProjectData = {
                    id: project.id,
                    name: project.name,
                    pool: TPrj.Pool[project.pool],
                    owncompany: project.owncompany,
                    location: project.location,
                    description: project.description,
                    budgetstartend: project.budget.startend,
                    constrtype: project.constrtypename,
                    budgetamount: formatNumber(project?.budget?.amount, this.locale, "1.0-0"),
                    budgetyearamount: formatNumber(project.getBudgetAmount(this.selectedYears), this.locale, "1.0-0"),
                    budgetyeartarget: project.getBudgetTarget(this.selectedYears),
                    owndept: project.owndept,
                    memo: project.memo,

                    actorprjowner: (project.actor?.prjowner?.name || "") + "\n" + (project.actor?.prjowner?.mobile || ""),
                };

                const objwslist = prj["workitem"];
                if (isNull(objwslist) || isUndefined(objwslist)) { return; }

                const p: object = {};

                // Map sum of project
                keys(sumproject).forEach(key => {
                    p[key] = sumproject[key];
                });

                const prjstatus: StatusCacheType[] = [];
                const prjstatuscnt: PrjSumCountType = {
                    refexed: 0, refexeon: 0, refpre: 0,
                    refall: 0, notused: 0, prjsumall: 0
                };

                this.selectedwscnt.forEach(ws => {
                    prjstatus.push({
                        id: ws.id,
                        isstage: ws.isstage,
                        status: dict.getStatusItem(TPrj.IWorkitem.Status.unknown)
                    });
                });

                objwslist.forEach(ws => {
                    // stage status update
                    const stageid = this.searchFilter?.checkstage(ws.stage);
                    if (isNull(stageid) || isUndefined(stageid)) return;

                    const stagestatus = prjstatus.find(s => s.id == stageid);
                    if (isNull(stagestatus) || isUndefined(stagestatus)) return;

                    stagestatus.status = statuslist.find(s => s.status == AllStatus.find(es => es.key == ws.status).value);

                    // workitem status update
                    if (!this.searchFilter?.checkworkitem(ws.id)) return;

                    var wsstatus = prjstatus.find(s => s.id == ws.id);
                    if (isNull(wsstatus) || isUndefined(wsstatus)) return;

                    wsstatus.status = statuslist.find(s => s.status == AllStatus.find(es => es.key == ws.status).value);
                });

                for (let index = 0; index < prjstatus.length; index++) {
                    const s = prjstatus[index];
                    if (s.status.status == TPrj.IWorkitem.Status.finished ||
                        s.status.status == TPrj.IWorkitem.Status.delayed) {
                        if (!s.isstage) prjstatuscnt.refexed += 1;
                        this.selectedwscnt[index].wssum.refexed += 1;
                    }
                    if (s.status.status == TPrj.IWorkitem.Status.delaying ||
                        s.status.status == TPrj.IWorkitem.Status.ongoing) {
                        if (!s.isstage) prjstatuscnt.refexeon += 1;
                        this.selectedwscnt[index].wssum.refexeon += 1;
                    }
                    if (s.status.status == TPrj.IWorkitem.Status.notstart ||
                        s.status.status == TPrj.IWorkitem.Status.unknown) {
                        if (!s.isstage) prjstatuscnt.refpre += 1;
                        this.selectedwscnt[index].wssum.refpre += 1;
                    }
                    if (s.status.status == TPrj.IWorkitem.Status.notused) {
                        if (!s.isstage) prjstatuscnt.notused += 1;
                        this.selectedwscnt[index].wssum.notused += 1;
                    }

                    p[s.id] = s.status.icontext;
                }

                prjstatuscnt.refall = prjstatuscnt.refexed + prjstatuscnt.refexeon + prjstatuscnt.refpre;
                prjstatuscnt.prjsumall = prjstatuscnt.refall + prjstatuscnt.notused;

                this.sumallcount.refexed += prjstatuscnt.refexed;
                this.sumallcount.refexeon += prjstatuscnt.refexeon;
                this.sumallcount.refpre += prjstatuscnt.refpre;
                this.sumallcount.notused += prjstatuscnt.notused;

                keys(prjstatuscnt).forEach(key => {
                    p[key] = prjstatuscnt[key];
                });

                this.dataList.push(p);

                this.totalbudget += (project.budget.amount) ? (project.budget.amount) : 0;
                this.yearbudget += project.getBudgetAmount(this.selectedYears);
                this.yearconsume += project.getBudgetConsume(this.selectedYears);
            });

            this.dataSource.data = this.dataList;
            this.prjcount = this.dataList.length;

            this.applySumData();
            this.applyTable();
        }).query(
            param
        );
    }

    getColspan(row: number, i: number): number {
        if (row === 1) {
            return this.sumFields[i].col;
        }

        if (row === 2) {
            return this.sumFields2[i].col;
        }

        if (row === 3) {
            return this.colFields[i].col;
        }
    }

    get sumFieldsData(): Editor.IFieldCols[] {
        return [this.sumFields, this.sumFields2];
    }

    get bodyDataSource(): Prj.SumProjectData[] {
        return this.dataList;
    }

    get colFieldsData(): Editor.IFieldCols {
        return this.colFields;
    }

    getFootColDef(i: number): string[] {
        var p: string[] = [];

        if (i == 0) p.push(this.sumcells1[0].key);
        if (i == 4) p.push(this.sumcells1[1].key);
        if (i == 5) p.push(this.sumcells1[2].key);

        p.push(this.sumcells2[i].key);
        p.push(this.sumcells3[i].key);
        p.push(this.sumcells4[i].key);
        p.push(this.sumcells5[i].key);

        if (i == 0) p.push(this.sumcells6[0].key);

        p = concat(p, this.selectedwscnt.map(seletedws => seletedws.id + keys(seletedws.wssum)[i]));
        if (i == 0) p = concat(p, this.SUMKEYS.map(key => this.SUMRIGHT + key));
        return p;
    }

    get advfilter(): TemplateRef<any> {
        return this.templates.get("filter_selector");
    }

    advfilterManagement() {
        const { searchFilter, searchFilters, dialog } = this;

        GovAdvfilterComponent.open(dialog, {
            searchFilter, searchFilters
        }).afterClosed().subscribe(result => {
            if (result) {
                let rst = result as Prj.SearchFilter;
                if (!rst) return;
            }
        });
    }
}
