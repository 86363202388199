import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { take, tap } from "rxjs/operators";
import { Observable } from 'rxjs';

import currentConfig from '../../../assets/json/config.json';
import { Property } from "../../utils/libs/property";
import { Async } from '../../utils/libs/async';
import { HTTP } from "./http.service";

export namespace Version {
    export interface IConfig {
        version: string;
    }
}

@Injectable()
export class VersioinService implements Resolve<object>, OnDestroy {
    private _props = Property.Of(this).values;

    get local(): Version.IConfig {
        return currentConfig;
    }

    get server(): Version.IConfig | undefined {
        const { _props: { server } } = this;
        return server;
    }

    get fetcher(): Observable<Version.IConfig> {
        const { _props: props, httpClient } = this

        if (!props.fetcher) {
            props.fetcher = new Async.Cache<Version.IConfig>({
                query: (): Observable<Version.IConfig> => {
                    return httpClient.get<Version.IConfig>('/assets/json/config.json', {
                        headers: HTTP.Header.Force
                    }).pipe(
                        tap((v) => {
                            props.server = v;
                        })
                    );
                }
            });
        }

        return props.fetcher;
    }

    constructor(
        private httpClient: HttpClient
    ) {
        Promise.resolve().then(() => {
            const { _props: props, fetcher } = this;

            fetcher.subscribe((config: Version.IConfig) => {
                props.server = config;
            })
        })
    }

    ngOnDestroy() {
        (this._props.fetcher as Async.Cache<any>)?.finish();
    }

    resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
        const { fetcher } = this;
        return fetcher.pipe(take(1));
    }

    refresh() {
        // version update by cache-control response header specified by backend.

        // this.fetcher.subscribe((config: Version.IConfig) => {
        //     if (this.local?.version !== config?.version) {
        //         this.httpClient
        //             .get("", {
        //                 headers: HTTP.Header.Force,
        //                 responseType: "text"
        //             })
        //             .subscribe(
        //                 () => location.reload()
        //             )
        //     }
        // })
    }
}
