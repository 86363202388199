<context-menu #govstage>
    <ng-template contextMenuItem let-item passive="true">
        <gov-form class="overflow-hidden h-100" [readonly]="readonly" [datasets]="stageModule"></gov-form>
    </ng-template>
</context-menu>

<context-menu #govworkitem>
    <ng-template contextMenuItem let-item passive="true">
        <gov-form class="overflow-hidden h-100" [readonly]="readonly" [datasets]="workitemModule"></gov-form>
    </ng-template>
</context-menu>

<div class="h-100 w-100" [gov-editor]="app.datasources.projecttypes" [toeditting]="toeditting"
    (onDiagramImported)="onDiagramImported($event)" (onDiagramDestroied)="onDiagramDestroied($event)"
    (onCurrentModule)="onCurrentModule($event)">
</div>