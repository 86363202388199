import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';
import EventBus from 'diagram-js/lib/core/EventBus.js';
import { Shape, } from "bpmn-js/lib/model/Types";
import * as _ from 'lodash';

import { GovDiagramDirective } from './diagram.directive';

type IResizeContext = ResizeHook.IResizeContext;
type IResizeSetting = ResizeHook.IResizeSetting;

const DefaultMinheight = 40, DefaultMinwidth = 50;
const DefaultSetting: IResizeSetting = {
    'bpmn:Task': {
        minheight: 30,
        minwidth: 100
    },

    'bpmn:CallActivity': {
        minheight: 30,
        minwidth: 100
    },

    'bpmn:SubProcess': {
        minheight: 30,
        minwidth: 100
    },

    'bpmn:Event': {
        minheight: 36,
        minwidth: 36
    }
}

export class ResizeHook extends RuleProvider {
    constructor(
        public diagram: GovDiagramDirective,
        public eventbus: EventBus,
        public setting: IResizeSetting = DefaultSetting
    ) {
        super(eventbus)
    }

    init() {
        this.addRule('shape.resize', 1500, ({ shape: { businessObject }, newBounds }: IResizeContext) => {
            const setting = this.settingOf(businessObject);
            if (!setting) return;

            if (newBounds) {
                const { minheight = DefaultMinheight, minwidth = DefaultMinwidth } = setting;
                newBounds.height = Math.max(minheight, newBounds.height);
                newBounds.width = Math.max(minwidth, newBounds.width);
            }

            return true;
        });
    }

    instanceOf(businessObject: any, types: string | string[]): boolean {
        if (!businessObject) return false;

        if (!_.isArray(types)) {
            return businessObject.$instanceOf(types);
        }

        for (const key of types) {
            if (businessObject.$instanceOf(key)) {
                return true;
            }
        }

        return false;
    }

    settingOf(businessObject: any): IResizeSetting[string] | undefined {
        const { setting } = this;

        for (const key in setting) {
            if (this.instanceOf(businessObject, key)) {
                return setting[key];
            }
        }
    }

    static Module = {
        govResizeHook: ['type', ResizeHook],
        __init__: ['govResizeHook']
    }

    static $inject = [
        'govDiagram',
        'eventBus',
        'config.ResizeHook'
    ];
}

export namespace ResizeHook {
    export interface IResizeContext {
        direction?: 's' | 'n' | 'w' | 'e' | 'se' | 'sw' | 'ne' | 'nw';
        delta?: { x: number, y: number };
        newBounds?: {
            height: number,
            width: number,
            x: number,
            y: number,
        };
        shape: Shape;
    }

    export interface IResizeSetting {
        [type: string]: {
            disabled?: boolean,
            minheight?: number,
            minwidth?: number,
        }
    }
}