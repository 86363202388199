import { Component, OnDestroy } from "@angular/core";

import { Exec as TExec, Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { GovEditorComponent } from "../../utils/view/gov.editor.component/gov.editor.component";
import { SuperviseComponent } from "../../supervise/view/supervise.component";
import { ProjectDetailComponent } from "./projectdetail.component";
import { Report } from '../../application/service/backface/report';
import { Prj } from '../../application/service/backface/prj';

@Component({ template: '' })
export class ProjectSupervise extends SuperviseComponent implements ProjectDetailComponent.IHandler, OnDestroy {
    get goveditor(): GovEditorComponent {
        return this.host.goveditor;
    }

    get project() {
        return this.host.project;
    }

    constructor(public host: ProjectDetailComponent) {
        super(host.app, host.sys, host.httpClient);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    loadWorkitems(): void {
        const { app: { org, prj }, execute, project } = this
        const rows = (execute.rows = execute.rows || []);

        // find global supervise workitem
        const { IWorkitem: { Type: { supervise } } } = TPrj;
        const { app: { prj: { workitems: gworkitems } } } = this;
        const workitems = gworkitems.filter(w => w.type == supervise);
        rows.splice(0, rows.length, ...workitems.map(w => new Prj.Workitem(
            org, prj, project, w
        )))
    }

    loadAudits(): void {
        const { app, audit, project } = this
        const rows = (audit.rows = audit.rows || []);

        this.report("/entity/results/retrieve", (res: TExec.IAuditResult[] = []) => {
            Report.buildAuditResults(app, res, rows);
        }).query({
            workitem: {
                type: ["supervise"]
            },
            audit: [
                "auditting",
                "callback",
                "nopass",
                "pass"
            ],
            project: [
                project.id
            ]
        });
    }

    loadProblems(): void {
        const { app, problem, project } = this
        const rows = (problem.rows = problem.rows || []);

        this.report("/entity/problems/retrieve", (res: Report.IProblemEx[] = []) => {
            Report.buildProblems(app, res, rows);
        }).query({
            project: [project.id]
        });
    }

}