<div class="d-flex flex-row justify-content-end align-items-stretch" style="margin-right: 20px">
  <ng-container *ngFor="let status of statuslist;let i=index">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <span [attr.style]="status.style">{{ status.icontext }}</span>
        <span [attr.style]="status.style">{{ status.text }}</span>
      </div>
      <ng-container *ngIf="statuscount">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <span>{{ statuscount[i]?.count }}{{'general.item'|translate}}</span>
          <span>({{ statuscount[i]?.percent }}%)</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>