import { Component } from '@angular/core';

import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./team.component.html",
    styleUrls: ['./team.component.scss']
})
export class TeamComponent {
    constructor(public app: AppService) {
    }
}
