import { isNumber, isObject, castArray } from 'lodash';
import { EventEmitter } from '@angular/core';
import { isFunction, each } from 'lodash'

import { DeepPartial, NameOf } from '../types/mixins';
import { Property } from '../property';
import { toArray } from '../mixins';
import { Unique } from '../unique';
import { _JSON } from './json';

export { };

/**
 * Array modification monitoring
 */
const ArraySymbol: symbol = Unique.symbol('ARRAYHOOK');
const KeysToHook: KeysToHook[] = ['reverse', 'sort', 'splice', 'shift', 'unshift', 'push', 'pop'];

type KeysToHook = 'reverse' | 'sort' | 'splice' | 'shift' | 'unshift' | 'push' | 'pop';

type HookedPropertyDescriptors<T> = {
    [P in KeysToHook]?: PropertyDescriptor;
}

type HookedPropertyFunctions<T> = {
    [P in KeysToHook]?: Array<T>[P];
}

type HookedCache<T> = {
    original: HookedPropertyFunctions<T>,

    events?: {
        added: EventEmitter<T[]>,
        removed: EventEmitter<T[]>,
        preAddRemove: EventEmitter<T[]>,
        addRemoved: EventEmitter<{
            removed: T[],
            added: T[],
        }>,

        created: EventEmitter<T[]>,
        destroied: EventEmitter<T[]>,
        precreatedestroy: EventEmitter<T[]>,
        createDestroied: EventEmitter<{
            destroied?: T[],
            created?: T[]
        }>
    }
}

declare global {
    interface Array<T> {
        readonly original: {
            [P in KeysToHook]: Array<T>[P];
        };

        readonly [_Array.itemtype]: T;

        readonly last: T;

        readonly first: T;

        readonly $cid: string;

        readonly isEmpty: boolean;

        readonly onAdded: EventEmitter<T[]>;

        readonly onRemoved: EventEmitter<T[]>;

        readonly onPreAddRemove: EventEmitter<T[]>;

        readonly onAddRemoved: EventEmitter<{
            removed: T[],
            added: T[],
        }>;

        readonly onCreated: EventEmitter<T[]>;

        readonly onDestroied: EventEmitter<T[]>;

        readonly onPreCreateDestroied: EventEmitter<T[]>;

        readonly onCreateDestroied: EventEmitter<{
            destroied?: T[],
            created?: T[]
        }>

        parent: any;
        toJSON(): any;
        init(parent: any, val?: T);
        init(parent: any, val?: T[]);

        // clear all items in the array
        clear(): T[];

        /**
         * extract the members from each item with key into an array
         * 
         * @param key : key of member from each item to extract
         * @param novalue : For none object/array item, whether need to add into result array
         */
        pluck<K extends NameOf<T>>(key: K, novalue?: boolean): (T | T[K])[];

        /**
         * build the key/value objectify object for each item by the member name of key
         * 
         * @param key : key of member from each item to build the Key/Value
         */
        //objectify<T extends object, K extends OOK<T>, KI extends string | number = T[K]>(this: Array<T>, key: K): { [P in KI]?: T };
        where(attrs: DeepPartial<T> | ((item: T) => boolean)): T[];
        firstOf(attrs: DeepPartial<T> | ((item: T) => boolean)): T;
        has(item: T): boolean;

        // callbacks for driven to notify the changes.
        _onAddRemoved?({ removed, added }: { removed: T[], added: T[] }): void;
        _onPreAddRemove?(current: T[]): void;
        _onRemoved?(removed: T[]): void;
        _onAdded?(added: T[]): void;

        _onCreateDestroied?({ created, destroied }: { created?: T[], destroied?: T[] }): void;
        _onPreCreateDestroy?(current: T[]);
        _onDestroied?(destroied: T[]): void;
        _onCreated?(created: T[]): void;

        // add the item at the specified pos, return the added item/items
        add(val: T, at?: number): T;
        add(val: T[], at?: number): T[];
        add(val: T | T[], at?: number): T | T[];

        // remove the item, return the removed item/items
        remove(val: T): T;
        remove(val: T[]): T[];
        remove(val: T | T[]): T | T[];

        // remove the item, return the removed item/items
        reset();
        reset(val: T): T;
        reset(val: T[]): T[];
        reset(val: T | T[]): T | T[];

        createnew(val: T, at?: number): T;

        // create and add a new item
        create(val: T, at?: number): T;
        create(val: T[], at?: number): T[];
        create(val: T | T[], at?: number): T | T[];

        // destroy the elements
        destroy(val: T): T;
        destroy(val: T[]): T[];
        destroy(val: T | T[]): T | T[];

        // remove the item, return the removed item/items
        recreate();
        recreate(val: T): T;
        recreate(val: T[]): T[];
        recreate(val: T | T[]): T | T[];
    }
}

function getArrayBag(obj: any) {
    const props = Property.Of(obj) || {};
    return props[ArraySymbol] = props[ArraySymbol] ?? {};
}

function freeArrayBag(obj: any) {
    const props = Property.Of(obj) || {};
    delete props[ArraySymbol];
}

function getHookCache<T>(arr: Array<T>, create: boolean): HookedCache<T> {
    const cache: HookedCache<T> = getArrayBag(arr);

    if (create && !cache.events) {
        cache.events = {
            preAddRemove: new EventEmitter,
            addRemoved: new EventEmitter,
            removed: new EventEmitter,
            added: new EventEmitter,

            precreatedestroy: new EventEmitter,
            createDestroied: new EventEmitter,
            destroied: new EventEmitter,
            created: new EventEmitter,
        }
    }

    return cache;
}

function freeHookCache<T>(arr: Array<T>) {
    freeArrayBag(arr);
}

function _polyfill(_Array: ArrayConstructor) {
    if (!_Array || _Array.prototype.onAdded) {
        return;
    }

    const hookedPropertyDescs: HookedPropertyDescriptors<any> = {};
    const hookedPropertyFuncs: HookedPropertyFunctions<any> = {};

    KeysToHook.forEach(key => {
        hookedPropertyDescs[key] = Object.getOwnPropertyDescriptor(_Array.prototype, key);
        hookedPropertyFuncs[key] = hookedPropertyDescs[key].value;
    });

    // hook methods.
    for (let key in hookedPropertyFuncs) {

        Object.defineProperty(_Array.prototype, key, {
            ...hookedPropertyDescs[key],

            value: function <T>(this: Array<T>) {
                const cache = getHookCache(this, false);
                if (!(cache.events || this._onAdded || this._onRemoved || this._onAddRemoved)) {
                    return hookedPropertyFuncs[key].apply(this, arguments);
                }

                const args = toArray(arguments);
                let added: T[], removed: T[];

                switch (key) {
                    case 'push':
                        added = args;
                        break;

                    case 'pop':
                        removed = this.length > 0 ? [this[this.length - 1]] : [];
                        break;

                    case 'shift':
                        removed = this.length > 0 ? [this[0]] : [];
                        break;

                    case 'unshift':
                        added = args;
                        break;

                    case 'splice':
                        let [start, count, ...rest] = args;
                        let end = isNumber(count) ? start + count : count;

                        removed = this.slice(start, end);
                        added = rest;
                        break;

                    default: // reverse/sort cause the order changed
                        return hookedPropertyFuncs[key].apply(this, args);
                }

                if (added.length > 0 || removed.length > 0) {
                    cache.events?.preAddRemove.emit(this);
                    this._onPreAddRemove?.(this);
                }

                let result = hookedPropertyFuncs[key].apply(this, args);

                if (removed?.length > 0) {
                    this._onRemoved?.(removed);
                    cache.events?.removed.emit(removed);
                }

                if (added.length > 0) {
                    this._onAdded?.(added);
                    cache.events?.added.emit(added);
                }

                if (added.length > 0 || removed.length > 0) {
                    this._onAddRemoved?.({ removed, added });
                    cache.events?.addRemoved.emit({ removed, added });
                }

                return result;
            }
        });
    }

    if (!_Array.prototype.onAdded) {
        Object.defineProperty(_Array.prototype, 'onAdded', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<T[]> {
                return getHookCache(this, true).events.added;
            }
        })
    }

    if (!_Array.prototype.onRemoved) {
        Object.defineProperty(_Array.prototype, 'onRemoved', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<T[]> {
                return getHookCache(this, true).events.removed;
            }
        })
    }

    if (!_Array.prototype.onAddRemoved) {
        Object.defineProperty(_Array.prototype, 'onAddRemoved', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<{
                removed: T[],
                added: T[],
            }> {
                return getHookCache(this, true).events.addRemoved;
            }
        })
    }

    if (!_Array.prototype.onPreAddRemove) {
        Object.defineProperty(_Array.prototype, 'onPreAddRemove', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<T[]> {
                return getHookCache(this, true).events.preAddRemove;
            }
        })
    }

    if (!_Array.prototype.onCreated) {
        Object.defineProperty(_Array.prototype, 'onCreated', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<T[]> {
                return getHookCache(this, true).events.created;
            }
        })
    }

    if (!_Array.prototype.onDestroied) {
        Object.defineProperty(_Array.prototype, 'onDestroied', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<T[]> {
                return getHookCache(this, true).events.destroied;
            }
        })
    }

    if (!_Array.prototype.onCreateDestroied) {
        Object.defineProperty(_Array.prototype, 'onCreateDestroied', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<{ created?: T[], destroied?: T[] }> {
                return getHookCache(this, true).events.createDestroied;
            }
        })
    }

    if (!_Array.prototype.onPreCreateDestroied) {
        Object.defineProperty(_Array.prototype, 'onPreCreateDestroied', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): EventEmitter<T[]> {
                return getHookCache(this, true).events.precreatedestroy;
            }
        })
    }

    if (!_Array.prototype.$cid) {
        Object.defineProperty(_Array.prototype, '$cid', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): string {
                return Unique.numbered(this);
            }
        })
    }

    if (!_Array.prototype.first) {
        Object.defineProperty(_Array.prototype, 'first', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): T {
                return this[0];
            }
        })
    }

    if (!_Array.prototype.last) {
        Object.defineProperty(_Array.prototype, 'last', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): T {
                return this[this.length - 1];
            }
        })
    }

    if (!_Array.prototype.isEmpty) {
        Object.defineProperty(_Array.prototype, 'isEmpty', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): boolean {
                return this.length <= 0;
            }
        })
    }

    if (!_Array.prototype.original) {
        Object.defineProperty(_Array.prototype, 'original', {
            enumerable: false,
            configurable: true,
            get: function <T>(this: Array<T>): HookedPropertyFunctions<T> {
                const bag = getHookCache(this, true);

                if (!bag.original) {
                    const _this = this;
                    bag.original = {};

                    each(hookedPropertyDescs, (val, key) => {
                        Object.defineProperty(bag.original, key, {
                            ...val,
                            value: function () {
                                return val.value.apply(_this, arguments);
                            }
                        })
                    })
                }

                return bag.original;
            }
        })
    }

    if (!_Array.prototype.clear) {
        Object.defineProperty(_Array.prototype, 'clear', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>): T[] {
                const cache = getHookCache(this, false), curs = this.slice();
                const hasd = curs?.length > 0;

                cache.events?.precreatedestroy.emit(this);
                this._onPreCreateDestroy?.(this);

                const _vals = this.splice(0, this.length);

                (hasd) && cache.events?.createDestroied.emit({ destroied: curs });
                hasd && cache.events?.destroied.emit(curs);

                (hasd) && this._onCreateDestroied?.({ destroied: curs });
                hasd && this._onDestroied?.(curs);

                return _vals;
            }
        })
    }

    if (!_Array.prototype.toJSON) {
        Object.defineProperty(_Array.prototype, 'toJSON', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>): any[] {
                return this.map((model) => _JSON.JsonOf(model));
            }
        })
    }

    if (!_Array.prototype.pluck) {
        Object.defineProperty(_Array.prototype, 'pluck', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T, K extends NameOf<T>>(this: Array<T>, key: K, novalue: boolean = false): (T | T[K])[] {
                return this.map((val) => (isObject(val) || _Array.isArray(val)) ? val[key] : (novalue ? undefined : val));
            }
        })
    }

    if (!_Array.prototype.firstOf) {
        Object.defineProperty(_Array.prototype, 'firstOf', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function firstOf<T>(this: Array<T>, attrs: DeepPartial<T> | ((item: T) => boolean)): T {
                // no filter condition, return the first one.
                if (!attrs) return this[0];

                for (let idx = 0, cnt = this.length; idx < cnt; idx++) {
                    const item = this[idx];
                    if (isFunction(item?.['firstOf'])) {
                        const sub = item['firstOf'].call(item, attrs);
                        if (sub) return sub;
                    } else if (this.match.call(item, attrs)) {
                        return item;
                    }
                }
            }
        })
    }

    if (!_Array.prototype.has) {
        Object.defineProperty(_Array.prototype, 'has', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function has<T>(this: Array<T>, item: T): boolean {
                return this.indexOf(item) >= 0;
            }
        })
    }

    /*
    if (!_Array.prototype.objectify) {
        Object.defineProperty(_Array.prototype, 'objectify', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function objectify<T extends object, K extends NameOf<T, never, string | number>>(this: Array<T>, key: K): { [P in T[K]]?: T } {
                const res: { [P in T[K]]?: T } = {};

                for (let idx = 0, n = this.length; idx < n; idx++) {
                    const obj = this[idx], rkey = obj[key];
                    (rkey !== null && rkey !== undefined) && (res[rkey] = obj);
                }

                return res;
            }
        })
    }
    */

    if (!_Array.prototype.where) {
        Object.defineProperty(_Array.prototype, 'where', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function where<T>(this: Array<T>, attrs: DeepPartial<T> | ((item: T) => boolean)): T[] {
                // no filter condition, return all.
                if (!attrs) return this;

                let res: T[] = [];
                for (let idx = 0, n = this.length; idx < n; idx++) {
                    let obj = this[idx];

                    if (this.match.call(obj, attrs)) {
                        res.push(obj);
                    }
                }

                return res;
            }
        })
    }

    if (!_Array.prototype.add) {
        Object.defineProperty(_Array.prototype, 'add', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T | T[], at?: number): T | T[] {
                var _val: T[] = castArray<T>(val);
                this.splice(at ?? this.length, 0, ..._val);

                return val;
            }
        })
    }

    if (!_Array.prototype.remove) {
        Object.defineProperty(_Array.prototype, 'remove', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T | T[]): T | T[] {
                castArray<T>(val).forEach(_val => {
                    for (var index = this.indexOf(_val); index >= 0; index = this.indexOf(_val)) {
                        this.splice(index, 1);
                    }
                });

                return val;
            }
        })
    }

    if (!_Array.prototype.reset) {
        Object.defineProperty(_Array.prototype, 'reset', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T | T[]): T | T[] {
                val = val || [];

                var _val: T[] = castArray<T>(val);
                this.splice(0, this.length, ..._val);

                return val;
            }
        })
    }

    if (!_Array.prototype.createnew) {
        Object.defineProperty(_Array.prototype, 'createnew', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T, at?: number): T {
                return this.create(val, at);
            }
        })
    }

    if (!_Array.prototype.create) {
        Object.defineProperty(_Array.prototype, 'create', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T | T[], at?: number): T | T[] {
                const cache = getHookCache(this, false), vals = castArray<T>(val);
                const hasc = vals?.length > 0;

                cache.events?.precreatedestroy.emit(this);
                this._onPreCreateDestroy?.(this);

                const _vals = this.add(val, at);

                hasc && cache.events?.createDestroied.emit({ created: vals });
                hasc && cache.events?.created.emit(vals);

                hasc && this._onCreateDestroied?.({ created: vals });
                hasc && this._onCreated?.(vals);

                return _vals;
            }
        })
    }

    if (!_Array.prototype.destroy) {
        Object.defineProperty(_Array.prototype, 'destroy', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T | T[]): T | T[] {
                const cache = getHookCache(this, false), vals = castArray<T>(val);
                const hasd = vals?.length > 0;

                cache.events?.precreatedestroy.emit(this);
                this._onPreCreateDestroy?.(this);

                const _vals = this.remove(val);

                hasd && cache.events?.createDestroied.emit({ destroied: vals });
                hasd && cache.events?.destroied.emit(vals);

                hasd && this._onCreateDestroied?.({ destroied: vals });
                hasd && this._onDestroied?.(vals);

                return _vals;
            }
        })
    }

    if (!_Array.prototype.recreate) {
        Object.defineProperty(_Array.prototype, 'recreate', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T>(this: Array<T>, val: T | T[]): T | T[] {
                const cache = getHookCache(this, false), vals = castArray<T>(val), curs = this.slice();
                const hasc = vals?.length > 0, hasd = curs?.length > 0;

                cache.events?.precreatedestroy.emit(this);
                this._onPreCreateDestroy?.(this);

                const _vals = this.reset(val);

                (hasc || hasd) && cache.events?.createDestroied.emit({ created: vals, destroied: curs });
                hasd && cache.events?.destroied.emit(curs);
                hasc && cache.events?.created.emit(vals);

                (hasc || hasd) && this._onCreateDestroied?.({ created: vals, destroied: curs });
                hasd && this._onDestroied?.(curs);
                hasc && this._onCreated?.(vals);

                return _vals;
            }
        })
    }

}

_polyfill(Array);

export namespace _Array {

    export type ItemType<T> = T extends Array<any> ? T[typeof itemtype] : never;
    export const itemtype: unique symbol = Symbol('arrayitemtype');

    export function cacheOf<T>(arr: Array<T>) {
        return getHookCache(arr, false);
    }

    export function polyfill(wnd: Window) {
        wnd && _polyfill(wnd['Array']);
    }
}