import { Component, AfterViewInit, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { isNull, isUndefined, max } from 'lodash';

import { WorkItemListComponent } from '../../../workitemlist/view/workitemlist.component';
import { ProjectListComponent } from '../../../projectlist/view/projectlist.component';
import { EChartOption, Report } from '../../../application/service/backface/report';
import { Backface } from '../../../application/service/backface/config';
import { AppService } from '../../../application/service/app.service';
import { Prj, Sys } from '../../../application/service/backface/types';
import { _JSON } from '../../../utils/libs/polyfill/json';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Property } from '../../../utils/libs/property';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'reportworkitemexe, [reportworkitemexe]',
    templateUrl: "./reportworkitemexe.component.html",
    styleUrls: ['./reportworkitemexe.component.scss']
})
export class ReportWorkitemExeComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of(this).values;
    readonly noinputtxt: boolean = true;

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    readonly prjcount: Sys.ICardSumItem = {
        title: 'report.refproject',
        year: this.app.dict.currentYear,
        count: 0,
    };

    readonly wscount: Sys.ICardSumItem = {
        title: 'report.workitemcount',
        year: this.app.dict.currentYear,
        count: 0,
    };

    readonly overduecount: Sys.ICardSumItem = {
        title: 'report.workitemoverdue',
        year: this.app.dict.currentYear,
        warning: true,
        count: 0,
    };

    readonly ongoingcount: Sys.ICardSumItem = {
        title: 'report.workitemongoing',
        year: this.app.dict.currentYear,
        count: 0,
    };

    readonly checkoption: EChartOption = new Report.ReportTemplate().ReportTemplateMixedLineBar(this.app.report.executionmixlinebarcolors, 1, 3);
    private dataMap: Map<string, object> = new Map<string, object>();

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    @Input('prjtypeid')
    prjtypeid: string;

    constructor(
        public app: AppService,
        public dialog: MatDialog,
        public router: ActivatedRoute,
        protected httpClient: HttpClient,
    ) {
        super(router, 'reportworkitemexec');
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);

        const { app: { dict: { rangeprjcountmax, rangewscountmax }, lang: { report } } } = this;
        this.checkoption.title['text'] = report.stagesummary;

        this.checkoption.legend['data'].clear();
        this.checkoption.legend['data'][0] = (report.refproject);
        this.checkoption.legend['data'][1] = (report.workitemexecount);
        this.checkoption.legend['data'][2] = (report.workitemexeongoing);
        this.checkoption.legend['data'][3] = (report.workitemexeoverdue);

        this.checkoption.xAxis[0]['data'] = this.app.prj.projecttypes.find(pt => pt.id == this.selectedProcess)?.stages.reduce((res, v) => {
            res.push(v.name);
            return res;
        }, []);

        this.checkoption.yAxis[0]['name'] = report.refproject;
        this.checkoption.yAxis[1]['name'] = report.workitemexecount;
        this.checkoption.yAxis[2]['name'] = report.workitemexeongoing;
        this.checkoption.yAxis[3]['name'] = report.workitemexeoverdue;

        this.checkoption.yAxis[0]['max'] = rangeprjcountmax;
        this.checkoption.yAxis[1]['max'] = rangewscountmax;
        this.checkoption.yAxis[2]['max'] = rangewscountmax;
        this.checkoption.yAxis[3]['max'] = rangewscountmax;

        this.checkoption.series[0]['id'] = ('project');
        this.checkoption.series[1]['id'] = ('count');
        this.checkoption.series[2]['id'] = ('ongoing');
        this.checkoption.series[3]['id'] = ('delaying');

        this.checkoption.series[0]['name'] = report.refproject;
        this.checkoption.series[1]['name'] = report.workitemexecount;
        this.checkoption.series[2]['name'] = report.workitemexeongoing;
        this.checkoption.series[3]['name'] = report.workitemexeoverdue;

        this.checkoption.series[0]['data'] = [0];
        this.checkoption.series[1]['data'] = [0];
        this.checkoption.series[2]['data'] = [0];
        this.checkoption.series[3]['data'] = [0];
    }

    ngAfterViewInit(): void {
        this.GetSummaryData();
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter.ngOnDestroy();
    }

    set selectedProcess(val: string) {
        const { toolbarcontent } = this;
        if (toolbarcontent.selectedProcess == val) return;

        toolbarcontent.selectedProcess = val;
        this.saveToolbarContent();

        const { selectedProcess } = this;
        this.checkoption.xAxis[0]['data'] = this.app.prj.projecttypes.find(pt => pt.id == selectedProcess)?.stages.reduce((res, v) => {
            res.push(v.name);
            return res;
        }, []);

        this.GetSummaryData();
    }

    get selectedProcess(): string {
        if (this.prjtypeid) return this.prjtypeid;

        const { toolbarcontent: { selectedProcess } } = this;
        return selectedProcess ?? this.app.prj.projecttypes[0].id;
    }

    processName(): string {
        return this.app.prj.projecttypes.find(p => p.id == this.selectedProcess)?.name;
    }

    GetSummaryData() {
        this.reporter.report("/report/workitem_count_per_project_stage_status_by_project_attribute", (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            if (!isNull(res['count']) && !isUndefined(['count'])) {
                //update count card
                this.prjcount.count = res['count'].project.nonpatched;
                this.overduecount.count = res['count'].delaying;
                this.ongoingcount.count = res['count'].ongoing;
                this.ongoingcount.count += res['count'].notstart;
                this.ongoingcount.count += res['count'].unknown;
                this.wscount.count = this.ongoingcount.count + this.overduecount.count;
            }

            if (!isNull(res['stage']) && !isUndefined(['stage'])) {
                const { app: { dict: { rangeprjcountmax, rangewscountmax }} } = this;

                var prjcountmax:number =  rangeprjcountmax;
                var wscountmax:number = rangewscountmax;

                //update stage
                var i = 0;
                this.app.prj.projecttypes.find(pt => pt.id == this.selectedProcess)?.stages.forEach(stage => {
                    var obj = res['stage'].find(s => s.id == stage.id);
                    if (isNull(obj) || isUndefined(obj)) {
                        this.checkoption.series[0]['data'][i] = 0;
                        this.checkoption.series[1]['data'][i] = 0;
                        this.checkoption.series[2]['data'][i] = 0;
                        this.checkoption.series[3]['data'][i] = 0;
                        i++;
                        return;
                    }

                    this.checkoption.series[0]['data'][i] = obj.count.project.nonpatched;
                    this.checkoption.series[1]['data'][i] = obj.count.delaying + obj.count.ongoing + obj.count.notstart + obj.count.unknown;
                    this.checkoption.series[2]['data'][i] = obj.count.ongoing + obj.count.notstart + obj.count.unknown;
                    this.checkoption.series[3]['data'][i] = obj.count.delaying;

                    prjcountmax = max([prjcountmax, this.checkoption.series[0]['data'][i]]);
                    wscountmax = max([wscountmax, this.checkoption.series[1]['data'][i],this.checkoption.series[2]['data'][i],this.checkoption.series[3]['data'][i]]);

                    if (this.checkoption.series[0]['data'][i] != 0 && this.dataMap) {
                        var key = obj.name + 'project';
                        this.dataMap.set(key, obj['project']);
                    }

                    i++;
                });

                if(prjcountmax > rangeprjcountmax) {
                    prjcountmax += 10; 
                    this.checkoption.yAxis[0]['max'] = prjcountmax;
                }
                
                if(wscountmax > rangewscountmax) {
                    wscountmax += 10;
                    this.checkoption.yAxis[1]['max'] = wscountmax;
                    this.checkoption.yAxis[2]['max'] = wscountmax;
                    this.checkoption.yAxis[3]['max'] = wscountmax;
                }

                Report.RefreshEchart("reportwsexeoption" + this.selectedProcess, this.checkoption);
            }
        }).query({
            constrstatus: { type: ["unknown", "notstart", "ongoing", "delaying"] },
            //stagestatus: { type: ["unknown","notstart", "ongoing", "delaying"] },
            projecttype: [{ id: this.selectedProcess }]
        });
    }

    onClickCharBar(params): void {
        if (params?.value <= 0) return;

        const { app: { lang: { stage, general } } } = this;

        var sid = params?.seriesId;
        var title = this.checkoption.title['text'];
        var header = `${stage.name}:${params.name}/${params.seriesName}/${params.value}`;

        if (sid == 'project')
            header += general.unit;
        else
            header += general.item;

        if (sid == 'project') {
            var idlist = [];
            if (this.dataMap) {
                var key = params.name + params.seriesId;
                var obj = this.dataMap.get(key);
                if (obj) {
                    idlist = (obj as Array<Object>);
                }
            }

            ProjectListComponent.open(this.dialog, {
                prjidlist: idlist,
                header: header,
                title: title,
            })
        } else if (sid == 'count' || sid == 'ongoing' || sid == 'delaying') {
            WorkItemListComponent.open(this.dialog, {
                title: title,
                header: header,
                prjidlist: null,
                stageidlist: [this.app.prj.projecttypes.find(pt => pt.id == this.selectedProcess)?.stages.find(stage => stage.name == params.name)?.id],
                workitemstatus: (sid == 'count') ? ["unknown", "notstart", "ongoing", "delaying"] : ((sid == 'delaying') ? ["delaying"] : ["unknown", "notstart", "ongoing"])
            })
        }
    }
}
