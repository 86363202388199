import { Directive, Input, Host, ElementRef } from "@angular/core";
import { Property } from "../../utils/libs/property";

@Directive({
    selector: "video[stopif], audio[stopif]"
}) export class MediaStopIfDirective {
    private props = Property.Of(this).values;

    @Input('stopif')
    get stop(): boolean {
        return this.props.stop;
    }

    set stop(val: boolean) {
        if ((this.props.stop != val) && val) {
            this.media?.nativeElement.pause();
        }

        this.props.stop = val;
    }

    constructor(
        @Host()
        public media: ElementRef<HTMLVideoElement | HTMLAudioElement>
    ) {
        this.props.stop = false;
    }
}
