
import { EventEmitter, TemplateRef, Injectable, Directive, OnInit, Output, Input } from '@angular/core';
import { isArray } from 'lodash';

@Injectable(
) export class TemplateService {
    constructor() {
    }

    private templates: {
        [id: string]: TemplateRef<any>
    } = {};

    @Output()
    changed: EventEmitter<{
        id: string,
        template: TemplateRef<any>
    }> = new EventEmitter;

    add(id: string | string[], template: TemplateRef<any>) {
        if (isArray(id)) {
            return id.forEach(sid => this.add(sid, template));
        }

        id = id.trim();
        if (!id || this.templates[id]) return;

        this.templates[id] = template;
        this.changed.emit({
            template: template,
            id: id,
        });
    }

    get(id: string): TemplateRef<any> {
        return this.templates[id];
    }
};

@Directive({
    selector: 'ng-template[id]'
}) export class TemplateDefine implements OnInit {
    @Input('id')
    id: string;

    constructor(
        private tmpls: TemplateService,
        private tpl: TemplateRef<any>
    ) {
    }

    ngOnInit(): void {
        this.tmpls.add(this.id.split(','), this.tpl);
    }
};