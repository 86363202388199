import { Component, AfterViewInit, OnInit, Input, OnDestroy, Inject, LOCALE_ID, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { isNull, isUndefined, keys, sum } from 'lodash';

import { Prj as TPrj } from '../../../application/service/backface/types';
import { ExcelService } from '../../../application/service/excel.service';
import { TemplateService } from '../../../utils/view/template.directive';
import { FieldProperty } from '../../../utils/view/model/field.property';
import { Backface } from '../../../application/service/backface/config';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Prj } from '../../../application/service/backface/prj';
import { Property } from '../../../utils/libs/property';
import { Editor } from '../../../utils/libs/editor';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: "sumongoingproject, [sumongoingproject]",
    templateUrl: "./sumongoingproject.component.html",
    styleUrls: ['./sumongoingproject.component.scss'],
    providers: [TemplateService]
})
export class SumOngoingProjectComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit, ExcelService.ISummary {
    private readonly _props = Property.Of(this).values;    
    readonly noinputtxt: boolean = true;

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    get fielder(): FieldProperty {
        const { _props: props, app } = this;
        return props.fielder || (
            props.fielder = new FieldProperty(app)
        )
    }

    // Summary Fields Parameter
    YEARMONTH: string = "yearlymonthcostinfo";
    YEARMONTHASSEST: string = "yearlyassetmonthcostinfo";

    prjcount: number = 0;
    totalbudget: number = 0;
    yearbudget: number = 0;
    yearconsume: number = 0;
    yearlyassetinvested: number = 0;

    sumdisplayedColumns: string[] = [];    
    sum2displayedColumns: string[] = [];
    get sumFields(): Editor.IFieldCols {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields || (props.sumFields = [
            {
                key: "total.key",
                title: lang.general.total,
                col: 3
            },
            {
                key: "total.value",
                get title() {
                    const { prjcount } = _this;
                    return `${prjcount}${lang.general.unit}`;
                },
                col: 3
            },
            {
                key: "amount.key",
                title: lang.budget.amount,
                col: 3
            },
            {
                key: "amount.value",
                get title() {
                    const { totalbudget, locale } = _this;
                    return formatNumber(totalbudget, locale, "1.0-0")
                },
                col: 3
            },
            {
                key: "yearamount.key",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.budget.amount}`
                },
                col: 3
            },
            {
                key: "yearamount.value",
                get title() {
                    const { yearbudget, locale } = _this;
                    return formatNumber(yearbudget, locale, "1.0-0");
                },
                col: 3
            },
            {
                key: "yearconsume.key",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.budget.consume}`;
                },
                col: 6
            },
            {
                key: "yearconsume.value",
                get title() {
                    const { yearconsume, locale } = _this;
                    return formatNumber(yearconsume, locale, "1.0-0");
                },
                col: 6
            },
            {
                key: "general.yearlyassetinvested",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.general.yearlyassetinvested}`;
                },
                col: 4
            },
            {
                key: "yearlyassetinvested.value",
                get title() {
                    const { yearlyassetinvested, locale } = _this;
                    return formatNumber(yearlyassetinvested, locale, "1.0-0");
                },
                col: 6
            },
            {
                key: "budgetunit",
                title: `${lang.budget.unit}：${lang.general.bunit}`,
                col: 2
            }
        ]);
    }

    get sumFields2(): Editor.IFieldCols {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields2 || (props.sumFields2 = [
            {
                key: "projectinfo",
                title: lang.summary.projectinfo,
                col: 18
            },
            {
                key: this.YEARMONTH,
                get title() {
                    return `${lang.sysenums.monthly}${lang.general.yearlyinvested}`;
                },
                col: 12
            },
            {
                key: this.YEARMONTHASSEST,
                get title() {
                    return `${lang.sysenums.monthly}${lang.general.yearlyassetinvested}`;
                },
                col: 12
            }
        ]);
    }    

    // Main Table Header & Data Zone parameter
    displayedColumns: string[] = [];
    dataSource: object[] = [];
    colFields = <Editor.IFieldCols>[
        {
            key: "id",
            title: this.app.lang.project.id,
            col: 1
        },
        {
            key: "planname",
            title: this.app.lang.project.plan,
            col: 1
        },
        {
            key: "name",
            title: this.app.lang.project.name,
            col: 1
        },
        {
            key: "actorleaders",
            title: this.app.lang.actor.leaders,
            col: 1
        },
        {
            key: "owndept",
            title: this.app.lang.project.owndept,
            col: 1
        },
        {
            key: "owncompany",
            title: this.app.lang.project.owncompany,
            col: 1
        },
        {
            key: "location",
            title: this.app.lang.project.location,
            col: 1
        },
        {
            key: "constrtype",
            title: this.app.lang.project.constrtype,
            col: 1
        },
        {
            key: "description",
            title: this.app.lang.project.description,
            cellcls: 'largewidth',
            col: 1
        },
        {
            key: "budgetstartend",
            title: this.app.lang.budget.startend,
            col: 1
        },
        {
            key: "budgetamount",
            title: this.app.lang.budget.amount,
            col: 1
        },
        {
            key: "budgetyearamount",
            title: this.app.lang.general.annual + this.app.lang.budget.amount,
            col: 1
        },
        {
            key: "budgetyeartarget",
            title: this.app.lang.general.annual + this.app.lang.budget.target,
            cellcls: 'largewidth',
            col: 1
        },
        {
            key: "budgetyearconsume",
            title: this.app.lang.general.annual + this.app.lang.budget.consume,
            col: 1
        },
        {
            key: "budgetyearpercent",
            title: this.app.lang.general.annual + this.app.lang.budget.percent,
            col: 1
        },
        {
            key: "progresscomment",
            title: this.app.lang.general.progresscomment,
            col: 1
        },
        {
            key: "problems",
            title: this.app.lang.problem.problem,
            col: 1
        },
        {
            key: "memo",
            title: this.app.lang.project.memo,
            col: 1
        }
    ];

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    @Input('module')
    // @ts-ignore
    module: Sys.IDatasetModule;

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        public app: AppService,
        public router: ActivatedRoute,

        protected httpClient: HttpClient,
    ) {
        super(router, 'sumongoingproject');
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);        
        
        this.app.dict.months.forEach(month => {
            this.colFields.push({
                key: this.YEARMONTH+month.id,
                title: month.name,
                col: 1
            });
        });

        this.app.dict.months.forEach(month => {
            this.colFields.push({
                key: this.YEARMONTHASSEST+month.id,
                title: month.name,
                col: 1
            });
        });

        this.sumdisplayedColumns = this.sumFields.map(p => p.key);
        this.sum2displayedColumns = this.sumFields2.map(p => p.key);
        this.displayedColumns = this.colFields.map(p => p.key);
        this.GetProjectData();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter.ngOnDestroy();
    }

    set selectedYear(val: number) {
        const { toolbarcontent } = this;
        if (toolbarcontent.selectedYear == val) return;

        toolbarcontent.selectedYear = val;
        this.saveToolbarContent();

        this.GetProjectData();
    }

    get selectedYear(): number {
        const { toolbarcontent: { selectedYear }, app: { dict: { currentYear } } } = this;
        return selectedYear || currentYear;
    }
    
    dosearch(): void {
        //TODO get data from backend service
        this.GetProjectData();
    }

    exportas(val: string) {
        const { selectedYear, app: { lang: { general: { orgname, annual }, tabs: { summaryongoingproject } } } } = this;
        ExcelService.generateExcel(`${orgname}${selectedYear}${annual}${this.app.dict.PrjPool.indexed[this.searcher['pool']]?.title}${summaryongoingproject}`, this as ExcelService.ISummary);
    }

    GetProjectData() {
        const param = {
            "year": [this.selectedYear, this.selectedYear],
            "project": [],
            "pool": [TPrj.Pool[this.searcher['pool']]]
        };

        const { app: { prj: { project: projects }, dict: { monthcostdefault } }, searcher } = this;
        projects.allOf({ ispatched: false, cancelled: false }, project => {
            if (!project.budget?.checkYear(this.selectedYear)) return;
            if ( project.pool != searcher['pool']) return;

            param.project.push({
                id: project.id
            });
        });

        this.dataSource = [];
        this.prjcount = 0;
        this.totalbudget = 0;
        this.yearbudget = 0;
        this.yearconsume = 0;

        projects.allOf({ ispatched: false, cancelled: false, ispreproject: false }, project => {
            if (!project.budget?.checkYear(this.selectedYear)) return;
            if ( project.pool != searcher['pool']) return;

            var sumproject: Prj.SumProjectData = {
                id: project.id,
                name: project.name,
                pool: TPrj.Pool[project.pool],
                owncompany: project.owncompany,
                owndept: project.owndept,
                planname: project?.plan?.name,
                location: project.location,
                positonDistrict: project.positonDistrict,
                description: project.description,
                budgetstartend: project.budget.startend,
                constrtype: project.constrtypename,
                constrstatus: project.constrstatusname,
                budgetamount: formatNumber(project.budget.amount, this.locale, "1.0-0"),
                budgetyearamount: formatNumber(project.getBudgetAmount(this.selectedYear), this.locale, "1.0-0"),
                budgetyeartarget: project.getBudgetTarget(this.selectedYear),
                actorheader: project.actor?.header?.name,
                actorleaders: project.getActorleaders(),
                actoractorsdept: project.getActoractorsdept(),
                actordomainsdept: project.getActordomainsdept(),
                actorsitesdept: project.getActorsitesdept(),
                memo: project.memo,
                budgetyearconsume: project.getBudgetConsume(this.selectedYear).toString(),
                budgetyearpercent: project.getBudgetPercent(this.selectedYear).toString() + "%",
                progresscomment: "",
                problems: ""
            };

            const p: object = {};

            // Map sum of project
            keys(sumproject).forEach(key => {
                p[key] = sumproject[key];
            });

            this.app.dict.months.forEach(month => {
                p[this.YEARMONTH+month.id] = "0";
            });

            this.app.dict.months.forEach(month => {
                p[this.YEARMONTHASSEST+month.id] = "0";
            });

            this.dataSource.push(p);
            this.totalbudget += (project.budget.amount) ? (project.budget.amount) : 0;
            this.yearbudget += project.getBudgetAmount(this.selectedYear);
            this.yearconsume += project.getBudgetConsume(this.selectedYear);
            this.yearlyassetinvested += 0; //Project don't implement this interface
        });

        this.prjcount = this.dataSource.length;

        this.reporter.report("/xretrieve/lastsupervise", (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            res.forEach(spitem => {
                var pid = spitem?.project?.id;
                if (isNull(pid) || isUndefined(pid)) { return; }

                var sumprj = this.dataSource.find(p => p['id'] == pid);
                if (isNull(sumprj) || isUndefined(sumprj)) { return; }
                
                var yearlyassetinvested = spitem?.value?.yearlyassetinvested? spitem?.value?.yearlyassetinvested : 0;
                this.yearlyassetinvested += yearlyassetinvested;

                if (!isNull(spitem['month']) && !isUndefined(spitem['month'])) {
                    //update yearly month cost
                    for (let index = 1; index <= 12; index++) {
                        var month = this.selectedYear + '-';
                        if (index < 10) month += '0';
                        month += index.toString();
                        var obj = spitem['month'].find(m => m.month == month);
                        if(isNull(obj) || isUndefined(obj) ) continue;
                        if(isNull(obj['consume']) || isUndefined(obj['consume']) ) continue;
                        if(!isNull(obj['consume']['yearlyinvested']) && !isUndefined(obj['consume']['yearlyinvested'])) {
                            var cost:number = obj['consume']['yearlyinvested'];
                            sumprj[this.YEARMONTH + formatNumber(index, this.locale, "2.0-0")] = formatNumber(cost, this.locale, "1.0-0");
                        }
                        if(!isNull(obj['consume']['yearlyassetinvested']) && !isUndefined(obj['consume']['yearlyassetinvested'])) {
                            var cost:number = obj['consume']['yearlyassetinvested'];
                            sumprj[this.YEARMONTHASSEST + formatNumber(index, this.locale, "2.0-0")] = formatNumber(cost, this.locale, "1.0-0");
                        } 
                    }
                }

                var pcomments = spitem?.value?.progresscomment;
                if (isNull(pcomments) || isUndefined(pcomments)) { return; }

                sumprj['progresscomment'] = pcomments;

                if (isNull(spitem?.value?.problem) || isUndefined(spitem?.value?.problem)) { return; }

                var problems = "";
                for (let index = 0; index < spitem?.value?.problem?.length; index++) {
                    const p = spitem?.value?.problem[index];
                    if (isNull(p) || isUndefined(p)) { continue; }
                    problems += index + 1;
                    problems += " " + p.problem;
                    problems += "(";
                    problems += p.fixed ? this.app.lang.general.fixed : this.app.lang.general.notfixed;
                    problems += ")";
                    problems = problems + "\n";
                }

                sumprj['problems'] = problems;
            });
        }).query(param);
    }

    getColspan(row: number, i: number): number {
        if (row == 1) {
            return this.sumFields[i].col;
        }

        if (row === 2) {
            return this.sumFields2[i].col;
        }

        if (row === 3) {
            return this.colFields[i].col;
        }
    }

    get sumFieldsData(): Editor.IFieldCols[] {
        return [this.sumFields, this.sumFields2];
    }

    get bodyDataSource(): object[] {
        return this.dataSource;
    }

    get colFieldsData(): Editor.IFieldCols {
        return this.colFields;
    }
}
