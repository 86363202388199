<ng-template #authForms let-mode="mode">
  <form novalidate #authform="ngForm" autocomplete="off" class="ng-untouched ng-pristine ng-valid">
    <ng-template #mfabtn>
      <button mat-button (click)="auth.mfa.get()" [disabled]="auth.mfa.state == 'none'">
        {{
          auth.mfa.state == "getting"
            ? auth.mfa.seconds + ("general.second" | translate)
            : auth.mfa.seconds == null
            ? ("mfa.getmfa" | translate)
            : ("mfa.regetmfa" | translate)
        }}
      </button>
    </ng-template>

    <ng-template #fieldinput_ let-icon="icon" let-type="type" let-name="name" let-field="field" let-label="label"
      let-onblur="onblur" let-readonly="readonly" let-pattern="pattern" let-command="command"
      let-patternalert="patternalert">
      <mat-form-field appearance="standard" class="pb-3">
        <mat-label>
          <mat-icon>{{ icon }}</mat-icon>
          {{ label }}
        </mat-label>

        <input matInput [type]="type" [pattern]="pattern" [readonly]="readonly" [placeholder]="label"
          [attr.name]="name || field" (blur)="onblur && onblur()" [(ngModel)]="auth.form[field]"
          [ngModelOptions]="{ name: field, updateOn: 'blur' }" autocomplete="on" required />

        <ng-container matSuffix *ngIf="command">
          <button mat-button *ngIf="!command.template" (click)="command.action && command.action()">
            {{ command.label }}
          </button>
          <ng-container *ngIf="command.template">
            <ng-container *ngTemplateOutlet="command.template; context: command.context"></ng-container>
          </ng-container>
        </ng-container>

        <mat-error *ngIf="
            authform.controls[field]?.touched &&
            authform.controls[field]?.invalid &&
            !readonly
          ">{{
            authform.controls[field]?.hasError("required")
              ? ("input.hint" | translate) + label
              : authform.controls[field]?.hasError("pattern")
              ? label +
                ("input.error1" | translate) +
                "," +
                (patternalert || ("input.error2" | translate) + label)
              : ""
          }}</mat-error>
      </mat-form-field>
    </ng-template>

    <ng-template #fieldinput let-icon="icon" let-type="type" let-name="name" let-field="field" let-label="label"
      let-onblur="onblur" let-readonly="readonly" let-command="command" let-pattern="pattern"
      let-patternalert="patternalert">
      <div class="form-group">
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text px-2">
              <mat-icon>{{ icon }}</mat-icon>
            </span>
          </div>

          <input matInput [type]="type" [pattern]="pattern" [readonly]="readonly" [placeholder]="label"
            [attr.name]="name || field" (blur)="onblur && onblur()" [(ngModel)]="auth.form[field]"
            [ngModelOptions]="{ name: field, updateOn: 'blur' }" class="form-control px-1 nobg shows" autocomplete="off"
            required />

          <div *ngIf="command" class="input-group-append">
            <button mat-button *ngIf="!command.template" (click)="command.action && command.action()"
              class="btn btn-primary btn-sm border-0 nobg shows">
              {{ command.label }}
            </button>
            <ng-container *ngIf="command.template">
              <ng-container *ngTemplateOutlet="command.template; context: command.context"></ng-container>
            </ng-container>
          </div>
        </div>

        <mat-error *ngIf="
            authform.controls[field]?.touched &&
            authform.controls[field]?.invalid &&
            !readonly
          ">{{
            authform.controls[field]?.hasError("required")
              ? ("input.hint" | translate) + label
              : authform.controls[field]?.hasError("pattern")
              ? label +
                ("input.error1" | translate) +
                "," +
                (patternalert || ("input.error2" | translate) + label)
              : ""
          }}</mat-error>
      </div>
    </ng-template>

    <ng-template #emailwithmobile let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            onblur: auth.mobileByMail.bind(auth),
            icon: 'contact_mail',
            pattern: '[^@]+@.+',
            field: field,
            type: 'email',
            label: ('email.label' | translate)
          }
        "></ng-container>
    </ng-template>

    <ng-template #passwordinput let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            icon: 'vpn_key',
            patternalert: ('password.error' | translate),
            pattern: '.{8,20}',
            type: 'password',
            field: field,
            label: ('password.label' | translate)
          }
        "></ng-container>
    </ng-template>

    <ng-template #newpasswordinput let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            icon: 'vpn_key',
            patternalert: ('password.error' | translate),
            pattern: '.{8,20}',
            type: 'password',
            field: field,
            label: ('password.newpwd' | translate)
          }
        "></ng-container>
    </ng-template>

    <ng-template #confirmpasswordinput let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            icon: 'vpn_key',
            patternalert: ('password.error' | translate),
            pattern: '.{8,20}',
            type: 'password',
            field: field,
            label: ('password.confirmpwd' | translate)
          }
        "></ng-container>
    </ng-template>

    <ng-template #mobileinput let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            icon: 'mobile_friendly',
            patternalert: ('mobile.errorhint' | translate),
            pattern: '.{11,11}',
            name: 'username',
            field: field,
            type: 'text',
            label: ('mobile.label' | translate)
          }
        "></ng-container>
    </ng-template>

    <ng-template #mobilereminder let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            icon: 'mobile_friendly',
            patternalert: ('mobile.errorhint' | translate),
            pattern: '.{11,11}',
            name: 'username',
            field: field,
            type: 'text',
            readonly: true,
            label: ('mobile.label' | translate)
          }
        "></ng-container>
    </ng-template>

    <ng-template #mfainput let-field="field" let-command="command">
      <ng-container *ngTemplateOutlet="
          fieldinput;
          context: {
            icon: 'verified_user',
            patternalert: ('mfa.error' | translate),
            pattern: '\\d{6}',
            label: ('mfa.label' | translate),
            field: field,
            type: 'text',
            command: {
              template: mfabtn
            }
          }
        "></ng-container>
    </ng-template>

    <ng-template id="login">
      <ng-container *ngTemplateOutlet="mobileinput; context: { field: 'mobile' }"></ng-container>

      <ng-container *ngTemplateOutlet="passwordinput; context: { field: 'password' }"></ng-container>

      <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

      <div class="text-center pt-2">
        <mat-error class="d-block" *ngIf="auth.status && auth.status['errkey']">
          {{ auth.status["errkey"] | translate }}
        </mat-error>
        <button type="submit" [disabled]="false" (click)="auth.login()" class="btn btn-primary nobg shows btnborder">
          {{ "login.label" | translate }}
        </button>
      </div>

      <div class="text-center pt-2 shows">
        <label (click)="auth.mode = 'change-password'"
          class="btn btn-link btn-sm border-0 nobg shows">{{ "login.forgetpwd" | translate }}</label>
        <span> | </span>
        <label (click)="disclaimer()"
          class="btn btn-link btn-sm border-0 nobg shows">{{ "login.disclaimer" | translate }}</label>
      </div>
    </ng-template>

    <ng-template id="reset-password">
      <ng-container *ngTemplateOutlet="mobileinput; context: { field: 'mobile' }"></ng-container>

      <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

      <div class="text-center pt-2">
        <mat-error class="d-block" *ngIf="auth.status && auth.status['errkey']">
          {{ auth.status["errkey"] | translate }}
        </mat-error>
        <button type="button" class="btn btn-light nobg shows btnborder" (click)="auth.mode = 'login'">
          {{ "login.backward" | translate }}
        </button>
        <button type="button" [disabled]="!authform.valid" (click)="auth.resetPassword()"
          class="btn btn-primary nobg shows btnborder">
          {{ "login.resetpwd" | translate }}
        </button>
      </div>
    </ng-template>

    <ng-template id="change-password">
      <ng-container *ngTemplateOutlet="mobileinput; context: { field: 'mobile' }"></ng-container>

      <ng-container *ngTemplateOutlet="newpasswordinput; context: { field: 'new_password' }"></ng-container>

      <ng-container *ngTemplateOutlet="
          confirmpasswordinput;
          context: { field: 'confirm_password' }
        "></ng-container>

      <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

      <div class="text-center pt-2">
        <mat-error *ngIf="
            (auth.form['new_password'] != '' ||
              auth.form['confirm_password'] != '') &&
            auth.form['new_password'] != auth.form['confirm_password']
          ">
          {{ "password.errornotsame" | translate }}
        </mat-error>
      </div>

      <div class="text-center pt-2">
        <mat-error class="d-block" *ngIf="auth.status && auth.status['errkey']">
          {{ auth.status["errkey"] | translate }}
        </mat-error>
        <button type="button" class="btn btn-light nobg shows btnborder" (click)="auth.mode = 'login'">
          {{ "general.backward" | translate }}
        </button>
        <button type="button" [disabled]="
            !(
              authform.valid &&
              auth.form['new_password'] == auth.form['confirm_password']
            )
          " (click)="auth.changePassword()" class="btn btn-primary nobg shows btnborder">
          {{ "password.modifypwd" | translate }}
        </button>
      </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="tpls.get(mode)"></ng-container>
  </form>
</ng-template>

<div class="section-shaped">
  <div class="page-header page-header-small header-filter">
    <div class="page-header-image" style="
        background-image: url('assets/img/loginbg8.jpeg?version={{ app.version.server?.version }}');
        background-color: white;
      "></div>
  </div>
</div>

<section class="upper">
  <div class="container">
    <div class="col-lg-5 col-md-8 mx-auto">
      <div class="card nobg" style="border: 1px solid; border-radius: 5px">
        <div class="card-header" style="
            background-color: rgba(173, 216, 230, 0.8);
            border: 1px solid;
            border-top: none;
            border-left: none;
            border-right: none;
          ">
          <div class="text-muted text-center">
            <h4 style="display: inline-block; color: rgb(0, 0, 0)">
              <strong> {{ "general.title" | translate }} </strong>
            </h4>
            <ng-container *ngIf="'general.webshowname' | translate">
              <br>
              <h2 style="display: inline-block; color: rgb(0, 0, 0)">
                <strong> {{ "general.appname" | translate }} </strong>
              </h2>
            </ng-container>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <ng-container *ngTemplateOutlet="authForms; context: { mode: auth.mode }"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="m-auto">
          <div class="copyright">
            {{ "general.copyright" | translate }} |
            <a target="_blank" href="https://beian.miit.gov.cn">
              <!-- <img src="/assets/img/beian.png?version={{ app.version.server?.version }}"/> -->
              <span style="color: black">{{
                "general.govregister" | translate
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>