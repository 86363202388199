<div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
  <h4 class="header d-flex flex-row align-items-stretch justify-content-center flex-none">
    <strong>
      {{selectedYear}}{{app.lang.general.year}}{{
      app.dict.PrjPool.indexed[searcher['pool']]?.title
      }}{{app.lang.general.projects}}
    </strong>
  </h4>
  <div class="d-flex flex-row justify-content-between align-items-stretch flex-none">
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="allprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="newprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="continueprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="constrpreprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="constringprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="acceptingprjsum"></gov-prjcard>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-stretch flex-none">
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="ongoingprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="delayingprjsum"></gov-prjcard>
    </div>
    <div class="col-md-2">
      <gov-prjcard [prjsumitem]="delayedprjsum"></gov-prjcard>
    </div>
    <div class="col-md-3">
      <gov-prjcard [prjsumitem]="finishedprjsum"></gov-prjcard>
    </div>
    <div class="col-md-3">
      <gov-prjcard [prjsumitem]="projectpreprjsum"></gov-prjcard>
    </div>
  </div>
  <div class="row min-vh-50">
    <div id="yearmonthcostOption" echarts class="h-100 w-100 d-inline-flex"
      [options]="yearmonthcostchartParam.option"></div>
  </div>
  <div class="row min-vh-50">
    <div id="planOption" echarts class="h-100 w-100 d-inline-flex" (chartClick)="onClickCharBar($event, planchartBarParam)"
      [options]="planchartBarParam.option"></div>
  </div>
  <div class="row min-vh-50">
    <div id="costOption" echarts class="h-100 w-100 d-inline-flex"
      (chartClick)="onClickCharBar($event, costchartBarParam)" [options]="costchartBarParam.option"></div>
  </div>
  <div class="row min-vh-50">
    <div id="percentOption" echarts class="h-100 w-100 d-inline-flex"
      (chartClick)="onClickCharBar($event, percentchartBarParam)" [options]="percentchartBarParam.option"></div>
  </div>
  <div class="row min-vh-50">
    <div id="yearcostOption" echarts class="h-100 w-100 d-inline-flex"
      (chartClick)="onClickCharBar($event, yearcostchartBarParam)" [options]="yearcostchartBarParam.option"></div>
  </div>
  <div class="row min-vh-50">
    <div id="yearpercentOption" echarts class="h-100 w-100 d-inline-flex"
      (chartClick)="onClickCharBar($event, yearpercentchartBarParam)" [options]="yearpercentchartBarParam.option"></div>
  </div>
</div>