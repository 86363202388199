import { create as svgCreate, clone as svgClone, remove as svgRemove, clear as svgClear, append as svgAppend } from 'tiny-svg';
import { attr as svgAttr, classes as svgClasses, KeyValue as svgKeyValue } from 'tiny-svg';

import { Event as BusEvent } from 'diagram-js/lib/core/EventBus';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import BaseViewer from 'bpmn-js/lib/BaseViewer';
import { Shape } from 'bpmn-js/lib/model/Types';
import { extend, isNumber } from 'lodash';
import { Observable } from 'rxjs';

import { Exec as TExec, Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { GovEditorComponent } from '../../utils/view/gov.editor.component/gov.editor.component';
import { GovDiagramDirective } from '../../utils/view/diagram.directive/diagram.directive';
import { ResultsComponent } from '../../results/view/results.component';
import { ProjectDetailComponent } from "./projectdetail.component";
import { Report } from '../../application/service/backface/report';
import { AuditComponent } from '../../audit/view/audit.component';
import { GovEditor } from '../../utils/view/model/form.editting';
import { Prj } from '../../application/service/backface/prj';
import { Property } from "../../utils/libs/property";
import { Editor } from '../../utils/libs/editor';
import { Differ } from '../../utils/libs/differ';
const VType = Editor.Value.Type;

type Destroied = GovDiagramDirective.Destroied;
type Imported = GovDiagramDirective.Imported;
type Command = GovDiagramDirective.Command;

type Stage = {
    entity: string;
    type: string;
    bo: any;
}

type Stages = (Stage[] & {
    [id: string]: Stage
})

type Track = {
    projecttype: Prj.ProjProjectType,
    stage?: Prj.ProjStage,
    workset?: Prj.ProjWorkset,
    workitem?: Prj.ProjWorkitem
}

type StackSlot = {
    track?: Track,
    shape: Shape,
}

function pushlinker(shape: Shape, stack: StackSlot[], visited: Set<Shape>, track?: Track) {
    shape?.incoming?.reduce((res, incoming) => {
        const source = incoming.source as Shape;
        if (source && !visited.has(source)) {
            res.push({ shape: source, track });
        }

        return res;
    }, stack);

    shape?.outgoing?.reduce((res, outgoing) => {
        const target = outgoing.target as Shape;
        if (target && !visited.has(target)) {
            res.push({ shape: target, track });
        }

        return res;
    }, stack);

    return stack;
}

@Component({ template: '' })
export class ProjectExec extends AuditComponent implements ProjectDetailComponent.IHandler, OnDestroy {
    private __props = Property.Of<ProjectExec & {
        differ?: Differ<{
            shapeCommand: Differ.Event<Command>,
            onWorkitemPostSave: Differ.Event,
            onWorkitemPreSave: Differ.Event,

        }>
    }>(this).values;

    get goveditor(): GovEditorComponent {
        return this.host.goveditor;
    }

    get execute(): TSys.IDatasetModule {
        const { __props: props, projecttype, app: { dict, auth: { me: { priviledges } } } } = this;

        return props.execute = props.execute || {
            cudable: priviledges.supervise?.problem?.problemupdate,
            accessable: priviledges.audit?.audit,
            dict: dict.exec.audit,
            nopaginator: true,
            monopage: true,
            key: 'projectexec',
            get monorow(): object {
                return projecttype;
            },
            headers: [{
                type: Editor.Value.Type.details | Editor.Value.Type.onlycolumn,
                key: "workflow", title: 'projecttype.workflow',
                must: true, readonly: true,
            }]
        }
    }

    get project() {
        return this.host.project;
    }

    get dialog(): MatDialog {
        return this.host.dialog;
    }

    get projecttype(): Prj.ProjProjectType {
        return this.project.projecttype;
    }

    get workitem(): Prj.ProjWorkitem | undefined {
        return this.__props.workitem;
    }

    get shape(): Shape {
        return this.__props.shape;
    }

    get viewer(): BaseViewer {
        return this.__props.viewer;
    }

    get diagram(): GovDiagramDirective {
        return this.__props.diagram;
    }

    get diagramEvent() {
        const _this = this, edittings: {
            [k: string]: GovEditor.IEditting
        } = {
            get ['gov.stage']() {
                return GovEditor.Editting.get(_this.stageModule)
            },
            get ['gov.workitem']() {
                return GovEditor.Editting.get(_this.workitemModule)
            }
        }

        const handles = () => {
            return {
                selection: {
                    changed(this: ProjectExec, event: BusEvent & {
                        type: "selection.changed"
                        newSelection: Shape[]
                        oldSelection: Shape[]
                    }) {
                        const { currentModule, execute } = this;
                        if (currentModule != execute) return;

                        const { __props: props } = this;
                        const { newSelection: selected } = event;
                        const shape = (props.shape = selected[0]);
                        const businessObject = getBusinessObject(shape);
                        const shapeeditting = edittings[businessObject?.type];

                        GovEditor.Editting.set(currentModule, shapeeditting || {});
                        props.workitem = this.getWorkitem(shape);
                    }
                }
            }
        }

        type IHandler = ReturnType<typeof handles>;
        const props = this.__props as { diagramEvent: IHandler };
        return (props.diagramEvent || (props.diagramEvent = handles()));
    }

    get stages(): Stages {
        const { __props: props } = this;
        if (props.stages) return props.stages;

        const stages: Stages = (props.stages = [] as any);
        const { diagram: { elementRegistry } = {} } = this;

        elementRegistry?.forEach((shape: Shape) => {
            const stage: Stage = this.getStage(shape);

            stage && (
                stages.push(stage),
                stage.entity && (
                    stages[stage.entity] = stage
                )
            );
        })

        return stages;
    }

    get stageModule(): TSys.IDatasetModule {
        const { __props: props } = this, _this = this;
        const { set } = GovEditor.Editting;

        const module = (props.stageModule || (
            props.stageModule = {
                key: 'projectexecstage'
            },

            set(props.stageModule, {
                form: {
                    noactionbar: true,
                    readonly: true,
                    headers: [{
                        title: 'stage.name',
                        key: "name"
                    }, {
                        title: 'stage.key',
                        key: "key"
                    }, {
                        type: Editor.Value.Type.options,
                        key: "depstage[,].bo.name",
                        title: 'stage.depstage',
                        source: "@stages",
                    }],
                    editor: {
                        get stages() {
                            return _this.stages;
                        },

                        get depstage(): Stage[] {
                            const { shape } = props;
                            return _this.getDepStage(shape);
                        },

                        get name(): string {
                            const { shape } = props;
                            const businessObject = getBusinessObject(shape);
                            return businessObject?.name;
                        },

                        get key(): string {
                            const { shape } = props;
                            const businessObject = getBusinessObject(shape);
                            return businessObject?.key;
                        }
                    } as any
                }
            }),

            props.stageModule
        ))

        return module;
    }

    get workitemModule(): TSys.IDatasetModule & {
        readonly editting: {
            reset(): void;
        }
    } {
        const { __props: { workitemModule, differ } } = this;
        if (workitemModule) return workitemModule;

        const _this = this, cache: {
            workitem?: Prj.ProjWorkitem,
            form: GovEditor.IForm[],
        } = {
            workitem: undefined,
            form: []
        }

        // create the module, and assign the editting.
        const { host: { app: { dict }, bwsparamset, bnormalmodify, bverifymodify } } = this;
        const baseeditting = (obj: Prj.ProjWorkitem) => {
            return super.editting(obj);
        }

        const editting: GovEditor.IEditting = {
            get form() {
                // empty return if invalid workitem
                const { workitem } = _this;
                if (!workitem) return;

                // cache form return if same workitem as cache
                const { form, workitem: _workitem } = cache;
                if (workitem == _workitem) return form;

                // build up the new form for new workitem
                const { status, closedate } = workitem, { finished, delayed } = TPrj.IWorkitem.Status;
                const isclosed = ((status == delayed || status == finished) && !!closedate);
                const { project: { processlock } = {} } = workitem;
                cache.workitem = workitem;
                form.clear();

                if (bwsparamset && !isclosed && !processlock) {
                    const notused = workitem.checkpoints.find(
                        c => c.key == 'notused'
                    );

                    if (notused) {
                        form.push({
                            title: 'general.setting',
                            headers: [notused],
                            editor: workitem,
                        });

                        differ.extend({
                            onWorkitemPreSave: Differ.Event.create(workitem.$presave_, (handle: {
                                handled?: boolean | Editor.Editor<any> | Observable<{
                                    handled?: boolean | Editor.Editor<any>
                                }>
                            }) => {

                            }),
                            onWorkitemPostSave: Differ.Event.create(workitem.$postsave_, (succeed: boolean) => {
                                if (!succeed) return;
                                _this.loadWorkitems();
                            })
                        })
                    }
                }

                const { type } = workitem, { normal, verify } = TPrj.IWorkitem.Type;
                if ((((type == normal) && bnormalmodify) || ((type == verify) && bverifymodify))) {
                    const { form: _form } = baseeditting(workitem);
                    const baseform = _form as GovEditor.ISection;
                    const bheaders = baseform.headers;

                    if (workitem['status'] == TPrj.IWorkitem.Status.notused) {
                        form.push({
                            editor: baseform.editor,
                            headers: [{
                                key: '', title: 'sysenums.notused', type: VType.hint
                            }]
                        })
                    } else {
                        form.push({
                            headers: bheaders,
                            editor: baseform.editor,
                            exclude: baseform.exclude,
                            invisible: () => !!workitem['notused'],
                            title: dict.Workitem.Type[type || normal],
                            get readonly(): boolean {
                                return isclosed;
                            }
                        });
                    }
                }

                // finally form
                return form;
            }
        }

        const { __props: props } = this;
        props.workitemModule = {
            key: 'projectexecworkitem',
            editting: {
                reset() {
                    delete cache.workitem;
                }
            }
        };

        const module = props.workitemModule;
        GovEditor.Editting.set(module, editting);
        return module;
    }

    constructor(public host: ProjectDetailComponent) {
        super(host.app, host.sys, host.httpClient);
        this.__props.differ = Differ.create();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        const { __props: { differ } } = this;
        differ?.clear();
    }

    getWorkitem(shape: Shape): Prj.ProjWorkitem | undefined {
        const { entity: workitem_id } = this.getBo(shape, 'gov.workitem');
        if (!workitem_id) return;

        const shape_workset = this.getParent(shape, 'gov.workset');
        const { entity: workteset_id } = this.getBo(shape_workset);
        if (!workteset_id) return;

        const shape_stage = this.getLinker(shape_workset, 'gov.stage')
        const { entity: stage_id } = this.getBo(shape_stage);
        if (!stage_id) return;

        return this.projecttype?.stages.firstOf({
            id: stage_id
        })?.worksets.firstOf({
            id: workteset_id
        })?.workitems.firstOf({
            id: workitem_id
        });
    }

    getDepStage(shape: Shape): Stage[] {
        const businessObject = getBusinessObject(shape);
        const { type } = businessObject || {};

        if (type !== 'gov.stage') {
            return;
        }

        if (shape.depstages) {
            return shape.depstages;
        }

        const { diagram: { moddle, elementRegistry } = {} } = this;
        const extensionElements = (businessObject.extensionElements || (
            businessObject.extensionElements = moddle?.create('bpmn:ExtensionElements')
        ));

        const depstages: Stage[] = (shape.depstages = extensionElements.get('values'));
        for (let idx = 0, cnt = depstages.length; idx < cnt; idx++) {
            const { entity } = depstages[idx];

            const _shape: Shape = entity && elementRegistry?.find((s: Shape) => {
                return getBusinessObject(s)?.entity == entity;
            }) as any;

            depstages[idx] = this.getStage(_shape);
        }

        depstages.remove(undefined);
        return depstages;
    }

    getStage(shape: Shape): Stage {
        const businessObject = getBusinessObject(shape);
        const { type, entity } = businessObject || {};
        if (type !== 'gov.stage') return;

        const { diagram: { moddle } = {} } = this;
        return (shape.stage || (
            shape.stage = moddle?.create('gov:DepStage'),
            shape.stage.bo = businessObject,
            shape.stage.entity = entity,
            shape.stage.type = type,
            shape.stage
        ));
    }

    getBo(shape: Shape, type?: string) {
        const { type: _type, entity } = getBusinessObject(shape) || {};
        return (!type || (type && _type == type)) ? {
            type: _type, entity
        } : {};
    }

    getLinker(shape: Shape, type: string) {
        const stack: StackSlot[] = [];
        const visited = new Set<Shape>();
        pushlinker(shape, stack, visited);

        let stackslot: StackSlot;
        while (stackslot = stack.pop()) {
            shape = stackslot.shape;
            if (visited.has(shape)) continue;
            visited.add(shape);

            if (this.getBo(shape, type)?.entity) {
                return shape;
            }

            pushlinker(shape, stack, visited);
        }
    }

    getParent(shape: Shape, type: string) {
        while (shape = shape?.parent as Shape) {
            const { entity } = this.getBo(shape, type);
            if (entity) return shape;
        }
    }

    onDiagramImported(event: {
        entity: any
    } & Imported) {
        const { diagram: _diagram, viewer: _viewer } = event;
        const { __props: props, __props: { differ } } = this;
        const diagram = (props.diagram = _diagram);
        const viewer = (props.viewer = _viewer);
        props.stages = undefined;

        // set the diagram event handlers
        const { command } = diagram;
        const { diagramEvent } = this;
        diagram.on(diagramEvent, this);

        differ.extend({
            shapeCommand: Differ.Event.create(command, ({ command, event, target }: Command) => {
                event.stopPropagation(), event.preventDefault();
                if (command !== 'gov.history') return;

                // Open the history records
                const { dialog, workitem } = this;
                ResultsComponent.open(dialog, {
                    title: 'audit.records',
                    object: workitem,
                    from: this,
                })
            })
        })
    }

    onDiagramDestroied(event: {
        entity: any
    } & Destroied) {
        const { diagramEvent, diagram, host: { ngzone } } = this;
        diagram?.off(diagramEvent);

        ngzone.run(() => {
            setTimeout(() => {
                diagramEvent.selection.changed.call(this, {
                    type: "selection.changed",
                    newSelection: [],
                    oldSelection: [],
                })
            });
        });

        const { __props: props } = this;
        props.diagram = undefined;
        props.viewer = undefined;
        props.differ.clear();
    }

    loadWorkitems() {
        const { currentModule, execute } = this;
        if (currentModule != execute) return;

        const { project, app } = this;
        const rows = (execute.rows = execute.rows || []);

        this.report("/report/project_workitem_status", (res: Report.IProjectWorkitems) => {
            Report.buildWorkitems(app, res, rows);
            this.updateDiagramStyle();
        }).query({
            project: [{ id: project.id }]
        });
    }

    loadAudits(): void {
        const { app, audit, project } = this
        const rows = (audit.rows = audit.rows || []);

        this.report("/entity/results/retrieve", (res: TExec.IAuditResult[] = []) => {
            Report.buildAuditResults(app, res, rows);
        }).query({
            workitem: {
                type: [
                    "normal",
                    "verify"
                ]
            },
            audit: [
                "auditting",
                "callback",
                "nopass",
                "pass"
            ],
            project: [
                project.id
            ]
        });
    }

    updateDiagramStyle() {
        // clear and reinitialize the editting;
        const { workitemModule } = this;
        workitemModule?.editting?.reset();

        /* 
            update diagram according to workitem status: 
                unknown,    --no exeddate.TODO:
                notstart,   --exepre, exeddate has
                ongoing,    --exeon, exeddate >= now
                delaying,   --exeon, exeddate < now
                delayed,    --exed / closed, exeddate < close date
                finished,   --exed / closed, exeddate >= close date
                notused,
        */

        const { diagram: { elementRegistry, canvas } = {} } = this;
        const starter = elementRegistry?.find((shape: Shape) => {
            return shape.type == "bpmn:StartEvent";
        }) as Shape;

        if (!canvas || !starter) return;

        const updateCss = (shape: Shape, status: string | TPrj.IWorkitem.Status) => {
            status = isNumber(status) ? TPrj.IWorkitem.Status[status] : status;
            const cls = status && `gov-${status}`;
            const gfx = canvas.getGraphics(shape);
            const clses = svgClasses(gfx).list;

            for (let idx = 0, cnt = clses.length; idx < cnt; idx++) {
                const _cls = clses[idx];
                if (/^gov-/.test(_cls)) {
                    if (_cls == cls) return;
                    canvas.removeMarker(shape, _cls);
                }
            }

            cls && canvas.addMarker(shape, cls);
        }

        const _this = this;
        const handles = {
            ["gov.workitem"](shape: Shape, track: Track, entity: string) {
                const { workset } = track;
                const workitem = (track.workitem = (
                    workset?.workitems.find(
                        w => w.id == entity
                    )
                ));

                updateCss(shape, workitem?.['status'] || 'nopriviledge');
            },

            ["gov.workset"](shape: Shape, track: Track, entity: string) {
                const { stage } = track;
                const workset = (track.workset = (
                    stage?.worksets.find(
                        w => w.id == entity
                    )
                ));

                shape.children.forEach((s: Shape) => {
                    const { type, entity } = _this.getBo(s);
                    if (type == 'gov.workitem' && entity) {
                        handles['gov.workitem'](s, { ...track }, entity);
                    }
                })
            },

            ["gov.stage"](shape: Shape, track: Track, entity: string) {
                const { projecttype } = track;
                const stage = (track.stage = (
                    projecttype?.stages.find(
                        s => s.id == entity
                    )
                ));

                updateCss(shape, stage?.['status'] || 'nopriviledge');
            }
        }

        const { projecttype } = this;
        const stack: StackSlot[] = [];
        const visited = new Set<Shape>();
        canvas.addMarker(starter, `gov-starter`);
        pushlinker(starter, stack, visited, {
            projecttype: projecttype
        });

        let stackslot: StackSlot;
        while (stackslot = stack.pop()) {
            let { shape, track } = stackslot;
            if (visited.has(shape)) continue;
            visited.add(shape);

            const { type, entity } = this.getBo(shape);
            const handle = handles[type];

            if (handle && entity) {
                track = { ...track }
                handle(shape, track, entity);
            }

            pushlinker(shape, stack, visited, track);
        }
    }

    initResult(result: TExec.IAuditResult): TExec.IAuditResult {
        const { execute, currentModule } = this;
        return extend(result, {
            value: currentModule == execute ? result.value || {} : {}
        });
    }

}