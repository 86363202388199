<ng-template let-context="context" let-content="content" let-caption="caption" let-definition="definition"
  id="save, delete, reset_password">
  <h2 mat-dialog-title>{{ caption | translate }}</h2>
  <mat-dialog-content class="
      mat-typography
      position-relative
      max-vh-80 max-vw-80
      min-vh-10 min-vw-30
      p-3
    ">
    <span class="d-block pt-2">{{ content?.major.format(context)}}</span>
    <span *ngIf="content?.minor" class="d-block pt-2">{{
      content?.minor.format(context)
    }}</span>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'general.confirm' | translate}}</button>
    <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
  </mat-dialog-actions>
</ng-template>

<ng-container *ngTemplateOutlet="
    tpls.get(data.type);
    context: {
      content: definitions[data.type].content,
      caption: definitions[data.type].caption,
      definition: definitions[data.type],
      context: data.context
    }
  "></ng-container>