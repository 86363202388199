<ng-template id="text" let-row="row" let-header="header">
  {{ fielder.getCellText(row, header, headers) }}
</ng-template>

<ng-template id="number" let-row="row" let-header="header">
  {{ fielder.getCellText(row, header, headers) | number : "1.0-0" }}
</ng-template>

<ng-template id="percent" let-row="row" let-header="header">
  {{ fielder.getCellText(row, header, headers) }}%
</ng-template>

<ng-template id="command" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-row="row">
  <button color="primary" mat-flat-button (click)="stopevent($event); onCommand(header, row)">
    <mat-label>
      <i class="material-icons" style="font-size: inherit">history</i>
      {{ (app._.isFunction(header.title) ? header.title(app, row) : header.title) | translate }}
    </mat-label>
  </button>
</ng-template>

<ng-template id="enum" let-header="header" let-cellField="cellField" let-optionSourceRows="optionSourceRows">
  <span [attr.style]="getEnumStyle(cellField, header)">
    {{ optionSourceRows.data.indexed[cellField.data]?.title | translate }}
  </span>
</ng-template>

<ng-template id="date" let-cellField="cellField" let-header="header">
  {{ cellField.data | date : app.sys.dateFormat }}
</ng-template>

<ng-template id="bool" let-cellField="cellField" let-header="header">
  <div class="toggle toggle--knob">
    <input type="checkbox" class="toggle--checkbox" id="field-table-toggle--{{ header.key }}"
      [(ngModel)]="cellField.data" [disabled]="true" />
    <label class="toggle--btn ml-1" for="field-table-toggle--{{ header.key }}">
      <span class="toggle--feature" data-label-off="否" data-label-on="是"></span>
    </label>
  </div>
</ng-template>

<ng-template id="richtext" let-row="row" let-header="header">
  <gov-wsitem [wsitem]="getSummaryItem(row, header, headers)" (onWorkItemDetailsTemplate)="onWorkItemDetails($event)">
  </gov-wsitem>
</ng-template>

<ng-template id="details" let-row="row" let-header="header">
  <button class="mx-1" color="primary" mat-flat-button
    matTooltip="{{ 'general.view' | translate }}{{ (app._.isFunction(header.title) ? header.title(app, row) : header.title) | translate }}"
    (click)="expandheader = expandheader == header && editting?.primary == row ? null : header">
    <mat-label>
      <i class="material-icons" style="font-size: inherit">{{
        expandheader == header && editting?.primary == row
          ? "expand_more"
          : "chevron_right"
      }}</i>
      {{ "general.view" | translate }}
    </mat-label>
  </button>
  <!-- <span *ngIf="!fielder.getProperty(header, headers)?.pathProp?.paths.has('[')">{{
    fielder.getCellText(row, header, headers)
  }}</span> -->
</ng-template>

<ng-template #checkpoints id="checkpoints" let-optionSourceRows="optionSourceRows" let-cellField="cellField"
  let-header="header" let-row="row">
  <div class="embedded-panel">
    <span #checkpointdataset class="d-none"></span>
    <gov-table [datasets]="
        createDatasource(checkpointdataset, app.datasources.checkpoint, row)
      " [headerids]="['title', 'type']" (editting)="onsubeditting($event)" [nopaginator]="true">
    </gov-table>
  </div>
</ng-template>

<ng-template #workitems id="workitems" let-optionSourceRows="optionSourceRows" let-cellField="cellField"
  let-header="header" let-row="row">
  <div class="embedded-panel">
    <span #workitemdataset class="d-none"></span>
    <gov-table [datasets]="
        createDatasource(workitemdataset, app.datasources.workitem, row)
      " [headerids]="['name', 'type', 'dept.name']" (editting)="onsubeditting($event)"
      [nopaginator]="true"></gov-table>
  </div>
</ng-template>

<ng-template #workset id="workset" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-row="row">
  <ng-container *ngTemplateOutlet="
      workitems;
      context: {
        row: row.workitems
      }
    "></ng-container>
</ng-template>

<ng-template #worksets id="worksets" let-optionSourceRows="optionSourceRows" let-cellField="cellField"
  let-header="header" let-row="row">
  <mat-accordion class="d-flex flex-row justify-content-start align-items-start" multi="true">
    <mat-expansion-panel #panel="matExpansionPanel" *ngFor="let item of row; let index = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <button type="button" class="btn btn-sm" [ngClass]="{
              'btn-info': editting?.source == item,
              'btn-light': editting?.source != item
            }" (click)="
              onrowselect(item, app.datasources.workset.headers);
              stopevent($event)
            ">
            {{ "workset.name" | translate }}: {{ item.name }}
          </button>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="panel.expanded">
        <ng-container *ngTemplateOutlet="
            workset;
            context: {
              row: item
            }
          "></ng-container>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #stage id="stage" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-row="row">
  <ng-container *ngTemplateOutlet="
      worksets;
      context: {
        row: row.worksets
      }
    "></ng-container>
</ng-template>

<ng-template #stages id="stages" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-row="row">
  <mat-vertical-stepper #stepper="matVerticalStepper" [selectedIndex]="0" [linear]="false">
    <mat-step *ngFor="let item of row; let index = index" [state]="index == stepper.selectedIndex ? 'edit' : 'closed'"
      [completed]="false" #step="matStep">
      <ng-template matStepLabel>
        <button type="button" class="btn btn-sm" [ngClass]="{
            'btn-info': editting?.source == item,
            'btn-light': editting?.source != item
          }" (click)="
            onrowselect(item, app.datasources.stage.headers); stopevent($event)
          ">
          {{ item.name }}
        </button>
      </ng-template>

      <ng-container *ngIf="index == stepper.selectedIndex">
        <ng-container *ngTemplateOutlet="
            stage;
            context: {
              row: row[stepper.selectedIndex || 0]
            }
          "></ng-container>
      </ng-container>
    </mat-step>

    <ng-template matStepperIcon="closed">
      <mat-icon>chevron_right</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="edit">
      <mat-icon>expand_more</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="error">
      <mat-icon>error</mat-icon>
    </ng-template>
  </mat-vertical-stepper>
</ng-template>

<ng-template #projecttype id="projecttype" let-optionSourceRows="optionSourceRows" let-cellField="cellField"
  let-header="header" let-row="row">
  <ng-container *ngTemplateOutlet="
      stages;
      context: {
        row: row.stages
      }
    "></ng-container>
</ng-template>

<ng-template #workflow id="workflow" let-optionSourceRows="optionSourceRows" let-cellField="cellField"
  let-header="header" let-row="row" let-primaryrow="primaryrow">

  <!-- <canvas [(gov-diagram)]="cellField.data" [(gov-svg)]="primaryrow.svg" [(gov-json)]="primaryrow.json"
    (imported)="onDiagramImported(primaryrow, $event)" (destroied)="onDiagramDestroied(primaryrow, $event)"
    class="diagram-container w-100 h-100" style="user-select: none; touch-action: none; cursor: auto;">
    This text is displayed if your browser does not support the Canvas HTML element.
  </canvas> -->

  <div class="w-100 h-100 position-relative">
    <div #diagram="GovDiagram" [(gov-diagram)]="cellField.data" [readonly]="header.readonly"
      [(gov-svg)]="primaryrow.svg" [(gov-json)]="primaryrow.json" (imported)="onDiagramImported(primaryrow, $event)"
      (destroied)="onDiagramDestroied(primaryrow, $event)" class="diagram-container w-100 h-100"></div>

    <div class="io-zoom-controls">
      <ul class="io-zoom-reset io-control io-control-list">
        <li class="icon">
          <button title="{{'general.fitscreen'|translate}}" (click)="diagram.fitWidth()">
            <mat-icon>fit_screen</mat-icon>
          </button>
        </li>
        <li class="break">
          <hr />
        </li>
        <li class="icon">
          <button title="{{'general.zoomin'|translate}}" (click)="diagram.zoomIn()">
            <mat-icon>zoom_in</mat-icon>
          </button>
        </li>
        <li class="break">
          <hr />
        </li>
        <li class="icon">
          <button href title="{{'general.zoomout'|translate}}" (click)="diagram.zoomOut()">
            <mat-icon>zoom_out</mat-icon>
          </button>
        </li>
        <li class="break">
          <hr />
        </li>
        <li class="icon">
          <button href title="{{'general.processhelp'|translate}}" (click)="showProcessHelp()">
            <mat-icon>error outline icon</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #notable>
  <ng-container *ngTemplateOutlet="
    fieldtpls.get(fielder.getProperty(headers[0], headers)?.pathProp?.paths[0]);
    context: {
        row: monorow && monorow[fielder.getProperty(headers[0], headers)?.pathProp?.paths[0]],
        optionSourceRows: fielder.getOptionSourceRows(monorow, headers[0], headers ),
        cellField: fielder.getCellField(monorow, headers[0], headers ),
        header: headers[0],
        primaryrow: monorow
    }
  ">
  </ng-container>
</ng-template>


<ng-container *ngIf="!monopage; else notable">
  <div class="h-100 w-100 d-flex flex-column align-items-center">
    <div class="w-100 overflow-auto flex-fill">
      <table matSort mat-table recycleRows class="min-w-100"
        [class.h-100]="nopaginator && (editting?.primary && expandheader)" [dataSource]="matDataSource"
        multiTemplateDataRows="expandable">
        <ng-container *ngFor="let header of headers">
          <ng-container *ngIf="
            !app.editor.Value.isFieldType(header, 'notcolumn') &&
            !app.editor.Value.isFieldType(header, 'label')
          " [sticky]="app.editor.Value.isFieldType(header, 'rowselect')" matColumnDef="{{ header.key }}">
            <!-- Checkbox Column -->
            <ng-container *ngIf="app.editor.Value.isFieldType(header, 'rowselect')">
              <ng-container *ngIf="!deletable; else checkboxTmpl">
                <th mat-header-cell *matHeaderCellDef>
                  <!-- Nothing Here -->
                </th>
                <td mat-cell *matCellDef="let row"></td>
              </ng-container>
              <ng-template #checkboxTmpl>
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected"
                    [indeterminate]="selection.hasValue() && !isAllSelected" [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="stopPropagation($event)" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
              </ng-template>
            </ng-container>

            <!-- Normal Element Column -->
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ (app._.isFunction(header.title) ? header.title(app) : header.title) | translate }}
            </th>

            <td mat-cell *matCellDef="let row">
              <ng-container *ngTemplateOutlet="
                fieldtpls.get(
                  app.editor.Value.Type[
                    app.editor.Value.isFieldType(header, 'details')
                      ? app.editor.Value.Type.details
                      : app.editor.Value.getFieldType(header, 'multi')
                  ]
                ) || fieldtpls.get('text');
                context: {
                  optionSourceRows: fielder.getOptionSourceRows(row, header, headers),
                  cellField: fielder.getCellField(row, header, headers),
                  header: header,
                  row: row
                }
              "></ng-container>
            </td>
          </ng-container>
        </ng-container>

        <!-- Table header details. -->
        <tr mat-header-row *matHeaderRowDef="headerids; sticky: true"></tr>

        <!-- normal data row -->
        <tr mat-row *matRowDef="let row; let index = index; columns: headerids"
          [style.height]="nopaginator && (editting?.primary && expandheader) ? '0px' : undefined"
          [ngStyle]="editting?.style && editting?.style(row)" [ngClass]="{ selected: editting?.source == row }"
          (click)="onrowselect(row); stopevent($event)">
        </tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="headerids.length">
            {{ "general.searchnone" | translate }}: "{{ searcher | json }}"
          </td>
        </tr>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container *ngIf="expandable" [matColumnDef]="expandColumn">
          <td mat-cell class="p-0 m-0" *matCellDef="let row" [attr.colspan]="headerids.length">
            <!-- <ng-container *ngIf="row[fielder.getProperty(editting?.primary == row && expandheader, headers)?.pathProp?.paths[0]]"> -->
            <ng-container *ngTemplateOutlet="
              fieldtpls.get(fielder.getProperty(editting?.primary == row && expandheader, headers)?.pathProp?.paths[0]);
              context: {
                  row: row[fielder.getProperty(editting?.primary == row && expandheader, headers)?.pathProp?.paths[0]],
                  optionSourceRows: fielder.getOptionSourceRows(row, editting?.primary == row && expandheader, headers ),
                  cellField: fielder.getCellField(row, editting?.primary == row && expandheader, headers ),
                  header: editting?.primary == row && expandheader,
                  primaryrow: row
              }
            ">
            </ng-container>
            <!-- </ng-container> -->
          </td>
        </ng-container>

        <ng-container *ngIf="expandable">
          <tr mat-row *matRowDef="let row; columns: [expandColumn]"
            [ngClass]="{ 'd-none': !(editting?.primary == row && expandheader) }"></tr>
        </ng-container>
      </table>
    </div>

    <mat-paginator *ngIf="!nopaginator" style="border-top: 1px solid rgb(211 211 211); padding-left: 10px"
      [ngClass]="{ 'd-none': defaultpaginator !== matDataSource.paginator }" [pageSize]="pageSize[pageSize.current]"
      [pageSizeOptions]="pageSize" showFirstLastButtons class="w-100">
    </mat-paginator>
  </div>
</ng-container>