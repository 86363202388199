import { ActivatedRoute, ActivatedRouteSnapshot, NavigationStart, Resolve, Route, Router, RouterStateSnapshot } from "@angular/router";
import { filter, map, shareReplay, take } from "rxjs/operators";
import { extend, forEach, isNull, isUndefined } from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { HttpClient } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";

import { Sys as TSys, Prj as TPrj } from './backface/types';
import { _JSON } from "../../utils/libs/polyfill/json";
import { Property } from "../../utils/libs/property";
import { VersioinService } from "./version.service";
import { Editor } from "../../utils/libs/editor";
import { AlertService } from "./alert.service";
import { AuthService } from "./auth.service";
import { Backface } from "./backface/config";
import { MsgService } from "./msg.service";
import { Report } from "./backface/report";
import { Dict } from "./backface/dict";
import { Exec } from "./backface/exec";
import { Lang } from "./backface/lang";
import { Sys } from "./backface/sys";
import { Prj } from "./backface/prj";
import { Org } from "./backface/org";
import { Auth } from "../types";

export interface RouteInfo {
    children?: RouteInfo[];

    path?: string;
    title?: string;
    icon?: string;
    class?: string;
}

const DefAppName = "重点项目建设信息管理系统";

// list any lang resource required while dynamic translation unavailable
//   such as: echarts option
//
// [NOTE]: strongly encourage to use translate pipe rather than this way
const Langs = {
    prepareproject: 'module.setting.prepareproject',
    systemconfig: 'systemconfig',
    sysenums: 'sysenums',
    stage: {
        name: 'stage.name'
    },
    report: {
        projectcountsummary: 'report.projectcountsummary',
        supervisesummary: 'report.supervisesummary',
        refproject: 'report.refproject',
        problemnotfixed: 'report.problemnotfixed',
        supervisecount: 'report.supervisecount',
        problemcount: 'report.problemcount',
        stagesummary: 'report.stagesummary',
        workitemexecount: 'report.workitemexecount',
        workitemexeongoing: 'report.workitemexeongoing',
        workitemexeoverdue: 'report.workitemexeoverdue',
        yearmonthcostchart: 'report.yearmonthcostchart'
    },
    general: {
        notfixed: 'general.notfixed',
        fixed: 'general.fixed',
        year: 'general.year',
        item: 'general.item',
        unit: 'general.unit',
        projects: 'general.projects',
        bunit: 'general.bunit',
        annual: 'general.annual',
        all: 'general.all',
        orgname: 'general.orgname',
        total: 'general.total',
        progresscomment: 'general.progresscomment',
        mediamaxamount: 'general.mediamaxamount',
        city_district: 'general.city_district',
        city_position: 'general.city_position',
        city_address: 'general.city_address',
        mapnotset: 'general.mapnotset',
        yearlyinvested: 'general.yearlyinvested',
        yearlyassetinvested: 'general.yearlyassetinvested'
    },
    budget: {
        percent: 'budget.percent',
        amount: 'budget.amount',
        consume: 'budget.consume',
        unit: 'budget.unit',
        startend: 'budget.startend',
        target: 'budget.target'
    },
    summary: {
        patchprjtable: 'summary.patchprjtable',
        cancelprjtable: 'summary.cancelprjtable',
        workitemprjtable: 'summary.workitemprjtable',
        workitemtitle: 'summary.workitemtitle',
        workitemsummary: 'summary.workitemsummary',
        projectinfo: 'summary.projectinfo',
        refedworkitem: 'summary.refedworkitem',
        sumnotused: 'summary.sumnotused',
        exed: 'summary.exed',
        subsum: 'summary.subsum',
        exeon: 'summary.exeon',
        exepre: 'summary.exepre',
        sumall: 'summary.sumall',
        peronused: 'summary.peronused',
        peronall: 'summary.peronall',
        workitemcatsum: 'summary.workitemcatsum',
        curstagestatus: 'summary.curstagestatus',
        workitemall: 'summary.workitemall',
        allcount: 'summary.allcount'
    },
    project: {
        id: 'project.id',
        name: 'project.name',
        plan: 'project.plan',
        owncompany: 'project.owncompany',
        location: 'project.location',
        description: 'project.description',
        constrtype: 'project.constrtype',
        owndept: 'project.owndept',
        memo: 'project.memo',
        position: 'project.position'
    },
    actor: {
        prjowner: 'actor.prjowner',
        header: 'actor.header',
        leaders: 'actor.leaders'
    },
    tabs: {
        summaryongoingproject: 'tabs.summaryongoingproject',
        summaryprojectstage: 'tabs.summaryprojectstage'
    },
    problem: {
        problem: 'problem.problem'
    },
    projectcard: {
        projectcount: 'projectcard.projectcount',
        budgettotal: 'projectcard.budgettotal',
        yearbudgettotal: 'projectcard.yearbudgettotal'
    },
    pool: {
        input: 'sysenums.pool.input',
        meditate: 'sysenums.pool.meditate',
        candidate: 'sysenums.pool.candidate',
        begun: 'sysenums.pool.begun',
        accept: 'sysenums.pool.accept'
    }
}

@Injectable({ providedIn: 'root' })
export class AppService implements Resolve<object> {
    private _props = Property.Of<AppService & {
        onLangChange: Observable<any>
    }>(this).values;
    readonly _ = _;

    get backface(): Backface {
        const { _props: props } = this;
        const backface = (props.backface = props.backface || new Backface(
            this.httpClient, this
        ));

        Editor.detect.cud = Editor.detect.cud || backface.cud;
        return backface;
    }

    get dict(): Dict {
        const { _props: props, lang: { systemconfig: { modules } = {} } } = this;
        return props.dict || (
            props.dict = new Dict(this),
            this.excludeHeaders(modules),
            props.dict
        );
    }

    get report(): Report {
        const { _props } = this;
        return _props.report || (
            _props.report = new Report(this)
        );
    }

    get org(): Org {
        const { _props: props } = this;
        return props.org = props.org || new Org(this, this.backface.org);
    }

    get prj(): Prj {
        const { _props: props } = this;
        return props.prj = props.prj || new Prj(this, this.backface.prj);
    }

    get exec(): Exec {
        const { _props: props } = this;
        return props.exec = props.exec || new Exec(this, this.backface.exec);
    }

    get sys() {
        return Sys;
    }

    get editor() {
        return Editor;
    }

    get datasources() {
        const build = () => {
            const { superviseaudit: usesuperviseaudit, executeaudit: useexecuteaudit, prepareproject: useprepareproject } = this;
            const { dict, auth: { me: { priviledges } } } = this;
            const app = this;

            const defindex = {
                currentIndex: 0
            };

            const dept: TSys.IDatasetModule = {
                dict: dict.org.dept,
                headers: dict.org.dept.headers,
                get rows() {
                    return app.org.depts
                },
                key: 'dept',
                accessable: priviledges.orgnization?.dept,
                cudable: priviledges.orgnization?.dept?.cud
            }

            const people: TSys.IDatasetModule = {
                dict: dict.org.people,
                headers: dict.org.people.headers,
                get rows() {
                    return app.org.peoples
                },
                key: 'people',
                accessable: priviledges.user?.people,
                cudable: priviledges.user?.people?.cud
            }

            const role: TSys.IDatasetModule = {
                dict: dict.org.role,
                headers: dict.org.role.headers,
                get rows() {
                    return app.org.roles
                },
                key: 'role',
                accessable: priviledges.user?.role,
                cudable: priviledges.user?.role?.cud
            }

            const actor: TSys.IDatasetModule = {
                dict: dict.org.actor,
                headers: dict.org.actor.headers,
                get rows() {
                    return app.org.actors
                },
                key: 'actor',
                accessable: priviledges.team?.actor,
                cudable: priviledges.team?.actor?.cud
            }

            const inspector: TSys.IDatasetModule = {
                dict: dict.org.inspector,
                headers: dict.org.inspector.headers,
                get rows() {
                    return app.org.inspectors
                },
                key: 'inspector',
                accessable: priviledges.team?.inspector,
                cudable: priviledges.team?.inspector?.cud
            }

            const projectplan: TSys.IDatasetModule = {
                dict: dict.prj.projectplan,
                headers: dict.prj.projectplan.headers,
                get rows() {
                    return app.prj.plans
                },
                key: 'projectplan',
                accessable: priviledges.plan?.projectplan,
                cudable: priviledges.plan?.projectplan?.cud
            }

            const project: TSys.IDatasetModule = {
                headers: dict.prj.project.headers,
                dict: dict.prj.project,
                get rows() {
                    return app.prj.projects
                },
                key: 'project',
                accessable: priviledges?.plan?.project,
                cudable: priviledges?.plan?.project?.cud
            };

            const begunproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.project, title: 'tabs.begunproject' },
                headers: dict.prj.project.headers,
                get rows() {
                    return app.prj.projects.begun;
                },
                key: 'begunproject',
                accessable: priviledges?.plan?.project,
                cudable: priviledges?.plan?.project?.cud
            } : undefined;

            const acceptproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.project, title: 'tabs.acceptproject' },
                headers: dict.prj.project.headers,
                get rows() {
                    return app.prj.projects.accept;
                },
                key: 'acceptproject',
                accessable: priviledges?.plan?.project,
                cudable: priviledges?.plan?.project?.cud
            } : undefined;

            const pilotproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                headers: dict.prj.pilotproject.headers,
                dict: dict.prj.pilotproject,
                get rows() {
                    return app.prj.pilotprojects
                },
                key: 'pilotproject',
                accessable: priviledges.plan?.pilotproject,
                cudable: priviledges.plan?.pilotproject?.cud
            } : undefined;

            const meditateproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.pilotproject, title: 'tabs.meditateproject' },
                headers: dict.prj.pilotproject.headers,
                get rows() {
                    return app.prj.pilotprojects.meditate;
                },
                key: 'meditateproject',
                accessable: priviledges.plan?.pilotproject,
                cudable: priviledges.plan?.pilotproject?.cud
            } : undefined;

            const candidateproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.pilotproject, title: 'tabs.candidateproject' },
                headers: dict.prj.pilotproject.headers,
                get rows() {
                    return app.prj.pilotprojects.candidate;
                },
                key: 'candidateproject',
                accessable: priviledges.plan?.pilotproject,
                cudable: priviledges.plan?.pilotproject?.cud
            } : undefined;

            const inputproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                headers: dict.prj.inputproject.headers,
                dict: dict.prj.inputproject,
                get rows() {
                    return app.prj.inputprojects
                },
                key: 'inputproject',
                accessable: priviledges.plan?.inputproject,
                cudable: priviledges.plan?.inputproject?.cud,
                creatable: priviledges.plan?.inputproject?.cud?.create,
                deletable: priviledges.plan?.inputproject?.cud?.delete,
            } : undefined;

            const projectsummary: TSys.IDatasetModule = {
                dict: dict.prj.projectsummary,
                headers: null,
                rows: null,
                key: 'projectsummary',
                template: 'reportgeneral',
                accessable: priviledges.plan?.projectsummary
            }

            const workitemexesummary: TSys.IDatasetModule = {
                dict: dict.exec.workitemexesummary,
                headers: null,
                rows: null,
                key: 'workitemexesummary',
                template: 'reportworkitemexe',
                accessable: priviledges.audit?.auditsummary
            }

            const supervisesummary: TSys.IDatasetModule = {
                dict: dict.exec.supervisesummary,
                headers: null,
                rows: null,
                key: 'supervisesummary',
                template: 'reportsupervise',
                accessable: priviledges.supervise?.supervisesummary
            }

            const supervise: TSys.IDatasetModule = {
                dict: dict.exec.supervise,
                headers: dict.exec.supervise.headers,
                rows: null,
                key: 'supervise',
                accessable: priviledges.supervise?.supervise,
                cudable: priviledges.supervise?.supervise?.supervisemodify
            }

            const superviseaudit: TSys.IDatasetModule = {
                dict: dict.exec.superviseaudit,
                headers: dict.exec.superviseaudit.headers,
                rows: null,
                key: 'superviseaudit',
                accessable: usesuperviseaudit ? (
                    priviledges.supervise?.supervise
                ) : undefined,
                cudable: usesuperviseaudit ? (
                    priviledges.supervise?.supervise?.superviseaudit ||
                    priviledges.supervise?.supervise?.supervisemodify
                ) : undefined
            }

            const problem: TSys.IDatasetModule = {
                dict: dict.exec.problem,
                headers: dict.exec.problem.headers,
                rows: null,
                key: 'problem',
                accessable: priviledges.supervise?.problem,
                cudable: priviledges.supervise?.problem?.problemupdate
            }

            const projectexe: TSys.IDatasetModule = {
                dict: dict.exec.audit,
                headers: dict.exec.audit.headers,
                rows: null,
                key: 'projectexe',
                accessable: priviledges.audit?.audit,
                cudable: (
                    priviledges.audit?.audit?.normalmodify ||
                    priviledges.audit?.audit?.verifymodify
                ) // TODO: "90302"
            }

            const executeaudit: TSys.IDatasetModule = {
                dict: dict.exec.executeaudit,
                headers: dict.exec.executeaudit.headers,
                rows: null,
                key: 'executeaudit',
                accessable: useexecuteaudit ? (
                    priviledges.audit?.audit
                ) : undefined,
                cudable: useexecuteaudit ? (
                    priviledges.audit?.audit?.executeaudit ||
                    priviledges.audit?.audit?.normalmodify ||
                    priviledges.audit?.audit?.verifymodify
                ) : undefined
            }

            const checkpoint: TSys.IDatasetModule = {
                headers: dict.prj.checkpoint.headers,
                dict: dict.prj.checkpoint,
                get rows() {
                    return app.prj.checkpoints
                },
                key: 'checkpoint',
                accessable: priviledges.process?.checkpoint,
                cudable: priviledges.process?.checkpoint?.cud
            }

            const workitem: TSys.IDatasetModule = {
                dict: dict.prj.workitem,
                headers: dict.prj.workitem.headers,
                get rows() {
                    return app.prj.workitems
                },
                key: 'workitem',
                accessable: priviledges.process?.workitem,
                cudable: priviledges.process?.workitem?.cud
            }

            const workset: TSys.IDatasetModule = {
                dict: dict.prj.workset,
                headers: dict.prj.workset.headers,
                get rows() {
                    return app.prj.worksets
                },
                key: 'workset',
                accessable: priviledges.process?.workset,
                cudable: priviledges.process?.workset?.cud
            }

            const stage: TSys.IDatasetModule = {
                dict: dict.prj.stage,
                headers: dict.prj.stage.headers,
                get rows() {
                    return app.prj.stages
                },
                key: 'stage',
                accessable: priviledges.process?.stage,
                cudable: priviledges.process?.stage?.cud
            }

            const projecttype: TSys.IDatasetModule = {
                dict: dict.prj.projecttype,
                headers: dict.prj.projecttype.headers,
                get rows() {
                    return app.prj.projecttypes
                },
                key: 'projecttype',
                accessable: priviledges.process?.projecttype,
                cudable: priviledges.process?.projecttype?.cud
            }

            const projecttypedefine: TSys.IDatasetModule = {
                dict: dict.prj.projecttypedefine,
                headers: dict.prj.projecttypedefine.headers,
                get rows() {
                    return app.prj.projecttypes
                },
                key: 'projecttypedefine',
                accessable: priviledges.process?.projecttype,
                cudable: priviledges.process?.projecttype?.cud,
                nopaginator: true
            }

            const systemconfig: TSys.IDatasetModule = {
                dict: dict.sys.systemconfig,
                headers: dict.sys.systemconfig.headers,
                rows: null,
                key: 'systemconfig',
                accessable: priviledges.systemset?.settings
            }

            const operationlog: TSys.IDatasetModule = {
                dict: dict.sys.operationlog,
                headers: dict.sys.operationlog.headers,
                rows: null,
                key: 'operationlog',
                accessable: priviledges.systemset?.operationlog
            }

            const summarypatchproject: TSys.IDatasetModule = {
                dict: dict.exec.summarypatchproject,
                headers: null,
                rows: null,
                key: 'summarypatchproject',
                template: 'sumpatchproject',
                accessable: priviledges.summary?.summarypatchproject
            }

            const summaryongoingproject: TSys.IDatasetModule = {
                dict: dict.exec.summaryongoingproject,
                headers: null,
                rows: null,
                key: 'summaryongoingproject',
                template: 'sumongoingproject',
                accessable: priviledges.summary?.summaryongoingproject
            }

            const summaryprojectstage: TSys.IDatasetModule = {
                dict: dict.exec.summaryprojectstage,
                headers: null,
                rows: null,
                key: 'summaryprojectstage',
                template: 'sumprojectstage',
                accessable: priviledges.summary?.summaryprojectstage
            }

            const summaryprojectworkitem: TSys.IDatasetModule = {
                dict: dict.exec.summaryprojectworkitem,
                headers: null,
                rows: null,
                key: 'summaryprojectworkitem',
                template: 'sumprojectworkitem',
                accessable: priviledges.summary?.summaryprojectworkitem
            }

            const summarycancelproject: TSys.IDatasetModule = {
                dict: dict.exec.summarycancelproject,
                headers: null,
                rows: null,
                key: 'summarycancelproject',
                template: 'sumcancelproject',
                accessable: priviledges.summary?.summarycancelproject
            }

            const projecttypes: TSys.IDatasetModules = extend([projecttypedefine, /*projecttype, stage, workset,*/ workitem, checkpoint], defindex);
            // const projectplans: TSys.IDatasetModules = extend([projectsummary, inputproject, pilotproject, project, projectplan].filter(m => !!m), defindex);
            const projectplans: TSys.IDatasetModules = extend([projectsummary, inputproject, meditateproject, candidateproject, begunproject, acceptproject, projectplan].filter(m => !!m), defindex);
            const teams: TSys.IDatasetModules = extend([actor, inspector], defindex);

            const supervises: TSys.IDatasetModules = extend([supervisesummary, supervise, superviseaudit, problem], defindex);
            const workitemexes: TSys.IDatasetModules = extend([workitemexesummary, projectexe, executeaudit], defindex);

            const users: TSys.IDatasetModules = extend([people, role], defindex);
            const depts: TSys.IDatasetModules = extend([dept], defindex);

            const problems: TSys.IDatasetModules = extend([problem], defindex);
            const systeminfo: TSys.IDatasetModules = extend([systemconfig, operationlog], defindex);
            const summary: TSys.IDatasetModules = extend([summarypatchproject, summaryongoingproject, /* summaryprojectstage, */ summaryprojectworkitem, summarycancelproject], defindex);

            const searchfilter: TSys.IDatasetModule = {
                dict: dict.sys.searchfilter,
                headers: dict.sys.searchfilter.headers,
                rows: null,
                key: 'searchfilter',
                template: 'searchfilter'
            }

            return {
                dept: dept,
                people: people,
                role: role,

                projecttype: projecttype,
                stage: stage,
                workset: workset,
                workitem: workitem,
                checkpoint: checkpoint,

                projectplan: projectplan,
                inspector: inspector,
                actor: actor,
                project: project,
                inputproject: inputproject,
                pilotproject: pilotproject,
                meditateproject: meditateproject,
                candidateproject: candidateproject,
                begunproject: begunproject,
                acceptproject: acceptproject,

                workitemexesummary: workitemexesummary,
                projectexe: projectexe,
                supervisesummary: supervisesummary,
                supervise: supervise,
                superviseaudit: superviseaudit,
                executeaudit: executeaudit,
                users: users,
                teams: teams,
                projecttypedefine: projecttypedefine,
                projecttypes: projecttypes,
                projectplans: projectplans,
                depts: depts,

                workitemexes: workitemexes,
                supervises: supervises,

                problem: problem,
                problems: problems,

                systemconfig: systemconfig,
                operationlog: operationlog,
                systeminfo: systeminfo,
                advfilter: searchfilter,

                summarypatchproject: summarypatchproject,
                summaryongoingproject: summaryongoingproject,
                summaryprojectstage: summaryprojectstage,
                summaryprojectworkitem: summaryprojectworkitem,
                summarycancelproject: summarycancelproject,
                summary: summary
            }
        }

        const props = this._props as { datasources: ReturnType<typeof build> };
        return props.datasources || (props.datasources = build());
    }

    get mainnavs(): RouteInfo[] {
        const build = (route: Route, path: string): RouteInfo[] => {
            return route.children?.reduce<{
                navs: RouteInfo[],
                path: string
            }>(({ navs, path }, i) => {
                path = path || "";

                const ipath = i.path || "";
                const curpath = `${path}${path && ipath && '/' || ''}${i.path}`

                if (true && !this.auth.me.priviledges[ipath]) {
                    return { navs, path };
                }

                navs.push({
                    ...((i.data || {}) as RouteInfo),
                    path: curpath
                });

                return { navs, path }
            }, {
                navs: [],
                path: path
            }).navs || [];
        }

        const { _props: props } = this;
        if (props.mainnavs) return props.mainnavs;

        const route: Route = (this.router.config || []).find(
            r => r?.data?.level == 1
        ) || {};

        return props.mainnavs = build(route, route.path);
    }

    get curRoute() {
        const findLast = (route: ActivatedRoute): ActivatedRoute => {
            return route && route.children.length > 0 ? findLast(route.children[0]) : route;
        }

        return findLast(this.curroute);
    }

    get curRouteInfo() {
        return this.curRoute?.routeConfig?.data as RouteInfo || {};
    }

    get consumeByProject(): boolean {
        return this.auth?.me?.consumebyproject;
    }

    get superviseaudit(): boolean {
        return this.auth?.me?.superviseaudit;
    }

    get executeaudit(): boolean {
        return this.auth?.me?.executeaudit;
    }

    get prepareproject(): boolean {
        return this.lang?.prepareproject ?? this.auth?.me?.prepareproject;
    }

    get defaultfiler(): Prj.SearchFilter {
        if (this._props.defaultfiler) return this._props.defaultfiler;

        this._props.defaultfiler = new Prj.SearchFilter(this.org, this.prj, {
            name: "",
            description: "",
            nowyear: true,
            start: new Date(),
            end: new Date(),
            constrstatus: [],
            constrtype: [],
            workitems: []
        });

        return this._props.defaultfiler;
    }

    get lang(): Lang.Res<typeof Langs> {
        const { _props: props } = this;
        return props.lang;
    }

    constructor(
        public curroute: ActivatedRoute,
        public translate: TranslateService,
        public httpClient: HttpClient,
        public auth: AuthService,
        public msg: MsgService,
        public router: Router,
        public adapter: DateAdapter<any>,
        public alerts: AlertService,
        public version: VersioinService,
        public titleService: Title
    ) {
        const app = this, { _props: props } = this;

        // create the language change resolve.
        const onLangChange = (props.onLangChange = (
            translate.onLangChange.pipe(
                map(({ lang, translations }) => {
                    return translations;
                }),
                shareReplay(1)
            )
        ));

        // create data which depends on the lang resource
        const subLang = onLangChange.subscribe({
            next(lang: any) {
                // now we can safely build language depent global definitions.
                props.lang = app.trans(Langs);

                // change the webpage name
                const appname = lang?.general?.title;
                titleService.setTitle(appname ?? DefAppName);
            },
            complete() {
                subLang?.unsubscribe();
            }
        })

        // initialize the language options
        const subVersion = version.fetcher.subscribe(() => {
            const defLang = 'zh-CN';
            adapter.setLocale(defLang);
            translate.addLangs([defLang]);
            translate.setDefaultLang(defLang);
            translate.use(defLang);
            subVersion?.unsubscribe();
        })

        // intercept the route jump to confirm login
        router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((e: NavigationStart) => {
            if (!auth.me && e.url != "/login") {
                auth.pathto = e.url;

                router.navigate(['/login/'], {
                    queryParams: {}
                });
            }
        })

        // handle error/notice/warning etc message default
        msg.onRequestError().subscribe(status => {
            this.alerts.push({
                ...status,
                type: "danger",
                dismiss: 5000
            })
        })

        // clear the service data while logout
        msg.onMeChanged.subscribe(({ cur, pre }: {
            cur: Auth.IMe;
            pre: Auth.IMe;
        }) => {
            if (!cur) {
                this.clearCaches();
            }
        })
    }

    resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
        const { _props: { onLangChange } } = this;
        return onLangChange.pipe(take(1));
    }

    trans<T extends string | Lang.Def>(key: T, interpolateParams?: Object): Lang.Res<T> {
        if (_.isString(key)) {
            return this.translate.instant(key, interpolateParams);
        }

        return _.reduce(key as Lang.Def, (res, r, k) => {
            return res[k] = this.trans(r as (string | Lang.Def), interpolateParams), res;
        }, <any>{})
    }

    clearCaches() {
        const { _props: props } = this;
        if (Editor.detect.cud == props.backface?.cud) {
            Editor.detect.cud = null;
        }

        const caches: (keyof typeof props)[] = [
            'alerts', 'datasources', 'mainnavs',
            'backface', 'org', 'prj', 'exec',
        ];

        caches.forEach(key => {
            const cache = props[key];
            cache?.['ngOnDestroy']?.();
            delete props[key];
        })
    }

    getenumkey(input: TPrj.IWorkitem.Type): string {
        return this.dict.WSProperties.indexed[input]?.title;
    }

    excludeHeaders(modules) {
        //////////////////////////////////
        // Sample object
        //////////////////////////////////
        /*         
        const resource = {
            systemconfg: {
                modules: {
                    exclude: {
                        actor: {
                            header: true,
                            domains: true
                        }
                    }
                }
            }
        }
         */
        //////////////////////////////////
        // Sample object
        //////////////////////////////////

        const excludes: {
            [Module: string]: {
                [Header: string]: boolean
            }
        } = modules?.exclude || {};

        const regField = /^([\w\d_]+)[\.\[]?/;
        const { dict: { prj, exec, org } } = this;
        forEach(excludes, (module, modulekey) => {
            const dicModule = prj[modulekey] || exec[modulekey] || org[modulekey];
            dicModule && forEach(module, (excluded, headerkey) => {
                const header = excluded && dicModule.headers?.find((h: { key: string }) => (
                    regField.exec(h.key)[1] == headerkey
                )) || null;

                if (header) {
                    dicModule.headers.remove(header);
                }
            })
        })
    }
}
