import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { isNull, isUndefined } from "lodash";

import { AppService } from "../../../application/service/app.service";
import { Prj, Sys } from "../../../application/service/backface/types";
import { ProjectListComponent } from "src/app/projectlist";

@Component({
    selector: "gov-prjcard, [gov-prjcard]",
    templateUrl: "./gov.prjcard.component.html",
    styleUrls: ["./gov.prjcard.component.scss"]
})
export class GovPrjCardComponent implements OnInit {
    @Input('prjsumitem')
    prjsumitem: Sys.IPrjBudgetSumItem;

    constructor(
        public app: AppService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    getStatusName(): string {
        const { app: { dict: { PrjConstrTypes, AllStatus } } } = this;
        var name = "";

        if (isNull(this.prjsumitem) || isUndefined(this.prjsumitem))
            return name;

        if (!isNull(this.prjsumitem.constrtype) && !isUndefined(this.prjsumitem.constrtype)) {
            name = PrjConstrTypes.find(p => p.value == this.prjsumitem.constrtype)?.title;
            return name;
        }

        if (!isNull(this.prjsumitem.constrstatus) && !isUndefined(this.prjsumitem.constrstatus)) {
            const { app: { lang: { sysenums = {} } = {} } } = this;
            if (this.prjsumitem.constrstatus == Prj.IWorkitem.Status.finished) return sysenums.prjfinished;
            name = AllStatus.find(s => s.value == this.prjsumitem.constrstatus)?.title;
            return name;
        }

        return name;
    }

    getPercent(): number {
        if (isNull(this.prjsumitem) || isUndefined(this.prjsumitem))
            return 0;

        if (isNull(this.prjsumitem.yearamount) || isUndefined(this.prjsumitem.yearamount) || this.prjsumitem.yearamount <= 0)
            return 0;

        if (isNull(this.prjsumitem.yearconsume) || isUndefined(this.prjsumitem.yearconsume) || this.prjsumitem.yearconsume <= 0)
            return 0;

        return Math.round(this.prjsumitem.yearconsume / this.prjsumitem.yearamount * 1000) / 10;
    }

    summaryList() {
        if (this.prjsumitem?.count == 0) return;

        const { app, prjsumitem: { year, prjlist, count } = {} } = this;
        const { lang: { general: { unit, projects }, report: { projectcountsummary } } } = app;

        const header = `${year}/${this.getStatusName()}/${count}${unit}${projects}`;
        ProjectListComponent.open(this.dialog, {
            title: projectcountsummary,
            prjidlist: prjlist,
            header: header,
        })
    }
}