import { Component, Inject, OnInit, ViewChild, AfterViewInit, OnDestroy, Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { extend, isNull, isNumber, isUndefined } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { EChartsOption, number, SeriesOption } from 'echarts';
import * as _ from 'lodash';

import { Exec as TExec, Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { GovEditorComponent } from '../../utils/view/gov.editor.component/gov.editor.component';
import { WorkItemListComponent } from '../../workitemlist/view/workitemlist.component';
import { ResultData, ResultsComponent } from '../../results/view/results.component';
import { ProblemListComponent } from '../../problemlist/view/problemlist.component';
import { Backface } from '../../../app/application/service/backface/config';
import { TemplateService } from '../../utils/view/template.directive';
import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';
import { SysService } from '../../application/service/sys.service';
import { Report } from '../../application/service/backface/report';
import { GovEditor } from '../../utils/view/model/form.editting';
import { PlanComponent } from '../../plan/view/plan.component';
import { Exec } from '../../application/service/backface/exec';
import { Prj } from '../../application/service/backface/prj';
import { _JSON } from '../../utils/libs/polyfill/json';
import { ProjectSupervise } from './projectsupervise';
import { Property } from '../../utils/libs/property';
import { Differ } from '../../utils/libs/differ';
import { Editor } from '../../utils/libs/editor';
import { Auth } from '../../application/types';
import { ProjectExec } from './projectexec';

@Component({
    templateUrl: "./projectdetail.component.html",
    styleUrls: ['./projectdetail.component.scss'],
    providers: [TemplateService]
})
export class ProjectDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of<ProjectDetailComponent & {
        _headerids: string[];
        _handlers: {
            projectsupervise: ProjectSupervise,
            projectexec: ProjectExec
        }
        differ: Differ
    }>(this).values;

    @ViewChild(GovEditorComponent, { static: false })
    goveditor: GovEditorComponent;

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    get component() {
        return ResultsComponent;
    }

    get handlers() {
        const { _props: props, _props: { _handlers } } = this;
        if (_handlers) return _handlers;

        return (props._handlers || (
            props._handlers = {
                projectexec: new ProjectExec(this),
                projectsupervise: new ProjectSupervise(this)
            }
        ))
    }

    get summery(): TSys.IDatasetModule {
        const { _props: props, auth: { me: { priviledges } } } = this;
        return props.summery = props.summery || {
            dict: { title: "projectdetail.status", icon: "visibility", },
            accessable: priviledges?.plan?.project,
            template: 'project.summery',
            key: 'projectdetailsummery'
        }
    }

    get actor(): TSys.IDatasetModule {
        const { _props: props, auth: { me: { priviledges } } } = this;
        return props.actor = props.actor || {
            dict: { title: "project.actor", icon: "group", },
            accessable: priviledges?.plan?.project,
            template: 'project.actor',
            key: 'projectdetailactor'
        }
    }

    get inspector(): TSys.IDatasetModule {
        const { _props: props, auth: { me: { priviledges } } } = this;
        return props.inspector = props.inspector || {
            dict: { title: "project.inspector", icon: "group", },
            accessable: priviledges?.plan?.project,
            template: 'project.inspector',
            key: 'projectdetailinspector'
        }
    }

    get execute(): TSys.IDatasetModule {
        const { handlers: { projectexec } } = this;
        return projectexec.execute;
    }

    get executeaudit(): TSys.IDatasetModule {
        const { handlers: { projectexec } } = this;
        return projectexec.audit;
    }

    get supervise(): TSys.IDatasetModule {
        const { handlers: { projectsupervise } } = this;
        return projectsupervise.execute;
    }

    get superviseaudit(): TSys.IDatasetModule {
        const { handlers: { projectsupervise } } = this;
        return projectsupervise.audit;
    }

    get problem(): TSys.IDatasetModule {
        const { handlers: { projectsupervise } } = this;
        return projectsupervise.problem;
    }

    get modules(): TSys.IDatasetModules {
        const { execute, executeaudit, supervise, superviseaudit, problem } = this;
        const { _props: props, project, summery, actor, inspector } = this;

        return props.modules = props.modules || extend(
            [
                summery,
                ...(project?.actor ? [actor] : []),
                ...(project?.inspector ? [inspector] : []),
                ...(project?.ispatched ? [] : [
                    execute,
                    executeaudit,
                    supervise,
                    superviseaudit,
                    problem
                ])
            ],
            { currentIndex: 0 }
        )
    }

    get stageSummery(): TSys.IDatasetModule {
        const { _props: props, project: { ispatched } } = this;

        return (props.stageSummery = (props.stageSummery || {
            key: 'projectdetailstagesummery',
            headers: ispatched ? [] : [{
                key: 'stage',
                title: 'stage.name',
                type: Editor.Value.Type.text,
                readonly: true
            }, {
                key: 'audit',
                title: 'audit.name',
                type: Editor.Value.Type.richtext,
                readonly: true
            }, {
                key: 'status',
                title: 'stage.status',
                type: Editor.Value.Type.text,
                readonly: true
            }],
            rows: []
        }));
    }

    get currentModule() {
        return this.goveditor?.currentmodule;
    }

    get project(): Prj.Project {
        return (this.data.object instanceof Prj.Project ? this.data.object : null);
    }

    get Report() {
        return Report;
    }

    get budgetoption(): EChartsOption {
        const { _props: props, app } = this;
        return (props.budgetoption = (props.budgetoption || new Report.ReportTemplate().ReportTemplateChartBar));
    }

    get selectedYear(): number {
        const { _props: props, app: { dict: { currentYear } } } = this;
        return props.selectedYear ?? currentYear;
    }

    set selectedYear(val: number) {
        const { _props: props } = this;
        if (val == props.selectedYear) return;

        props.selectedYear = val;

        const { selectedYear } = this;
        this.loadSuperviseDatabyYear(selectedYear);
    }

    get checkoption(): EChartsOption {
        const { _props: props, app, app: { report: { supervisemixlinebarcolors } } } = this;
        return (props.checkoption = (props.checkoption || new Report.ReportTemplate().ReportTemplateMixedLineBar(supervisemixlinebarcolors, 1, 3)));
    }

    resultData: ResultData = {
        title: null,
        object: this.app.prj.workitems.find(ws => ws.type == TPrj.IWorkitem.Type.supervise) as Object as Prj.ProjWorkitem,
        results: Report.buildResult(this.app)
    };

    get lastResultData(): ResultData {
        return this.resultData;
    }

    get selectedYearCost(): number {
        const { _props: props, app: { dict: { currentYear } } } = this;
        return props.selectedYearCost ?? currentYear;
    }

    set selectedYearCost(val: number) {
        const { _props: props } = this;
        if (val == props.selectedYearCost) return;

        props.selectedYearCost = val;

        const { selectedYearCost } = this;
        this.loadYearMonthCostDatabyYear(selectedYearCost,this.yearmonthcostchartParam);
    }

    get prjyearmonthcostoption(): EChartsOption {
        const { _props: props, app, app: { report: { } } } = this;
        return (props.prjyearmonthcostoption = (props.prjyearmonthcostoption || new Report.ReportTemplate().ReportTemplateChartStackLine));
    }

    bwsparamset: boolean = false; //工作项参数设置
    bnormalmodify: boolean = false; //普通项更新
    bverifymodify: boolean = false; //审批项更新
    private bnormalview: boolean = false; //普通项查看
    private bverifyview: boolean = false;  //审批项查看
    private bsuperviseview: boolean = false; //督查记录查看
    private bsupervisemodify: boolean = false; //督查记录增删改
    private bproblemview: boolean = false;  //问题查看
    private bproblemupdate: boolean = false; //问题更新

    prjstatuscount: Array<TSys.IStatusCount>;

    constructor(
        public app: AppService,
        public sys: SysService,
        public auth: AuthService,
        public router: ActivatedRoute,
        public httpClient: HttpClient,
        public dialog: MatDialog,
        public ngzone: NgZone,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            from: any
            object: Prj.Project,
            header: TSys.IDatasetHeader,
            dataset: TSys.IDatasetModule,
            dialog: MatDialog
        }
    ) {
        this.editting = this.editting.bind(this);
    }

    ngOnInit() {
        const { handlers: { projectexec, projectsupervise }, app: { dict: { statuslist } } } = this;
        projectsupervise?.ngOnInit();
        projectexec?.ngOnInit();

        this.prjstatuscount = new Array<TSys.IStatusCount>();
        statuslist.forEach(s => {
            this.prjstatuscount.push(
                { status: s.status, count: 0, percent: 0 } as TSys.IStatusCount
            );
        });
    }

    ngAfterViewInit(): void {
        this.initBudget();
        this.initYearCost();
        this.initExec();
        this.initSupervise();
        this.loadLastSuperviseResult();
    }

    ngOnDestroy(): void {
        const { _props: { reporter, _handlers: { projectexec, projectsupervise } } } = this;
        projectsupervise?.ngOnDestroy();
        projectexec?.ngOnDestroy();
        reporter?.ngOnDestroy();
    }

    onCurrentModule(module: TSys.IDatasetModule) {
        const { summery, execute, executeaudit, supervise, superviseaudit, problem } = this;
        const { handlers: { projectexec, projectsupervise } } = this;

        switch (module) {
            case summery:
                this.loadYearMonthCostDatabyYear(this.selectedYearCost,this.yearmonthcostchartParam);
                this.loadSuperviseDatabyYear(this.selectedYear);
                this.loadLastSuperviseResult();
                this.loadStageSummary();
                return;

            case execute:
            case executeaudit:
                return projectexec.onCurrentModule(module);

            case problem:
            case supervise:
            case superviseaudit:
                return projectsupervise.onCurrentModule(module);
        }
    }

    editting(obj: Prj.ProjWorkitem | Exec.Problem | Exec.AuditResult, def?: ((obj: object) => GovEditor.IEditting)): GovEditor.IEditting {
        const { currentModule, handlers: { projectexec, projectsupervise } } = this;
        const { execute, executeaudit, supervise, superviseaudit, problem } = this;

        switch (currentModule) {
            case execute:
            case executeaudit:
                return projectexec.editting(obj, def);

            case problem:
            case supervise:
            case superviseaudit:
                return projectsupervise.editting(obj, def);
        }
    }

    initBudget() {
        const { app, app: { report: { prjcostChartConfig } } } = this;
        this.budgetoption.title['text'] = prjcostChartConfig.title;
        this.budgetoption.xAxis['name'] = prjcostChartConfig.xAxis;
        this.budgetoption.yAxis['name'] = prjcostChartConfig.yAxis;

        var year = new Report.ReportTemplate().ReportTemplateChartBarItem;
        var total = new Report.ReportTemplate().ReportTemplateChartBarItem;
        const { app: { lang: { general: { annual, bunit }, budget: { amount, consume } } } } = this;

        total.name = `${annual}${amount}(${bunit})`;
        year.name = `${annual}${consume}(${bunit})`;

        total.id = 'total';
        year.id = 'year';

        const { project } = this;
        this.budgetoption.series[0] = total;
        this.budgetoption.series[1] = year;

        this.budgetoption.xAxis['data'] = project.budgets.map(budget => budget.start.getFullYear().toString());

        this.budgetoption.series[0]['data'] = project.budgets.map(budget => budget.amount);
        this.budgetoption.series[1]['data'] = project.budgets.map(budget => budget.consume);
    }

    loadStageSummary() {
        const { project, reporter, app: { dict: { AllStatus } } } = this;

        reporter.report("/report/workitem_count_per_project_stage_status_by_project_attribute", (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            if (!isNull(res['count']) && !isUndefined(res['count'])) {
                //update count card
            }

            if (!isNull(res['stage']) && !isUndefined(res['stage'])) {
                //update stage                
                var iTotal = 0;
                var i = 0;
                this.stageSummery.rows.clear();
                this.prjstatuscount.forEach(ps => {
                    ps.count = 0;
                    ps.percent = 0;
                });
                project?.projecttype?.stages?.forEach(stage => {
                    var obj = res['stage'].find(s => s.id == stage.id);
                    var row = { stage: stage.name }

                    var audititem = <TSys.IStageSumItem>{
                        wssum: new Array<TSys.IWSSumItem>(),
                        project: project,
                        stage: stage
                    };

                    TPrj.IWorkitem.Status.forEach(element => {
                        if (!isNumber(element)) return;
                        var wsitem = <TSys.IWSSumItem>{
                            prjlist: [project.id],
                            count: 0,
                            status: element
                        };

                        if (!isNull(obj) && !isUndefined(obj)) {
                            var key = AllStatus.find(s => s.value == element)?.key;
                            var count = key ? obj.count[key] : 0;
                            wsitem.count = count ? count : 0;
                        };

                        audititem.wssum.push(wsitem);
                        var prjscnt = this.prjstatuscount.find(s => s.status == element);
                        if (prjscnt) {
                            prjscnt.count += wsitem.count;
                            prjscnt.percent = 0;
                            iTotal += wsitem.count;
                        }
                    });

                    row['audit'] = audititem;

                    if (!isNull(obj) && !isUndefined(obj) && !isNull(obj["status"]) && !isUndefined(obj["status"])) {
                        row['status'] = AllStatus.find(s => s.key == obj["status"])?.title;
                    } else {
                        row['status'] = AllStatus.find(s => s.value == TPrj.IWorkitem.Status.notstart)?.title;
                    };

                    this.stageSummery.rows.push(row);
                    i++;
                });

                this.prjstatuscount.forEach(ps => {
                    ps.percent = (iTotal == 0) ? 0 : (Math.round(ps.count / iTotal * 1000) / 10);
                });
            }
        }).query({
            "project": [{ "id": project.id }],
        });
    }

    onWorkItemDetails(data: GovEditor.IWorkItemSumData) {
        if (isNull(data) || isUndefined(data) ||
            isNull(data.stageitem) || isUndefined(data.workitemsum)) {
            return;
        }

        const { workitemsum: { status, count }, stageitem: { stage } } = data;
        const { app: { dict: { AllStatus }, lang: { general: { item = '' } } } } = this;
        var wsstatus = AllStatus.find(s => s.value == status);
        var header = `${stage.name}/${wsstatus?.title}/${count}${item}`;

        WorkItemListComponent.open(this.dialog, {
            title: this.project.name,
            header: header,
            prjidlist: [this.project.id],
            stageidlist: [stage.id],
            workitemstatus: [wsstatus?.key]
        })
    }

    initExec() {
        if (this.project.ispatched) return;
        const { app: { auth: { me: { priviledges: { plan: { project: { projectdetails = <Auth.Priviledge>{} } = {} } = {} } } } } } = this;
        this.bsupervisemodify = !!projectdetails.supervisemodify;
        this.bproblemupdate = !!projectdetails.problemupdate;
        this.bsuperviseview = !!projectdetails.superviseview;
        this.bnormalmodify = !!projectdetails.normalmodify;
        this.bverifymodify = !!projectdetails.verifymodify;
        this.bproblemview = !!projectdetails.problemview;
        this.bwsparamset = !!projectdetails.wsparamset;
        this.bnormalview = !!projectdetails.normalview;
        this.bverifyview = !!projectdetails.verifyview;
    }

    initSupervise() {
        if (this.project.ispatched) return;

        const { app: { dict: { months }, lang: { report } } } = this;
        this.checkoption.title['text'] = report.supervisesummary;

        this.checkoption.legend['data'].clear();

        this.checkoption.legend['data'].add(report.refproject);
        this.checkoption.legend['data'].add(report.problemnotfixed);
        this.checkoption.legend['data'].add(report.supervisecount);
        this.checkoption.legend['data'].add(report.problemcount);

        this.checkoption.xAxis[0]['data'] = months.reduce((res, v) => {
            res.push(v.name); return res;
        }, []);

        this.checkoption.yAxis[0]['name'] = report.refproject;
        this.checkoption.yAxis[1]['name'] = report.problemnotfixed;
        this.checkoption.yAxis[2]['name'] = report.supervisecount;
        this.checkoption.yAxis[3]['name'] = report.problemcount;

        this.checkoption.yAxis[0]['max'] = 2;
        this.checkoption.yAxis[1]['max'] = 20;
        this.checkoption.yAxis[2]['max'] = 10;
        this.checkoption.yAxis[3]['max'] = 10;

        this.checkoption.series[0]['name'] = report.refproject;
        this.checkoption.series[1]['name'] = report.problemnotfixed;
        this.checkoption.series[2]['name'] = report.supervisecount;
        this.checkoption.series[3]['name'] = report.problemcount;

        this.checkoption.series[0]['id'] = ('project');
        this.checkoption.series[1]['id'] = ('problemnotfixed');
        this.checkoption.series[2]['id'] = ('supervisecount');
        this.checkoption.series[3]['id'] = ('problemcount');

        this.checkoption.series[0]['data'] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.checkoption.series[1]['data'] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.checkoption.series[2]['data'] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.checkoption.series[3]['data'] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        this.loadSuperviseDatabyYear(this.selectedYear);
    }

    loadSuperviseDatabyYear(year: number) {
        this.reporter.report("/report/supervise_per_month_by_project_attribute", (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            if (!isNull(res['count']) && !isUndefined(['count'])) {
                //update count card
            }

            if (!isNull(res['month']) && !isUndefined(['month'])) {
                //update stage
                var i = 0;

                for (let index = 1; index <= 12; index++) {
                    var month = this.selectedYear + '-';
                    if (index < 10) month += '0';
                    month += index.toString();

                    var obj = res['month'].find(m => m.month == month);
                    if (isNull(obj) || isUndefined(obj)) {
                        this.checkoption.series[0]['data'][index - 1] = 0;
                        this.checkoption.series[1]['data'][index - 1] = 0;
                        this.checkoption.series[2]['data'][index - 1] = 0;
                        this.checkoption.series[3]['data'][index - 1] = 0;
                        continue;
                    };

                    this.checkoption.series[0]['data'][index - 1] = obj['count'].project.nonpatched ? obj['count'].project.nonpatched : 0;
                    this.checkoption.series[1]['data'][index - 1] = obj['count'].problem.tofix ? obj['count'].problem.tofix : 0;
                    this.checkoption.series[2]['data'][index - 1] = obj['count'].count ? obj['count'].count : 0;
                    this.checkoption.series[3]['data'][index - 1] = obj['count'].problem.count ? obj['count'].problem.count : 0;
                }

                Report.RefreshEchart("prjsuperviseoption", this.checkoption);
            }
        }).query({
            "project": [{ "id": this.project.id }],
            "year": [year, year]
        });
    }

    header = "";
    onClickCharBar(params): void {
        if (params?.value <= 0) return;

        var title = this.data?.object?.fullName;
        const { selectedYear, reporter, app: { lang: { general: { year = '', unit = '' } } } } = this;
        this.header = `${selectedYear}${year}/${params.name}/${params.seriesName}/${params.value}${unit}`;

        var sid = params?.seriesId;

        if (sid == 'problemcount' || sid == 'problemnotfixed') {
            var month = params.dataIndex + 1;

            var start = this.selectedYear + "-";
            if (month < 10) start += "0";
            start += month.toString() + "-01 00:00:00";

            var end = this.selectedYear + "-";
            if ((month + 1) < 10) end += "0";
            if (month == 12) {
                end += month.toString() + "-31 23:59:59";
            } else {
                end += (month + 1).toString() + "-01 00:00:00";
            }

            ProblemListComponent.open(this.dialog, {
                title: title,
                header: this.header,
                prjidlist: [this.data?.object?.id],
                date: [start, end],
                fixed: null,
                type: [sid == 'problemcount' ? 'newfound' : 'notfix']
            })
        }

        if (sid == 'supervisecount') {
            var month = params.dataIndex + 1;

            var start = this.selectedYear + "-";
            if (month < 10) start += "0";
            start += month.toString() + "-01 00:00:00";

            var end = this.selectedYear + "-";
            if ((month + 1) < 10) end += "0";
            if (month == 12) {
                end += month.toString() + "-31 23:59:59";
            } else {
                end += (month + 1).toString() + "-01 00:00:00";
            }

            reporter.report("/entity/results/retrieve", (res: TExec.IResult[] = []) => {
                if (isNull(res) || isUndefined(res)) { return; }

                title += ("/" + this.header);

                const { app, dialog } = this;
                ResultsComponent.open(dialog, {
                    title: title,
                    object: this.app.prj.workitems.find(ws => ws.type == TPrj.IWorkitem.Type.supervise),
                    results: Report.buildResult(app, res)
                })
            }).query({
                "project": [this.data?.object?.id],
                "date": [start, end],
                "workitem": '77',
            });
        }
    }

    loadLastSuperviseResult(): void {
        this.reporter.report("/xretrieve/lastsupervise", (res: TExec.IResult[] = []) => {
            if (isNull(res) || isUndefined(res)) { return; }

            const { app, lastResultData } = this;
            lastResultData.results = Report.buildResult(app, res);
        }).query({
            "project": [this.data?.object?.id]
        });
    }
    
    readonly yearmonthcostchartParam: Report.IChartParam = {
        charttype: Report.eChartType.line,
        chartid: "prjyearmonthcost",
        option: new Report.ReportTemplate().ReportTemplateChartStackLine,
        reqURL: "/report/costconsume_per_month_by_project_attribute",
        legends: this.app.report.yearcostinvest,
        xAxisItems: this.app.dict.months,
        chartCfg: this.app.report.monthChartConfig,
        legtag: "month",
        dataMap: new Map<string, object>()
    }
    
    initYearCost() {
        if (this.project.ispatched) return;
        Report.InitChartParam(this.yearmonthcostchartParam);

        const { app: { dict: { monthcostdefault } } } = this;
        
        this.yearmonthcostchartParam.option.series[0].data = monthcostdefault;
        this.yearmonthcostchartParam.option.series[1].data = monthcostdefault;     
    }

    loadYearMonthCostDatabyYear(year: number, param: Report.IChartParam): void {        
        if (this.project.ispatched) return;
        if (isNull(param) || isUndefined(param)) return;

        this.reporter.report(param.reqURL, (res) => {
            if (isNull(res) || isUndefined(res)) { return; }
            if (!isNull(res['month']) && !isUndefined(res['month'])) {
                //update stage
                const series = param.option.series as SeriesOption[];
                param.dataMap.clear();

                for (let index = 1; index <= 12; index++) {
                    var month = this.selectedYear + '-';
                    if (index < 10) month += '0';
                    month += index.toString();
                    var obj = res['month'].find(m => m.month == month);
                    if (isNull(obj) || isUndefined(obj)) {
                        series[0]['data'][index - 1] = 0;
                        series[1]['data'][index - 1] = 0;
                        continue;
                    };

                    series[0]['data'][index - 1] = obj['consume'].yearlyinvested ? obj['consume'].yearlyinvested : 0;
                    series[1]['data'][index - 1] = obj['consume'].yearlyinvested ? obj['consume'].yearlyassetinvested : 0;
                    if (series[0]['data'][index - 1] != 0 && param.dataMap) {

                    }
                }
                Report.RefreshEchart(param.chartid, param.option);
            }            
        }).query({
            year: [this.selectedYear, this.selectedYear],
            project: [{ id: this.project.id }]
        });
    }

    static open(dialog: MatDialog, data: {} = {}, injector?: Injector): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '100vh',
            maxWidth: '100vw',
            minWidth: '100vw',
            height: '100vh',
            width: '100vw',
            data: {
                ...data
            }
        }

        // temporarily switch off the change detection of parent PlanComponent instance for performance optimization.
        const { cdf } = injector?.get(PlanComponent, null) || {}; cdf?.detach();
        const dialogref = dialog.open(ProjectDetailComponent, config);
        const sub = cdf && dialogref.beforeClosed().subscribe({
            complete() {
                sub?.unsubscribe();
                cdf?.reattach();
            }
        });

        return dialogref;
    }
}

export namespace ProjectDetailComponent {
    export interface IHandler {
        onCurrentModule(module: TSys.IDatasetModule);
        ngOnDestroy(): void;
        ngOnInit(): void;
    }
}