import { EventEmitter, Output, Input, ElementRef, ViewContainerRef } from '@angular/core';
import { isObject, clone, reduce, capitalize } from 'lodash';
import { __decorate, __metadata } from 'tslib';

import { Class, FreeOptions, Unreadonly } from './types/mixins';
import { Extend } from './extends';
import { Unique } from './unique';

const _PropertySymbol: symbol = Unique.symbol('PROPERTY');

function getPropertyBag<T = FreeOptions>(obj: any, forceOwn: boolean = true): Property.IBag<T> {
    if (obj && (forceOwn && !obj.hasOwnProperty(_PropertySymbol))) {
        const values: T = {} as any, before: Partial<T> = {}, after: Partial<T> = {}, changes = {
            get before() { return before },
            get after() { return after },
        }, parent = obj[_PropertySymbol];

        const props: Property.IBag<T> = {
            get parent() {
                return parent;
            },

            get changes() {
                return changes;
            },

            get values() {
                return values;
            }
        };

        Object.defineProperty(obj, _PropertySymbol, {
            enumerable: false,
            configurable: true,
            get: function (this: any): {} {
                return props;
            }
        });
    }

    return obj?.[_PropertySymbol];
}

export function Property<T, TV = T>(props: Property.PROP = Property.PROP.gsio, options?: {
    preset?: (val: TV extends Element ? ViewContainerRef | ElementRef<TV> | TV : TV, prop?: string) => boolean | { value: T },
    postset?: (val: T, prop?: string) => void,
    preget?: (prop?: string) => { value: T },
}): PropertyDecorator {

    function Prop(
        target: Object,
        propertyKey: string,
    ): TypedPropertyDescriptor<T> {
        const prop_desc: TypedPropertyDescriptor<T> = Object.getOwnPropertyDescriptor(target, propertyKey) || {
            configurable: true,
            enumerable: true
        };

        if (props & Property.PROP.get) {
            prop_desc.get = prop_desc.get || _Defaults.getValue(propertyKey);
        } else {
            delete prop_desc.get;
        }

        if (props & Property.PROP.set) {
            prop_desc.set = prop_desc.set || (() => {
                const setter = _Defaults.setValue(propertyKey);

                const getter = function (this: Object) {
                    try {
                        return this[propertyKey];
                    } catch (e) { }
                }

                return function (this: Object, value: T) {
                    if ((props & Property.PROP.track)) {
                        const pb = getPropertyBag(this);

                        pb.changes.before[propertyKey] = getter.call(this);//pb.values[propertyKey];
                        pb.changes.after[propertyKey] = value;
                    }

                    setter.call(this, value);
                }
            })()
        } else {
            delete prop_desc.writable;
            delete prop_desc.set;
        }

        if (props & Property.PROP.output) {

            const event_name = propertyKey;
            const prop_name = `on${capitalize(event_name)}`;

            let ev_desc: TypedPropertyDescriptor<EventEmitter<T>> = {
                configurable: true,
                enumerable: true,
                get: function (this: Object): EventEmitter<T> {
                    let pb = getPropertyBag(this);

                    pb[prop_name] = pb[prop_name] || new EventEmitter<T>();

                    return pb[prop_name];
                }
            };

            const set = prop_desc.set;
            prop_desc.set = function (this: Object, value: T) {
                set?.call(this, value);
                this[prop_name].emit(value);
            };

            ev_desc = __decorate([
                Object(Output)(event_name),
                __metadata("design:type", EventEmitter)
            ], target, prop_name, ev_desc);
        }

        // check whether has preset/postset filter and call
        if (options?.preset || options?.postset) {
            const set = prop_desc.set;

            prop_desc.set = function (this: Object, value: T) {
                if (options.preset) {
                    const res = options.preset.call(this, value, propertyKey) as boolean | {
                        value: T;
                    };

                    if (!res) {
                        return;
                    }

                    if (isObject(res)) {
                        value = res.value
                    }
                }

                set?.call(this, value);

                if (options.postset) {
                    options.postset.call(this, set ? this[propertyKey] : value, propertyKey);
                }
            }
        }

        // check whether has preget filter and call
        if (options?.preget) {
            const get = prop_desc.get;

            prop_desc.get = function (this: Object): T {
                const res: { value: any; } = options.preget.call(this, propertyKey);
                if (!res) return get?.call(this);
                return res.value;
            }
        }

        return __decorate((props & Property.PROP.input) ? [
            Object(Input)(propertyKey),
        ] : [], target, propertyKey, prop_desc);
    }

    return Prop;
}

export namespace Property {

    /**
     * Property decorator
     */
    export enum PROP {
        output = 0x01,
        input = 0x02,
        track = 0x04,
        set = 0x10,
        get = 0x20,

        o = output,
        i = input,
        t = track,
        s = set,
        g = get,

        io = i | o,
        gs = g | s,
        si = s | i,
        so = s | o,
        gi = g | i,
        go = g | o,
        sio = s | io,
        gsi = gs | i,
        gso = gs | o,
        gsio = gs | io,

        iot = i | o | t,
        gst = g | s | t,
        sit = s | i | t,
        sot = s | o | t,
        git = g | i | t,
        got = g | o | t,
        siot = s | io | t,
        gsit = gs | i | t,
        gsot = gs | o | t,
        gsiot = gs | io | t,

        none = 0x00,
        all = gsiot,
    }

    export interface IBag<T = FreeOptions> {
        readonly changes: {
            readonly before: Partial<T>,
            readonly after: Partial<T>
        },

        readonly parent: IBag<T>;
        readonly values: T
    }

    export function Of<T = never, O = any>(obj: O): IBag<Unreadonly<[T] extends [never] ? O : T>> {
        return getPropertyBag(obj);
    }

    function getInheritBag(bag: Property.IBag, key: symbol) {
        while (bag) {
            if (bag[key]) {
                return bag[key];
            }

            bag = bag.parent;
        };
    }

    export function inheritBag<T = { [k: string]: any }>(key: symbol, bagcreator?: (parentbag?: T) => T) {
        return {
            creator: bagcreator,
            Of(obj: any, forceown: boolean): T {
                const bag = getPropertyBag(obj, forceown);
                const _ibag = getInheritBag(bag, key);

                if (!forceown || (_ibag && bag?.[key] == _ibag)) {
                    return _ibag;
                }

                return bag[key] = bagcreator ? bagcreator(_ibag) : {
                    ...(_ibag || {})
                }
            }
        }
    }

}

namespace _Defaults {
    const _DefaultsSymbol: symbol = Unique.symbol('DEFAULTS');
    const _AccessedSymbol: symbol = Unique.symbol('ACCESSED');
    export const _DefaultsBag = Property.inheritBag(_DefaultsSymbol);

    export function getValuesBag(obj: Object): {
        values: { [k: string]: any },
        accessed: { [k: string]: boolean }
    } {
        const props = Property.Of(obj) as any;

        return {
            accessed: (props[_AccessedSymbol] = props[_AccessedSymbol] ?? {}),
            values: props.values,
        };
    }

    export function getValue(prop_name: string) {
        return function (this: Object) {
            const props = getValuesBag(this);
            if (props.accessed[prop_name]) {
                // already accessed
                return props.values[prop_name];
            }

            // mark as accessed, try to assign the default value.
            props.accessed[prop_name] = true;

            const defs = Defaults.Of(this);
            if (defs && defs.hasOwnProperty(prop_name)) {
                // has a default value defined.
                props.values[prop_name] = clone(defs[prop_name]);
            }

            return props.values[prop_name];
        }
    }

    export function setValue(prop_name: string) {
        return function (this: Object, val: any) {
            const props = getValuesBag(this);
            props.accessed[prop_name] = true;
            props.values[prop_name] = val;
        }
    }
}

export function Defaults<T>(value?: T): PropertyDecorator {
    function getDescriptor(target: Object, prop_name: string) {
        while (target) {
            const desc: TypedPropertyDescriptor<T> = Object.getOwnPropertyDescriptor(target, prop_name) || {
                configurable: true,
                enumerable: true
            };

            if (desc.get || desc.set) {
                return desc;
            }

            target = Extend.getProto(target);
        }

        return {
            configurable: true,
            enumerable: true
        }
    }

    return function (prototype: Object, propertyKey: string): TypedPropertyDescriptor<T> | void {
        const ownDefs = _Defaults._DefaultsBag.Of(prototype, true);
        ownDefs[propertyKey] = value;

        const desc = getDescriptor(prototype, propertyKey);
        const get = desc.get, set = desc.set;

        if (!get) {
            desc.get = _Defaults.getValue(propertyKey)
        }

        if (!set) {
            desc.set = _Defaults.setValue(propertyKey)
        }

        return __decorate([], prototype, propertyKey, desc);
    }
}

export namespace Defaults {
    export function Of<T = FreeOptions, TObj extends Object | Class = any>(obj: TObj): T;
    export function Of<T = FreeOptions, Key extends keyof T = keyof T, TObj extends Object | Class = any>(obj: TObj, key: Key): T[Key];
    export function Of<T = FreeOptions, Key extends keyof T = keyof T, TObj extends Object | Class = any>(obj: TObj, ...key: Key[]): { [P in Key]?: T[P] };
    export function Of<T = FreeOptions, TObj extends Object | Class = any>(obj: TObj, ...keys: string[]): any {
        const _proto = typeof obj === 'function' ? obj.prototype : Extend.getProto(obj);
        const _defs = _Defaults._DefaultsBag.Of(_proto, false) || {};

        if (keys.length == 1) return _defs[keys[0]];
        if (keys.length <= 0) return _defs;

        return reduce(keys, (result, key) => {
            return result[key] = _defs[key], result;
        }, {});
    }

    export function KeysOf<TObj extends Object | Class>(obj: TObj): string[] {
        return Object.keys(Of(obj));
    }

    export function InstanceOf<T, TObj extends Object = any>(obj: TObj): T {
        return KeysOf(obj).reduce((res, k) => {
            res[k] = obj[k];
            return res;
        }, <T>{});
    }
}