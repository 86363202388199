import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { isNull, isUndefined, cloneDeep } from 'lodash';
import { HttpClient } from '@angular/common/http';

import { Backface } from '../../../app/application/service/backface/config';
import { ResultsComponent } from '../../results/view/results.component';
import { Sys as TSys } from '../../application/service/backface/types';
import { TemplateService } from '../../utils/view/template.directive';
import { Report } from '../../application/service/backface/report';
import { AppService } from '../../application/service/app.service';
import { Property } from '../../utils/libs/property';

@Component({
    templateUrl: "./problemlist.component.html",
    styleUrls: ['./problemlist.component.scss'],
    providers: [TemplateService]
})
export class ProblemListComponent extends Backface.AsyncReport implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of(this).values;

    get currentmodule(): TSys.IDatasetModule {
        const { _props: props, app: { dict: { exec: { problem, problem: { headers } } } } } = this;

        return (props.currentmodule = (props.currentmodule || {
            headers: cloneDeep(headers.filter(h => h.key != "id")),
            dict: problem,
            rows: [],
            key: 'problemlist',
        }));
    }

    get component() {
        return ResultsComponent;
    }

    constructor(
        public app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string,
            header: string,
            prjidlist: string[],
            date: string[],
            fixed: boolean,
            type: string[]
        },

        protected httpClient: HttpClient,
    ) {
        super(httpClient);
    }

    ngOnInit() {
        this.loadProblems();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    loadProblems(): void {
        const { data: { title, prjidlist, date, fixed, type } } = this;
        const param = {
            project: prjidlist?.slice() || [],
            date: date?.slice() || [],
            type: type?.slice() || []
        };

        if (!isNull(fixed) && !isUndefined(fixed)) {
            param["fixed"] = fixed;
        }

        this.report("/entity/problems/retrieve", (res: Report.IProblemEx[] = []) => {
            const { app, currentmodule: { rows } } = this;
            Report.buildProblems(app, res, (rows.clear(), rows));
        }).query(param);
    }

    static open(dialog: MatDialog, data: {} = {}): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '80vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
            data: {
                ...data
            }
        }

        return dialog.open(ProblemListComponent, config);
    }
}
