import { Component, Input, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import * as _ from "lodash";

import { AppService } from "../../../application/service/app.service";
import { Property } from "../../../utils/libs/property";
import { GovEditor } from "../model/form.editting";
import { Unreadonly } from "../../libs/types/mixins";
import { Differ } from "../../libs/differ";

@Component({
    selector: "gov-toolbar, [gov-toolbar]",
    templateUrl: "./gov.toolbar.component.html",
    styleUrls: ["./gov.toolbar.component.scss"],
    exportAs: "GovToolbar"
})
export class GovToolbarComponent implements GovEditor.ISetBinder, OnDestroy {
    private _props = Property.Of<Unreadonly<GovToolbarComponent> & {
        searchcontent: object,
    }>(this).values;

    get differ(): Differ<{
        onreload: Differ.Event
    }> {
        const { _props: props } = this;
        return props.differ || (
            props.differ = Differ.create()
        )
    }

    @Input('toolbar')
    get toolbar(): GovEditor.IToolbar {
        return this._props.toolbar;
    }

    set toolbar(val: GovEditor.IToolbar) {
        const { _props: props } = this;
        if (props.toolbar == val) return;
        props.toolbar = val;

        this.reload();
        this.differ.extend({
            onreload: Differ.Event.create(
                val?.reloader, this.reload, this
            )
        })
    }

    get filterdata(): GovEditor.IFilterData[] {
        return this.toolbar.filterdata;
    }

    get noinputtxt(): boolean {
        return !!this.toolbar.noinputtxt;
    }

    get inputtxt(): string {
        const { _props: { searchcontent } } = this;
        return searchcontent['*'] as string;
    }

    set inputtxt(val: string) {
        const { _props: { searchcontent } } = this;
        searchcontent['*'] = val;
        this.search();
    }

    get optionvalue(): GovEditor.IFilterData {
        return this._props.optionvalue;
    }

    set optionvalue(val: GovEditor.IFilterData) {
        const { _props: props, _props: { optionvalue, searchcontent } } = this;
        if (optionvalue == val) return;

        if ((optionvalue?.key ?? "") != "") {
            // remove previous option
            delete searchcontent[optionvalue.key];
        }

        props.optionvalue = val;
        if ((val?.key ?? "") != "") {
            // assign selected option
            searchcontent[val.key] = val.value;
        }

        this.search();
    }

    constructor(
        public app: AppService
    ) {
        this._props.searchcontent = {};
    }

    ngOnDestroy(): void {
        const { _props: { differ } } = this;
        differ?.clear();
    }

    reload() {
        const { _props: props, toolbar: { searcher, filterdata, noinputtxt } } = this;

        // initialize searchcontent/optionvalue from toolbar
        const searchcontent = _.isString(searcher) ? { '*': searcher } : { ...(searcher ?? {}) };
        props.searchcontent = searchcontent;
        delete props.optionvalue;

        _.forEach(_.keys(searchcontent), (key) => {
            const fdata = filterdata?.find(fd => fd.key == key && fd.value == searchcontent[key]);

            fdata && (props.optionvalue = fdata);
            if (!fdata && (key != '*' || noinputtxt)) {
                delete searchcontent[key];
            }
        })

        if (!props.optionvalue) {
            props.optionvalue = filterdata?.find(p => p.isdefault);
        }

        this.search();
    }

    bindsource(val: GovEditor.IToolbar): void {
        this.toolbar = val;
    }

    search(): void {
        const { _props: { searchcontent }, toolbar } = this;
        toolbar && _.hasIn(toolbar, 'searcher') && (
            toolbar.searcher = searchcontent
        );
    }

    isTemplateRef(val: any): boolean {
        return val instanceof TemplateRef;
    }

    isFunction(val: any): boolean {
        return typeof val == "function";
    }

    advfilterex(): void {
        const { toolbar: { advfilter } } = this;
        _.isFunction(advfilter) && advfilter();
    }
}