import { isArray, isString, property } from "lodash";
import * as _ from "lodash";

import { AppService } from "../../../application/service/app.service";
import { Sys } from "../../../application/service/backface/types";
import { Property } from "../../libs/property";
import { Unique } from "../../libs/unique";
import { Editor } from "../../libs/editor";

const _FieldPropSymbol: symbol = Unique.symbol('FieldProp');

const emptyEditor: Sys.IDatasetModule = {
    headers: [],
    rows: [],
    key: ''
}

interface IData<T = any> {
    data: T
}

interface IPathProp {
    rowsindex: number,
    dispindex: number,
    isoption: boolean,
    paths: string[],
    isarray: boolean,
}

function getter(data: object, header: Editor.IField, pathprop: IPathProp, start: number, end?: number): any {
    if (data === null || data === undefined) return data;

    if (pathprop.paths.length <= start || (end ?? pathprop.paths.length) <= start) {
        return data as any;
    }

    if (pathprop.paths[start] === '[') {
        const [sep, _idx] = pathprop.paths[start + 1] === ']' ? ['', start + 2] : [pathprop.paths[start + 1], start + 3];
        return (isArray(data) ? data : []).map(di => getter(di, header, pathprop, _idx, end)).join(sep);
    }

    if (pathprop.paths[start] === '>') {
        start = start + 1;
    }

    return getter(data?.[pathprop.paths[start]], header, pathprop, ++start, end);
}

function setter(data: object, header: Editor.IField, pathprop: IPathProp, val: any, start: number, end?: number) {
    if (data === null || data === undefined) return;

    if (pathprop.paths.length <= (start + 1) || (end ?? pathprop.paths.length) <= (start + 1)) {
        if (pathprop.isarray) {
            data[pathprop.paths[start]].recreate(val);
        } else {
            if (header.type & Editor.Value.Type.multi) {
                data[pathprop.paths[start]].recreate(val);
            } else {
                data[pathprop.paths[start]] = val;
            }
        }

        return;
    }

    return setter(data?.[pathprop.paths[start]], header, pathprop, val, ++start, end);
}

export class FieldProperty {
    constructor(public app: AppService) {
    }

    getProperty(headerId: Editor.IField | string, headers: Editor.IFields): FieldProperty.IProperty {
        const indexedHeaders = (headers.indexed = headers.indexed || _.keyBy(headers, 'key'));
        const header = indexedHeaders?.[isString(headerId) ? headerId : headerId?.key];

        if (!header || !header.key || Editor.Value.isFieldType(header, Editor.Value.Type.added)) {
            return;
        }

        const headerpropbag = Property.Of(header), headerprop = (headerpropbag[_FieldPropSymbol] = headerpropbag[_FieldPropSymbol] || {
        }) as {
            property: FieldProperty.IProperty,
        };

        if (headerprop.property) {
            return headerprop.property;
        }

        const pathprop: IPathProp = {
            paths: header.key.split(/(\[)(.*)(\])|(>)|(?:\.)/g).filter(s => s),
            isoption: Editor.Value.isFieldType(header, Editor.Value.Type.option),
            isarray: false,
            rowsindex: 1,
            dispindex: 1,
        }

        if (!pathprop.isoption) {
            pathprop.dispindex = pathprop.paths.length;
            pathprop.rowsindex = pathprop.paths.length;
        } else {
            const plen = pathprop.paths.length;
            const arridx = pathprop.paths.indexOf('[');
            const optidx = pathprop.paths.indexOf('>');
            const residx = arridx < 0 && optidx < 0 ? 1 : Math.max(Math.min(arridx >= 0 ? arridx : plen, optidx >= 0 ? optidx : plen), 1);

            switch (pathprop.paths[residx]) {
                case '[':
                    pathprop.dispindex = pathprop.paths.indexOf(']') + 1;
                    pathprop.rowsindex = residx;
                    pathprop.isarray = true;
                    break;

                case '>':
                    pathprop.dispindex = residx + 1;
                    pathprop.rowsindex = residx;
                    pathprop.isarray = false;
                    break;

                default:
                    pathprop.dispindex = 1;
                    pathprop.rowsindex = 1;
                    pathprop.isarray = false;
                    break;
            }
        }

        const { app } = this;
        return headerprop.property = {
            pathProp: pathprop,
            header: header,
            headers: headers,
            cellText(rowdata: object): string | number {
                return getter(rowdata, header, pathprop, 0);
            },
            cellField(rowdata: object): { data: any } {
                if (!rowdata) return { data: null }

                const prop = Property.Of(rowdata), rowprop = (prop[_FieldPropSymbol] = prop[_FieldPropSymbol] || {
                }) as { [P: string]: { cellField: IData } };

                rowprop[header.key] = rowprop[header.key] || { cellField: null }

                return rowprop[header.key].cellField = rowprop[header.key].cellField || {
                    get data() { return getter(rowdata, header, pathprop, 0, pathprop.rowsindex) },
                    set data(val) { setter(rowdata, header, pathprop, val, 0, pathprop.rowsindex) }
                }
            },
            optionCellText(rowdata: object): string | number {
                return getter(rowdata, header, pathprop, pathprop.dispindex)
            },
            optionSourceRows(rowdata: object): IData<object[]> {
                if (!rowdata) return { data: [] }

                const prop = Property.Of(rowdata), rowprop = (prop[_FieldPropSymbol] = prop[_FieldPropSymbol] || {
                }) as { [P: string]: { optionSourceRows: IData<object[]> } };

                rowprop[header.key] = rowprop[header.key] || { optionSourceRows: null }
                return rowprop[header.key].optionSourceRows = rowprop[header.key].optionSourceRows || {
                    get data(): object[] {
                        const { data } = headerprop.property.optionSource(rowdata);
                        const rows = ('rows' in Object(data) ? data.rows : data);
                        return isArray(rows) ? rows : [];
                    }
                }
            },
            optionSource(rowdata: object): IData<Sys.IDatasetModule> {
                const prop = Property.Of(rowdata), rowprop = (prop[_FieldPropSymbol] = prop[_FieldPropSymbol] || {
                }) as { [P: string]: { getters: (() => Sys.IDatasetModule)[], optionSource: IData<Sys.IDatasetModule> } };

                rowprop[header.key] = rowprop[header.key] || { optionSource: null, getters: null };

                rowprop[header.key].getters = rowprop[header.key].getters || (() => {
                    if (!header.source) return [() => emptyEditor];

                    if (Editor.Value.isFieldType(header, Editor.Value.Type.enum)) {
                        return [(): Sys.IDatasetModule => {
                            const source = _.isFunction(header.source) ? header.source(app, rowdata) : header.source;

                            return {
                                rows: source as Editor.IEnum,
                                headers: [],
                                key: ''
                            }
                        }]
                    }

                    const source = _.isFunction(header.source) ? header.source(app, rowdata) : header.source;
                    return _.transform<any[], (() => Sys.IDatasetModule)[]>(
                        isString(source) ? [source] : (isArray(source) ? source : []),

                        (res, s) => {
                            if (!isString(s)) return;
                            const p = (s[0] == '@' ? s.slice(1) : s).split('.');
                            const d = (s[0] == '@' ? rowdata : app);
                            res.push(property(p).bind(null, d));
                        },

                        []
                    )
                })();

                return rowprop[header.key].optionSource = rowprop[header.key].optionSource || {
                    get data() {
                        const { getters } = rowprop[header.key];
                        for (let idx = 0, len = getters.length; idx < len; idx++) {
                            const v = getters[idx]?.();
                            if (v) return v;
                        }
                    }
                }
            }
        }
    }

    getCellText(rowdata: object, headerId: Editor.IField | string, headers: Editor.IFields): string | number | boolean {
        return this.getProperty(isString(headerId) ? headerId : headerId?.key, headers)?.cellText(rowdata);
    }

    getCellField(rowdata: object, headerId: Editor.IField | string, headers: Editor.IFields): IData {
        return this.getProperty(isString(headerId) ? headerId : headerId?.key, headers)?.cellField(rowdata);
    }

    getOptionCellText(optionrowdata: object, headerId: Editor.IField | string, headers: Editor.IFields): string | number | boolean {
        return this.getProperty(isString(headerId) ? headerId : headerId?.key, headers)?.optionCellText(optionrowdata);
    }

    getOptionSourceRows(rowdata: object, headerId: Editor.IField | string, headers: Editor.IFields): IData<object[]> {
        return this.getProperty(isString(headerId) ? headerId : headerId?.key, headers)?.optionSourceRows(rowdata);
    }

    getOptionSource(rowdata: object, headerId: Editor.IField | string, headers: Editor.IFields): IData<Sys.IDatasetModule> {
        return this.getProperty(isString(headerId) ? headerId : headerId?.key, headers)?.optionSource(rowdata);
    }
}

export namespace FieldProperty {

    export interface IProperty {
        pathProp: IPathProp,

        header: Editor.IField,

        headers: Editor.IFields,

        cellField?(rowdata: object): IData,

        cellText(rowdata: object): string | number | boolean,

        optionCellText(rowdata: object): string | number | boolean,

        optionSourceRows(rowdata: object): IData<object[]>,

        optionSource(rowdata: object): IData<Sys.IDatasetModule>,
    }

}