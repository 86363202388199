import { AfterViewInit, Component, OnInit } from '@angular/core';

import { TemplateService } from '../../utils/view/template.directive';
import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./login.component.html",
    styleUrls: ['./login.component.scss'],
    providers: [
        TemplateService
    ]
})
export class LoginComponent implements OnInit, AfterViewInit {
    constructor(
        public auth: AuthService,
        public tpls: TemplateService,
        public app: AppService
    ) {

    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    disclaimer() {
        const { app: { version: { server: { version } = {} } } } = this;
        window.open(`assets/i18n/disclaimer?version=${version}`);
    }
}
