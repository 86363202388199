import { Component, EventEmitter, Input, Output } from "@angular/core";

import { AppService } from "../../../application/service/app.service";
import { Dict } from "../../../application/service/backface/dict";
import { Sys } from "../../../application/service/backface/types";
import { GovEditor } from '../model/form.editting';
import { Property } from '../../libs/property';

@Component({
    selector: "gov-wsitem, [gov-wsitem]",
    templateUrl: "./gov.wsitem.component.html",
    styleUrls: ["./gov.wsitem.component.scss"]
})
export class GovWSItemComponent {
    private _props = Property.Of(this).values;
    readonly Dict = Dict;

    @Input('wsitem')
    wsitem: Sys.IStageSumItem;

    @Output('onWorkItemDetailsTemplate')
    get onWorkItemDetailsTemplate(): EventEmitter<GovEditor.IWorkItemSumData> {
        const { _props: props } = this;

        return props.onWorkItemDetailsTemplate = props.onWorkItemDetailsTemplate || new EventEmitter();
    }

    constructor(
        public app: AppService
    ) {
    }

    onDetailsCheck(stageitem: Sys.IStageSumItem, workitemsum: Sys.IWSSumItem) {
        var data: GovEditor.IWorkItemSumData = {
            stageitem: stageitem,
            workitemsum: workitemsum
        };

        this.onWorkItemDetailsTemplate.emit(data);
    }

}