import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { AmapPluginLoaderService, NgxAmapComponent } from 'ngx-amap';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNull, isUndefined, noop } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AmapUILoaderService } from 'ngx-amap';

import { AppService } from '../../application/service/app.service';
import { Sys } from '../../application/service/backface/types';
import { Prj } from '../../application/service/backface/prj';
import { Property } from '../../utils/libs/property';

@Component({
    templateUrl: "./maplocate.component.html",
    styleUrls: ['./maplocate.component.scss']
})
export class MaplocateComponent implements OnInit {
    private _props = Property.Of<MaplocateComponent & {
        _init: Promise<boolean>
    }, MaplocateComponent>(this).values;

    @ViewChild(NgxAmapComponent)
    set amapComponent(val: NgxAmapComponent) {
        this._props.amapComponent = val;
    };

    get amapComponent() {
        return this._props.amapComponent;
    }

    get amap(): AMap.Map {
        return this._props.amap;
    }

    set amap(val: AMap.Map) {
        this._props.amap = val;
    }

    get AMap() {
        return globalThis.AMap;
    }

    get isReady() {
        const { AMap } = this;
        return !!(AMap?.DistrictSearch && AMap?.PlaceSearch && this.amapComponent && this.amap);
    }

    get mark_icon(): AMap.Icon {
        const { _props: props, app: { version: { server: { version } = {} } } } = this;

        return props.mark_icon || (props.mark_icon = new AMap['Icon']({
            image: `assets/img/map/location-red.png?version=${version}`,
            size: [48, 48],
        }));
    }

    get district(): AMap.DistrictSearch.District {
        const { _props, ngzone } = this;

        if (_props.district === undefined && this.isReady) {
            _props.district = null;

            const districtSearch = new AMap.DistrictSearch({
                extensions: 'all',
                level: 'district',
                subdistrict: 1,
            });

            districtSearch.search(this.city_district, (status, result: AMap.DistrictSearch.SearchResult) => {
                ngzone.run(() => _props.district = result.districtList[0]);
            });
        }

        return _props.district;
    }

    get projectLocater(): {
        locations?: string | AMap.PlaceSearch.SearchResult,
        position?: AMap.PositionPicker.PositionPickerResult
    } {
        const { _props, amap, uiLoader, ngzone, isReady, positionToSave, locationToSave } = this;
        const { app: { version: { server: { version } = {} } } } = this;
        if (_props.projectLocater !== undefined || !isReady) {
            return _props.projectLocater;
        }

        _props.projectLocater = null;

        var autoOptions = {
            input: "tipinput",
            city: this.city_district,
            citylimit: true,
        };

        this.placeSearch = new AMap.PlaceSearch({
            pageSize: 4, // 单页显示结果条数
            pageIndex: 1, // 页码
            city: this.city_district, // 兴趣点城市
            citylimit: true,  //是否强制限制在设置的城市内搜索
            map: amap, // 展现结果的地图实例
            panel: "locationpanel", // 结果列表将在此容器中进行展示。
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });

        const thislocate = this;
        this.plugin.load('AMap.Autocomplete').subscribe(() => {
            var auto = new AMap['Autocomplete'](autoOptions);
            AMap.event.addListener(auto, "select", select);
            function select(e) {
                const searchname = e.poi.name;
                thislocate.placeSearch.search(searchname, (
                    status: AMap.PlaceSearch.SearchStatus,
                    result: string | AMap.PlaceSearch.SearchResult
                ) => {
                    ngzone.run(() => {
                        _props.projectLocater = {
                            locations: result
                        }
                    })
                });
            }
        });

        this.placeSearch.on('selectChanged', function (selectChangeResult) {
            ngzone.run(() => {
                _props.positionToSave = selectChangeResult.selected.data.location.toString().split(",");
                _props.locationToSave = selectChangeResult.selected.data.address;
            });
        });

        uiLoader.load(['misc/PositionPicker']).subscribe(PositionPicker => {
            const positionPicker = new PositionPicker({
                mode: 'dragMarker',
                map: amap,
                iconStyle: { //自定义外观
                    url: `/assets/img/map/position-picker2.png?version=${version}`,
                    ancher: [24, 40],
                    size: [48, 48]
                }
            });

            positionPicker.on('success', function (positionResult: AMap.PositionPicker.PositionPickerResult) {
                ngzone.run(() => {
                    if (_props.projectLocater?.locations) {
                        _props.projectLocater = {
                            position: positionResult,
                            locations: _props.projectLocater?.locations
                        };
                    } else {
                        _props.projectLocater = {
                            position: positionResult
                        };
                    }

                    _props.positionToSave = positionResult.position.toString().split(",");
                    _props.locationToSave = positionResult.address;
                })
            });

            positionPicker.on('fail', function () {
                ngzone.run(() => {
                    _props.projectLocater = {
                        position: null
                    }
                })
            });

            const orgPoint = new AMap.LngLat(Number.parseFloat(this.positionToSave[0]), Number.parseFloat(this.positionToSave[1]));
            _props.projectLocater = {
                position: {
                    position: orgPoint,
                    address: this.locationToSave
                } as AMap.PositionPicker.PositionPickerResult
            };

            positionPicker.start(orgPoint);
            amap.panBy(0, 1);
        });

        return _props.projectLocater;
    }

    infoWindowOffset = {
        x: 0,
        y: -40,
    };

    get positionToSave(): string[] {
        return this._props.positionToSave;
    }

    set positionToSave(val: string[]) {
        this._props.positionToSave = val;
    }

    get locationToSave(): string {
        return this._props.locationToSave;
    }

    set locationToSave(val: string) {
        this._props.locationToSave = val;
    }

    inputVar: string;
    placeSearch: AMap.PlaceSearch;

    city_district: string = "610722";
    city_position: string[] = ["107.333545", "33.156308"];
    city_address: string = "陕西省汉中市城固县博望街道民主街33号城固县人民政府";

    constructor(
        private app: AppService,
        private plugin: AmapPluginLoaderService,
        private uiLoader: AmapUILoaderService,
        private ngzone: NgZone,
        private httpClient: HttpClient,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            object: Prj.Project,
            header: Sys.IDatasetHeader,
            dataset: Sys.IDatasetModule
        }
    ) {
        this.inputVar = "";
        const { app: { lang: { general } = {} } } = this;

        this.city_district = general.city_district || this.city_district;
        this.city_position = general.city_position || this.city_position;
        this.city_address = general.city_address || this.city_address;

        const { data: { object: { position } = {} } = {} } = this;
        const [{ postion, detail }] = position ?? [{}];

        this.positionToSave = (postion?.length ?? 0) == 2 ? postion : this.city_position;
        this.locationToSave = detail ?? this.city_address;

        this.plugin.load('AMap.DistrictSearch').subscribe(noop);
        this.plugin.load("AMap.PlaceSearch").subscribe(noop);
    }

    ngOnInit() {
    }

    saveClick() {
        var url = "https://restapi.amap.com/v3/geocode/regeo?";
        url += "key=987e36227a29053373a38a244a606cc1";
        url += "&location=" + this.positionToSave;
        url += "&extensions=base";

        this.data.object.position = [];
        this.data.object.position[0] = {};
        this.data.object.position[0].postion = this.positionToSave;
        this.data.object.position[0].detail = this.locationToSave;
        this.data.object.position[0].district = '';

        this.httpClient.get(url).subscribe(response => {
            if (isNull(response) || isUndefined(response)) {
                console.log("No result for get district");
                return;
            }

            var status = response?.['status'];
            var info = response?.['info'];
            if (status != '1' || info != 'OK') {
                console.log("Result Error for get district");
                return;
            }

            var address = response?.['regeocode']?.['addressComponent'];
            if (isNull(address) || isUndefined(address)) {
                console.log("Result Error addressComponent for get district");
                return;
            }

            this.data.object.position[0].district = address?.['district'] + address?.['township'];
        });
    }
}
