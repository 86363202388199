import { OnInit, Optional, Renderer2, Input, ElementRef, Output, EventEmitter, Directive } from '@angular/core';

import { GovSplitterComponent } from './gov.splitter.component';
import { Property } from '../../libs/property';

@Directive({
    selector: '[gov-splitter-panel]'
})
export class GovSplitterPanelDirective implements OnInit {
    private _props = Property.Of(this).values;

    index: number;

    @Input()
    max: number;

    @Input()
    min: number;

    @Input()
    set size(v: number | null) {
        this._props.size = Number(v);
        this.splitter.updatePanel(this);
    }

    get size() {
        return this._props.size;
    }

    @Input()
    set order(v: number | null) {
        v = Number(v), v = !isNaN(v) ? v : null;
        this.setStyleOrder(this._props.order = v);
    }

    get order(): number | null {
        return this._props.order;
    }

    @Input()
    set visible(val: boolean) {
        const sizes = this.splitter.displayedPanels.map(dp => dp.size);

        // prevent the event fired in the init process
        if (sizes.length > 0) {
            this.collapsedChange.emit({
                collapsedComponentSize: this.size,
                collapsed: !val,
                sizes: sizes,
            })
        }

        val = (typeof (val) === 'boolean') ? val : (val === 'false' ? false : true);
        this._props.visible = val;

        if (this.visible) {
            this.splitter.showPanel(this);
        }
        else {
            this.splitter.hidePanel(this);
        }
    };

    get visible(): boolean {
        return this._props.visible ?? true;
    }

    @Output()
    collapsedChange: EventEmitter<any> = new EventEmitter();

    constructor(
        @Optional()
        public splitter: GovSplitterComponent,
        public renderer: Renderer2,
        private elRef: ElementRef
    ) {

    }

    ngOnInit() {
        this.splitter?.addPanel(this);

        this.renderer.setStyle(this.elRef.nativeElement, 'flex-grow', '0');
        this.renderer.setStyle(this.elRef.nativeElement, 'flex-shrink', '0');
    }

    ngOnDestroy(): void {
        this.splitter.removePanel(this);
    }

    setStyleOrder(value: number): void {
        this.renderer.setStyle(this.elRef.nativeElement, 'order', value);
    }

    setStyleFlexbasis(value: string): void {
        this.renderer.setStyle(this.elRef.nativeElement, 'flex-basis', value);
    }

    getSizePixel(prop: 'offsetWidth' | 'offsetHeight'): number {
        return this.elRef.nativeElement[prop];
    }

    setStyleVisibleAndDir(isVisible: boolean, isDragging: boolean, direction: 'horizontal' | 'vertical'): void {
        if (isVisible === false) {
            this.setStyleFlexbasis('0');
            this.renderer.setStyle(this.elRef.nativeElement, 'overflow-x', 'hidden');
            this.renderer.setStyle(this.elRef.nativeElement, 'overflow-y', 'hidden');

            if (direction === 'vertical') {
                this.renderer.setStyle(this.elRef.nativeElement, 'max-width', '0');
            }
        }
        else {
            this.renderer.setStyle(this.elRef.nativeElement, 'overflow-x', 'hidden');
            this.renderer.setStyle(this.elRef.nativeElement, 'overflow-y', 'auto');
            this.renderer.removeStyle(this.elRef.nativeElement, 'max-width');
        }

        if (direction === 'horizontal') {
            this.renderer.setStyle(this.elRef.nativeElement, 'height', '100%');
            this.renderer.removeStyle(this.elRef.nativeElement, 'width');
        }
        else {
            this.renderer.setStyle(this.elRef.nativeElement, 'width', '100%');
            this.renderer.removeStyle(this.elRef.nativeElement, 'height');
        }
    }
}
