<div *ngIf="wsitem != null" class="my-auto d-flex align-items-center flex-none"
  [ngClass]="{'flex-row':!wsitem.iscol, 'flex-column':wsitem.iscol}">
  <ng-container *ngFor="let item of wsitem.wssum">
    <button *ngIf="item.count != 0" mat-button (click)="onDetailsCheck(wsitem,item)">
      <div class="d-flex flex-row">
        <span [attr.style]="app.dict.getStatusItem(item.status)?.style">{{
          app.dict.getStatusItem(item.status)?.icontext
        }}</span>
        <span
          [attr.style]="app.dict.getStatusItem(item.status)?.style">{{ item.count }}{{ "general.item" | translate }}</span>
      </div>
    </button>
  </ng-container>
</div>