import { Component } from '@angular/core';

import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./user.component.html",
    styleUrls: ['./user.component.scss']
})
export class UserComponent {
    constructor(public app: AppService) {
    }
}
