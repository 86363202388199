<div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
  <div mat-dialog-title class="d-flex flex-row flex-nowrap justify-content-start align-items-center">
    <div class="flex-fill">{{ "account.setting" | translate }}</div>
    <label class="btn btn-sm" mat-dialog-close>
      <i class="fa fa-close fa-fw"></i>
    </label>
  </div>
  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
  <mat-dialog-content class="mat-typography position-relative px-0 flex-fill">
    <div class="card card-chart" style="margin-top: 20px">
      <div class="card-header card-header-success">
        <div class="ct-chart" id="dailySalesChart">
          <h4 class="card-title">{{ auth.me.name }}</h4>
        </div>
      </div>
      <div class="card-body" style="text-align: left">
        <h4 class="card-title">
          {{ "people.mobile" | translate }}:{{ auth.me.mobile }}
        </h4>
        <h4 class="card-title">
          {{ "people.dept" | translate }}:{{ auth.me.dept?.name }}
        </h4>
        <h4 *ngFor="let role of auth.me.role; let i = index" class="card-title">
          {{ "people.roles" | translate }}{{ i + 1 }}:{{
          app.org?.roles?.firstOf({ id: role?.id }).name
          }}
        </h4>
      </div>
    </div>
  </mat-dialog-content>
</div>