import { ShareReplayConfig } from 'rxjs/internal/operators/shareReplay';
import { switchMap, takeUntil, shareReplay } from 'rxjs/operators';
import { Observable, Subject, of, ObservableInput } from 'rxjs';
import * as _ from 'lodash';

import { Property } from './property';
import { Extend } from './extends';

export namespace Async {

    var desces: { [x: string]: PropertyDescriptor } = null;

    interface IRequest<Value, Param> {
        query?: (p: Param) => Observable<Value>,
        param?: Param,
        value?: Value
    }

    interface IMembers<Value, Param> {
        _reloader: Subject<IRequest<Value, Param>>;
        _query: (p: Param) => Observable<Value>;
        _config: ShareReplayConfig;
        _finish: Subject<void>;
        _toload: boolean;
    }

    export type Request<Value, Param> = {
        value: Value
    } | {
        query: (p: Param) => Observable<Value>,
        param?: Param
    }

    export class Cache<Value, Param = void> extends Observable<Value> {
        private _props = Property.Of<IMembers<Value, Param>>(this).values;

        constructor(
            req: Request<Value, Param>,
            bufferSize: number = 1,
            preload: boolean = true
        ) {
            super();

            const creq = { ...(req as IRequest<Value, Param>) };
            const { _props: props } = this;

            props._config = {
                bufferSize: bufferSize,
                refCount: false
            };

            props._reloader = new Subject();
            props._finish = new Subject();
            props._query = creq?.query;
            props._toload = preload;
            delete creq.query;

            const _cache = props._reloader.pipe(
                switchMap<
                    IRequest<Value, Param>,
                    ObservableInput<Value>
                >((next: IRequest<Value, Param> = {}): ObservableInput<Value> => {
                    if (!props._toload && _.has(next, 'value')) return of(next.value);
                    return (next.query || props._query)(next.param);
                }),

                takeUntil(props._finish),
                shareReplay<Value>(props._config)
            )

            // need to give the initial data to the first subscriber
            _cache.subscribe = (() => {
                const _subscribe = _cache.subscribe;

                return (...args: any[]) => {
                    const subscription = _subscribe.call(_cache, ...args);
                    delete _cache.subscribe;

                    if (preload) {
                        props._reloader.next(creq);
                    }

                    return subscription;
                };
            })()

            // only to trigger data loading firstly.
            if (preload) {
                _cache.subscribe((v: Value) => { }).unsubscribe();
            }

            // bridge patch the methods from this to props._cache
            desces = desces || Object.getOwnPropertyDescriptors(Cache.prototype);
            Object.defineProperties(_cache, desces);
            Extend.setProto(this, _cache);
        }

        get bufferSize(): number {
            return this._props._config.bufferSize;
        }

        set bufferSize(size: number) {
            this._props._config.bufferSize = size;
        }

        query(p?: Param, method?: (p: Param) => Observable<Value>): Cache<Value, Param> {
            const next: IRequest<Value, Param> = {};
            const { _props: props } = this;

            (typeof method !== 'undefined') && (next.query = method);
            (typeof p !== 'undefined') && (next.param = p);

            props._toload = true;
            props._reloader.next(next);
            return this;
        }

        emit(v: Value): Cache<Value, Param> {
            const { _props: props } = this;

            props._toload = false;
            props._reloader.next({ value: v });
            return this;
        }

        finish(): Cache<Value, Param> {
            this._props._finish.next();
            return this;
        }
    }
}