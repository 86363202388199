<ng-template id="text" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>{{ header.title | translate }}</mat-label>
    <textarea matInput [readonly]="true" [ngModel]="cellField.data" [mat-autosize]="true"></textarea>
  </mat-form-field>
</ng-template>

<ng-template id="texts" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-12">
    <mat-label>{{ header.title | translate }}</mat-label>
    <textarea matInput class="p-1" [readonly]="true" [mat-autosize]="true" [ngModel]="cellField.data"></textarea>
  </mat-form-field>
</ng-template>

<ng-template id="email" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>{{ header.title | translate }}</mat-label>
    <input matInput type="email" [readonly]="true" [ngModel]="cellField.data" />
  </mat-form-field>
</ng-template>

<ng-template id="password" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label #temp>{{ header.title | translate }}</mat-label>
    <input matInput [readonly]="true" [ngModel]="cellField.data" [type]="temp.hide ? 'password' : 'text'" />
    <button mat-icon-button matSuffix (click)="temp.hide = !temp.hide" [attr.aria-label]="'隐藏密码'"
      [attr.aria-pressed]="temp.hide">
      <mat-icon>{{ temp.hide ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  </mat-form-field>
</ng-template>

<ng-template id="number" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>{{ header.title | translate }}</mat-label>
    <input matInput type="number" [readonly]="true" [ngModel]="cellField.data" />
  </mat-form-field>
</ng-template>

<ng-template id="phone" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>{{ header.title | translate }}</mat-label>
    <input matInput type="tel" [readonly]="true" [ngModel]="cellField.data" />
  </mat-form-field>
</ng-template>

<ng-template id="bool" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>{{ header.title | translate }}</mat-label>
    <input matInput [readonly]="true" class="d-none" />
    <div class="toggle toggle--knob">
      <input type="checkbox" class="toggle--checkbox" id="field-form-toggle-result--{{ header.key }}"
        [ngModel]="cellField.data" [readonly]="true" [disabled]="true" />
      <label class="ml-0 toggle--btn" for="field-form-toggle-result--{{ header.key }}">
        <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
          [attr.data-label-on]="'general.yes' | translate"></span></label>
    </div>
  </mat-form-field>
</ng-template>

<ng-template id="date" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>{{ header.title | translate }}</mat-label>
    <input matInput [matDatepicker]="picker" [readonly]="true" [ngModel]="cellField.data" readonly />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker [disabled]="true"></mat-datepicker>
  </mat-form-field>
</ng-template>

<ng-template id="option" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label> {{ header.title | translate }} </mat-label>
    <div class="h-100 d-flex flex-row align-items-stretch justify-content-start">
      <mat-chip-list class="mat-chip-list-stacked flex-fill" aria-orientation="vertical">
        <mat-chip [removable]="false" [selectable]="false">
          {{ getOptionCellText(cellField.data, header, headers) }}
        </mat-chip>
      </mat-chip-list>
    </div>
  </mat-form-field>
</ng-template>

<ng-template id="options" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-12">
    <mat-label> {{ header.title | translate }} </mat-label>

    <mat-chip-list class="flex-fill">
      <mat-chip *ngFor="let val of cellField.data" [removable]="false" [selectable]="false">
        {{ getOptionCellText(val, header, headers) }}
      </mat-chip>
    </mat-chip-list>
  </mat-form-field>
</ng-template>

<ng-template id="enum" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-4">
    <mat-label>
      {{ header.title | translate }}
    </mat-label>
    <mat-select [disabled]="true" [value]="cellField.data" [multiple]="app.editor.Value.isFieldType(header, 'multi')">
      <mat-option *ngFor="let opt of optionSourceRows.data" [value]="opt.value">
        {{ opt.title | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template id="json" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
  let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-12">
    <mat-label>
      {{ header.title | translate }}
    </mat-label>

    <input #temp matInput class="d-none" />

    <json-editor #jsoneditor (change)="cellField.data = jsoneditor.get()" [options]="jsoneditoroptions"
      [data]="cellField.data"></json-editor>
  </mat-form-field>
</ng-template>

<ng-template id="command" let-cellField="cellField" let-header="header" let-editor="editor">
</ng-template>

<ng-template id="pics" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-12">
    <mat-label>
      {{ header.title | translate }}
    </mat-label>

    <input #temp matInput class="d-none" />

    <div class="row m-0">
      <div class="mat-card-pic col-4 p-1" *ngFor="let pic of cellField.data">
        <mat-card class="card-frame w-100 h-100 p-1">
          <mat-card-content class="h-100 w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
            <ng-container [ngSwitch]="pic.content_type">
              <video async *ngSwitchCase="'video'" (click)="openPics(cellField.data, pic)"
                (canplaythrough)="pic.onLoaded($event)" class="mat-card-image border m-0 p-0 mr-1">
                <source [src]="pic.url | safe : 'url'" type="{{ pic.content_type + '/' + pic.mime_type }}" />
                Your browser does not support HTML5 video.
              </video>

              <img async *ngSwitchDefault [src]="pic.url | safe : 'url'" (load)="pic.onLoaded($event)"
                (click)="openPics(cellField.data, pic)" class="mat-card-image border m-0 p-0 mr-1"
                [style.background-repeat]="'no-repeat'" [style.background-position]="'center'"
                [style.object-fit]="'contain'" />
            </ng-container>

            <textarea matInput class="border h-100 m-0 p-1" [ngModel]="pic.comment" [mat-autosize]="true"
              [readonly]="true"></textarea>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-form-field>
</ng-template>

<ng-template id="files" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-12">
    <mat-label>
      {{ header.title | translate }}
    </mat-label>

    <input #temp matInput class="d-none" />

    <div class="row m-0">
      <div class="mat-card-file col-4 p-1" *ngFor="let file of cellField.data">
        <mat-card class="card-frame w-100 h-100 p-1">
          <mat-card-content class="h-100 w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
            <ng-container [ngSwitch]="file.content_type">
              <video async *ngSwitchCase="'video'" (click)="openXFiles(cellField.data, file)"
                (canplaythrough)="file.onLoaded($event)" class="mat-card-image border m-0 p-0 mr-1">
                <source [src]="file.url | safe : 'url'" type="{{ file.content_type + '/' + file.mime_type }}" />
                Your browser does not support HTML5 video.
              </video>

              <ng-container *ngSwitchCase="'application'">
                <img async src="{{app.dict.getFormatObj(file.mime_type)?.icon}}" (load)="file.onLoaded($event)"
                  (click)="openXFiles(cellField.data, file)" [style.background-repeat]="'no-repeat'"
                  [style.background-position]="'center'" [style.object-fit]="'contain'" />
              </ng-container>

              <img async *ngSwitchDefault [src]="file.url | safe : 'url'" (load)="file.onLoaded($event)"
                (click)="openXFiles(cellField.data, file)" class="mat-card-image border m-0 p-0 mr-1"
                [style.background-repeat]="'no-repeat'" [style.background-position]="'center'"
                [style.object-fit]="'contain'" />
            </ng-container>

            <textarea matInput class="border h-100 m-0 p-1" [ngModel]="file.comment" [mat-autosize]="true"
              [readonly]="true"></textarea>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-form-field>
</ng-template>

<ng-template id="problems" let-cellField="cellField" let-header="header" let-editor="editor">
  <mat-form-field floatLabel="always" appearance="standard" class="col-12">
    <mat-label>
      {{ header.title | translate }}
    </mat-label>

    <input #temp matInput class="d-none" />

    <div class="row m-0">
      <div class="mat-card-problem col-6 p-1" *ngFor="let problem of cellField.data; let index = index">
        <mat-card class="card-frame w-100 h-auto p-1">
          <mat-card-header class="row m-0 p-0 pb-2">
            <mat-card-title class="col-6 pr-1">
              <h5 class="m-0">
                <b class="m-0 mr-1">{{ "problem.auditid" | translate }}:</b>
              </h5>
              <h5 class="m-0">{{ problem.auditid }}</h5>
            </mat-card-title>
            <mat-card-title class="col-6 pl-1">
              <h5 class="m-0">
                <b class="m-0 mr-1">{{ "problem.fixed" | translate }}:</b>
              </h5>
              <div class="toggle toggle--knob">
                <input type="checkbox" class="toggle--checkbox"
                  id="field-form-toggle-problem--{{ header.key }}--{{ index }}" [ngModel]="problem.fixed"
                  [disabled]="true" />
                <label class="ml-0 toggle--btn" for="field-form-toggle-problem--{{ header.key }}--{{ index }}">
                  <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
                    [attr.data-label-on]="'general.yes' | translate"></span>
                </label>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="h-auto w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
            <textarea matInput class="border m-0 p-1" [ngModel]="problem.problem" [mat-autosize]="true"
              [readonly]="true"></textarea>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-form-field>
</ng-template>

<div class="h-100 w-100 m-0 p-0 d-flex flex-column align-items-stretch justify-content-start">
  <h2 *ngIf="data.title" mat-dialog-title>
    <div class="d-flex flex-row flex-nowrap justify-content-start align-items-center">
      <div class="flex-fill">{{ data.title | translate }}</div>
      <label class="btn btn-sm" mat-dialog-close>
        <i class="fa fa-close fa-fw"></i>
      </label>
    </div>
    <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
  </h2>

  <mat-dialog-content class="position-relative flex-fill">
    <div
      class="h-100 w-100 max-h-100 max-w-100 m-0 px-2 d-flex flex-column flex-nowrap justify-content-start align-items-stretch">
      <div *ngIf="isProjectedResult()" class="w-100 project-workitem d-flex flex-row">
        <mat-form-field class="nowrap project">
          <mat-label>{{ "general.projects" | translate }}</mat-label>
          <mat-chip-list class="nowrap">
            <mat-chip *ngFor="let header of projecthints" [selectable]="false" [removable]="false">
              {{ property(header)(this) }}
            </mat-chip>
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field class="nowrap workitem flex-fill">
          <mat-label>{{ app.getenumkey(workitem.type) | translate }}</mat-label>
          <div class="d-flex list-group-horizontal align-items-end justify-content-between">
            <mat-chip-list class="nowrap">
              <ng-container *ngFor="let header of workitemhints">
                <mat-chip *ngIf="property(header)(workitem)" [selectable]="false" [removable]="false">
                  {{ property(header)(workitem) }}
                </mat-chip>
              </ng-container>

            </mat-chip-list>
            <div class="btn-group btn-group-sm pr-3">
              <button type="button" (click)="accordion.openAll()" class="btn btn-sm btn-outline-secondary">
                <i class="material-icons" style="font-size: inherit">expand_more</i>
                {{ "projectdetail.expend" | translate }}
              </button>

              <button type="button" (click)="accordion.closeAll()" class="btn btn-sm btn-outline-secondary">
                <i class="material-icons" style="font-size: inherit">expand_less</i>
                {{ "projectdetail.fold" | translate }}
              </button>
            </div>
          </div>
        </mat-form-field>
      </div>

      <div class="w-100 overflow-auto flex-fill">
        <mat-accordion #accordion="matAccordion" multi="true" class="w-100">
          <mat-expansion-panel class="records card-frame my-1" *ngFor="let rst of results" #panel="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b class="mr-1">{{ "general.projects" | translate }}:{{
                  rst.project?.name
                  }}/{{ rst.project?.plan?.name }}</b>
              </mat-panel-title>
              <mat-panel-description>
                <b class="mr-1">
                  {{ (issuperviseaudit ? "supervise.people" : "execute.people") | translate }}:{{ rst.people?.name }}
                </b>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b class="mr-1">
                  {{ "general.datehint" | translate }}:{{
                  rst.date | date : app.sys.datetimeFormat
                  }}
                </b>
              </mat-panel-description>
              <div *ngIf="isaudit" style="margin-right: 10px">
                <b class="mr-1">
                  {{ "audit.audit.status" | translate }}:{{
                  app.dict.AuditStatus.indexed[rst.audit?.status]?.title
                  | translate
                  }}
                </b>
              </div>
            </mat-expansion-panel-header>
            <mat-form-field *ngIf="isaudit" appearance="standard" floatLabel="always" class="col-12">
              <mat-label>
                {{ (issuperviseaudit ? "tabs.superviseaudit" : "tabs.executeaudit") | translate }}
              </mat-label>

              <input #temp matInput class="d-none" />

              <mat-card class="card-frame w-100 h-auto p-1">
                <mat-card-header class="row m-0 p-0 pb-2">
                  <mat-card-title class="col-4 pr-1">
                    <h5 class="m-0">
                      <b class="m-0 mr-1">{{ "audit.audit.people" | translate }}:</b>
                    </h5>
                    <h5 class="m-0">{{ rst.audit?.people?.name }}</h5>
                  </mat-card-title>
                  <mat-card-title class="col-4 pl-1">
                    <h5 class="m-0">
                      <b class="m-0 mr-1">{{ "audit.audit.status" | translate }}:</b>
                    </h5>
                    <h5 class="m-0">
                      {{
                        app.dict.AuditStatus.indexed[rst.audit?.status]
                          ?.title | translate
                      }}
                    </h5>
                  </mat-card-title>
                  <mat-card-title class="col-4 pl-1">
                    <h5 class="m-0">
                      <b class="m-0 mr-1">{{ "audit.audit.date" | translate }}:</b>
                    </h5>
                    <h5 class="m-0">
                      {{ rst.audit?.date | date : app.sys.datetimeFormat }}
                    </h5>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-auto w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
                  <textarea matInput class="border m-0 p-1" [ngModel]="rst.audit?.comment" [mat-autosize]="true"
                    [readonly]="true"></textarea>
                </mat-card-content>
              </mat-card>
            </mat-form-field>
            <ng-container *ngIf="panel.expanded">
              <ng-container *ngFor="let header of headers">
                <ng-container *ngIf="
                    !app.editor.Value.isFieldType(header, 'onlycolumn') &&
                    isVisible(header, rst.value)
                  ">
                  <ng-container *ngTemplateOutlet="
                      fieldtpls.get(
                        app.editor.Value.Type[
                          app.editor.Value.getFieldType(header, 'multi') ||
                            app.editor.Value.Type.text
                        ]
                      ) || fieldtpls.get('text');
                      context: {
                        optionSourceRows: getOptionSourceRows(
                          rst.value,
                          header,
                          headers
                        ),
                        cellField: getCellField(rst.value, header, headers),
                        editor: rst.value,
                        header: header
                      }
                    "></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </mat-dialog-content>
</div>