import { Injectable } from "@angular/core";

import { Extend } from "../../utils/libs/extends";

@Injectable({ providedIn: 'root' })
export class AlertService extends Array<Object> {
    constructor() {
        super();

        Extend.setProto(
            this,
            new.target.prototype
        );
    }

    close(alert: Object) {
        this.remove(alert);
    }
}
