import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PromptComponent } from "../view/prompt/prompt.component";
import { MatIconRegistry } from "@angular/material/icon";
import { Injectable, TemplateRef } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import * as _ from "lodash";

import { Property } from "../../utils/libs/property";
import { Dict } from "./backface/dict";

// size definition same as the bootstrap
const Size = {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576
}

const Devices = {
    mobile: Size.sm,
    laptop: Size.lg,
    pad: Size.md,
}

@Injectable({ providedIn: 'root' })
export class SysService {
    private _props = Property.Of(this).values;

    get isPad() {
        return !!window.matchMedia(`(max-width: ${Devices.pad}px)`).matches;
    };

    get isMobile() {
        return !!window.matchMedia(`(max-width: ${Devices.mobile}px)`).matches;
    };

    get isLaptop() {
        return !!window.matchMedia(`(max-width: ${Devices.laptop}px)`).matches;
    };

    get isWindows() {
        return navigator.platform.toUpperCase().indexOf('Win') >= 0;
    }

    get isMac() {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }

    get isIPad() {
        return navigator.platform.toUpperCase().indexOf('IPAD') >= 0;
    }

    get isApple() {
        return this.isMac || this.isIPad;
    }

    get alertPWDUpdateinterval() {
        return 600000;
    }

    constructor(
        private matIconRegistry: MatIconRegistry,
        public dialog: MatDialog,
    ) {
        matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    }

    prompt<TP extends Dict.PromptTypes>(type: TP, yes?: () => void, no?: () => void, context?: Dict.Prompts[TP]['context']) {
        const dialogRef = this.dialog.open(PromptComponent, {
            disableClose: true,
            hasBackdrop: true,
            data: {
                type: type,
                context: context
            },
        });

        if (yes || no) {
            const sub = dialogRef.afterClosed().subscribe(result => {
                (result ? yes : no)?.();
                sub.unsubscribe();
            });
        }

        return dialogRef;
    }

    openDialog(template: ComponentType<any> | TemplateRef<any>, config?: MatDialogConfig<any>, yes?: () => void, no?: () => void) {
        const dialogRef = this.dialog.open(template, _.extend({
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '80vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
        }, config || {}));

        if (yes || no) {
            const sub = dialogRef.afterClosed().subscribe(result => {
                (result ? yes : no)?.();
                sub.unsubscribe();
            });
        }

        return dialogRef;
    }
}