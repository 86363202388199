import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { Subscription, timer } from "rxjs";
import * as _ from "lodash";

import { Property } from "../libs/property";

@Directive({
    selector: "[timer]"
}) export class TimerDirective implements OnDestroy, OnChanges {
    private props = Property.Of<{
        _subscription: Subscription,
        _countdown: number
    } & TimerDirective>(this).values;

    @Input('timer')
    get interval(): number {
        return this.props.interval;
    }

    set interval(val: number) {
        this.props.interval = val;
    }

    @Input('countdown')
    get countdown(): number {
        return this.props.countdown;
    }

    set countdown(val: number) {
        this.props.countdown = val;
        this.props._countdown = val;
    }

    @Output('finish')
    get finish(): EventEmitter<boolean> {
        return this.props.finish = (this.props.finish || new EventEmitter());
    }

    constructor(
    ) {
    }

    ngOnDestroy(): void {
        this.props._subscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { finish, props, props: { interval } } = this;
        let { props: { _countdown = 1 } } = this;

        props._subscription?.unsubscribe();
        if (!_.isNumber(interval)) return;

        props._subscription = timer(interval, _countdown <= 1 ? undefined : interval).subscribe(() => {
            if (--_countdown <= 0) {
                props._subscription.unsubscribe();
                props._subscription = null;
                finish.emit(true);
                return;
            }

            finish.emit(false)
        })
    }
}
