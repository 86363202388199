
import { AppService } from "../app.service";

export abstract class Lang {
    trans<T extends string | Lang.Def>(key: T, interpolateParams?: Object): Lang.Res<T> {
        return this.app.trans(key, interpolateParams);
    }

    constructor(public app: AppService) {
    }
}

export namespace Lang {
    export type Def = {
        [key: string]: string | Def
    }

    export type Res<T extends string | Def> = T extends string ? any : {
        [key in keyof T]: T[key] extends Def ? Res<T[key]> : any
    }
}