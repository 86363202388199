import { Component, Input, OnInit } from "@angular/core";

import { AppService } from "../../../application/service/app.service";
import { Sys } from "../../../application/service/backface/types";
import { EChartOption, Report } from '../../../application/service/backface/report';
import { Property } from "../../libs/property";

@Component({
    selector: "gov-prjsumcard, [gov-prjsumcard]",
    templateUrl: "./gov.prjsumcard.component.html",
    styleUrls: ["./gov.prjsumcard.component.scss"]
})
export class GovPrjSumCardComponent implements OnInit {
    private _props = Property.Of(this).values;

    @Input('projectsum')
    set projectsum(val: Report.IChartPieData) {
        const {_props} = this;
        _props.projectsum = val;
        this.updateOption();
    };

    get projectsum(): Report.IChartPieData {
        return this._props.projectsum;
    }

    public option: EChartOption = new Report.ReportTemplate().ReportTemplateChartPie;

    constructor(
        public app: AppService
    ) {
    }

    ngOnInit(): void {
    }
    
    updateOption() {
        this.option.graphic['style'].text = this.projectsum.totalname+ '\n' + 
        String(this.projectsum.totalsum);
        this.option.series[0].name = this.projectsum.title;
        this.option.series[0].data = this.projectsum.data;
        this.option.series[0].id = this.projectsum.id;
    }

    onClickCharBar(params): void {
        if(params && this.projectsum?.trigger) {
            this.projectsum?.trigger({
                serialid: params.seriesId,
                dataindex: params.dataIndex
            });
        } 
    }
}