import { Component, Directive, ElementRef, Host, Inject, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../application/service/app.service';
import { Exec } from '../../application/service/backface/exec';
import { Property } from '../../utils/libs/property';

@Component({
    templateUrl: "./pics.component.html",
    styleUrls: ['./pics.component.scss'],
    providers: [NgbCarouselConfig]
})
export class PicsComponent implements OnInit {
    get pics() {
        return this.data.pics;
    }

    get current() {
        return this.data.current;
    }

    constructor(
        public config: NgbCarouselConfig,
        public app: AppService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            pics: Exec.Pic[],
            current: Exec.Pic
        }
    ) {
        config.interval = 0;
        config.showNavigationIndicators = true;
        config.showNavigationArrows = true;
        config.pauseOnHover = true;
        config.pauseOnFocus = true;
        config.keyboard = true;
        config.wrap = true;
    }

    ngOnInit() {
    }
}
