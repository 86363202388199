<mat-sidenav-container class="app-frame vh-100 vw-100">
  <mat-sidenav #sidenav [mode]="slidemode" [opened]="slideopened" (openedChange)="slideopened = $event"
    [fixedInViewport]="false" [fixedBottomGap]="0" [fixedTopGap]="0">
    <div data-color="danger" data-background-color="white" class="sidebar h-100">
      <div app-sidebar class="d-flex flex-column h-100 pl-1 pr-1" style="background: whitesmoke; opacity: 0.93"></div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="d-flex flex-column align-items-stretch">
    <div app-navbar class="main-bg border-bottom">
      <div ngProjectAs="left" class="btn-group btn-group-sm">
        <button type="button" (click)="sidenav.toggle()" class="btn btn-sm btn-outline-secondary">
          <mat-icon class="align-middle">menu</mat-icon>
        </button>
        <div ngbDropdown role="group" class="btn-group btn-group-sm">
          <button type="button" ngbDropdownToggle class="btn btn-sm btn-outline-secondary"></button>
          <div class="dropdown-menu px-1" ngbDropdownMenu>
            <mat-button-toggle-group [(ngModel)]="slidemode">
              <mat-button-toggle value="over">{{
                "general.popover" | translate
              }}</mat-button-toggle>
              <mat-button-toggle value="side">{{
                "general.popside" | translate
              }}</mat-button-toggle>
              <mat-button-toggle value="push">{{
                "general.poppush" | translate
              }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
      <div ngProjectAs="center">
        <div class="logo" *ngIf="!sys.isMobile">
          <div class="text-muted text-center">
            <h4 class="header">
              <strong> {{ "general.title" | translate }} </strong>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-auto m-0 p-0 flex-fill">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>