import { Component, OnInit, Input, NgZone, ElementRef, Renderer2, HostBinding, Output, EventEmitter } from '@angular/core';

import { GovSplitterPanelDirective } from './gov.splitter.panel.directive';
import { Property } from '../../libs/property';

const index = (() => {
    let num = 0;
    return function () {
        num++;
        return num;
    }
})();

interface IPoint {
    x: number;
    y: number;
}

@Component({
    selector: 'gov-splitter, [gov-splitter]',
    templateUrl: "./gov.splitter.component.html",
    styleUrls: ['./gov.splitter.component.scss']
})
export class GovSplitterComponent implements OnInit {
    private _props = Property.Of(this).values;

    @Input('splitterBarWidth')
    splitterBarWidth: number = 8;

    @Input()
    set direction(v: 'horizontal' | 'vertical') {
        v = (v === 'vertical') ? 'vertical' : 'horizontal';
        this._props.direction = v;

        [...this.displayedPanels, ...this.hidedPanels].forEach(panel => {
            panel.setStyleVisibleAndDir(panel.visible, this.isDragging, this.direction);
        });

        this.build();
    }

    get direction(): 'horizontal' | 'vertical' {
        return this._props.direction || 'horizontal';
    }

    @Output()
    sizeChange: EventEmitter<any> = new EventEmitter();

    @HostBinding('style.flex-direction')
    get cssFlexdirection() {
        return (this.direction === 'horizontal') ? 'row' : 'column';
    }

    @HostBinding('style.display')
    get cssDisplay() {
        return 'flex';
    }

    private readonly dragStartValues = {
        sizePixelContainer: 0,
        sizePixelA: 0,
        sizePixelB: 0,
        sizePercentA: 0,
        sizePercentB: 0,
    };

    currentbarNum: number;
    draggingWithoutMove: boolean;

    private isDragging: boolean = false;
    private readonly dragListeners: Array<Function> = [];
    private readonly hidedPanels: Array<GovSplitterPanelDirective> = [];
    public readonly displayedPanels: Array<GovSplitterPanelDirective> = [];

    constructor(
        private ngZone: NgZone,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    build() {
        if (this.displayedPanels.every(dp => dp.index !== null)) {
            this.displayedPanels.sort((a, b) => (<number>a.index) - (<number>b.index));
        }

        this.displayedPanels.forEach((panel: GovSplitterPanelDirective, key: number) => {
            panel.order = 2 * key;
        })

        this.refreshStyleSizes();
    }

    private refreshStyleSizes(): void {
        const sumbarSize = this.splitterBarWidth * (this.displayedPanels.length - 1);

        this.displayedPanels.forEach(panel => {
            panel.setStyleFlexbasis(`calc( ${panel.size}% - ${sumbarSize * panel.size / 100}px )`)
        });
    }

    addPanel(panel: GovSplitterPanelDirective): void {
        panel.index = index();

        if (panel.visible) {
            this.displayedPanels.push(panel)
        } else {
            this.hidedPanels.push(panel);
        }

        panel.setStyleVisibleAndDir(panel.visible, this.isDragging, this.direction);

        this.build();
    }

    updatePanel(panel: GovSplitterPanelDirective): void {
        if (this.displayedPanels.find(dp => dp === panel)) {
            this.build();
        }
    }

    showPanel(panel: GovSplitterPanelDirective): void {
        const area = this.hidedPanels.find(a => a === panel);

        if (area) {
            panel.setStyleVisibleAndDir(panel.visible, this.isDragging, this.direction);

            const panels = this.hidedPanels.splice(this.hidedPanels.indexOf(area), 1);
            this.displayedPanels.push(...panels);

            this.build();
        }
    }

    hidePanel(panel: GovSplitterPanelDirective): void {
        const area = this.displayedPanels.find(a => a === panel);

        if (area) {
            panel.setStyleVisibleAndDir(panel.visible, this.isDragging, this.direction);

            const areas = this.displayedPanels.splice(this.displayedPanels.indexOf(area), 1);
            this.hidedPanels.push(...areas);

            this.build();
        }
    }

    removePanel(panel: GovSplitterPanelDirective): void {
        if (this.displayedPanels.some(dp => dp === panel)) {
            const area = this.displayedPanels.find(dp => dp === panel)
            this.displayedPanels.splice(this.displayedPanels.indexOf(area), 1);

            this.build();
        }
        else if (this.hidedPanels.some(hp => hp === panel)) {
            const area = this.hidedPanels.find(hp => hp === panel)
            this.hidedPanels.splice(this.hidedPanels.indexOf(area), 1);
        }
    }

    startDragging(startEvent: MouseEvent | TouchEvent, barOrder: number, barNum: number): void {
        startEvent.preventDefault();

        this.currentbarNum = barNum;
        this.draggingWithoutMove = true;

        this.ngZone.runOutsideAngular(() => {
            this.dragListeners.push(this.renderer.listen('document', 'mouseup', (e: MouseEvent) => this.stopDragging()));
            this.dragListeners.push(this.renderer.listen('document', 'touchend', (e: TouchEvent) => this.stopDragging()));
            this.dragListeners.push(this.renderer.listen('document', 'touchcancel', (e: TouchEvent) => this.stopDragging()));
        });

        const panelA = this.displayedPanels.find(dp => dp.order === barOrder - 1);

        let alterPanelA, panelB;
        panelB = this.displayedPanels.find(a => a.order === barOrder + 1);

        if (!panelA || !panelB) {
            return;
        }

        const prop = (this.direction === 'horizontal') ? 'offsetWidth' : 'offsetHeight';
        this.dragStartValues.sizePixelContainer = this.elRef.nativeElement[prop];
        this.dragStartValues.sizePixelA = panelA.getSizePixel(prop);
        this.dragStartValues.sizePixelB = panelB.getSizePixel(prop);
        this.dragStartValues.sizePercentA = panelA.size;
        this.dragStartValues.sizePercentB = panelB.size;

        let start: IPoint;
        if (startEvent instanceof MouseEvent) {
            start = {
                x: startEvent.screenX,
                y: startEvent.screenY,
            };
        }
        else if (startEvent instanceof TouchEvent) {
            start = {
                x: startEvent.touches[0].screenX,
                y: startEvent.touches[0].screenY,
            };
        }
        else {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.dragListeners.push(this.renderer.listen('document', 'mousemove', (e: MouseEvent) => this.dragEvent(e, start, panelA, panelB, alterPanelA)));
            this.dragListeners.push(this.renderer.listen('document', 'touchmove', (e: TouchEvent) => this.dragEvent(e, start, panelA, panelB, alterPanelA)));
        });

        this.isDragging = true;
    }

    private dragEvent(event: MouseEvent | TouchEvent, start: IPoint, panelA: GovSplitterPanelDirective, panelB: GovSplitterPanelDirective, alterPanelA: GovSplitterPanelDirective): void {
        if (!this.isDragging) {
            return;
        }

        let end: IPoint;
        if (event instanceof MouseEvent) {
            end = {
                x: event.screenX,
                y: event.screenY,
            };
        }
        else if (event instanceof TouchEvent) {
            end = {
                x: event.touches[0].screenX,
                y: event.touches[0].screenY,
            };
        }
        else {
            return;
        }

        this.draggingWithoutMove = false;
        this.drag(start, end, panelA, panelB, alterPanelA);
    }

    private drag(start: IPoint, end: IPoint, panelA: GovSplitterPanelDirective, panelB: GovSplitterPanelDirective, alterPanelA: GovSplitterPanelDirective): void {
        let offsetPixel = (this.direction === 'horizontal') ? (start.x - end.x) : (start.y - end.y);
        let newSizePixelA = this.dragStartValues.sizePixelA - offsetPixel;
        let newSizePixelB = this.dragStartValues.sizePixelB + offsetPixel;

        if (this.dragStartValues.sizePercentA === 0) {
            panelB.size = this.dragStartValues.sizePercentB / this.dragStartValues.sizePixelB * newSizePixelB;
            panelA.size = this.dragStartValues.sizePercentB - panelB.size;
        }
        else if (this.dragStartValues.sizePercentB === 0) {
            panelA.size = this.dragStartValues.sizePercentA / this.dragStartValues.sizePixelA * newSizePixelA;
            panelB.size = this.dragStartValues.sizePercentA - panelA.size;
        }
        else {
            panelA.size = this.dragStartValues.sizePercentA / this.dragStartValues.sizePixelA * newSizePixelA;
            panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;

            if (panelA.size >= panelA.max) {
                panelA.size = panelA.max;
                panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;
            } else if (panelA.size <= panelA.min) {
                panelA.size = panelA.min;

                if (alterPanelA) {
                    let sizePixelalterA = this.dragStartValues.sizePixelContainer - this.dragStartValues.sizePixelA - this.dragStartValues.sizePixelB;
                    let offsetPixelalterA = offsetPixel - (this.dragStartValues.sizePixelA - this.dragStartValues.sizePixelContainer * panelA.min / 100);
                    let newSizePixelalterA = sizePixelalterA - offsetPixelalterA;

                    if (newSizePixelalterA <= this.dragStartValues.sizePixelContainer * alterPanelA.min / 100) {
                        alterPanelA.size = alterPanelA.min
                    } else {
                        alterPanelA.size = newSizePixelalterA / this.dragStartValues.sizePixelContainer * 100;
                        panelB.size = 100 - panelA.size - alterPanelA.size;
                    }
                } else {
                    panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;
                }
            } else {
                panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;
            }

            if (panelB.size >= panelB.max) {
                if (alterPanelA) {
                    panelB.size = panelB.max;
                } else {
                    panelB.size = panelB.max;
                    panelA.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelB.size;
                }
            } else if (panelB.size <= panelB.min) {
                panelB.size = panelB.min;
                panelA.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelB.size;
            }
        }

        let sizeArr = this.displayedPanels.map(value => value.size)
        if (sizeArr.length > 0) {
            this.sizeChange.emit({
                barNum: this.currentbarNum,
                sizes: sizeArr
            })
        }

        this.refreshStyleSizes();
    }

    private stopDragging(): void {
        if (this.isDragging === false && this.draggingWithoutMove === false) {
            return;
        }

        while (this.dragListeners.length > 0) {
            const fct = this.dragListeners.pop();
            if (fct) {
                fct();
            }
        }

        this.isDragging = false;
        this.draggingWithoutMove = false;
    }
}
