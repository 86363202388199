<div class="card card-chart w-100">
  <div class="card-header card-header-{{prjsumitem?.warning?'warning':'success'}}">
    <div class="ct-chart" id="dailySalesChart">
      <h4 class="card-title">
        <a (click)="summaryList()">{{getStatusName()}}{{(prjsumitem==null || prjsumitem.count == null)?0:prjsumitem.count}}
        {{'general.unit'|translate}}{{'general.projects'|translate}}</a>
      </h4>
    </div>
  </div>
  <div class="card-body" style="padding:15px 10px">
    <div class="card-title">
      {{'budget.amount'| translate}}：
      {{(prjsumitem==null || prjsumitem.totalamount == null)?0:prjsumitem.totalamount | number:'1.0-0' }}
      {{ 'general.bunit'| translate}}
    </div>
    <div class="card-title">
      {{ 'general.annual' | translate}}{{ 'budget.amount'| translate}}：
      {{(prjsumitem==null || prjsumitem.yearamount == null)?0:prjsumitem.yearamount | number:'1.0-0' }}
      {{ 'general.bunit'| translate}}
    </div>
    <div class="card-title">
      {{ 'general.annual' | translate}}{{ 'budget.consume' | translate}}：
      {{(prjsumitem==null || prjsumitem.yearconsume == null)?0:prjsumitem.yearconsume | number:'1.0-0' }}
      {{ 'general.bunit'| translate}}({{getPercent()}}%)
    </div>
  </div>
  <div class="card-footer">
    <div class="stats">
      <i class="material-icons">access_time</i>
      {{ 'general.annual'| translate}}：
      {{(prjsumitem==null || prjsumitem.year == null)?'':prjsumitem.year}}
    </div>
  </div>
</div>