import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import { TemplateService } from '../../utils/view/template.directive';
import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./userinfo.component.html",
    styleUrls: ['./userinfo.component.scss'],
    providers: [
        TemplateService
    ]
})
export class UserinfoComponent implements OnInit, AfterViewInit {
    constructor(
        public auth: AuthService,
        public tpls: TemplateService,
        public app: AppService
    ) {

    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    static open(dialog: MatDialog): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '30vh',
            minHeight: '30vh',
            maxWidth: '20vw',
            minWidth: '20vw',
            height: '30vh',
            width: '20vw'
        }

        return dialog.open(UserinfoComponent, config);
    }
}
