import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { TemplateService } from "../../../utils/view/template.directive";
import { Dict } from "../../service/backface/dict";
import { AppService } from "../../service/app.service";

@Component({
    templateUrl: "./prompt.component.html",
    styleUrls: ['./prompt.component.scss'],
    providers: [TemplateService]
})
export class PromptComponent implements OnInit {
    definitions = this.app.dict.prompt;

    constructor(
        public app: AppService,
        public tpls: TemplateService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            type: Dict.PromptTypes,
            context: Object
        },
    ) {

    }

    ngOnInit() {
    }

}
