import { EventEmitter, Injectable } from "@angular/core";

import { Property } from "../../utils/libs/property";
import { AppAlert, Auth, Server } from "../types";

@Injectable({ providedIn: 'root' })
export class MsgService {
    private _props = Property.Of<{
        request: {
            any?: EventEmitter<Server.Status.Error>,
            each?: {
                [P in string]: EventEmitter<Server.Status.Error>
            }
        }
    } & MsgService>(this).values;

    constructor() {
    }

    onRequestError(errKey?: string): EventEmitter<Server.Status.Error> {
        const server = (this._props.request = this._props.request || {});

        if (!errKey) {
            return server.any = server.any || new EventEmitter();
        }

        const each = (server.each = server.each || {});
        return each[errKey] = each[errKey] || new EventEmitter();
    }

    emitRequestError(err: Server.Status.Error) {
        const { request: { any, each } = {} } = this._props;
        err.errkey && each?.[err.errkey]?.emit(err);
        any?.emit(err);
    }

    get onAppAlert(): EventEmitter<AppAlert> {
        return (this._props.onAppAlert = this._props.onAppAlert || new EventEmitter());
    }

    emitAppAlert(msg: AppAlert) {
        this._props.onAppAlert?.emit(msg);
    }

    get onMeChanged(): EventEmitter<{
        cur: Auth.IMe,
        pre: Auth.IMe
    }> {
        return (this._props.onMeChanged = this._props.onMeChanged || new EventEmitter());
    }

    emitMeChanged(cur: Auth.IMe, pre: Auth.IMe) {
        this._props.onMeChanged?.emit({ cur, pre });
    }

}