import { Component, NgZone, OnInit } from '@angular/core';
import { AmapMarkerClustererDirective } from 'ngx-amap';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { AmapPluginLoaderService } from 'ngx-amap';
import { ActivatedRoute } from '@angular/router';
import { isNull, isUndefined } from 'lodash';

import { GovEditor } from '../../utils/view/model/form.editting';
import { Unreadonly } from "../../utils/libs/types/mixins";
import { Property } from "../../utils/libs/property";

import { ProjectDetailComponent } from '../../projectdetail/view/projectdetail.component';
import { AppService } from '../../application/service/app.service';

import { Prj } from '../../application/service/backface/prj';
import { EChartOption, Report } from '../../application/service/backface/report';
import { ProjectListComponent } from '../../../app/projectlist/view/projectlist.component';
import { Backface } from '../../../app/application/service/backface/config';

declare var AMap: any;

@Component({
    // selector: 'map',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends GovEditor.ToolBar implements OnInit {
    private _props = Property.Of<Unreadonly<HomeComponent> & {
        searchcontent: {
            [k: string]: boolean | string
        }
    }>(this).values;

    // ng-map
    amap: AMap.Map;
    customStyles = [
        {
            url: `assets/img/map/cluster.png?version=${this.app.version.server?.version}`,
            size: { width: 32, height: 32 },
            offset: { x: -16, y: -16 },
        },
        {
            url: `assets/img/map/cluster.png?version=${this.app.version.server?.version}`,
            size: { width: 32, height: 32 },
            offset: { x: -16, y: -16 },
        },
    ];
    markers = [];
    markerNull = ["-100", "-100"];
    mark_icon: any;
    districtSearch: any;
    polygonArr: any;
    infoWindowOffset = {
        x: 0,
        y: -10,
    };

    // search panel
    selectprj: Prj.Project = null;
    searchtext: string = "";
    bInputing: boolean = true;

    mapnotset: string = '';
    city_district: string = "610722";
    city_position: string[] = ["107.333545", "33.156308"];
    city_address: string = "陕西省汉中市城固县博望街道民主街33号城固县人民政府";

    // Cache pool projects
    poolprjs: Map<string, Array<Object>> = new Map<string, Array<Object>>();
    poolpies: Report.IChartPieData[] = [];
    poolpiedata(val:number): Report.IChartPieData {
        return this.poolpies[val];
    }

    set selectedYear(val: number) {
        const { toolbarcontent } = this;
        if (toolbarcontent.selectedYear == val) return;

        toolbarcontent.selectedYear = val;
        this.saveToolbarContent();

        const { _props: props } = this;
        delete props.filteredprojects;

        this.GetProjectFromBackEnd();
    }

    get selectedYear(): number {
        const { toolbarcontent: { selectedYear }, app: { dict: { currentYear } } } = this;
        return selectedYear ?? currentYear;
    }

    set filteredprojects(val: Prj.Project[]) {
        const { _props: props } = this;
        if (props.filteredprojects == val) return;

        props.filteredprojects = val;
    }

    get filteredprojects(): Prj.Project[] {
        const { _props: props, selectedYear, app: { prj: { project: projects } } } = this;

        return (props.filteredprojects || (props.filteredprojects =
            projects.allOf(prj => {
                return prj.budget.checkYear(selectedYear);
            })
        ));
    }

    constructor(
        public app: AppService,
        public router: ActivatedRoute,
        private plugin: AmapPluginLoaderService,
        private ngzone: NgZone,
        public dialog: MatDialog,
        protected httpClient: HttpClient
    ) {
        super(router, 'home');
        const { app: { lang: { general } } } = this;
        this.city_district = general.city_district || this.city_district;
        this.city_position = general.city_position || this.city_position;
        this.city_address = general.city_address || this.city_address;
        this.mapnotset = general.mapnotset || this.mapnotset;
    }

    ngOnInit() {
        this.onProjectsCheck = this.onProjectsCheck.bind(this);
        this.InitProjectCount();
        this.GetProjectFromBackEnd();
    }

    ngAfterViewInit() {
    }

    onItemSeleted(prj: Prj.Project) {
        this.selectprj = prj;
        this.searchtext = prj.name;
        this.onDetailsCheck(prj);
    }

    CheckSimilar(opt: Prj.Project): boolean {
        if (isNull(opt) || isUndefined(opt)) return false;

        let optname = opt.name;
        if (isNull(opt.position) || isUndefined(opt.position)) {
            optname = `${this.mapnotset}${optname}`;
        }

        return optname.includes(this.searchtext);
    }

    onMapReady(amap: AMap.Map) {
        this.amap = amap;
        const { ngzone, app: { version: { server: { version } = {} } } } = this;

        this.mark_icon = new AMap['Icon']({
            size: [48, 48],
            image: `assets/img/map/location-red.png?version=${version}`,
        });

        this.plugin.load('AMap.DistrictSearch').subscribe(async () => {
            this.districtSearch = new AMap['DistrictSearch']({
                level: 'district',
                subdistrict: 1,
                extensions: 'all',
            });

            this.districtSearch.search(this.city_district, (status, result) => {
                // 查询成功时，result即为对应的行政区信息
                ngzone.run(() => {
                    this.polygonArr = result.districtList[0].boundaries;
                });
            });
        });
    }

    locateOnMap(p: AmapMarkerClustererDirective, prj: Prj.Project, infoWindow?: any) {
        if (p) {
            p.get().subscribe(v => {
                var index: number = this.filteredprojects.indexOf(prj);
                if (prj.position?.[0].postion && index >= 0) {
                    var overlaysList = [];
                    overlaysList.push(v.getMarkers()[index]);
                    v.getMap().setFitView(overlaysList);//自适应显示
                    //v.getMarkers()[index].emit('click', { target: v.getMarkers()[index] });
                }
            });
        }
    }

    markClick(p: AmapMarkerClustererDirective, index: number, project: Prj.Project) {
        if (p) {
            p.get().subscribe(v => {
                //var markcurrent = v.getMarkers()[index];
                //markcurrent.setLabel(null);
                this.onDetailsCheck(project);
            });
        }
    }

    infoWindowClose(p: AmapMarkerClustererDirective, index: number) {
        if (p) {
            p.get().subscribe(v => {
                var markcurrent = v.getMarkers()[index];
                markcurrent.setLabel({ content: this.filteredprojects[index].name, direction: 'top' });
            });
        }
    }

    onDetailsCheck(project: Prj.Project) {
        ProjectDetailComponent.open(this.dialog, {
            from: this,
            object: project,
            header: this.app.datasources.project.headers['projectdetails'],
            dataset: null
        })
    }

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    onProjectsCheck(val: {serialid:string, dataindex:number}) {
        const { app: { lang: { general: { year = '', unit = '', projects='' } = {} } = {} }, selectedYear } = this;
        var data = this.poolpies.firstOf(pie=>pie.id==val.serialid)?.data[val.dataindex];
        
        var title = "";
        var idlist = this.poolprjs.get(data.key) as Array<Object>;       
        var header = `${selectedYear}${year}/${data.name}${projects}/${idlist.length}${unit}`;

        ProjectListComponent.open(this.dialog, {
            prjidlist: idlist,
            header: header,
            title: title,
        });        
    }

    GetProjectFromBackEnd() {
        //Need to refine after interface lock down
        const { _props: props, selectedYear, poolpies, poolprjs } = this;

        this.reporter.report("/report/project_pool_summary_by_project_attribute", (res) => {
            if(isNull(res) || isUndefined(res)) return;

            this.poolpies = poolpies.map(pie=>{
                pie = {...pie};

                if(!isNull(res[pie.id]) && !isUndefined(res[pie.id])) {
                    pie.totalsum = res[pie.id]['totalamount'];
                    pie.data.forEach(de=>{
                        if(!isNull(res[pie.id][de.key]) && !isUndefined(res[pie.id][de.key])) {
                            de.value = res[pie.id][de.key]['amount'];
                            poolprjs.set(de.key, res[pie.id][de.key]['project']);
                        } else {
                            de.value = 0;
                            poolprjs.set(de.key, new Array<Object>());
                        }
                    });
                }
                
                return pie;
            })


        }).query({
            year: [selectedYear],
            pool: ['meditate','candidate','begun','accept']
        }); 
    }

    InitProjectCount() : void {
        const { app: { lang: { projectcard: { projectcount, budgettotal,yearbudgettotal } } } } = this;
        const { app: { lang: { pool: { input, meditate, candidate, begun, accept } } } } = this;
        
        this.poolpies.push({
            id: 'projectcount',
            title: projectcount,
            totalname: projectcount,
            totalsum: 0,
            data: [
                {value:0,name:meditate, key:'meditate'},{value:0,name:candidate, key:'candidate'},
                {value:0,name:begun, key:'begun'},{value:0,name:accept, key:'accept'}
            ],
            trigger: this.onProjectsCheck
        } as Report.IChartPieData);

        this.poolpies.push({
            id: 'projectamount',
            title: budgettotal,
            totalname: budgettotal,
            totalsum: 0,
            data: [
                {value:0,name:meditate, key:'meditate'},{value:0,name:candidate, key:'candidate'},
                {value:0,name:begun, key:'begun'},{value:0,name:accept, key:'accept'}
            ],
            trigger: this.onProjectsCheck
        } as Report.IChartPieData);

        this.poolpies.push({
            id: 'yearamount',
            title: yearbudgettotal,
            totalname: yearbudgettotal,
            totalsum: 0,
            data: [
                {value:0,name:meditate, key:'meditate'},{value:0,name:candidate, key:'candidate'},
                {value:0,name:begun, key:'begun'},{value:0,name:accept, key:'accept'}
            ],
            trigger: this.onProjectsCheck
        } as Report.IChartPieData);

        this.poolprjs.set('meditate',new Array<Object>());
        this.poolprjs.set('candidate',new Array<Object>());
        this.poolprjs.set('begun',new Array<Object>());
        this.poolprjs.set('accept',new Array<Object>());
    }
}
