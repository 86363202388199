
import * as CryptoJS from "crypto-js";
import { uniqueId } from 'lodash'
import uuid from 'uuid/v4';

const newnumber = (() => {
    var idx = 0;
    return () => ++idx;
})();

const symbols: {
    [P: string]: symbol
} = {}

export namespace Unique {
    export type guid = string;

    export function symbol(prefix: string, obj?: Object | Array<any>): symbol {
        if (!obj) return symbols[prefix] = symbols[prefix] || newsymbol(prefix);

        const Uid = getUidsBag(obj);
        const _symbols = Uid.symbols || (Uid.symbols = {});
        return _symbols[prefix] = _symbols[prefix] || newsymbol(prefix);
    }

    export function number(obj?: Object | Array<any>, prefix: string = "idx"): number {
        if (!obj) return newnumber();

        const Uid = getUidsBag(obj);
        const idx = Uid.idx || (Uid.idx = {});
        return idx[prefix] || (idx[prefix] = newnumber());
    }

    export function numbered(obj?: Object | Array<any>, prefix: string = 'num'): string {
        if (!obj) return uniqueId(prefix);

        const Uid = getUidsBag(obj);
        const numbers = Uid.number || (Uid.number = {});
        return numbers[prefix] || (numbers[prefix] = uniqueId(prefix));
    }

    export function uuid(obj?: Object | Array<any>, prefix: string = 'uid', hex: boolean = true): string {
        if (!obj) return newuuid(hex);

        const Uid = getUidsBag(obj);
        const uuids = Uid.uuid || (Uid.uuid = {});

        return uuids[`${prefix}${hex}`] || (uuids[`${prefix}${hex}`] = newuuid(hex));
    }
}

const _UidSymbol: symbol = Unique.symbol('UID');

function getUidsBag(obj: any) {

    if (!obj.hasOwnProperty(_UidSymbol)) {
        const props = {};

        Object.defineProperty(obj, _UidSymbol, {
            enumerable: false,
            configurable: true,
            get: function (this: any): {} {
                return props;
            }
        });
    }

    return obj?.[_UidSymbol];
}

function newuuid(hex: boolean): string {
    const id = uuid();
    return hex ? id.replace('-', '') : id;
}

function newsymbol(prefix: string): symbol {
    return Symbol(`@$${prefix}${CryptoJS.lib.WordArray.random(4).toString(CryptoJS.enc.Hex).toUpperCase()}`)
}