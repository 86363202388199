import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { TemplateService } from '../../utils/view/template.directive';
import { AppService } from '../../application/service/app.service';
import { GovEditor } from '../../utils/view/model/form.editting';

@Component({
    templateUrl: "./plan.component.html",
    styleUrls: ['./plan.component.scss'],
    providers: [TemplateService]
})
export class PlanComponent {
    constructor(
        public cdf: ChangeDetectorRef,
        public app: AppService,
        public router: Router,
    ) {
    }

    onFilter(data: GovEditor.IFilterData) {
    }

    onWorkItemDetails(data: GovEditor.IWorkItemSumData) {
        this.router.navigate(['/audit/'], {
            queryParams: {
                project: data.stageitem.project.id,
                stage: data.stageitem.stage.id,
                status: data.workitemsum.status
            }
        });
    }
}
