import { Component, AfterViewInit, OnInit, Input, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { isNull, isUndefined } from 'lodash';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { Prj as TPrj } from '../../../application/service/backface/types';
import { FieldProperty } from '../../../utils/view/model/field.property';
import { TemplateService } from '../../../utils/view/template.directive';
import { Backface } from '../../../application/service/backface/config';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Prj } from '../../../application/service/backface/prj';
import { Property } from '../../../utils/libs/property';
import { Editor } from '../../../utils/libs/editor';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: "sumprojectstage, [sumprojectstage]",
    templateUrl: "./sumprojectstage.component.html",
    styleUrls: ['./sumprojectstage.component.scss'],
    providers: [TemplateService]
})
export class SumProjectStageComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of(this).values;
    readonly noinputtxt: boolean = true;
    
    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    projectstageidlist: string[] = [];
    projectstagestatus: Map<string, Array<{ id: string, status: TPrj.IWorkitem.Status }>> = new Map();

    // Summary Fields Parameter
    prjcount: number = 0;
    totalbudget: number = 0;
    yearbudget: number = 0;

    sumdisplayedColumns: string[] = [];
    get sumFields(): Editor.IFieldCols {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields || (props.sumFields = [
            {
                key: "total.key",
                title: lang.general.total
            },
            {
                key: "total.value",
                get title() {
                    const { prjcount } = _this;
                    return `${prjcount}${lang.general.unit}`;
                },
            },
            {
                key: "amount.key",
                title: lang.budget.amount
            },
            {
                key: "amount.value",
                get title() {
                    const { totalbudget, locale } = _this;
                    return formatNumber(totalbudget, locale, "1.0-0")
                },
            },
            {
                key: "yearamount.key",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.budget.amount}`
                },
            },
            {
                key: "yearamount.value",
                get title() {
                    const { yearbudget, locale } = _this;
                    return formatNumber(yearbudget, locale, "1.0-0");
                },
            },
            {
                key: "budgetunit",
                title: lang.budget.unit + "：" +
                    lang.general.bunit
            }
        ]);
    }

    // Main Table Header & Data Zone parameter
    displayedColumns: string[] = [];
    dataSource: Prj.SumProjectData[] = [];
    colFields = <Editor.IFields>[
        {
            key: "id",
            title: this.app.lang.project.id
        },
        {
            key: "name",
            title: this.app.lang.project.name
        },
        {
            key: "actorheader",
            title: this.app.lang.actor.header
        },
        {
            key: "actorleaders",
            title: this.app.lang.actor.leaders
        },
        {
            key: "owndept",
            title: this.app.lang.project.owndept
        },
        {
            key: "owncompany",
            title: this.app.lang.project.owncompany
        },
        {
            key: "positonDistrict",
            title: this.app.lang.project.position
        },
        {
            key: "constrtype",
            title: this.app.lang.project.constrtype
        },
        {
            key: "description",
            title: this.app.lang.project.description
        },
        {
            key: "memo",
            title: this.app.lang.project.memo
        },
        {
            key: "budgetstartend",
            title: this.app.lang.budget.startend
        },
        {
            key: "budgetamount",
            title: this.app.lang.budget.amount
        },
        {
            key: "budgetyearamount",
            title: this.app.lang.general.annual + this.app.lang.budget.amount
        },
        {
            key: "budgetyeartarget",
            title: this.app.lang.general.annual + this.app.lang.budget.target
        }
    ];

    @Input('toolbar')
    toolbar: GovEditor.ISetBinder;

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        public app: AppService,
        public dialog: MatDialog,
        public router: ActivatedRoute,

        protected httpClient: HttpClient,
    ) {
        super(router, 'sumprojectstage');
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
        // Here need to refine for different projecttypes
        this.app.prj.projecttypes[0].stages.forEach(stage => {
            this.colFields.push({
                key: stage.id,
                title: stage.name
            });
            this.projectstageidlist.push(stage.id);
        });

        this.sumdisplayedColumns = this.sumFields.map(p => p.key);
        this.displayedColumns = this.colFields.map(p => p.key);
        this.GetProjectData();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter.ngOnDestroy();
    }

    set selectedYear(val: number) {
        const { toolbarcontent } = this;
        if (toolbarcontent.selectedYear == val) return;

        toolbarcontent.selectedYear = val;
        this.saveToolbarContent();

        this.GetProjectData();
    }

    get selectedYear(): number {
        const { toolbarcontent: { selectedYear }, app: { dict: { currentYear } } } = this;
        return selectedYear ?? currentYear;
    }

    exportas(val: string) {
        const { selectedYear, app: { lang: { general: { orgname = '', annual = '' } = {}, tabs: { summaryprojectstage = '' } = {} } } } = this;
        this.generateExcel(`${orgname}${selectedYear}${annual}${summaryprojectstage}`);
    }

    async generateExcel(path: string) {
        const { app: { dict: { statuslist } } } = this;

        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(path);

        // Add Row and formatting
        const titleRow = worksheet.addRow([path]);
        worksheet.mergeCells(titleRow.number, 1, titleRow.number, this.colFields.length);

        titleRow.font = {
            name: '方正小标宋简体',
            family: 4, size: 20,
            bold: true
        };

        titleRow.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true,
            shrinkToFit: true
        };

        titleRow.getCell(1).border = {
            top: { style: 'thin' }, left: { style: 'thin' },
            bottom: { style: 'thin' }, right: { style: 'thin' }
        };

        titleRow.height = 50;

        var hintData = ['报告时间: ' + new Date().toLocaleString()];
        for (let n = 0; n < this.colFields.length - statuslist.length - 1; n++) {
            hintData.push('');
        }

        for (let m = 0; m < statuslist.length; m++) {
            hintData.push(statuslist[m].icontext + statuslist[m].text);
        }

        const subTitleRow = worksheet.addRow(hintData);
        worksheet.mergeCells(subTitleRow.number, 1, subTitleRow.number, this.colFields.length - statuslist.length);
        subTitleRow.border = {
            top: { style: 'thin' }, left: { style: 'thin' },
            bottom: { style: 'thin' }, right: { style: 'thin' }
        };

        subTitleRow.height = 15;
        subTitleRow.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true,
            shrinkToFit: true
        };

        for (let z = 1; z <= this.colFields.length; z++) {
            if (z == 1) {
                subTitleRow.getCell(1).alignment = {
                    horizontal: 'right'
                };
                continue;
            }

            if (z > this.colFields.length - statuslist.length && statuslist[z - (this.colFields.length - statuslist.length) - 1].style) {
                subTitleRow.getCell(z).font = { color: { argb: 'FFFF0000' } };
            }
        }

        // Add Header Row
        var hRow1Data = [];
        for (let k = 0; k < this.sumFields.length; k++) {
            hRow1Data.push(this.sumFields[k].title);
            var cellColSpan = this.getColspan(k);
            for (let p = 0; p < cellColSpan - 1; p++) {
                hRow1Data.push("");
            }
        }

        const headerRow1 = worksheet.addRow(hRow1Data);
        for (let m = 0, pos = 0; m < this.sumFields.length; m++) {
            var cellColSpan = this.getColspan(m);
            worksheet.mergeCells(headerRow1.number, pos + 1, headerRow1.number, pos + this.getColspan(m));
            pos = pos + cellColSpan;
        }

        const headerRow2 = worksheet.addRow(this.colFields.map(head => { return head.title }));

        // Cell Style : Fill and Border
        [headerRow1, headerRow2].forEach(r => {
            r.height = 40;
            r.eachCell((cell, number) => {
                cell.font = {
                    name: '宋体',
                    family: 4, size: 12,
                    bold: true
                };

                cell.alignment = {
                    horizontal: 'center',
                    vertical: 'middle',
                    wrapText: true
                };

                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF00' },
                    bgColor: { argb: 'FF0000FF' }
                };

                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            })
        });

        // Add Data and Conditional Formatting
        for (let index = 0;
            index < this.dataSource.length; index++) {
            const d = this.dataSource[index];
            var data = [];
            var redcols = [];
            var x = 0;
            this.colFields.forEach(col => {
                x++;

                if (col.key == "id") {
                    var n = index + 1;
                    data.push(n.toString());
                    return;
                }
                var v = this.getElementValue(d, col.key);
                data.push(v = v ? v : "");

                var status = this.getProjectStageStatus(d, col.key);

                if (status == TPrj.IWorkitem.Status.delaying ||
                    status == TPrj.IWorkitem.Status.delayed) {
                    redcols.push(x);
                }
            });

            const row = worksheet.addRow(data);
            row.eachCell((cell, number) => {
                cell.font = {
                    name: '宋体',
                    family: 4, size: 15,
                    bold: false
                };

                if (redcols.indexOf(number) >= 0) {
                    cell.font = { color: { argb: 'FFFF0000' } };
                }

                cell.alignment = {
                    horizontal: (number == 1 || number > (this.colFields.length - this.app.prj.projecttypes[0].stages.length)) ? 'center' : 'left',
                    vertical: 'middle',
                    wrapText: true,
                    shrinkToFit: true
                };

                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });
        }

        for (let index = 0; index < this.colFields.length; index++) {
            const col = this.colFields[index];
            const colxls = worksheet.getColumn(index + 1);
            colxls.width = 15;
            if (col.key == 'id') colxls.width = 5;
            if (col.key == 'description') colxls.width = 25;
        }

        // No Footer Row
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, path);
        });
    }

    GetProjectData() {
        this.dataSource = [];
        this.prjcount = 0;
        this.totalbudget = 0;
        this.yearbudget = 0;

        const { app: { prj: { project: projects } } } = this;
        projects.allOf({ ispatched: false, cancelled: false }, project => {
            if (!project.budget?.checkYear(this.selectedYear)) return;

            const sumproject: Prj.SumProjectData = {
                id: project.id,
                name: project.name,
                pool: TPrj.Pool[project.pool],
                owncompany: project.owncompany,
                owndept: project.owndept,
                location: project.location,
                positonDistrict: project.positonDistrict,
                description: project.description,
                budgetstartend: project.budget.startend,
                constrtype: project.constrtypename,
                constrstatus: project.constrstatusname,
                budgetamount: formatNumber(project.budget.amount, this.locale, "1.0-0"),
                budgetyearamount: formatNumber(project.getBudgetAmount(this.selectedYear), this.locale, "1.0-0"),
                budgetyeartarget: project.getBudgetTarget(this.selectedYear),
                actorheader: project.actor?.header?.name,
                actorleaders: project.getActorleaders(),
                actoractorsdept: project.getActoractorsdept(),
                actordomainsdept: project.getActordomainsdept(),
                actorsitesdept: project.getActorsitesdept(),
                memo: project.memo
            };

            this.dataSource.push(sumproject);
            this.totalbudget += (project.budget.amount) ? (project.budget.amount) : 0;
            this.yearbudget += project.getBudgetAmount(this.selectedYear);

            const prjstatus = new Array<{ id: string, status: TPrj.IWorkitem.Status }>();
            this.projectstageidlist.forEach(id => {
                prjstatus.push({
                    id: id,
                    status: TPrj.IWorkitem.Status.delayed
                });
            });

            this.projectstagestatus.set(sumproject.id, prjstatus);
        });

        this.prjcount = this.dataSource.length;
    }

    getColspan(i: number): number {
        if (i == this.sumFields.length - 1)
            return this.app.prj.projecttypes[0].stages.length;

        var p = 1;

        var hRow1length = this.sumFields.length - 1;
        var hRow2length = this.colFields.length - this.app.prj.projecttypes[0].stages.length;

        if (isNull(i) || isUndefined(i) ||
            hRow2length <= 0 || hRow1length <= 0 ||
            hRow2length <= hRow1length) return p;
        p = Math.floor(hRow2length / hRow1length);

        if (i == hRow1length - 1)
            p = hRow2length - p * i;

        return p;
    }

    getElementValue(obj: object, key: string): string {
        const { app: { dict, prj: { project: projects } } } = this;

        if (this.projectstageidlist.indexOf(key) >= 0) {
            const pd = obj as Prj.SumProjectData;
            const project = projects.firstOf({ id: pd.id, pool: TPrj.Pool[pd.pool] });
            const statuslist = this.projectstagestatus.get(project?.id);
            const wsstatus = statuslist.find(status => status.id == key);
            return dict.getStatusItem(wsstatus.status)?.icontext;
        }

        return obj[key];
    }

    getProjectStageStatus(obj: object, key: string): TPrj.IWorkitem.Status {
        const { app: { prj: { project: projects } } } = this;

        if (this.projectstageidlist.indexOf(key) >= 0) {
            const pd = obj as Prj.SumProjectData;
            const project = projects.firstOf({ id: pd.id, pool: TPrj.Pool[pd.pool] });
            const statuslist = this.projectstagestatus.get(project?.id);
            const wsstatus = statuslist.find(status => status.id == key);
            return wsstatus?.status;
        }

        return TPrj.IWorkitem.Status.unknown;
    }
}
