import { castArray, clone, extend, forEach, isArray, isBoolean, isFunction, isNull, isNumber, isObject, isString, isUndefined, noop, reduce, some } from "lodash";
import { catchError, finalize, map, shareReplay, switchMap } from "rxjs/operators";
import { EventEmitter, TemplateRef, Type, TypeDecorator } from "@angular/core";
import { Observable, ObservableInput, of, Subject, Subscription } from "rxjs";
import { ComponentType } from "@angular/cdk/portal";
import { Moment } from "moment";
import * as _ from "lodash";

import { Unreadonly } from "./types/mixins";
import { AppService } from "../../application";
import { Collection } from './collection';
import { _Array } from "./polyfill/array";
import { _JSON } from "./polyfill/json";
import { Property } from "./property";
import { Unique } from "./unique";

const InheritKey = Unique.symbol('InheritKey').toString();

type IOP = {
    (obj?: object): void;
    preval?: any;
};

type ClsType<T> = Type<T>;

export interface IDestroies<T extends Editor.Editor<T, TI>, TI> extends Array<IOP> {
    host: {
        [Key in Editor.TKeys<T, TI>]?: {
            callback: IOP,
            data?: any
        }
    }
}

type IContext<T extends Editor.Editor<T, TI>, TI> = {
    memberscancel?: IMembersCancel<T, TI>,
    membersowned?: IMembersOwned<T, TI>,
    createnew?: IOP,
}

type IMembersOwned<T extends Editor.Editor<T, TI>, TI> = {
    [Key in Editor.TKeys<T, TI>]?: Editor.Editor<any> | Array<Editor.Editor<any>>
}

type IMembersCancel<T extends Editor.Editor<T, TI>, TI> = {
    [Key in Editor.TKeys<T, TI>]?: IOP;
}

interface Prop {
    handler?: _Editor.IHandle<any, any>;
    type: Editor.Type;
    settings?: any;
}

interface isReadonly {
    (obj: object): boolean;
}

const _FieldSymbol: symbol = Unique.symbol('FIELDKEYS');
const _FieldBag = Property.inheritBag<{
    isReadonly: ReturnType<typeof Editor.isReadonly>,
    readonlys: { [P: string]: isReadonly },
    prop: { [P: string]: Prop },
}>(_FieldSymbol);

export namespace Editor {
    let cud: TSettingHandler = undefined;

    export const detect = {
        get cud(): TSettingHandler {
            return cud;
        },
        set cud(val: TSettingHandler) {
            cud = val;
        }
    }
}

export namespace Editor {
    export class Editor<
        T extends Editor<T, TI>,
        TI = Partial<Unreadonly<T>>
    > {
        readonly $props_ = Property.Of(this).values as any as Editor.TProp<T, TI>;
        readonly $eprops_ = Property.Of(this.$props_).values as any as _Editor.EProp<T, TI>;

        get $presave_(): EventEmitter<{
            handled?: boolean | Editor<any> | Observable<{
                handled?: boolean | Editor<any>
            }>
        }> {
            const { $eprops_: eprops } = this;
            return eprops.$presave_ = eprops.$presave_ || new EventEmitter();
        }

        get $postsave_(): EventEmitter<boolean> {
            const { $eprops_: eprops } = this;
            return eprops.$postsave_ = eprops.$postsave_ || new EventEmitter();
        }

        get $precancel_(): EventEmitter<string> {
            const { $eprops_: eprops } = this;
            return eprops.$precancel_ = eprops.$precancel_ || new EventEmitter();
        }

        get $postcancel_(): EventEmitter<string> {
            const { $eprops_: eprops } = this;
            return eprops.$postcancel_ = eprops.$postcancel_ || new EventEmitter();
        }

        get $temp_(): TProp<T, TI> {
            const { $eprops_: eprops } = this;
            return eprops.$temp_ = (eprops.$temp_ || {}) as any;
        }

        get $setting_(): TSetting<T, TI> {
            const { $eprops_: eprops } = this;
            return eprops.$setting_;
        }

        get $base_(): T {
            const { $eprops_: { $setting_: setting }, $thistype_: thiscls } = this;
            return setting instanceof thiscls ? setting : undefined;
        }

        get $inherits(): TProp<T, TI> {
            return this.$eprops_.$inherits_;
        }

        get $thistype_(): ClsType<T> {
            return (this as any).constructor;
        }

        get $forcreate(): boolean {
            const { $eprops_: { $$context_: ctx } } = this;
            return !!ctx.createnew;
        }

        set $forcreate(val: boolean) {
            const { $eprops_: { $$context_: ctx } } = this;
            if (!!ctx.createnew && val) return;

            if (val) {
                ctx.createnew = ctx.createnew || noop;
            } else {
                delete ctx.createnew;
            }
        }

        get $json(): {
            readonly exclude: {
                [P in TKeys<T, TI>]: boolean
            }
        } {
            const { $eprops_: eprops } = this;

            const exclude = {}, json = (eprops.$json = (eprops.$json || {
                get exclude(): {
                    [P in TKeys<T, TI>]: boolean
                } {
                    return exclude as any;
                }
            }) as any);

            return json;
        }

        get $saving_(): boolean {
            return this.$eprops_.$$saving_.level > 0;
        }

        $pushSaving_() {
            this.$eprops_.$$saving_.push();
        }

        $popSaving_() {
            this.$eprops_.$$saving_.pop();
        }

        constructor(setting: T | TI) {
            const { $eprops_: eprops, $thistype_: thisType } = this;
            setting = (setting || {} as TI);

            // extract the inherit props, and free
            if (setting[InheritKey]) {
                eprops.$inherits_ = setting[InheritKey];
                delete setting[InheritKey];
            }

            if (setting['$inherits']) {
                eprops.$inherits_ = { ...(setting['$inherits'] || {}), ...(eprops.$inherits_ || {}) }
            }

            // the destroies/context interoperations
            eprops.$$context_ = eprops.$$context_ || {};
            eprops.$setting_ = setting as TSetting<T, TI>;
            eprops.$$destroies_ = eprops.$$destroies_ || extend([], { host: {} });
            if (eprops.$setting_ instanceof thisType) {
                // build the same life-cycle.
                _Editor.$destroy_ref(this as any, eprops.$setting_);
            }

            // the saving_ chain
            const _this = this, chain = (eprops.$$saving_ = {
                level: 0,
                push() {
                    ++chain.level;

                    const { $eprops_: { $$context_: { membersowned } } } = _this;
                    for (let k in (membersowned || {})) {
                        _Editor.ownedsaving(membersowned[k], true);
                    }
                },
                pop() {
                    if (chain.level > 0) {
                        --chain.level;

                        const { $eprops_: { $$context_: { membersowned } } } = _this;
                        for (let k in (membersowned || {})) {
                            _Editor.ownedsaving(membersowned[k], false);
                        }
                    }
                },
            });

        }

        $onvalue_?(key: string, value: any, changed?: boolean);

        $readonly_(key: string): boolean {
            return isReadonly(this).is(key);
        }

        $changed_(key?: TKeys<T, TI>): boolean {
            const { $temp_: temp, $eprops_: { $$context_: { createnew, membersowned, memberscancel } } } = this;

            return key ? (
                temp.hasOwnProperty(key) || _Editor.ownedchanged(membersowned?.[key]) || !!memberscancel?.[key]
            ) : (
                Object.keys(temp).length > 0 || !!createnew || !!memberscancel || some(membersowned || {}, o => _Editor.ownedchanged(o))
            );
        }

        $cancel_(key?: TKeys<T, TI>): void {
            if (!this.$changed_()) return;

            const { $saving_: saving, $temp_: temp, $eprops_: { $$context_: context, $$saving_ } } = this;
            const { $eprops_: { $$context_: { memberscancel, membersowned, createnew } } } = this;

            $$saving_.push();

            if (key) {
                this.$precancel_.emit(key as string);
                if (memberscancel?.[key]) {
                    memberscancel[key]?.();
                    delete memberscancel[key];
                }

                if (membersowned?.[key]) {
                    _Editor.ownedcancel(membersowned[key]);
                }

                delete temp[key as string];

                $$saving_.pop();
                this.$postcancel_.emit(key as string);
                return;
            }

            if (!saving) {
                this.$precancel_.emit();
            }

            if (!saving && createnew) {
                createnew?.();
            }

            if (!saving && memberscancel) {
                forEach(memberscancel, o => o?.());
            }

            if (!saving && membersowned) {
                forEach(membersowned || {}, o => _Editor.ownedcancel(o))
            }

            for (let k in temp) {
                delete temp[k]
            }

            delete context.memberscancel;
            delete context.createnew;
            $$saving_.pop();

            if (!saving) {
                this.$postcancel_.emit();
            }
        }

        $preval_<Key extends TKeys<T, TI>>(key?: Key): TProp<T, TI>[Key] {
            const { prop } = _FieldBag.Of(this, false);
            const handler = prop[<string>key]?.handler;
            return handler?.prevalget?.call(this, key);
        }

        $save_(state?: { drilling?: boolean }): boolean | Observable<boolean> {
            if (!this.$changed_()) return false;

            const next = (handled?: boolean | Editor<any>) => {
                if (isBoolean(handled) && handled) {
                    this.$postsave_.emit(true);
                    return true;
                }

                if (handled instanceof Editor) {
                    const ret = handled.$save_();
                    if (ret instanceof Observable) {
                        const onsave = new Subject<boolean>();
                        const sub = ret.subscribe((succeed: boolean) => {
                            this.$postsave_.emit(succeed);
                            sub.unsubscribe();
                            onsave.next(true);
                            onsave.complete();
                        }, (error) => {
                            this.$postsave_.emit(false);
                            onsave.error(error);
                            onsave.complete();
                        });

                        return onsave;
                    }

                    return ret;
                }

                // Backend service update succeed, update locally, need to 
                // remove override object/parameter according to response from backend.
                const {
                    $temp_: temp,
                    $eprops_: {
                        $$saving_,
                        $$context_: {
                            membersowned,
                            createnew,
                        },
                    }
                } = this;

                $$saving_.push();

                // call the backend service to update the service data, 
                // backend need to consider remove the override object/parameter.
                const onresult = (value?: any, succeed: boolean = true) => {
                    if (succeed) {
                        this.$update_(value);

                        // save into editor object.
                        forEach(membersowned || {}, (o) => {
                            _Editor.ownedsave(o);
                        })

                        forEach(temp, (val, key) => {
                            this[key] = val ?? value?.[key]
                        });

                        // discard the temp values
                        this.$cancel_();
                    }

                    // restore the saving state.
                    $$saving_.pop();
                    this.$postsave_.emit(succeed);
                    return succeed;
                }

                const changed = _JSON.JsonOf(this);
                const entity = detect.cud.$entity(this);
                const ret: boolean | Observable<any> = entity && !state?.drilling && (createnew ?
                    detect.cud.$create(changed, entity) :
                    detect.cud.$update(changed, entity)
                );

                if (ret instanceof Observable) {
                    const onsave = new Subject<boolean>();
                    const _ret = ret.pipe(
                        map((value: any): boolean => {
                            onresult(value, true);
                            onsave.next(true);
                            onsave.complete();
                            return true;
                        }),
                        catchError((error: any) => {
                            onresult(undefined, false);
                            onsave.error(error);
                            onsave.complete();
                            return of(false);
                        }),
                        finalize(() => {
                            _sub.unsubscribe();
                        }),
                        shareReplay(1)
                    ), _sub = _ret.subscribe();

                    return onsave;
                }

                return onresult(changed, true);
            }

            const handle: {
                handled?: boolean | Editor<any> | Observable<{
                    handled?: boolean | Editor<any>
                }>
            } = {};

            !state?.drilling && this.$presave_.emit(handle);
            if (!(handle.handled instanceof Observable)) {
                return next(handle.handled);
            }

            const ret = handle.handled.pipe(
                switchMap(({ handled }): ObservableInput<boolean> => {
                    const value = next(handled);
                    if (value instanceof Observable) {
                        return value;
                    }

                    return of(!!value);
                }),
                catchError(() => {
                    return of(false);
                }),
                finalize(() => {
                    sub.unsubscribe();
                }),
                shareReplay(1)
            ), sub = ret.subscribe();

            return ret;
        }

        $destroy_() {
            [...this.$eprops_.$$destroies_].forEach(cb => cb(this));
            forEach(this.$eprops_.$$destroies_.host, (cb) => cb.callback(this));
        }

        $update_(value: T | TI) {
            if (!isObject(value)) return;

            // TODO: currently we only update the object id even not enough for sync over cross-operation
            const { $setting_: setting, $eprops_: {
                $$context_: { membersowned }
            } } = this;

            // save into editor object.
            forEach(membersowned || {}, (o, key) => {
                _Editor.ownedupdate(o, value?.[key])
            })

            if (value["id"] != setting["id"]) {
                setting["id"] = value["id"];
            }
        }

        static UpdateIfHave<
            D extends Object,
            S extends {
                [P in keyof D]?: any
            },
            H extends {
                [P in keyof D]?: (dst: D, val: any) => any
            }
        >(dst: D, src: S, handle: H) {
            _.forEach(handle, (h, k) => {
                if (_.has(src, k)) {
                    h(dst, _.get(src, k));
                }
            })
        }
    }
}

export namespace Editor {
    export function Collections<
        T extends Collection<TV, TP, TA>,
        TP = T extends Collection<any, infer P, any> ? P : never,
        TA = T extends Collection<any, any, infer A> ? A : never,
        TV extends Editor<any, any> = _Array.ItemType<T>
    >(clsVal?: ClsType<TV>): TypeDecorator {

        return function (cls: ClsType<T>): ClsType<T> {
            const { prototype } = cls, { createnew } = prototype as Array<any>;

            Object.defineProperty(prototype, 'createnew', {
                enumerable: true, configurable: true,
                value: function (this: TV[], val: TA | TV, at?: number): TV {
                    const newone: TV = createnew.call(this, val, at);
                    newone.$eprops_.$$context_.createnew = _Editor.refcreate_cancel(
                        this, newone
                    );

                    return newone;
                }
            })

            return cls;
        }

    }
}

export namespace Editor {
    export interface IDynamic {
        [key: string]: any;
    }

    export class Dynamic<TParent> extends Editor<Dynamic<TParent>, IDynamic> implements IDynamic {
        get $parent_(): TParent {
            return this.$props_.$parent_;
        }

        get $fields_(): IFields {
            return this.$props_.$fields_;
        }

        get $exclude_(): {
            [P: string]: boolean
        } {
            const { $props_: props } = this;
            return props.$exclude_ || (
                props.$exclude_ = {}
            )
        }

        get $fieldtypes_(): Dynamic.IFieldTypes {
            return this.$props_.$fieldtypes_;
        }

        constructor(
            setting: IDynamic,

            parent: TParent,
            fields: IFields,
            fieldtypes?: Dynamic.IFieldTypes
        ) {
            super(setting);

            const { $props_: props } = this;
            props.$fieldtypes_ = fieldtypes;
            props.$fields_ = clone(fields);
            props.$parent_ = parent;

            // build the properties according to fields
            (fields || []).forEach(f => {
                Dynamic.build<Dynamic<TParent>, IDynamic, TParent>(this, parent, f, fieldtypes);
            });

            if ((fields || []).length <= 0) {
                Object.defineProperty(this, 'toJSON', {
                    writable: true, enumerable: false, configurable: true,
                    value: function (this: any, res: { [P: string]: any } = {}) {
                        return;
                    }
                })
            }
        }
    }

    export namespace Dynamic {
        export type IFieldTypes = {
            [P in Value.Type]?: {
                new(parent: any, setting?: any)
            }
        }

        export function build<
            T extends Editor<T, TI>, TI, TParent
        >(obj: T, parent: TParent, field: IField, fieldtypes?: Dynamic.IFieldTypes, settings?: {}) {
            if (!obj) return;

            const valmtyp: Value.Type = Value.getFieldType(field, Value.Type.multi);
            const ismulti = Value.isFieldType(field, Value.Type.multi);
            const thisType: ClsType<T> = (obj as any).constructor;
            const valtyp: Value.Type = Value.getFieldType(field);
            const fldtyp = fieldtypes?.[valmtyp];

            if (ismulti) {
                Field<T, TI>(thisType, Type.owneds, {
                    ...Owneds(
                        function (this: T) {
                            return fldtyp ? new fldtyp(parent) : [];
                        }, field.key as any
                    ),
                    ...(settings || {})
                })(obj, field.key);
            } else if (fldtyp) {
                Field<T, TI>(thisType, [Type.owned, valtyp], {
                    ...Owned(
                        function (this: T, item: any) {
                            return new fldtyp(parent, item);
                        }, () => fldtyp, field.key as any
                    ),
                    ...(settings || {})
                })(obj, field.key);
            } else {
                const { source } = field;
                Field<T, TI>(thisType, [Type.owned, valtyp as Value.Type.enum, source, 0], (
                    settings || {}
                ))(obj, field.key);
            }

            JSON.Key()(obj, field.key);
        }
    }
}

export namespace Editor {

    function buildReadonlys(prop: { [P: string]: Prop; }): { [P: string]: isReadonly } {
        return _.reduce(prop, (res, { handler: { isReadonly } }, key) => {
            if (isReadonly) {
                res[key] = isReadonly.bind(undefined, key)
            }

            return res;
        }, <{ [P: string]: isReadonly }>{})
    }

    export function isReadonly(obj: object): {
        handles: { [P: string]: isReadonly }
        handle(key: string): isReadonly
        is(key: string): boolean
    } {
        const bag = _FieldBag.Of(obj, false);
        return bag.isReadonly || (
            bag.isReadonly = {
                is(key: string): boolean {
                    const { prop } = _FieldBag.Of(obj, false);
                    const func = prop[key]?.handler?.isReadonly;
                    return func?.(key, obj);
                },
                handle(key: string): isReadonly {
                    return bag.isReadonly.handles[key];
                },
                get handles(): {
                    [P: string]: isReadonly
                } {
                    const bag = _FieldBag.Of(obj, false);
                    return (bag.readonlys || (
                        bag.readonlys = buildReadonlys(bag.prop)
                    ));
                }
            }
        )
    }

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.guidid): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.numberid): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.owned): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type.enum, any, number]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.owneds, Value.Type.enum, any]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.owned, settings: _Editor.Owned<TP, TPI, TKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type], settings: _Editor.Owned<TP, TPI, TKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type.enum, any, number], settings: _Editor.Owned<TP, TPI, TKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.owneds, Value.Type.enum, any], settings: _Editor.Owned<TP, TPI, TKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.owneds, settings: _Editor.Owneds<TP, TPI, TRefsKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.inherit): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.inherit, Value.Type]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: [Type.inherit, Value.Type.enum, any, number]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.refowned, settings: _Editor.RefOwned<TP, TPI, TKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.refowneds, settings: _Editor.RefOwneds<TP, TPI, TRefsKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI, TSrc
    >(cls: ClsType<TP>, type: Type.refinherit, settings: _Editor.RefInherit<TP, TPI, TKeys<TP, TPI>, TSrc>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI, TSrc
    >(cls: ClsType<TP>, type: Type.refinherits, settings: _Editor.RefInherits<TP, TPI, TRefsKeys<TP, TPI>, TSrc>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI
    >(cls: ClsType<TP>, type: Type.refparent, settings: _Editor.RefParent<TP, TPI, TKeys<TP, TPI>>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI, TSrc, TTree
    >(cls: ClsType<TP>, type: Type.refflattens, settings: _Editor.RefFlattens<TP, TPI, TRefsKeys<TP, TPI>, TSrc, TTree>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, TPI>, TPI, TSrc, TTree
    >(cls: ClsType<TP>, type: Type | [Type, Value.Type] | [Type, Value.Type.enum, any, number] | [Type.owneds, Value.Type.enum, any, []?], settings?: {}): PropertyDecorator {

        const valuetype = (isArray(type) ? type[1] : 0) as Value.Type;
        const editortype = isArray(type) ? type[0] : type;
        const enumtype = isArray(type) ? type[2] : undefined;
        const valuedef = isArray(type) ? type[3] : undefined;

        const numberid: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting } = this;
                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                return setting[key] = setting[key] ?? Unique.number() as any;
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                return numberid.prevalget.call(this, key);
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                return true;
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude } } = obj;
                if (exclude[key]) return;

                const { $saving_: saving, $eprops_: { $$context_: { createnew } } } = obj;
                if (saving && createnew) return;

                return _JSON.JsonOf(obj.$setting_[key]);
            }
        }

        const guidid: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting } = this;
                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                return setting[key] = setting[key] ?? Unique.uuid(this, '') as any;
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                return guidid.prevalget.call(this, key);
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                return true;
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude } } = obj;
                if (exclude[key]) return;

                const { $saving_: saving, $eprops_: { $$context_: { createnew } } } = obj;
                if (saving && createnew) return;

                return _JSON.JsonOf(obj.$setting_[key]);
            }
        }

        const owned: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting, $eprops_: { $$context_: context, $$saving_ } } = this;
                const reffer = settings as _Editor.Owned<TP, TPI, Key>;
                const getter = reffer?.getter?.call(this);
                const itemtype = reffer?.itemtype?.();
                const creator = reffer?.creator;

                if (getter) {
                    return setting[key] = getter();
                }

                if (owned.isReadonly(key, this) && !!(setting instanceof cls)) {
                    return setting[key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL object access exception, need further solution.
                    return undefined;
                }

                // check the raw config
                if (creator && (itemtype ? (
                    !(setting[key] instanceof itemtype)
                ) : (
                    isNull(setting[key]) || isUndefined(setting[key])
                ))) {
                    $$saving_.push();

                    // not yet create, create it.
                    const value = setting[key] = creator.call(this, setting[key]);

                    if (value instanceof Editor) {
                        context.membersowned = context.membersowned || {};
                        context.membersowned[key] = value;
                    }

                    $$saving_.pop();
                }

                return (setting[key] = Value.toValue(valuetype, setting[key], enumtype, valuedef)) as TP[Key];
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp, $setting_: setting } = this;
                const reffer = settings as _Editor.Owned<TP, TPI, Key>;
                const getter = reffer?.getter?.call(this);

                if (!(getter || (owned.isReadonly(key, this) && !!(setting instanceof cls))) && temp.hasOwnProperty(key)) {
                    return temp[key] = Value.toValue(valuetype, temp[key], enumtype, valuedef);
                }

                return owned.prevalget.call(this, key);
            },
            set<Key extends TKeys<TP, TPI>>(this: TP, key: Key, value: TP[Key]) {
                if (owned.isReadonly(key, this)) return;

                const { $saving_: saving, $temp_: temp, $setting_: setting, } = this;
                const reffer = settings as _Editor.Owned<TP, TPI, Key>;
                const itemtype = reffer?.itemtype?.();

                if (itemtype && !(value instanceof itemtype)) {
                    // reset the setting
                    setting[key] = value;
                    return;
                }

                if (saving) {
                    setting[key] = value;
                    return;
                }

                if (value == setting[key]) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;
                if (owned.isReadonly(key, obj) && !!(setting instanceof cls)) return;

                if (saving) {
                    $$saving_.push();
                    const v = _JSON.JsonOf(Value.fromValue(valuetype, obj[key] ?? undefined, enumtype, valuedef));
                    $$saving_.pop();
                    return v;
                }

                // not saving mode, seialize all.
                if (setting[key] == undefined) return;
                return _JSON.JsonOf(Value.fromValue(valuetype, setting[key], enumtype, valuedef));
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                const reffer = settings as _Editor.Owned<TP, TPI, Key>;
                if (reffer?.hasOwnProperty('isReadonly')) {
                    if (isFunction(reffer.isReadonly)) {
                        return reffer.isReadonly();
                    }

                    return reffer.isReadonly;
                }

                const { $setting_: setting } = obj;
                const getter = reffer?.getter?.call(obj);
                return !!(getter || setting instanceof cls);
            }
        }

        const owneds: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $eprops_: { $$context_: { memberscancel } } } = this;
                const iop = memberscancel[key];
                if (iop) return iop.preval;

                return owneds.get.call(this, key);
            },
            get<Key extends TRefsKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.Owneds<TP, TPI, Key>;
                const creator = reffer?.creator;

                if (props[key]) return props[key];

                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize the owneds array for next round trial.
                    //       may cause NULL array access exception, need further solution
                    return undefined;
                }

                $$saving_.push();

                const values: _Array.ItemType<TP[Key]>[] = (
                    props[key] = creator?.call(this) || []
                );

                if (enumtype && Array.isArray(setting[key])) {
                    setting[key].forEach(s => {
                        values.push(Value.toValue(valuetype, s, enumtype, valuedef))
                    })
                } else {
                    values.recreate(setting[key] || [] as any)
                }

                const ret: TP[Key] = buildOwneds(this, values, key, false) as any;
                return $$saving_.pop(), ret;
            },
            toJSON<Key extends TRefsKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const values = owneds.get.call(obj, key) as _Array.ItemType<TP[Key]>[];
                let v: undefined | _JSON.Type;
                if (values?.length > 0) {
                    $$saving_.push();
                    v = values.map(v => v && _JSON.JsonOf(
                        enumtype ? Value.fromValue(valuetype, v ?? undefined, enumtype, valuedef) : v
                    )).filter(v => v ?? true);

                    $$saving_.pop();
                }

                if (saving && !v) v = [];
                return v;
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const inherit: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $eprops_: eprops, $setting_: setting } = this;
                const inherits = eprops.$inherits_;

                if (!!(setting instanceof cls)) {
                    if (inherits?.hasOwnProperty(key)) props[key] = inherits[key], delete inherits[key];
                    return props[key] === undefined ? setting[key] : (props[key] = Value.toValue(valuetype, props[key], enumtype, valuedef));
                }

                return (setting[key] = Value.toValue(valuetype, setting[key], enumtype, valuedef)) as TP[Key];
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp } = this;

                if (temp.hasOwnProperty(key)) {
                    return temp[key] = Value.toValue(valuetype, temp[key], enumtype, valuedef);
                }

                return inherit.prevalget.call(this, key);
            },
            set<Key extends TKeys<TP, TPI>>(this: TP, key: Key, value: TP[Key]) {
                const { $saving_: saving, $temp_: temp, $props_: props, $eprops_: eprops, $setting_: setting } = this;
                const inherits = eprops.$inherits_;

                if (saving) {
                    if (!!(setting instanceof cls)) {
                        inherits && (delete inherits[key]);
                        props[key] = value;
                    } else {
                        setting[key] = value;
                    }

                    return;
                }

                if (value == (setting instanceof cls ? (props[key] === undefined ? setting[key] : props[key]) : setting[key])) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $props_: props, $setting_: setting, $saving_: saving } = obj;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                if (saving) {
                    $$saving_.push();
                    const v = _JSON.JsonOf(Value.fromValue(valuetype, obj[key] ?? undefined, enumtype, valuedef));
                    $$saving_.pop();
                    return v;
                }

                if (!!(setting instanceof cls)) {
                    // inherit object
                    const inherited = props.hasOwnProperty(key) && props[key] !== undefined;
                    return inherited ? _JSON.JsonOf(Value.fromValue(valuetype, props[key], enumtype, valuedef)) : undefined;
                }

                // primary object
                if (setting[key] == undefined) return;
                return _JSON.JsonOf(Value.fromValue(valuetype, setting[key], enumtype, valuedef));
            }
        }

        const refowned: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.RefOwned<TP, TPI, Key, TSrc>;
                const getter = reffer.getter?.call(this);
                const itemtype = reffer?.itemtype?.();

                if (getter) {
                    // specified getter and just return the result
                    const v = getter();
                    if (v) {
                        return setting[key] = v;
                    }
                }

                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                if (!!(itemtype && setting[key] instanceof itemtype)) {
                    _Editor.$destroy_refowned<TP, TPI, Key>(this, key, setting[key] as TP[Key]);
                    return setting[key] as TP[Key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    return undefined;
                }

                $$saving_.push();
                const id = setting[key]?.['id'];

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                const sources: ReturnType<_Editor.RefOwned<TP, TPI, Key>['source']> = id && reffer.source.call(this);

                if (!sources?.firstOf) {
                    return $$saving_.pop(), undefined;
                }

                const value = sources.firstOf({ id });
                _Editor.$destroy_refowned<TP, TPI, Key>(this, key, value);
                return $$saving_.pop(), setting[key] = value;
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp } = this;

                if (temp.hasOwnProperty(key)) {
                    return temp[key];
                }

                return refowned.prevalget.call(this, key);
            },
            set<Key extends TKeys<TP, TPI>>(this: TP, key: Key, value: TP[Key]) {
                if (refowned.isReadonly(key, this)) return;

                const { $saving_: saving, $temp_: temp, $setting_: setting } = this;
                if (saving) {
                    _Editor.$destroy_refowned<TP, TPI, Key>(this, key, value);
                    setting[key] = value;
                    return;
                }

                if (value == setting[key]) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                if (saving) {
                    return { id: _JSON.JsonOf(obj[key]?.['id'] ?? undefined) };
                }

                if (setting[key]?.['id'] == undefined) return;
                return { id: _JSON.JsonOf(setting[key]?.['id']) };
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                const reffer = settings as _Editor.RefOwned<TP, TPI, Key, TSrc>;
                const getter = reffer.getter?.call(obj);
                const { $setting_: setting } = obj;

                return !!(getter || setting instanceof cls);
            }
        }

        const refowneds: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $eprops_: { $$context_: { memberscancel } } } = this;
                const iop = memberscancel[key];
                if (iop) return iop.preval;

                return refowneds.get.call(this, key);
            },
            get<Key extends TRefsKeys<TP, TPI>>(this: TP, key: Key): _Array.ItemType<TP[Key]>[] {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.RefOwneds<TP, TPI, Key, TSrc>;

                if (props[key]) return props[key] as any;

                if (!!(setting instanceof cls)) {
                    return setting[key] as any;
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL array access exception, need further solution.
                    return undefined;
                }

                $$saving_.push();

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       may cause NULL array access exception, need further solution.
                const sources: ReturnType<_Editor.RefOwneds<TP, TPI, Key>['source']> = reffer.source?.call(this);
                if (!sources?.firstOf) {
                    return $$saving_.pop(), undefined;
                }

                const values: _Array.ItemType<TP[Key]>[] = (
                    props[key] = reffer.creator.call(this)
                );

                values.reset(((setting[key] || []) as Array<_Array.ItemType<TP[Key]>>).map(
                    item => item && sources.firstOf({ id: item['id'] })
                ).filter(item => !!item))

                const ret = buildOwneds(this, values, key, true);
                return $$saving_.pop(), ret;
            },
            toJSON<Key extends TRefsKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const values = owneds.get.call(obj, key) as _Array.ItemType<TP[Key]>[];
                let v: undefined | _JSON.Type;
                if (values?.length > 0) {
                    v = values.map(v => v && ({ id: _JSON.JsonOf(v['id']) })).filter(v => !!v);
                }

                if (saving && !v) v = [];
                return v;
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const refparent: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.RefParent<TP, TPI, Key>;
                const itemtype = reffer?.itemtype?.();

                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                if (!!(itemtype && setting[key] instanceof itemtype)) {
                    _Editor.$destroy_refparent(this, key, setting[key] as TP[Key], reffer);
                    return setting[key] as TP[Key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    return undefined;
                }

                $$saving_.push();
                const id = setting[key]?.['id'];

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                const sources: ReturnType<_Editor.RefParent<TP, TPI, Key>['source']> = id && reffer.source.call(this);

                if (!sources?.firstOf) {
                    return $$saving_.pop(), undefined;
                }

                const value = sources.firstOf({ id });
                _Editor.$destroy_refparent(this, key, value, reffer);

                return $$saving_.pop(), setting[key] = value;
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp, } = this;

                if (temp.hasOwnProperty(key)) {
                    return temp[key];
                }

                return refparent.prevalget.call(this, key);
            },
            set<Key extends TKeys<TP, TPI>>(this: TP, key: Key, value: TP[Key]) {
                if (refparent.isReadonly(key, this)) return;

                const { $saving_: saving, $temp_: temp, $setting_: setting } = this;
                const reffer = settings as _Editor.RefParent<TP, TPI, Key>;
                value = value || reffer?.root?.call(this);

                if (saving) {
                    _Editor.$destroy_refparent(this, key, value, reffer);
                    setting[key] = value;
                    return;
                }

                if (value == setting[key]) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const reffer = settings as _Editor.RefParent<TP, TPI, Key>;
                const root = reffer?.root?.call(obj);

                if (saving) {
                    return {
                        id: _JSON.JsonOf(
                            ((root != obj[key]) ? obj[key]?.['id'] : undefined) ?? undefined
                        )
                    };
                }

                if (setting[key]?.['id'] == undefined) {
                    return;
                }

                return {
                    id: _JSON.JsonOf(
                        ((root != setting[key]) ? setting[key]?.['id'] : undefined) ?? undefined
                    )
                };
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const refinherit: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.RefInherit<TP, TPI, Key, TP[Key]>;

                if (props[key] !== undefined) return props[key];

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    return undefined;
                }

                $$saving_.push();

                let source: TP[Key];
                if (!!(setting instanceof cls)) {
                    source = setting[key];
                } else {
                    const id = setting[key]?.["id"];

                    // TODO: invalid sources may encounter for it's setting load failed from network liked.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    const sources: ReturnType<_Editor.RefInherit<TP, TPI, Key>['source']> = id && reffer.source.call(this);

                    if (!sources?.firstOf) {
                        return $$saving_.pop(), undefined;
                    }

                    const prop = { ...(setting[key] || {}) }; delete prop['id'];
                    const has = Object.keys(prop).length > 0;

                    source = sources.firstOf({ id });
                    source && has && (source[InheritKey] = prop);
                }

                _Editor.$destroy_refinherit(this, key, source, reffer);
                props[key] = (source && reffer.creator.call(this, source)) ?? undefined;

                return $$saving_.pop(), props[key];
            },
            get<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp, } = this;
                if (temp.hasOwnProperty(key)) return temp[key];
                return refinherit.prevalget.call(this, key);
            },
            set<Key extends TKeys<TP, TPI>>(this: TP, key: Key, value: TP[Key]) {
                const { $saving_: saving, $temp_: temp, $props_: props, $eprops_: { $$context_: context } } = this;
                const reffer = settings as _Editor.RefInherit<TP, TPI, Key, TP[Key]>;

                if (saving) {
                    if (props[key] != value) {
                        _Editor.$destroy_refinherit(this, key, value?.['$base_'], reffer);
                        props[key] = value;
                    }

                    return;
                }

                if (value == props[key]?.['$base_']) {
                    this.$cancel_(key);
                    return;
                }

                if (temp.hasOwnProperty(key) && value == temp[key]?.['$base_']) {
                    return;
                }

                _Editor.$destroy_(temp[key]);
                context.memberscancel = context.memberscancel || {};
                temp[key] = value && reffer.creator.call(this, value);
                context.memberscancel[key] = _Editor.$destroy_cancel(temp[key]);
            },
            toJSON<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $props_: props, $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                if (saving) {
                    $$saving_.push();
                    const v = _JSON.JsonOf(obj[key], { id: _JSON.JsonOf(obj[key]?.['id'] ?? undefined) });
                    $$saving_.pop();
                    return v;
                }

                if (props[key]?.["id"] == undefined) return;
                return _JSON.JsonOf(props[key], { id: _JSON.JsonOf(props[key]?.['id']) });
            }
        }

        const refinherits: _Editor.IHandle<TP, TPI> = {
            prevalget<Key extends TKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $eprops_: { $$context_: { memberscancel } } } = this;
                const iop = memberscancel[key];
                if (iop) return iop.preval;

                return refinherits.get.call(this, key);
            },
            get<Key extends TRefsKeys<TP, TPI>>(this: TP, key: Key): _Array.ItemType<TP[Key]>[] {
                const { $props_: props, $eprops_: eprops, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.RefInherits<TP, TPI, Key, TSrc>;

                if (props[key]) return props[key] as any;

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL array access exception, need further solution.
                    return undefined;
                }

                $$saving_.push();

                const reffield: _Array.ItemType<TP[Key]>[] = (setting instanceof cls) && setting[key] as any;
                const values = (props[key] = reffer.creator.call(this));
                const inherits = eprops.$inherits_;

                if (!!(setting instanceof cls)) {
                    const ivals: _Array.ItemType<TP[Key]>[] = inherits?.[key] as any;
                    inherits && (delete inherits[key]);

                    values.create(!ivals ? reffield : reffield.map(source => {
                        const prop = ivals.find(v => v['id'] == source?.['id']);
                        return source && prop && (source[InheritKey] = prop), source;
                    }));
                } else {
                    // TODO: invalid sources may encounter for it's setting load failed from network liked.
                    //       may cause NULL array access exception, need further solution.
                    const sources: ReturnType<_Editor.RefInherits<TP, TPI, Key>['source']> = reffer.source.call(this);
                    if (!sources?.firstOf) {
                        return delete props[key], $$saving_.pop(), undefined;
                    }

                    values.create(((setting[key] || []) as _Array.ItemType<TP[Key]>[]).map(item => {
                        const source = item && sources.firstOf({ id: item['id'] });
                        const prop = { ...(item || {}) }; delete prop['id'];
                        const has = Object.keys(prop).length > 0;

                        return source && has && (source[InheritKey] = prop), source;
                    }).filter(item => !!item));
                }

                const ret = buildRefInherits(this, values, key, reffield);
                return $$saving_.pop(), ret;
            },
            toJSON<Key extends TRefsKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const values = refinherits.get.call(obj, key) as _Array.ItemType<TP[Key]>[];
                let v: undefined | _JSON.Type;
                if (values?.length > 0) {
                    $$saving_.push();

                    const subs = values.reduce((r, v) => {
                        const sub = v && _JSON.JsonOf(v, {});
                        const valid = sub && (!(obj.$setting_ instanceof cls) || Object.keys(sub).length > 1);
                        return valid && r.push(sub), r;
                    }, []);
                    subs && subs.length > 0 && (v = subs);

                    $$saving_.pop();
                }

                if (saving && !v) v = [];
                return v;
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const refflattens: _Editor.IHandle<TP, TPI> = {
            get<Key extends TRefsKeys<TP, TPI>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = settings as _Editor.RefFlattens<TP, TPI, Key, TSrc, TTree>;

                if (props[key]) return props[key];

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL array access exception, need further solution.
                    return undefined;
                }

                // first-time, recursive to access the tree to flatten
                $$saving_.push();

                // init the flatten array to try.
                const items: _Array.ItemType<TP[Key]>[] = (props[key] = [] as any);

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       may cause NULL array access exception, need further solution.
                const source = reffer.children.call(this, this);

                if (!source) {
                    // no children found, reset flatten arry for next round initialization
                    return delete props[key], $$saving_.pop(), undefined;
                }

                // recursively flatten each.
                const fetch = (items: TTree[]) => {
                    items?.forEach(p => fetch(reffer.children.call(this, p)));
                }

                fetch(source);

                // build the create/delete react chain
                buildFlatten(this, items, key);

                $$saving_.pop();

                return props[key];
            },
            toJSON<Key extends TRefsKeys<TP, TPI>>(key: Key, obj: TP): undefined | _JSON.Type {
                return;
            },
            isReadonly<Key extends TKeys<TP, TPI>>(key: Key, obj: TP): boolean {
                return true;
            }
        }

        function buildOwneds<Key extends TRefsKeys<TP, TPI>>(host: TP, source: Array<_Array.ItemType<TP[Key]>>, key: Key, asref: boolean): _Array.ItemType<TP[Key]>[] {
            const { $eprops_: { $$context_: context, $$destroies_: destroies, $$saving_ } } = host;

            _Editor.$destroy_owneds<TP, TPI, Key>(host, key, { added: source });
            context.membersowned = context.membersowned || {};
            context.membersowned[key] = source;

            destroies.push(_Editor.$destroy_subscription(source.onAddRemoved.subscribe(({ added, removed }: {
                removed: _Array.ItemType<TP[Key]>[];
                added: _Array.ItemType<TP[Key]>[];
            }) => {
                _Editor.$destroy_owneds<TP, TPI, Key>(host, key, { added, removed });
            })))

            destroies.push(_Editor.$destroy_subscription(source.onPreAddRemove.subscribe((current: _Array.ItemType<TP[Key]>[]) => {
                // in case of first time to change, record it for canceling.
                if ($$saving_.level <= 0) {
                    const memberscancel: IMembersCancel<TP, TPI> = (context.memberscancel = context.memberscancel || {});

                    !memberscancel[key] && (
                        console.assert(memberscancel[key] == undefined),

                        memberscancel[key] = _Editor.owneds_cancel(
                            source, current.slice()
                        )
                    );
                }
            })))

            const { destroy } = source;
            !asref && Object.defineProperty(source, 'destroy', {
                enumerable: true, configurable: true,
                value: _Editor.array_destroy<TP, TPI>(host, key, destroy, function (this: _Array.ItemType<TP[Key]>[], vals: _Array.ItemType<TP[Key]>[]): void {
                    vals.forEach(v => {
                        _Editor.$destroy_(v);
                    });
                })
            })

            return source;
        }

        function buildRefInherits<Key extends TRefsKeys<TP, TPI>>(host: TP, source: Array<_Array.ItemType<TP[Key]>>, key: Key, reffield: _Array.ItemType<TP[Key]>[]): Array<_Array.ItemType<TP[Key]>> {
            const { $eprops_: { $$context_: context, $$destroies_: destroies, $$saving_ } } = host;

            _Editor.$destroy_refinherits<TP, TPI, Key>(host, key, { added: source });

            reffield && destroies.push(_Editor.$destroy_subscription(reffield.onAddRemoved.subscribe(({ added, removed }: {
                removed: _Array.ItemType<TP[Key]>[];
                added: _Array.ItemType<TP[Key]>[];
            }) => {
                $$saving_.push();

                removed && source.destroy(removed.reduce((res, r) => {
                    const f = source.find(s => s?.['$base_'] == r);
                    return f && res.push(f), res;
                }, []));

                added && source.create(added);

                $$saving_.pop();
            })))

            destroies.push(_Editor.$destroy_subscription(source.onAddRemoved.subscribe(({ added, removed }: {
                removed: _Array.ItemType<TP[Key]>[];
                added: _Array.ItemType<TP[Key]>[];
            }) => {
                _Editor.$destroy_refinherits<TP, TPI, Key>(host, key, { added, removed });
            })))

            destroies.push(_Editor.$destroy_subscription(source.onPreCreateDestroied.subscribe((current: _Array.ItemType<TP[Key]>[]) => {
                // in case of first time to change, record it for canceling.
                if ($$saving_.level <= 0) {
                    const memberscancel: IMembersCancel<TP, TPI> = (context.memberscancel = context.memberscancel || {});
                    !memberscancel[key] && (
                        console.assert(memberscancel[key] == undefined),
                        memberscancel[key] = _Editor.refinherits_cancel(
                            source, current.slice()
                        )
                    );
                }
            })))

            const { destroy } = source;
            Object.defineProperty(source, 'destroy', {
                enumerable: true, configurable: true,
                value: _Editor.array_destroy<TP, TPI>(host, key, destroy, function (this: _Array.ItemType<TP[Key]>[], vals: _Array.ItemType<TP[Key]>[]): void {
                    vals.forEach(v => {
                        _Editor.$destroy_(v);
                    });
                })
            })

            return source;
        }

        function buildFlatten<Key extends TRefsKeys<TP, TPI>>(host: TP, arr: _Array.ItemType<TP[Key]>[], key: Key): _Array.ItemType<TP[Key]>[] {
            const reffer = settings as _Editor.RefFlattens<TP, TPI, Key, TSrc, TTree>;
            const { createnew, destroy } = arr;

            Object.defineProperty(arr, 'createnew', {
                enumerable: true, configurable: true,
                value: function (this: _Array.ItemType<TP[Key]>[], val: TSrc, at?: number): _Array.ItemType<TP[Key]> {
                    if (!reffer.creator) return undefined;

                    const tval = reffer.creator.call(host, val);
                    const newone = createnew.call(this, tval, at);
                    newone instanceof Editor && (
                        newone.$eprops_.$$context_.createnew = _Editor.refcreate_cancel(
                            this, newone as _Array.ItemType<TP[Key]>
                        )
                    );

                    return newone;
                }
            })

            Object.defineProperty(arr, 'destroy', {
                enumerable: true, configurable: true,
                value: _Editor.array_destroy<TP, TPI>(host, key, destroy, function (this: _Array.ItemType<TP[Key]>[], vals: _Array.ItemType<TP[Key]>[]): void {
                    vals.forEach(v => {
                        _Editor.$destroy_(v);
                        reffer.container?.call(this, v)?.destroy(v)
                    });
                })
            })

            return arr;
        }

        const handles = {
            [Type.guidid]: guidid,
            [Type.numberid]: numberid,
            [Type.owned]: owned,
            [Type.owneds]: owneds,
            [Type.inherit]: inherit,
            [Type.refowned]: refowned,
            [Type.refowneds]: refowneds,
            [Type.refinherit]: refinherit,
            [Type.refinherits]: refinherits,
            [Type.refparent]: refparent,
            [Type.refflattens]: refflattens,
        };

        return function (prototype: Object, fieldname: string): void {
            const handler: _Editor.IHandle<TP, TPI> = handles[editortype];
            const jsonhandles = _JSON.HandlesOf(prototype, true).handles;
            const bag = _FieldBag.Of(prototype, true);
            const desc: PropertyDescriptor = {};

            (bag.prop = bag.prop || {})[fieldname] = {
                type: editortype, settings, handler
            }

            if (handler.toJSON) {
                jsonhandles[fieldname] = (
                    (obj: TP): undefined | _JSON.Type => (
                        handler.toJSON(fieldname as TRefsKeys<TP, TPI>, obj)
                    )
                )
            }

            if (handler.get) {
                desc.get = function (this: TP): any {
                    const val = handler.get.call(this, fieldname);
                    return this.$onvalue_?.(fieldname, val, false), val;
                }
            }

            if (handler.set) {
                desc.set = function (this: TP, value: any): void {
                    handler.set.call(this, fieldname, value);

                    handler.get && this.$onvalue_?.(
                        fieldname, handler.get.call(
                            this, fieldname
                        ), !this.$saving_
                    );
                }
            }

            Object.defineProperty(prototype, fieldname, {
                configurable: true,
                enumerable: true,
                ...desc,
            })
        }
    }
}

export namespace Editor {
    export interface IEnum extends Array<{ key: string, value: number | string, title?: string }> {
        indexed: {
            [P: string]: {
                key: string, value: number | string, title?: string
            }
        } & {
            [P: number]: {
                key: string, value: number | string, title?: string
            }
        }
    }

    export interface IArrayIndexed<T> extends Array<T> {
        indexed?: {
            [P: string]: T
        } & {
            [P: number]: T
        }
    }

    export interface IField {
        source?: string | string[] | IEnum | ComponentType<any> | TemplateRef<any>,
        invisible?: boolean | ((app: AppService, obj: Editor<any>) => boolean),
        readonly?: boolean | ((app: AppService, obj: Editor<any>) => boolean),
        dependfield?: IField,
        dependvalues?: any[],
        must?: boolean,
        type?: Value.Type,
        title?: string | ((app: AppService, obj: Editor<any>) => string),
        children?: string, // to indicate the children field name incase of type is a tree
        key?: string
    }

    export interface IFields extends IArrayIndexed<IField> {

    }

    export interface IFieldCol extends IField {
        cellcls?: string;
        col?: number;
    }

    export interface IFieldCols extends IArrayIndexed<IFieldCol> {

    }

    export namespace Value {
        export enum Type {
            catmask = 0xFFFF0000,
            typemask = 0x0000FFFF,

            onlycolumn = 0x00010000,
            notcolumn = 0x00020000,
            select = 0x00040000,
            added = 0x00080000,
            multi = 0x00100000,
            details = 0x00200000,
            children = 0x00300000,

            pic = 1,
            bool = 2,       //  done: govform, govtable
            file = 3,
            date = 4,       //  done: govform, govtable
            text = 5,       //  done: govform, govtable
            number = 7,     //  done: govform, govtable
            enum = 8,
            json = 9,       //  done: govform, govtable
            percent = 10,   //  TBD: govform, govtable
            datenow = 11,   //  done: govform, govtable
            texts = 12,     //  done: govform

            email = 21,     //  done: govform
            phone = 22,     //  done: govform
            password = 23,  //  done: govform

            list = 41,
            tree = 42,
            option = 43,     //  done: govform, govtable

            command = 100,   //  done: govform
            problem = 101,   //  done: govform
            richtext = 102,

            label = 103,
            editor = 104,
            hint = 105,

            pics = 1048577,             //  multi | pic,     //  done: govform
            files = 1048579,            //  multi | file,    //  done: govform
            options = 1048619,          //  multi | option,  //  done: govform
            problems = 1048677,         //  multi | problem, //  done: govform
            rowselect = 851968,         //  select | added | onlycolumn,
            enums = 1048584             //  multi | enum,
        }

        export function isFieldType(header: IField | Type, type: (keyof typeof Type) | Type): boolean {
            const hdtype = isObject(header) ? header.type : header;
            if (hdtype === null || hdtype === undefined) return false;

            const typvalue: number = isString(type) ? Type[type] : type;
            const typcat = Type.catmask & typvalue, typval = Type.typemask & typvalue;
            const hdcat = Type.catmask & hdtype, hdval = Type.typemask & hdtype;
            if (typval == 0) return ((typcat & hdcat) == typcat);
            if (typcat == 0) return typval == hdval;

            return ((typcat & hdcat) == typcat) && (typval == hdval);
        }

        export function getFieldType(header: IField | Type, catmask?: string | number): number {
            const hdtype = isObject(header) ? header.type : header;
            if (hdtype === null || hdtype === undefined) return 0;

            catmask = catmask ?? 0;
            const mask: number = (isString(catmask) ? Type[catmask] : catmask) & Type.catmask;
            return (hdtype & Type.typemask) | (hdtype & mask);
        }

        const toValueHandles = {
            [Value.Type.bool]: {
                handle: toBool,
                type: Boolean
            },
            [Value.Type.date]: {
                handle: toDate,
                type: Date
            },
            [Value.Type.text]: {
                handle: toText,
                type: String
            },
            [Value.Type.number]: {
                handle: toNumber,
                type: Number
            },
            [Value.Type.json]: {
                handle: toJSON,
                type: JSON
            },
            [Value.Type.enum]: {
                handle: toEnum,
                type: Number
            }
        }

        const fromValueHandles = {
            [Value.Type.enum]: {
                handle: fromEnum,
                type: String
            }
        }

        function isNullUndefined(val: any): val is undefined | null {
            return isNull(val) || isUndefined(val);
        }

        export function toBool(val: any): boolean {
            return isBoolean(val) ? val : (!isNullUndefined(val) ? Boolean(val) : !!val);
        }

        export function toDate(val: string | Date | Moment): Date {
            return val instanceof Date ? val : (!isNullUndefined(val) ? (
                isString(val) || isNumber(val) ? new Date(val) : val?.toDate()
            ) : val);
        }

        export function toText(val: any): string {
            return isString(val) ? val : (!isNullUndefined(val) ? String(val) : val);
        }

        export function toNumber(val: any): number {
            return isNumber(val) ? val : (!isNullUndefined(val) ? Number(val) : val);
        }

        export function toJSON(val: any): JSON | string {
            if (!isString(val)) return val;

            try {
                return JSON.parse(val);
            } catch (e) { }

            return val;
        }

        export function toEnum(val: string | number, aux: IEnum, valdef?: any): number {
            return isString(val) ? (
                (
                    isObject(aux?.indexed?.[val]) ?
                        aux?.indexed?.[val].value :
                        aux?.[val]
                ) ?? valdef
            ) : val ?? valdef;
        }

        export function fromEnum(val: string | number, aux: IEnum, valdef?: any): string {
            return isNumber((val = val ?? valdef)) ? (
                isObject(aux?.indexed?.[val]) ?
                    aux?.indexed?.[val].key :
                    aux?.[val] as any
            ) : val;
        }

        export function toValue(type: Value.Type, val: any, aux?: any, valdef?: any): any {
            const handle = toValueHandles[getFieldType(type)];

            if (handle?.handle) {
                return handle.handle(val, aux, valdef);
            }

            return val;
        }

        export function fromValue(type: Value.Type, val: any, aux?: any, valdef?: any): any {
            const handle = fromValueHandles[getFieldType(type)];

            if (handle?.handle) {
                return handle.handle(val, aux, valdef);
            }

            return val;
        }
    }
}

export namespace Editor {
    export enum Type {
        catmask = 0xFFFF0000,
        typemask = 0x0000FFFF,

        ref = 0x00010000,
        refs = 0x00020000,

        guidid = 0x00000001,
        numberid = 0x00000002,
        owned = 0x00000003,
        owneds = 0x00000004,
        inherit = 0x00000005,
        inherits = 0x00000006,  // not yet used
        parent = 0x00000007,    // not yet used
        flattens = 0x00000008,   // not yet used

        refowned = 0x00010003,
        refowneds = 0x00020004,
        refinherit = 0x00010005,
        refinherits = 0x00020006,
        refparent = 0x00010007,
        refflattens = 0x00020008,
    }

    export function Owned<TP extends Editor<TP, TPI>, TPI, Key extends TKeys<TP, TPI>, OI = any>(
        creator: _Editor.Owned<TP, TPI, Key, OI>['creator'], itemtype: undefined | (() => ClsType<TP[Key]>), key: Key, getter?: (this: TP) => (() => TP[Key])
    ): _Editor.Owned<TP, TPI, Key, OI> {
        return { creator, itemtype, key, getter };
    }

    export function Owneds<TP extends Editor<TP, TPI>, TPI, Key extends TRefsKeys<TP, TPI>>(
        creator: _Editor.Owneds<TP, TPI, Key>['creator'], key: Key
    ): _Editor.Owneds<TP, TPI, Key> {
        return { creator, key }
    }

    export function RefOwned<TP extends Editor<TP, TPI>, TPI, Key extends TKeys<TP, TPI>, TSrc = TP[Key]>(
        source: _Editor.RefOwned<TP, TPI, Key, TSrc>['source'], itemtype: () => ClsType<TP[Key]>, key: Key, getter?: (this: TP) => (() => TP[Key])
    ): _Editor.RefOwned<TP, TPI, Key, TSrc> {
        return { source, itemtype, key, getter };
    }

    export function RefOwneds<TP extends Editor<TP, TPI>, TPI, Key extends TRefsKeys<TP, TPI>, TSrc>(
        source: _Editor.RefOwneds<TP, TPI, Key, TSrc>['source'], creator: _Editor.RefOwneds<TP, TPI, Key, TSrc>['creator'], key: Key
    ): _Editor.RefOwneds<TP, TPI, Key, TSrc> {
        return { source, creator, key }
    }

    export function RefInherit<TP extends Editor<TP, TPI>, TPI, Key extends TKeys<TP, TPI>, TSrc = TP[Key]>(
        source: _Editor.RefInherit<TP, TPI, Key, TSrc>['source'], creator: _Editor.RefInherit<TP, TPI, Key, TSrc>['creator'], key: Key
    ): _Editor.RefInherit<TP, TPI, Key, TSrc> {
        return { creator, source, key };
    }

    export function RefInherits<TP extends Editor<TP, TPI>, TPI, Key extends TRefsKeys<TP, TPI>, TSrc = _Array.ItemType<TP[Key]>>(
        source: _Editor.RefInherits<TP, TPI, Key, TSrc>['source'], creator: _Editor.RefInherits<TP, TPI, Key, TSrc>['creator'], key: Key
    ): _Editor.RefInherits<TP, TPI, Key, TSrc> {
        return { creator, source, key };
    }

    export function RefParent<TP extends Editor<TP, TPI>, TPI, Key extends TKeys<TP, TPI>>(
        source: _Editor.RefParent<TP, TPI, Key>['source'], children: _Editor.RefParent<TP, TPI, Key>['children'], itemtype: () => ClsType<TP[Key]>, key: Key, root?: (this: TP) => TP[Key]
    ): _Editor.RefParent<TP, TPI, Key> {
        return { source, children, itemtype, key, root }
    }

    export function RefFlattens<TP extends Editor<TP, TPI>, TPI, Key extends TRefsKeys<TP, TPI>, TTree>(
        children: _Editor.RefFlattens<TP, TPI, Key, never, TTree>['children'], key: Key
    ): _Editor.RefFlattens<TP, TPI, Key, never, TTree>;
    export function RefFlattens<TP extends Editor<TP, TPI>, TPI, Key extends TRefsKeys<TP, TPI>, TSrc, TTree>(
        creator: _Editor.RefFlattens<TP, TPI, Key, TSrc, TTree>['creator'], container: _Editor.RefFlattens<TP, TPI, Key, TSrc, TTree>['container'], children: _Editor.RefFlattens<TP, TPI, Key, TSrc, TTree>['children'], key: Key
    ): _Editor.RefFlattens<TP, TPI, Key, TSrc, TTree>;
    export function RefFlattens() {
        const creator = arguments[arguments.length == 2 ? arguments.length : 0];
        const container = arguments[arguments.length == 2 ? arguments.length : 1];
        const children = arguments[arguments.length == 2 ? 0 : 2];
        const key = arguments[arguments.length == 2 ? 1 : 3];
        return { creator, container, children, key }
    }
}

export namespace Editor {
    export type TBase<T extends Editor<T, TI>, TI> = {
        [P in Exclude<keyof T, _Editor.EKeys<T, TI>>]: T[P]
    }

    export type TKeys<T extends Editor<T, TI>, TI> = (
        keyof TBase<T, TI>
    );

    export type TProp<T extends Editor<T, TI>, TI> = (
        Partial<Unreadonly<TBase<T, TI>>>
    )

    export type TRefsKeys<T extends Editor<T, TI>, TI> = (
        {
            [Key in TKeys<T, TI>]: T[Key] extends Collection<any, any, any> ? Key : (T[Key] extends Array<any> ? Key : never)
        }[TKeys<T, TI>]
    )

    export type TEntity = {
        cls?: ClsType<any>;
        refetch?: boolean;
        loaded?: boolean;
        section?: string;
        entity?: string;
        payload?: any;
    }

    export type TSettingHandler = {
        $mayfail?(key?: string | number | symbol): boolean;
        $create?(val: any, entity: TEntity): boolean | Observable<any>;
        $update?(val: any, entity: TEntity): boolean | Observable<any>;
        $delete?(val: any, entity: TEntity): boolean | Observable<any>;
        $entity?(val: any, property: string | number | symbol): TEntity;
        $entity?(val: any): TEntity
    }

    export type TSetting<T extends Editor<T, TI>, TI> = (
        TProp<T, TI> & TSettingHandler
    )
}

namespace _Editor {
    export type EBase<T extends Editor.Editor<T, TI>, TI> = (
        Editor.Editor<T, TI>
    )

    export type EKeys<T extends Editor.Editor<T, TI>, TI> = (
        keyof EBase<T, TI>
    )

    export type EProp<T extends Editor.Editor<T, TI>, TI> = (
        Partial<Unreadonly<EBase<T, TI> & {
            $inherits_?: Editor.TProp<T, TI>,
            $$destroies_: IDestroies<T, TI>,
            $$context_: IContext<T, TI>,
            $$saving_: {
                readonly level: number,
                push(): void,
                pop(): void,
            }
        }>>
    )
}

namespace _Editor {
    export interface IHandle<T extends Editor.Editor<T, TI>, TI> {
        isReadonly?<Key extends Editor.TKeys<T, TI>>(key: Key, obj: T): boolean;
        isReadonly?<Key extends Editor.TRefsKeys<T, TI>>(key: Key, obj: T): boolean;

        toJSON?<Key extends Editor.TRefsKeys<T, TI>>(key: Key, obj: T): undefined | _JSON.Type;
        toJSON?<Key extends Editor.TKeys<T, TI>>(key: Key, obj: T): undefined | _JSON.Type;

        set?<Key extends Editor.TKeys<T, TI>>(this: T, key: Key, value: T[Key]): void;

        prevalget?<Key extends Editor.TRefsKeys<T, TI>>(this: T, key: Key): T[Key];
        prevalget?<Key extends Editor.TKeys<T, TI>>(this: T, key: Key): T[Key];

        get?<Key extends Editor.TRefsKeys<T, TI>>(this: T, key: Key): T[Key];
        get?<Key extends Editor.TKeys<T, TI>>(this: T, key: Key): T[Key];
    }

    export interface Owned<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TKeys<TP, TPI>, OI = any> {
        creator?: (this: TP, item: OI) => TP[Key],
        isReadonly?: boolean | (() => boolean),
        getter?: (this: TP) => (() => TP[Key]),
        itemtype?: () => Type<TP[Key]>,
        key?: Key
    }

    export interface Owneds<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TRefsKeys<TP, TPI>> {
        creator?: (this: TP) => TP[Key],
        key?: Key
    }

    export interface RefOwned<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TKeys<TP, TPI>, TSrc = TP[Key]> {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc },
        getter?: (this: TP) => (() => TP[Key]),
        itemtype?: () => Type<TP[Key]>,
        key?: Key,
    }

    export interface RefOwneds<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TRefsKeys<TP, TPI>, TSrc = _Array.ItemType<TP[Key]>> {
        source?: undefined | null | ((this: TP) => { firstOf(by: { id: any }): TSrc }),
        creator?: (this: TP) => TP[Key],
        key?: Key
    }

    export interface RefInherit<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TKeys<TP, TPI>, TSrc = TP[Key]> {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc },
        creator?: (this: TP, item: TSrc) => TP[Key],
        key?: Key
    }

    export interface RefInherits<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TRefsKeys<TP, TPI>, TSrc = _Array.ItemType<TP[Key]>> {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc },
        creator?: (this: TP) => TP[Key],
        key?: Key
    }

    export interface RefParent<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TKeys<TP, TPI>> {
        source?: (this: TP) => { firstOf(by: { id: any }): TP[Key] },
        children?: (this: TP, parent: TP[Key]) => TP[],
        itemtype?: () => Type<TP[Key]>,
        root?: (this: TP) => TP[Key],
        key?: Key
    }

    export interface RefFlattens<TP extends Editor.Editor<TP, TPI>, TPI, Key extends Editor.TRefsKeys<TP, TPI>, TSrc = _Array.ItemType<TP[Key]>, TTree = _Array.ItemType<TP[Key]>> {
        container?: undefined | null | ((this: TP, obj: _Array.ItemType<TP[Key]>) => _Array.ItemType<TP[Key]>[]),
        creator?: undefined | null | ((this: TP, item: TSrc) => _Array.ItemType<TP[Key]>),
        children?: (this: TP, obj: TP | TTree) => TTree[],
        key?: Key
    }
}

namespace _Editor {
    export function array_destroy<
        T extends Editor.Editor<T, TI>, TI, Key extends string | number | symbol = Editor.TRefsKeys<T, TI>
    >(host: T, property: Key, destroy: (val: any) => any, proc?: (this: any, vals: any[]) => void) {
        const { $eprops_: { $$saving_ } } = host;

        return function <Key extends Editor.TRefsKeys<T, TI>>(
            this: _Array.ItemType<T[Key]>[],
            val: _Array.ItemType<T[Key]> | _Array.ItemType<T[Key]>[]
        ): _Array.ItemType<T[Key]> | _Array.ItemType<T[Key]>[] | Observable<_Array.ItemType<T[Key]> | _Array.ItemType<T[Key]>[]> {

            const vals = castArray<_Array.ItemType<T[Key]>>(val);

            if ($$saving_.level <= 1) {
                const entity = Editor.detect.cud.$entity(host, property);
                let rets = entity ? Editor.detect.cud.$delete(vals.map(
                    v => v?.["id"]).filter(v => !!v
                    ), entity) : true;

                if (rets instanceof Observable) {
                    $$saving_.push();

                    const sub = (rets = rets.pipe(finalize(() => {
                        sub?.unsubscribe();
                    }))).subscribe((value: any): void => {
                        proc?.call(this, vals);
                        const _val = destroy.call(this, val);
                        $$saving_.pop();
                        return _val;
                    }, (error: any): void => {
                        $$saving_.pop();
                    })

                    return rets;
                }
            }

            proc?.call(this, vals);
            const _val = destroy.call(this, val);
            return _val;

        }

    }
}

namespace _Editor {

    export function ownedchanged(owneds: Editor.Editor<any> | Array<Editor.Editor<any>>): boolean {
        return (isArray(owneds) ? owneds : [owneds]).some(o => o instanceof Editor.Editor && o.$changed_());
    }

    export function ownedcancel(owneds: Editor.Editor<any> | Array<Editor.Editor<any>>): void {
        (isArray(owneds) ? owneds : [owneds]).forEach(o => o instanceof Editor.Editor && o.$cancel_());
    }

    export function ownedsave(owneds: Editor.Editor<any> | Array<Editor.Editor<any>>): void {
        (isArray(owneds) ? owneds : [owneds]).forEach(o => o instanceof Editor.Editor && o.$save_({ drilling: true }));
    }

    export function ownedupdate(owneds: Editor.Editor<any> | Array<Editor.Editor<any>>, value: any): void {
        if (isArray(owneds)) {
            return owneds.forEach((o, idx) => o instanceof Editor.Editor && o.$update_(value?.[idx]));
        }

        owneds instanceof Editor.Editor && owneds.$update_(value);
    }

    export function ownedsaving(owneds: Editor.Editor<any> | Array<Editor.Editor<any>>, saving: boolean): void {
        (isArray(owneds) ? owneds : [owneds]).forEach(
            o => o instanceof Editor.Editor && (
                saving ? o.$eprops_.$$saving_.push() : o.$eprops_.$$saving_.pop()
            )
        );
    }

    export function owneds_cancel<T>(arr: T[], pres: T[]): IOP {
        return _.extend(function () {
            arr.reset(pres);
        }, {
            preval: pres,
        })
    }

    export function refcreate_cancel<T extends Editor.Editor<any>>(arr: T[], elm: T) {
        return function () {
            $destroy_(elm), arr.destroy(elm);
        }
    }

    export function refinherits_cancel<T>(arr: T[], pres: T[]): IOP {
        return _.extend(function () {
            // 1. destroy elements from arr which not in pres.
            // 2. keep elements in pres but not in arr, and notify create.

            const todestroy = arr.filter(a => (
                pres.findIndex(p => (p['$base_'] || p) == (a['$base_'] || a)) < 0
            ))

            const tocreate = pres.filter(p => (
                arr.findIndex(a => (a['$base_'] || a) == (p['$base_'] || p)) < 0
            ))

            arr.destroy(todestroy);

            const cache = _Array.cacheOf(arr);
            const hasc = tocreate.length > 0;

            arr.add(tocreate);

            hasc && cache.events?.createDestroied.emit({ created: tocreate });
            hasc && cache.events?.created.emit(tocreate);

            hasc && arr._onCreateDestroied?.({ created: tocreate });
            hasc && arr._onCreated?.(tocreate);
        }, {
            preval: pres
        })
    }

    export function $destroy_(obj) {
        obj?.['$destroy_']?.();
    }

    export function $destroy_cancel(obj) {
        return function () {
            $destroy_(obj);
        }
    }

    export function $destroy_subscription(subscription: Subscription) {
        return function () {
            subscription.unsubscribe();
        }
    }

    export function $destroy_ref<T extends Editor.Editor<any>>(host: T, src: T) {
        const key = '$destroy_', { $eprops_: { $$destroies_ } } = host;

        const cb = ($$destroies_.host[key] = $$destroies_.host[key] || {
            callback: function (obj: object) {
                src?.$eprops_.$$destroies_?.remove(cb.callback);
                if (obj != host) $destroy_(host);
            }
        });

        (src as Editor.Editor<any>)?.$eprops_.$$destroies_?.add(cb.callback);
    }

    export function $destroy_refowned<T extends Editor.Editor<T, TI>, TI, Key extends Editor.TKeys<T, TI>>(host: T, key: Key, src: T[Key]) {
        const cb = (host.$eprops_.$$destroies_.host[key] = host.$eprops_.$$destroies_.host[key] || {
            callback: _.extend(function (obj: object) {
                const _src = (obj == host ? host[key] : obj);

                (_src instanceof Editor.Editor) && _src?.$eprops_.$$destroies_?.remove(cb.callback);
                console.assert(_src == host[key]);
                host[key] = undefined;
            }, {
                preval: src
            })
        })

        if (cb.data == src) return cb;

        (cb.data instanceof Editor.Editor) && cb.data.$eprops_.$$destroies_?.remove(cb.callback);
        (src instanceof Editor.Editor) && src.$eprops_.$$destroies_?.add(cb.callback);

        cb.callback.preval = src;
        return cb.data = src, cb;
    }

    export function $destroy_refparent<T extends Editor.Editor<T, TI>, TI, Key extends Editor.TKeys<T, TI>>(host: T, key: Key, src: T[Key], reffer: RefParent<T, TI, Key>) {
        function set(_host: T, _preparent: T[Key], _parent: T[Key]) {
            if (_preparent == _parent) return;

            const oldcoll: T[] = reffer.children.call(_host, _preparent);
            const newcoll: T[] = reffer.children.call(_host, _parent);

            // try to remove from previous parent
            oldcoll?.remove(_host);

            // try to insert into new parent
            !newcoll?.has(_host) && newcoll?.add(_host, 0);
        }

        const cb = (host.$eprops_.$$destroies_.host[key] = host.$eprops_.$$destroies_.host[key] || {
            callback: _.extend(function (obj: object) {
                const _src = (obj == host ? host[key] : obj);

                (_src instanceof Editor.Editor) && _src?.$eprops_.$$destroies_?.remove(cb.callback);
                console.assert(_src == host[key]);
                host[key] = undefined;
            }, {
                preval: src
            })
        })

        if (cb.data == src) return cb;

        (cb.data instanceof Editor.Editor) && cb.data.$eprops_.$$destroies_?.remove(cb.callback);
        (src instanceof Editor.Editor) && src?.$eprops_.$$destroies_?.add(cb.callback);
        set(host, cb.data, src);

        cb.callback.preval = src;
        return cb.data = src, cb;
    }

    export function $destroy_refinherit<T extends Editor.Editor<T, TI>, TI, Key extends Editor.TKeys<T, TI>>(host: T, key: Key, src: T[Key], reffer: RefInherit<T, TI, Key>) {
        function set(_host: T, _preobj: T[Key], _obj: T[Key]) {
            if (_preobj == _obj) return;
            const { $props_: props } = host;

            console.assert(props[key]?.['$base_'] == _preobj);
            $destroy_(props[key]);
        }

        const cb = (host.$eprops_.$$destroies_.host[key] || (host.$eprops_.$$destroies_.host[key] = {
            callback: _.extend(function (obj: object) {
                const { $props_: props } = host, _src = (obj == host ? props[key]?.['$base_'] : obj);

                (_src instanceof Editor.Editor) && _src?.$eprops_.$$destroies_?.remove(cb.callback);
                console.assert(_src == props[key]?.['$base_']);
                host[key] = undefined;
            }, {
                preval: src
            })
        }))

        if (cb.data == src) return cb;

        (cb.data instanceof Editor.Editor) && cb.data.$eprops_.$$destroies_?.remove(cb.callback);
        (src instanceof Editor.Editor) && src.$eprops_.$$destroies_?.add(cb.callback);
        set(host, cb.data, src);

        cb.callback.preval = src;
        return cb.data = src, cb;
    }

    export function $destroy_owneds<T extends Editor.Editor<T, TI>, TI, Key extends Editor.TRefsKeys<T, TI>>(host: T, key: Key, { added, removed }: {
        removed?: _Array.ItemType<T[Key]>[],
        added?: _Array.ItemType<T[Key]>[],
    }) {

        const cb = (host.$eprops_.$$destroies_.host[key] = host.$eprops_.$$destroies_.host[key] || {
            callback: function (obj: object) {
                const values: _Array.ItemType<T[Key]>[] = host[key] as any;

                if (obj == host) {
                    values?.forEach((r: any) => {
                        (r instanceof Editor.Editor) && r.$eprops_.$$destroies_?.remove(cb.callback);
                    });

                    values?.clear();
                    return;
                }

                (obj instanceof Editor.Editor) && obj.$eprops_.$$destroies_?.remove(cb.callback);
                values?.remove(obj as _Array.ItemType<T[Key]>);
            }
        })

        removed?.forEach((r: any) => {
            (r instanceof Editor.Editor) && r.$eprops_.$$destroies_?.remove(cb.callback);
        })

        added?.forEach((a: any) => {
            (a instanceof Editor.Editor) && a.$eprops_.$$destroies_?.add(cb.callback);
        })

        return cb;
    }

    export function $destroy_refinherits<T extends Editor.Editor<T, TI>, TI, Key extends Editor.TRefsKeys<T, TI>>(host: T, key: Key, { added, removed }: {
        removed?: _Array.ItemType<T[Key]>[],
        added?: _Array.ItemType<T[Key]>[],
    }) {
        const cb = (host.$eprops_.$$destroies_.host[key] = host.$eprops_.$$destroies_.host[key] || {
            callback: function (obj: object) {
                const values: _Array.ItemType<T[Key]>[] = host[key] as any;

                if (obj == host) {
                    values?.forEach(r => {
                        (r?.['$base_'] instanceof Editor.Editor) && r['$base_']?.$eprops_.$$destroies_?.remove(cb.callback);
                    });

                    values?.clear();
                    return;
                }

                (obj instanceof Editor.Editor) && obj.$eprops_.$$destroies_?.remove(cb.callback);
                const value = values.find(v => v?.['$base_'] == obj);
                values?.destroy(value);
            }
        })

        removed?.forEach(r => {
            (r?.['$base_'] instanceof Editor.Editor) && r['$base_']?.$eprops_.$$destroies_?.remove(cb.callback);
        })

        added?.forEach(a => {
            (a?.['$base_'] instanceof Editor.Editor) && a['$base_']?.$eprops_.$$destroies_?.add(cb.callback);
        })

        return cb;
    }
}
