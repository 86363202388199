import { EventEmitter, TemplateRef } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { ComponentType } from "@angular/cdk/portal";
import { ActivatedRoute } from "@angular/router";
import * as CryptoJS from "crypto-js";
import * as _ from "lodash";

import { Sys } from "../../../application/service/backface/types";
import { Property } from "../../libs/property";
import { Editor } from "../../libs/editor";
import { Unique } from "../../libs/unique";

const _BoundEdittingKey = Unique.symbol('BoundEditting').toString();
const _EdittingKey = Unique.symbol('FormEditting').toString();

const key = CryptoJS.enc.Utf8.parse("DW$eY9@hc*6a1#$6");
const iv = {
    iv: CryptoJS.enc.Utf8.parse('8apPZ^md9XdpgODT'),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
}


export namespace GovEditor {
    export interface ISetBinder {
        bindsource(val: Object): void;
    }

    export interface IFilterData {
        isdefault?: boolean;
        title?: string;
        key?: string;
        value?: any;
    }

    export interface IWorkItemSumData {
        stageitem?: Sys.IStageSumItem,
        workitemsum?: Sys.IWSSumItem
    }

    export interface IToolbar {
        reloader: EventEmitter<void>;

        selection?: SelectionModel<object>;
        delete?(): void;
        create?(): void;

        searcher?: string | object;
        readonly noinputtxt?: boolean;
        readonly filterdata?: IFilterData[];

        minYear?: number;
        maxYear?: number;
        selectedYear?: number;

        selectedProcess?: string;

        readonly advfilter?: TemplateRef<any> | (() => void);

        exportas?(val: string): void;
    }

    export interface ISection {
        editor: Editor.Editor<any>;
        headers: Editor.IFields;
        exclude?: { [P: string]: boolean };
        invisible?: boolean | (() => boolean);
        noactionbar?: boolean;
        readonly?: boolean;
        title?: string;
    }

    export type IForm = ISection | {
        sections: ISection[]
    }

    export interface IEditting {
        style?: (row: any) => {};
        form?: IForm | IForm[];
        handled?: IEditting;
        primary?: object;
        source?: object;
    }

    export function isSection(form: IForm): form is ISection {
        return !form?.['sections'];
    }

    export interface ToEditting {
        (obj: object, def?: ((obj: object) => IEditting)): IEditting;
    }

    export interface CommandConfig<D = any> {
        template?: ComponentType<any> | TemplateRef<any>,
        config?: MatDialogConfig<D>
    }

    export class Editting implements IEditting {
        private $props = Property.Of(this).values;

        handled?: GovEditor.IEditting;
        style?: (row: any) => {};
        primary: object;

        get form(): IForm | IForm[] {
            return this.handled ? this.handled.form : this.$props.form;
        }

        set form(val: IForm | IForm[]) {
            this.$props.form = val;
        }

        get source(): object {
            return this.handled ? this.handled.source : this.$props.source;
        }

        set source(val: object) {
            this.$props.source = val;
        }

        reset(editting: IEditting) {
            const { $props } = this;

            editting.hasOwnProperty('primary') && (this.primary = editting.primary);

            this.style = editting.style;
            this.handled = editting.handled;
            $props.source = editting.source;
            $props.form = editting.form;

            return this;
        }

        isSame(editting: IEditting = {}): boolean {
            return (
                this.form == editting.form &&
                this.source == editting.source &&
                this.handled == editting.handled &&
                this.primary == editting.primary
            );
        }

        static get(datasets: Sys.IDatasetModule): IEditting {
            return datasets?.[_EdittingKey];
        }

        static set(datasets: Sys.IDatasetModule, editting: IEditting): IEditting {
            console.assert(!!datasets);
            if (!datasets) return;

            return datasets[_EdittingKey] = editting;
        }

        static remove(datasets: Sys.IDatasetModule) {
            console.assert(!!datasets);
            if (!datasets) return;

            delete datasets[_EdittingKey];
        }

        static getBound(datasets: Sys.IDatasetModule): {
            expandheader?: Editor.IField,
            editting: Editting,
        } {
            if (datasets && !datasets[_BoundEdittingKey]) {
                const bound: ReturnType<typeof Editting.getBound> = {
                    editting: new Editting()
                }

                datasets[_BoundEdittingKey] = bound;
            }

            return datasets?.[_BoundEdittingKey];
        }

        static removeBound(datasets: Sys.IDatasetModule) {
            console.assert(!!datasets);
            if (!datasets) return;

            delete datasets[_BoundEdittingKey];
        }
    }

    export abstract class ToolBar implements GovEditor.IToolbar {
        private $props = Property.Of(this).values;

        get reloader(): EventEmitter<void> {
            const { $props: props } = this;
            return props.reloader || (
                props.reloader = new EventEmitter()
            )
        }

        get toolbarcontent(): object & {
            searcher?: string | object,
            selectedProcess?: string,
            selectedYear?: number,
        } {
            const { $props: props } = this;
            return props.toolbarcontent || (
                props.toolbarcontent = this.loadToolbarContent()
            );
        }

        get storageKey(): string {
            const { router: { snapshot: { url } }, module, modulekey } = this;
            return `${url.join('.')}.${module?.key ?? modulekey}`;
        }

        get module(): Sys.IDatasetModule {
            return this.$props.module;
        }

        set module(val: Sys.IDatasetModule) {
            const { $props: props } = this;
            if (props.module == val) return;

            delete props.toolbarcontent;
            props.module = val;
        }

        get searcher(): string | object {
            const { toolbarcontent } = this;
            return toolbarcontent.searcher;
        }

        set searcher(val: string | object) {
            const { toolbarcontent } = this;
            toolbarcontent.searcher = val;
            this.saveToolbarContent();
            this.dosearch?.();
        }

        get filterdata(): GovEditor.IFilterData[] | undefined {
            return this.module?.dict?.filterdata;
        }

        constructor(
            public router: ActivatedRoute,
            public modulekey: string = ''
        ) {
            const { $props: props, router: { snapshot: { url } } } = this;
            delete props.toolbarcontent;
        }

        dosearch?(): void;

        loadToolbarContent() {
            const { $props: props, storageKey } = this;

            try {
                delete props.toolbarcontent;

                // load the latest filter content
                props.toolbarcontent = JSON.parse(
                    CryptoJS.AES.decrypt(
                        CryptoJS.enc.Base64.stringify(
                            CryptoJS.enc.Hex.parse(
                                localStorage[storageKey]
                            )
                        ), key, iv
                    ).toString(CryptoJS.enc.Utf8)
                        .toString()
                );
            } catch (e) {
                console.log(e)
            }

            if (!_.isObject(props.toolbarcontent)) delete props.toolbarcontent;
            const toolbarcontent = (props.toolbarcontent || (props.toolbarcontent = {
                searcher: {}
            }));

            toolbarcontent.searcher = toolbarcontent.searcher || {};
            return this.reloader.emit(), props.toolbarcontent;
        }

        saveToolbarContent() {
            const { storageKey, toolbarcontent } = this;

            try {
                // store the latest searcher content
                localStorage[storageKey] = CryptoJS.AES.encrypt(
                    JSON.stringify(toolbarcontent), key, iv
                ).ciphertext.toString().toUpperCase();
            } catch (e) {
                console.log(e)
            }
        }
    }
}