import { isNull, isUndefined } from 'lodash';
import * as echarts from 'echarts';
import * as _ from 'lodash';

import { Exec as TExec, Prj as TPrj } from './types';
import { Unique } from '../../../utils/libs/unique';
import { Editor } from '../../../utils/libs/editor';
import { AppService } from '../app.service';
import { Lang } from './lang';
import { Exec } from './exec';
import { Prj } from './prj';

export type EChartOption = echarts.EChartsOption;
type IRangCondition = Report.IRangCondition;
type IChartConfig = Report.IChartConfig;
type IxAxisItem = Report.IxAxisItem;

export class Report extends Lang {
    readonly costRange: IRangCondition[] = [
        {
            id: this.trans('report.costrangeid1'),
            name: this.trans('report.costrange1'),
            min: 0,
            max: 100
        },
        {
            id: this.trans('report.costrangeid2'),
            name: this.trans('report.costrange2'),
            min: 100,
            max: 1000
        },
        {
            id: this.trans('report.costrangeid3'),
            name: this.trans('report.costrange3'),
            min: 1000,
            max: 2000
        },
        {
            id: this.trans('report.costrangeid4'),
            name: this.trans('report.costrange4'),
            min: 2000,
            max: 5000
        },
        {
            id: this.trans('report.costrangeid5'),
            name: this.trans('report.costrange5'),
            min: 5000,
            max: 1000000
        }
    ];

    readonly percentRange: IRangCondition[] = [
        {
            id: this.trans('report.percentrangeid1'),
            name: this.trans('report.percentrange1'),
            min: 0,
            max: 0.25
        },
        {
            id: this.trans('report.percentrangeid2'),
            name: this.trans('report.percentrange2'),
            min: 0,
            max: 0.5
        },
        {
            id: this.trans('report.percentrangeid3'),
            name: this.trans('report.percentrange3'),
            min: 0.5,
            max: 0.75
        },
        {
            id: this.trans('report.percentrangeid4'),
            name: this.trans('report.percentrange4'),
            min: 0.75,
            max: 1
        },
        {
            id: this.trans('report.percentrangeid5'),
            name: this.trans('report.percentrange5'),
            min: 1,
            max: 1.5
        },
        {
            id: this.trans('report.percentrangeid6'),
            name: this.trans('report.percentrange6'),
            min: 1.5,
            max: 1000000
        }
    ];

    readonly statuslist: IxAxisItem[] = [
        {
            id: 'all',
            name: this.trans('sysenums.all')
        },
        {
            id: 'newcreate',
            name: this.trans('sysenums.newcreate')
        },
        {
            id: 'continous',
            name: this.trans('sysenums.continous')
        },
        {
            id: 'ongoing',
            name: this.trans('sysenums.ongoing')
        },
        {
            id: 'delaying',
            name: this.trans('sysenums.delaying')
        },
        {
            id: 'delayed',
            name: this.trans('sysenums.delayed')
        },
        {
            id: 'finished',
            name: this.trans('sysenums.prjfinished')
        },
        {
            id: 'constrpre',
            name: this.trans('sysenums.constrpre')
        },
        {
            id: 'constring',
            name: this.trans('sysenums.constring')
        },
        {
            id: 'accepting',
            name: this.trans('sysenums.accepting')
        }
    ];

    readonly yearcostinvest: IxAxisItem[] = [
        {
            id: 'yearlyinvested',
            name: this.trans('general.yearlyinvested')
        },
        {
            id: 'yearlyassetinvested',
            name: this.trans('general.yearlyassetinvested')
        }
    ]

    readonly monthChartConfig: IChartConfig = {
        title: this.trans('report.yearmonthcostchart'),
        xAxis: this.trans('general.month2'),
        yAxis: this.trans('general.investedcost')
    };

    readonly prjcostChartConfig: IChartConfig = {
        title: this.trans('projectdetail.budgetchart'),
        xAxis: this.trans('general.annual'),
        yAxis: ""
    }

    readonly statusChartConfig: IChartConfig = {
        title: this.trans('report.statuschart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly plancostChartConfig: IChartConfig = {
        title: this.trans('report.costchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly plancostpercentChartConfig: IChartConfig = {
        title: this.trans('report.percentchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly yearplancostChartConfig: IChartConfig = {
        title: this.trans('report.yearcostchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly yearplancostpercentChartConfig: IChartConfig = {
        title: this.trans('report.yearpercentchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    //barcolors is default
    static readonly barcolors: string[] = ['#5470c6', '#91cc75', 'blanchedalmond', '#add8e6', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#3ba272'];
    readonly planbarcolors: string[] = [
        this.trans('syscolors.all'),
        this.trans('syscolors.newcreate'),
        this.trans('syscolors.continous'),
        this.trans('syscolors.ongoing'),
        this.trans('syscolors.delaying'),
        this.trans('syscolors.delayed'),
        this.trans('syscolors.finished'),
        this.trans('syscolors.constrpre'),
        this.trans('syscolors.constring'),
        this.trans('syscolors.accepting'),
    ];

    readonly costbarcolors: string[] = [
        this.trans('syscolors.costrangeid1'),
        this.trans('syscolors.costrangeid2'),
        this.trans('syscolors.costrangeid3'),
        this.trans('syscolors.costrangeid4'),
        this.trans('syscolors.costrangeid5'),
    ];

    readonly percentbarcolors: string[] = [
        this.trans('syscolors.percentrangeid1'),
        this.trans('syscolors.percentrangeid2'),
        this.trans('syscolors.percentrangeid3'),
        this.trans('syscolors.percentrangeid4'),
        this.trans('syscolors.percentrangeid5'),
        this.trans('syscolors.percentrangeid6'),
    ];

    readonly supervisemixlinebarcolors: string[] = [
        this.trans('syscolors.refproject'),
        this.trans('syscolors.problemnotfixed'),
        this.trans('syscolors.supervisecount'),
        this.trans('syscolors.problemcount')
    ];
    readonly executionmixlinebarcolors: string[] = [
        this.trans('syscolors.refproject'),
        this.trans('syscolors.workitemexecount'),
        this.trans('syscolors.workitemexeoverdue'),
        this.trans('syscolors.workitemexeongoing')
    ];
}

export namespace Report {
    export enum eChartType {
        bar,
        pie,
        line
    }

    export interface IChartConfig {
        title?: string,
        xAxis?: string,
        yAxis?: string
    }

    export interface IxAxisItem {
        id?: string,
        name?: string
    }

    export interface IRangCondition {
        id?: string,
        name?: string,
        min?: number,
        max?: number
    }

    export interface IChartParam {
        charttype: eChartType,
        chartid: string,
        option: EChartOption,
        reqURL: string,
        legends: Array<any>,
        xAxisItems: Array<any>,
        chartCfg: IChartConfig,
        legtag: string,
        dataMap?: Map<string, object>
    }

    export interface IChartPieDataItem {
        value: number,
        name: string,
        key: string
    }

    export interface IChartPieData {
        id: string,
        title: string,
        totalname: string,
        totalsum: number,
        data: IChartPieDataItem[],
        trigger?(val: {serialid:string, dataindex:number}): void;
    }

    export class ReportTemplate {
        get ReportTemplateChartPie(): EChartOption {
            return {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    selectedMode: false,
                    show: false
                },
                graphic: {
                    type: 'text',
                    top: 'center',
                    left: 'center',
                    style: {
                        text: 'Need to input', //'项目总数\n' + String(sum).replace(/(\d)(?=(?:\d{6})+$)/g, '$1.'),
                        //textAlign: 'center',   // need to solve this problem
                        fontSize: 18
                    }
                },
                series: [
                    {
                        id: 'Need to input',
                        name: 'Need to input',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        minAngle: 30,
                        itemStyle: {
                            borderRadius: 5,
                            borderColor: '#fff',
                            borderWidth: 5
                        },
                        label: {
                            show: true,
                            position: 'inner',
                            formatter: '{b}\n{c}\n{d}%'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 18,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            }
        }

        get ReportTemplateChartStackLine(): EChartOption {
            return {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    align: 'auto',
                    top: 30,
                    padding: 0
                },
                grid: {
                    left: '5%',
                    right: '8%',
                    bottom: '5%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: []
                },
                yAxis: {
                    name: '',
                    type: 'value',
                    axisLine: { show: true }
                },
                series: []
            };
        }

        get ReportTemplateChartLineItem() {
            return {
                id: '',
                name: '',
                type: 'line',
                smooth: true,
                data: []
            }
        }

        get ReportTemplateChartBarStackItem() {
            return {
                name: '',
                type: 'bar',
                stack: '',
                barMaxWidth: '10%',
                barMinWidth: '5%',
                barMaxHeight: '10%',
                barMinHeight: '1%',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            }
        }

        get ReportTemplateChartBarItem() {
            return {
                name: '',
                type: 'bar',
                id: '',
                barMaxWidth: '10%',
                barMinWidth: '5%',
                barMaxHeight: '10%',
                barMinHeight: '1%',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            }
        }

        get ReportTemplateChartBar(): EChartOption {
            const { barcolors } = Report;

            return {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    align: 'auto',
                    top: 40,
                    padding: 0
                },
                grid: {
                    left: '5%',
                    right: '8%',
                    bottom: '5%',
                    containLabel: true
                },
                color: barcolors,
                tooltip: {
                    trigger: 'axis',
                    showContent: true,
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    name: '',
                    type: 'category',
                    axisLabel: { interval: 0, rotate: 40 },
                    data: []
                },
                yAxis: {
                    name: '',
                    type: 'value',
                    axisLine: { show: true }
                },
                series: []
            };
        }

        ReportTemplateMixedLineBar(mixlinebarcolors: string[], line: number, bar: number): EChartOption {
            const option: EChartOption = {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },

                color: mixlinebarcolors,

                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                grid: {
                    right: '20%'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    align: 'auto',
                    top: 40,
                    padding: 0,
                    data: []
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: { interval: 0, rotate: 40 },
                        axisTick: {
                            alignWithLabel: true
                        },
                        data: []
                    }
                ],
                yAxis: [],
                series: []
            };

            for (let index = 0, offset = 0; index < (line + bar); index++, offset++) {
                if (index == 0 || index == line) offset = 0;
                option.yAxis[index] = {
                    type: 'value',
                    name: '',
                    min: 0,
                    max: 100,
                    position: index < line ? 'left' : 'right',
                    offset: offset * 80,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: mixlinebarcolors[index]
                        }
                    },
                    axisLabel: {
                        formatter: '{value} '
                    }
                };

                option.series[index] = {
                    name: '',
                    type: index < line ? 'line' : 'bar',
                    yAxisIndex: index,
                    barMinHeight: 10,
                    data: []
                };
            };

            return option;
        }
    }

    export function InitEchart(id: string, option: EChartOption): void {
        var chartDom = document.getElementById(id) as HTMLDivElement;
        if (isNull(chartDom) || isUndefined(chartDom)) {
            console.log("InitEchart can't find Echart element: " + id);
            return;
        }

        var myChart = echarts.init(chartDom);
        if (isNull(myChart) || isUndefined(myChart)) {
            console.log("InitEchart can't init Echart element: " + id);
            return;
        }

        myChart.setOption(option);
        myChart.on('click', function (params) {
            var param1 = params.seriesName;
            var param2 = params.value;
            alert(param1 + param2 + params.name);
        });
    }

    export function RefreshEchart(id: string, option: EChartOption): void {
        var chartDom = document.getElementById(id) as HTMLDivElement;
        if (isNull(chartDom) || isUndefined(chartDom)) {
            console.log("RefreshEchart: can't find Echart element: " + id);
            return;
        }

        var myChart = echarts.getInstanceByDom(chartDom);
        if (isNull(myChart) || isUndefined(myChart)) {
            console.log("RefreshEchart: can't init Echart element: " + id);
            return;
        }

        myChart.setOption(option);
    }

    export function onClickCharBar(params: {
        seriesName: string,
        value: string,
        name: string
    }): void {
        const { seriesName, value, name } = params
        alert(`${seriesName}${value}${name}`);
    }

    export function InitChartParam(param: Report.IChartParam): void {
        param.option.title['text'] = param.chartCfg.title;
        param.option.xAxis['name'] = param.chartCfg.xAxis;
        param.option.yAxis['name'] = param.chartCfg.yAxis;

        var i = 0;
        param.legends.forEach(legend => {
            var legitem = (param.charttype == Report.eChartType.line) ?
            new Report.ReportTemplate().ReportTemplateChartLineItem
            : new Report.ReportTemplate().ReportTemplateChartBarItem;
            legitem.id = legend.id;
            legitem.name = legend.name;
            param.option.series[i] = legitem;
            i++;
        });

        param.xAxisItems.forEach(element => {
            param.option.xAxis['data'].add(element.name);
        });

        if (param.dataMap) {
            param.xAxisItems.forEach(element => {
                param.legends.forEach(legend => {
                    var key = element.name + legend.id + legend.name;
                    param.dataMap.set(key, null);
                });
            });
        }
    }
}

export namespace Report {
    export interface IProblemEx extends TExec.IProblem {
        project: { id: string },
        projecttype: { id: string },
        stage: { id: string },
        workset: { id: string },
        workitem: { id: string },
        result: { id: string },
        people: { id: string },

        order: number;
        id?: Unique.guid;
        auditid?: number,
        problem?: string,
        date?: string | Date,
        fixed?: boolean,
    }

    export type IProjectWorkitems = {
        id: string;
        name: string;
        pool: string,
        workitem: {
            id: string;
            workset: string;
            stage_status?: string;
            stage_order?: number,
            stage: string;
            name: string;
            type: string;
            begindate: string;
            enddate: string;
            status: string;
            value: { // the last result value depends on the checkpoints in this workitem
                [P: string]: any
            };
            audit?: TExec.IAudit,
            checkpoint: string;
            closedate: string;
            dept: {
                id: string;
                name: string;
            }
        }[],
    }[]
}

export namespace Report {
    export function buildProblems(app: AppService, _problems: Report.IProblemEx[] = [], output: Exec.Problem[] = []): Exec.Problem[] {
        const results: { [id: string]: Exec.Result } = {};

        const rows = _.reduce(_problems, (rows, v) => {
            const result: Exec.Result = (results[v.result.id] || (
                results[v.result.id] = new Exec.Result(
                    app, <TExec.IResult>{
                        id: v.result.id,
                        project: v.project,
                        projecttype: v.projecttype,
                        stage: v.stage,
                        workset: v.workset,
                        workitem: v.workitem,
                        people: v.people,
                        date: v.date
                    }
                )
            ))

            return rows.push(new Exec.Problem(result, {
                id: v.id,
                auditid: v.auditid,
                problem: v.problem,
                date: v.date,
                fixed: v.fixed,
            })), rows;
        }, []);

        return output.splice(0, output.length, ...rows), output;
    }

    export function buildResult(app: AppService, _results: TExec.IResult[] = [], output: Exec.Result[] = [], host: {
        project?: Prj.Project;
        workitem?: Prj.ProjWorkitem;
    } = {}): Exec.Result[] {
        const rows = _results.map(r => new Exec.Result(app, _.extend(r, host)));
        return output.splice(0, output.length, ...rows), output;
    }

    export function buildAuditResults(app: AppService, _results: TExec.IAuditResult[] = [], output: Exec.AuditResult[] = [], host: {
        project?: Prj.Project;
        workitem?: Prj.ProjWorkitem;
    } = {}): Exec.AuditResult[] {
        const rows = _results.map(r => new Exec.AuditResult(app, _.extend(r, host))).filter((r) => !!r.workitem);
        return output.splice(0, output.length, ...rows), output;
    }

    export function buildWorkitems(app: AppService, _projectworkitems: Report.IProjectWorkitems, output: Prj.ProjWorkitem[] = []): Prj.ProjWorkitem[] {
        const { prj, prj: { project: projects }, org, dict: { AllStatus } } = app;
        const _projects = _projectworkitems || [];
        type Status = TPrj.IWorkitem.Status;

        const rows = _projects.reduce((rows, { pool, id: projectid, workitem: _workitems }) => {
            const project = projects.firstOf({ id: projectid, pool: TPrj.Pool[pool] });
            if (!project) {
                console.assert(!!project, 'cannot find project for project_workitem_status')
                return rows;
            }

            const { workitems, projecttype: { stages } = {} } = project;
            const { workitems: gworkitems } = prj;
            if (!workitems || !_workitems) {
                return rows;
            }

            const staged: { [id: string]: Prj.ProjStage } = {};
            for (const { stage, workset, id, checkpoint, stage_status, type, value, audit, status, closedate, begindate, enddate } of _workitems) {
                let projectworkitem;

                if (type != 'supervise') {
                    if (!staged[stage]) {
                        const pstage = (staged[stage] = stages?.firstOf({
                            id: stage
                        }));

                        const status = AllStatus.indexed[stage_status];
                        pstage && (pstage.status = status.value as Status);
                    }

                    projectworkitem = workitems.firstOf({
                        stage: { id: stage }, workset: { id: workset }, id
                    })
                } else {
                    const gworkitem = gworkitems.firstOf({ id });
                    projectworkitem = new Prj.Workitem(
                        org, prj, project, gworkitem as TPrj.IWorkitem
                    )
                }

                if (!projectworkitem) continue;

                projectworkitem['value'] = value;
                projectworkitem['audit'] = audit;
                projectworkitem.closedate = Editor.Value.toDate(closedate);
                projectworkitem.begindate = Editor.Value.toDate(begindate);
                projectworkitem.enddate = Editor.Value.toDate(enddate);
                projectworkitem.status = TPrj.IWorkitem.Status[status];
                rows.push(projectworkitem);

                if (checkpoint) {
                    // has closed, init the value of checkpoint identify closed
                    const checkpoint = projectworkitem.checkpoints.find(
                        c => c.key == checkpoint
                    );

                    if (Editor.Value.isFieldType(checkpoint?.type, Editor.Value.Type.bool)) {
                        value[checkpoint] = !!closedate;
                    }
                }

                const notused = projectworkitem.checkpoints.find(
                    c => c.key == 'notused'
                );

                if (notused) {
                    if (!Object.getOwnPropertyDescriptor(projectworkitem, 'notused')) {
                        Editor.Dynamic.build(projectworkitem, projectworkitem.parent, notused, Exec.FieldTypes, { isReadonly: false })
                    }

                    projectworkitem.$pushSaving_();
                    projectworkitem['notused'] = value?.notused;
                    projectworkitem.$popSaving_();
                }
            }

            return rows;
        }, []);

        return output.splice(0, output.length, ...rows), output;
    }
}