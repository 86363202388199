<div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
    <h4 class="header d-flex flex-row align-items-stretch justify-content-center flex-none">
        <strong>{{processName()}}</strong>
    </h4>
    <div class="d-flex flex-row justify-content-between align-items-stretch flex-none">
        <div class="col-md-3">
            <gov-wscard [cardsum]="prjcount"></gov-wscard>
        </div>
        <div class="col-md-3">
            <gov-wscard [cardsum]="wscount"></gov-wscard>
        </div>
        <div class="col-md-3">
            <gov-wscard [cardsum]="overduecount"></gov-wscard>
        </div>
        <div class="col-md-3">
            <gov-wscard [cardsum]="ongoingcount"></gov-wscard>
        </div>
    </div>
    <div id="reportwsexeoption{{selectedProcess}}" echarts class="h-100 w-100 d-inline-flex"
        (chartClick)="onClickCharBar($event)" [options]="checkoption">
    </div>
</div>