import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNull, isUndefined } from 'lodash';

import { Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { TemplateService } from '../../utils/view/template.directive';
import { Backface } from '../../application/service/backface/config';
import { AppService } from '../../application/service/app.service';
import { Report } from '../../application/service/backface/report';
import { Property } from '../../utils/libs/property';
import { Editor } from '../../utils/libs/editor';

@Component({
    templateUrl: "./workitemlist.component.html",
    styleUrls: ['./workitemlist.component.scss'],
    providers: [TemplateService]
})
export class WorkItemListComponent extends Backface.AsyncReport implements OnInit, OnDestroy, AfterViewInit {
    private _props = Property.Of(this).values;

    get currentmodule(): TSys.IDatasetModule {
        const { _props: props, app: { dict: { exec: { audit, audit: { headers } } } } } = this;

        return (props.currentmodule = (props.currentmodule || {
            headers: headers.filter(h => h.key != "records"),
            dict: audit,
            rows: [],
            key: 'workitemlist',
        }));
    }

    constructor(
        public app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string
            header: string,
            prjidlist: string[],
            stageidlist: string[],
            workitemstatus: string[]
        },

        protected httpClient: HttpClient,
    ) {
        super(httpClient)
    }

    ngOnInit() {
        this.GetWorkItemStatus();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    GetWorkItemStatus(): void {
        var param = {
            project: [],
            stage: [],
            workitemstatus: { type: [] }
        };

        this.data?.prjidlist?.forEach(prjid => {
            param.project.push({ id: prjid });
        });

        this.data?.stageidlist?.forEach(stageid => {
            param.stage.push({ id: stageid });
        });

        this.data?.workitemstatus?.forEach(status => {
            param.workitemstatus.type.push(status);
        });

        this.report("/report/project_workitem_status", (res: Report.IProjectWorkitems) => {
            if (isNull(res) || isUndefined(res)) { return; }

            if (res) {
                this.currentmodule.rows.clear();
                const { app: { prj: { project: projects } } } = this;

                const rows = res.reduce((result, { id: projectid, workitem: _workitems, pool }) => {
                    const project = projects.firstOf({ id: projectid, pool: TPrj.Pool[pool] });
                    if (!project) {
                        console.assert(!!project, 'cannot find project for project_workitem_status')
                        return result;
                    }

                    const { workitems } = project;
                    for (const { stage, workset, id, status, enddate } of _workitems) {
                        const projectworkitem = workitems.firstOf({
                            stage: { id: stage }, workset: { id: workset }, id
                        });

                        if (!projectworkitem) {
                            console.assert(!!projectworkitem, 'cannot find project workitem for project_workitem_status');
                            continue;
                        }

                        projectworkitem.status = TPrj.IWorkitem.Status[status];
                        projectworkitem.enddate = Editor.Value.toDate(enddate);
                        result.push(projectworkitem);
                    }

                    return result;
                }, []);

                rows.forEach(ws => {
                    this.currentmodule.rows.push(ws);
                });
            }
        }).query(
            param
        );
    }

    static open(dialog: MatDialog, data: {} = {}): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '80vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
            data: {
                ...data
            }
        }

        return dialog.open(WorkItemListComponent, config);
    }

}
