
import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { extend, isArray, isNull, isString, isUndefined, noop } from 'lodash';
import * as _ from 'lodash';

import { DeepPartial } from '../../../utils/libs/types/mixins';
import { Org as TOrg, Prj as TPrj, Sys as TSys } from './types';
import { Collection } from '../../../utils/libs/collection';
import { Property } from '../../../utils/libs/property';
import { Editor } from "../../../utils/libs/editor";
import { Unique } from '../../../utils/libs/unique';
import { AppService } from '../app.service';
import { Backface } from './config';
import { Dict } from './dict';
import { Org } from "./org"
import { Sys } from './sys';

type Pool = TPrj.Pool;
const Pool = TPrj.Pool;

@Injectable()
export class Prj extends Editor.Editor<Prj, TPrj.IPrj> implements TPrj.IPrj, OnDestroy {
    @JSON.Key()
    @Backface.Entity(() => Prj.Checkpoint, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.Checkpoints(this, this);
        }, 'checkpoints')
    ) readonly checkpoints: Prj.Checkpoints<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.Workitem, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.Workitems(this.app.org, this, this);
        }, 'workitems')
    ) readonly workitems: Prj.Workitems<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.Workset, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.Worksets(this.app.org, this, this);
        }, 'worksets')
    ) readonly worksets: Prj.Worksets<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.Stage, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.Stages(this.app.org, this, this);
        }, 'stages')
    ) readonly stages: Prj.Stages<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.ProjectType, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.ProjectTypes(this.app.org, this, this);
        }, 'projecttypes')
    ) readonly projecttypes: Prj.ProjectTypes;

    @JSON.Key()
    @Backface.Entity(() => Prj.Plan, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.Plans(this.app.org, this, this);
        }, 'plans')
    ) readonly plans: Prj.Plans;

    @JSON.Key()
    @Backface.Entity(() => Prj.Project, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.Projects(this.app.org, this.app.prj, this);
        }, 'projects')
    ) readonly projects: Prj.Projects;

    @JSON.Key()
    @Backface.Entity(() => Prj.PilotProject, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.PilotProjects(this.app.org, this.app.prj, this);
        }, 'pilotprojects')
    ) readonly pilotprojects: Prj.PilotProjects;

    @JSON.Key()
    @Backface.Entity(() => Prj.InputProject, "prj")
    @Editor.Field(Prj, Editor.Type.owneds,
        Editor.Owneds(function (this: Prj) {
            return new Prj.InputProjects(this.app.org, this.app.prj, this);
        }, 'inputprojects')
    ) readonly inputprojects: Prj.InputProjects;

    get project(): {
        firstOf(prj: DeepPartial<Prj.Project>): Prj.Project | undefined,
        allOf(prj: DeepPartial<Prj.Project>, compare: (project: Prj.Project) => (void | boolean | undefined)): Prj.Project[],
        allOf(compare: (project: Prj.Project) => (void | boolean | undefined)): Prj.Project[],
        allOf(prj: DeepPartial<Prj.Project>): Prj.Project[],
    } {
        const { $props_: props } = this, { poolmap } = Prj, _this = this;

        const collOf = (prj: DeepPartial<Prj.Project>): Prj.Project[] | undefined => {
            const pool: Pool = (Prj.Project.stdValue(prj)?.pool ?? 0) as Pool;
            const poolmsk = (pool & Pool.poolmask);
            if (poolmsk <= 0) return;

            // find in specified collection
            const poolname = poolmap[poolmsk];
            const projects = _this[poolname];
            if ((pool & ~Pool.poolmask) == 0) {
                return projects;
            }

            const collname = Pool[pool];
            return projects?.[collname] || projects;
        }

        return props.project || (
            props.project = {
                firstOf(prj: DeepPartial<Prj.Project>): Prj.Project | undefined {
                    const colls = collOf(prj);

                    if (colls) {
                        return colls?.firstOf?.(prj);
                    }

                    for (const pool in poolmap) {
                        // find in loaded collection.
                        const poolkey = poolmap[pool];
                        if (Backface.Of(_this, poolkey)?.loaded) {
                            const ret = _this[poolkey]?.firstOf(prj);
                            if (ret) return ret;
                        }
                    }

                    // find in not loaded collection
                    for (const pool in poolmap) {
                        const poolkey = poolmap[pool];
                        if (!(Backface.Of(_this, poolkey)?.loaded)) {
                            const ret = _this[poolkey]?.firstOf(prj);
                            if (ret) return ret;
                        }
                    }
                },
                allOf(prj: DeepPartial<Prj.Project> | ((project: Prj.Project) => (void | boolean | undefined)), compare?: ((project: Prj.Project) => (void | boolean | undefined))): Prj.Project[] | undefined {
                    compare = _.isFunction(prj) ? prj : compare;
                    prj = _.isFunction(prj) ? undefined : prj;

                    if (prj?.id) {
                        const project = props.project.firstOf(prj);

                        if (project && (!compare || compare(project))) {
                            return [project]
                        }

                        return [];
                    }

                    const colls = collOf(prj);
                    if (colls) {
                        const targets = colls?.where?.(prj);
                        if (!compare) return targets;
                        return targets?.filter(compare);
                    }

                    // worest case that we must search in all collections
                    let targets: Prj.Project[];
                    for (const pool in poolmap) {
                        // find in loaded collection.
                        const poolkey = poolmap[pool];
                        let parts = _this[poolkey]?.where?.(prj);
                        if (compare) parts = parts?.filter(compare);
                        if ((parts?.length ?? 0) > 0) targets = targets && parts.concat(targets) || parts;
                    }

                    return targets;
                }
            }
        )
    }

    constructor(
        public app: AppService,

        @Optional()
        @Inject(Prj.setting)
        setting?: TPrj.IPrj
    ) {
        super(setting);
    }

    ngOnDestroy(): void {
        _.forEach(Prj.poolmap, (pkey) => {
            if (Backface.Of(this, pkey)?.loaded) {
                this[pkey]?.['ngOnDestroy']?.();
            }
        })
    }

    static readonly poolmap = {
        [Pool.coll]: 'inputprojects',
        [Pool.pilot]: 'pilotprojects',
        [Pool.authed]: 'projects',
    }

    static readonly setting = new InjectionToken('PrjSetting');
}

export namespace Prj {
    export class Checkpoint<TParent extends Prj | Workitem<Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>>> extends Editor.Editor<Checkpoint<TParent>, TPrj.ICheckpoint> implements TPrj.ICheckpoint {
        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.guidid)
        readonly id: string;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.owned)
        title: string;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Checkpoint, [Editor.Type.owned, Editor.Value.Type.enum, Editor.Value.Type, Editor.Value.Type.text])
        type: Editor.Value.Type;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.inherit)
        must: boolean;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.refowned,
            Editor.RefOwned(function (this: Checkpoint<TParent>) {
                return this.prj.checkpoints;
            }, () => Checkpoint, 'dependfield')
        ) dependfield: Checkpoint<Prj>;

        @JSON.Key()
        @Editor.Field(Checkpoint, [Editor.Type.owned, Editor.Value.Type.json])
        dependvalues: any[];

        @JSON.Key()
        @Editor.Field(Checkpoint, [Editor.Type.owned, Editor.Value.Type.json])
        typesource: string | { key: string, title: string, value: number | string }[];

        get source(): string | string[] | Editor.IEnum {
            const { $props_: { source } } = this;
            if (source !== undefined) return source;

            const { $props_: props, typesource, type } = this;
            if (type == Editor.Value.Type.enum && (isArray(typesource) || isString(typesource))) {
                if (isString(typesource)) return props.source = Dict[typesource];

                return typesource.reduce<Editor.IEnum>((res, val) => {
                    const { key, title } = val, { value = key } = val;
                    const _val = { key, title, value }, { indexed } = res;

                    indexed[value] = _val, indexed[key] = _val;
                    return res.push(_val), res;
                }, props.source = extend(
                    [], { indexed: {} }
                ))
            }

            if (isString(typesource)) {
                props.source = typesource;
                return props.source;
            }

            props.source = null;
            return props.source;
        }

        get workitem(): Workitem<Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>> {
            const { $props_: { workitem } } = this;
            if (workitem) return workitem;

            const { parent, $props_: props } = this;
            return props.workitem = (parent instanceof Workitem ? parent : undefined);
        }

        get key(): string {
            return this.id?.toString();
        }

        constructor(
            public prj: Prj,
            public parent: TParent,
            _checkpoint: TPrj.ICheckpoint | Checkpoint<TParent> = {}
        ) {
            super(_checkpoint);
        }
    }

    @Editor.Collections(Checkpoint)
    export class Checkpoints<TParent extends Prj | Workitem<Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>>> extends Collection<Checkpoint<TParent>, TParent, TPrj.ICheckpoint> implements TPrj.ICheckpoints {
        constructor(
            public prj: Prj,
            public parent: TParent,
            _checkpoints: (TPrj.ICheckpoint | Checkpoint<TParent>)[] = []
        ) {
            super(parent, []);
            this.recreate(_checkpoints);
        }

        construct(val: Checkpoint<TParent> | TPrj.ICheckpoint): Checkpoint<TParent> {
            return new Checkpoint<TParent>(this.prj, this.parent, val);
        }
    }

    export class Workitem<TParent extends Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>> extends Editor.Editor<Workitem<TParent>, TPrj.IWorkitem> implements TPrj.IWorkitem {
        get status(): TPrj.IWorkitem.Status {
            // only project assocated workitem can have real status value;
            if (!this.project) return TPrj.IWorkitem.Status.unknown;

            const { $props_: props } = this;
            return props.status;
        }

        set status(s: TPrj.IWorkitem.Status) {
            const { $props_: props } = this;
            if (isString(s)) {
                s = TPrj.IWorkitem.Status[s] as any;
            }
            props.status = s;
        }

        get enddate(): Date {
            const { $props_: props } = this;
            return Editor.Value.toDate(props.enddate);
        }

        set enddate(edate: Date) {
            const { $props_: props } = this;
            props.enddate = edate;
        }

        get begindate(): Date {
            const { $props_: props } = this;
            return Editor.Value.toDate(props.begindate);
        }

        set begindate(edate: Date) {
            const { $props_: props } = this;
            props.begindate = edate;
        }

        get closedate(): Date {
            const { $props_: props } = this;
            return Editor.Value.toDate(props.closedate);
        }

        set closedate(edate: Date) {
            const { $props_: props } = this;
            props.closedate = edate;
        }

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Workitem, [Editor.Type.owned, Editor.Value.Type.enum, TPrj.IWorkitem.Type, TPrj.IWorkitem.Type.normal])
        type: TPrj.IWorkitem.Type;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.inherit)
        duration: number;

        @JSON.Key()
        @Editor.Field(Workitem, [Editor.Type.inherit, Editor.Value.Type.enum, TSys.Frequency, TSys.Frequency.randomly])
        occurancy: TSys.Frequency;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.refowned,
            Editor.RefOwned(function (this: Workitem<TParent>) {
                return this.org.dept;
            }, () => Org.Dept, 'dept')
        ) dept: Org.Dept;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.refinherits,
            Editor.RefInherits<
                Workitem<TParent>, TPrj.IWorkitem, 'checkpoints', Checkpoint<Prj>
            >(function (this: Workitem<TParent>): Checkpoint<Prj>[] {
                return this.prj.checkpoints;
            }, function (this: Workitem<TParent>) {
                return new Checkpoints<Workitem<TParent>>(this.prj, this);
            }, 'checkpoints')
        ) readonly checkpoints: Checkpoints<Workitem<TParent>>;

        @JSON.Key('id')
        get workset(): Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>> {
            const { $props_: { workset } } = this;
            if (workset) return workset;

            const { parent, $props_: props } = this;
            return props.workset = (parent instanceof Workset ? parent : undefined);
        }

        @JSON.Key('id')
        get stage(): Stage<Prj | ProjectType<Prj | Project>> {
            const { $props_: { stage } } = this;
            if (stage) return stage;

            const { workset: { parent } = {}, $props_: props } = this;
            return props.stage = (parent instanceof Stage ? parent : undefined);
        }

        @JSON.Key('id')
        get projecttype(): ProjectType<Prj | Project> {
            const { $props_: { projecttype } } = this;
            if (projecttype) return projecttype;

            const { stage: { parent } = {}, $props_: props } = this;
            return props.projecttype = (parent instanceof ProjectType ? parent : undefined);
        }

        @JSON.Key('id')
        get project(): Project {
            const { $props_: { project } } = this;
            if (project) return project;

            const { parent, $props_: props } = this;
            if (parent instanceof Project) {
                return props.project = parent;
            }

            const { projecttype: { parent: _parent } = {} } = this;
            return props.project = (_parent instanceof Project ? _parent : undefined);
        }

        get projectplan(): Plan {
            return this.project?.plan;
        }

        get processpath(): string {
            return this.stage?.name + "/" + this.workset?.name;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _workitem: TPrj.IWorkitem | Workitem<any> = {}
        ) {
            super(_workitem);
        }
    }

    @Editor.Collections(Workitem)
    export class Workitems<TParent extends Prj | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>> extends Collection<Workitem<TParent>, TParent, TPrj.IWorkitem> implements TPrj.IWorkitems {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _workitems: (TPrj.IWorkitem | Workitem<TParent>)[] = []
        ) {
            super(parent, []);
            this.recreate(_workitems);
        }

        _onCreateDestroied({ created, destroied }: { created: Workitem<TParent>[]; destroied: Workitem<TParent>[]; }) {
            super._onCreateDestroied?.({ created, destroied });

            [Workset, Stage, ProjectType, Project].reduce<[boolean, any]>(([tocheck, obj], type) => {
                let parent = obj?.parent;

                if (tocheck) {
                    if (parent instanceof type) {
                        destroied && parent.workitems.remove(destroied as []);
                        created && parent.workitems.add(created as [], 0);
                    } else {
                        parent = null;
                    }
                }

                return [true, parent]
            }, [false, this]);
        }

        construct(val: Workitem<TParent> | TPrj.IWorkitem): Workitem<TParent> {
            return new Workitem<TParent>(this.org, this.prj, this.parent, val);
        }
    }

    export class Workset<TParent extends Prj | Stage<Prj | ProjectType<Prj | Project>>> extends Editor.Editor<Workset<TParent>, TPrj.IWorkset> implements TPrj.IWorkset {
        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.refinherits,
            Editor.RefInherits<
                Workset<TParent>, TPrj.IWorkset, 'workitems', Workitem<Prj>
            >(function (this: Workset<TParent>) {
                return this.prj.workitems;
            }, function (this: Workset<TParent>) {
                return new Workitems<Workset<TParent>>(this.org, this.prj, this);
            }, 'workitems')
        ) readonly workitems: Workitems<Workset<TParent>>;

        get order(): number {
            return this.$inherits?.order;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _workset: TPrj.IWorkset | Workset<TParent> = {}
        ) {
            super(_workset);
        }
    }

    @Editor.Collections(Workset)
    export class Worksets<TParent extends Prj | Stage<Prj | ProjectType<Prj | Project>>> extends Collection<Workset<TParent>, TParent, TPrj.IWorkset> implements TPrj.IWorksets {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _worksets: (TPrj.IWorkset | Workset<TParent>)[] = []
        ) {
            super(parent, []);
            this.recreate(_worksets);
        }

        construct(val: Workset<TParent> | TPrj.IWorkset): Workset<TParent> {
            return new Workset<TParent>(this.org, this.prj, this.parent, val);
        }
    }

    export class Stage<TParent extends Prj | ProjectType<Prj | Project>> extends Editor.Editor<Stage<TParent>, TPrj.IStage> implements TPrj.IStage {
        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.refinherits,
            Editor.RefInherits<
                Stage<TParent>, TPrj.IStage, 'worksets', Workset<Prj>
            >(function (this: Stage<TParent>) {
                return this.prj.worksets;
            }, function (this: Stage<TParent>) {
                return new Worksets<Stage<TParent>>(this.org, this.prj, this);
            }, 'worksets')
        ) readonly worksets: Worksets<Stage<TParent>>;

        @Editor.Field(Stage, Editor.Type.refflattens,
            Editor.RefFlattens<
                Stage<TParent>, TPrj.IStage, 'workitems', Workset<Stage<TParent>>
            >(function (this: Stage<TParent>, obj: Stage<TParent> | Workset<Stage<TParent>>): Workset<Stage<TParent>>[] {
                return obj instanceof Stage ? obj.worksets : (noop(obj.workitems), null);
            }, 'workitems')
        ) readonly workitems: Workitem<Workset<Stage<TParent>>>[];

        get status(): TPrj.IWorkitem.Status {
            const { $props_: props } = this;
            return props.status;
        }

        set status(s: TPrj.IWorkitem.Status) {
            const { $props_: props } = this;
            if (isString(s)) {
                s = TPrj.IWorkitem.Status[s] as any;
            }

            props.status = s;
        }

        get order(): number {
            return this.$inherits?.order;
        }

        get children(): Workitem<Workset<Stage<TParent>>>[] {
            return this.workitems;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _stage: TPrj.IStage | Stage<TParent> = {}
        ) {
            super(_stage);
        }
    }

    @Editor.Collections(Stage)
    export class Stages<TParent extends Prj | ProjectType<Prj | Project>> extends Collection<Stage<TParent>, TParent, TPrj.IStage> implements TPrj.IStages {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _stages: (TPrj.IStage | Stage<TParent>)[] = []
        ) {
            super(parent, []);
            this.recreate(_stages);
        }

        construct(val: Stage<TParent> | TPrj.IStage): Stage<TParent> {
            return new Stage<TParent>(this.org, this.prj, this.parent, val);
        }
    }

    export class ProjectType<TParent extends Prj | Project> extends Editor.Editor<ProjectType<TParent>, TPrj.IProjectType> implements TPrj.IProjectType {
        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.refinherits,
            Editor.RefInherits<
                ProjectType<TParent>, TPrj.IProjectType, 'stages', Stage<Prj>
            >(function (this: ProjectType<TParent>): Stage<Prj>[] {
                return this.prj.stages;
            }, function (this: ProjectType<TParent>) {
                return new Stages<ProjectType<TParent>>(this.org, this.prj, this);
            }, 'stages')
        ) readonly stages: Stages<ProjectType<TParent>>;

        @Editor.Field(ProjectType, Editor.Type.refflattens,
            Editor.RefFlattens<
                ProjectType<TParent>, TPrj.IProjectType, 'workitems', Stage<ProjectType<TParent>>
            >(function (this: ProjectType<TParent>, obj: ProjectType<TParent> | Stage<ProjectType<TParent>>): Stage<ProjectType<TParent>>[] {
                return obj instanceof ProjectType ? obj.stages : (noop(obj.workitems), null);
            }, 'workitems')
        ) readonly workitems: Workitem<Workset<Stage<ProjectType<TParent>>>>[];

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        workflow: string; // bpmn formated xml data

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        svg: string; // svg string

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        json: object; // json for to bpmn formated workflow xml data

        get children(): Stages<ProjectType<TParent>> {
            return this.stages;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _projecttype: TPrj.IProjectType | ProjectType<TParent> = {}
        ) {
            super(_projecttype);
        }
    }

    @Editor.Collections(ProjectType)
    export class ProjectTypes extends Collection<ProjectType<Prj>, Prj, TPrj.IProjectType> implements TPrj.IProjectTypes {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            projecttypes: TPrj.IProjectType[] = [],
        ) {
            super(parent, []);
            this.recreate(projecttypes);
        }

        get children(): ProjectType<Prj>[] {
            return this;
        }

        construct(val: ProjectType<Prj> | TPrj.IProjectType): ProjectType<Prj> {
            return new ProjectType<Prj>(this.org, this.prj, this.parent, val);
        }
    }
}

export namespace Prj {
    export class Budget<TParent extends Project> extends Editor.Editor<Budget<TParent>, TPrj.IBudget> implements TPrj.IBudget {
        @JSON.Key()
        @Editor.Field(Budget, [Editor.Type.owned, Editor.Value.Type.enum, TPrj.Source, TPrj.Source.unknown])
        source: TPrj.Source;

        @JSON.Key()
        @Editor.Field(Budget, Editor.Type.owned)
        amount: number;

        @JSON.Key()
        @Editor.Field(Budget, Editor.Type.owned)
        consume: number;

        @JSON.Key()
        @Editor.Field(Budget, [Editor.Type.owned, Editor.Value.Type.date])
        start: Date;

        @JSON.Key()
        @Editor.Field(Budget, [Editor.Type.owned, Editor.Value.Type.date])
        end: Date;

        @JSON.Key()
        @Editor.Field(Budget, Editor.Type.owned)
        target: string;

        get budgets(): Budgets<TParent> {
            const { $props_: props } = this;
            return props.budgets;
        }

        get startend(): string {
            var rst = "";
            if (!isNull(this.start) && !isUndefined(this.start)) {
                rst = rst + this.start.getFullYear().toString();
            }

            rst = rst + "~";

            if (!isNull(this.end) && !isUndefined(this.end)) {
                rst = rst + this.end.getFullYear().toString();
            }

            return rst;
        }

        get percent(): number {
            const { amount = 0, consume = 0 } = this;
            if (amount <= 0) return 0;

            return Math.round(consume / amount * 1000) / 10;
        }

        checkYear(year: number): boolean {
            const { start, end } = this;
            if (!isNull(start) && !isUndefined(start) &&
                !isNull(end) && !isUndefined(end)) {
                return (year >= start.getFullYear()) && (year <= end.getFullYear());
            }

            return false;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _budgets: Budgets<TParent>,
            _budget: TPrj.IBudget = {}
        ) {
            super(_budget);

            const { $props_: props } = this;
            props.budgets = _budgets;
        }

        $onvalue_<Key extends Editor.TKeys<Budget<TParent>, TPrj.IBudget>>(key: Key, value: Budget<TParent>[Key], changed: boolean) {
            if (!(('start' == key && this.$changed_(key)) || ('end' == key && this.$changed_(key)))) return;

            const props = Property.Of<{ $onbreaking_: boolean }>(this).values, { budgets } = this;
            if (props.$onbreaking_ || !budgets) return;
            props.$onbreaking_ = true;

            {
                let startyear = this.start?.getFullYear(), endyear = this.end?.getFullYear();
                startyear = startyear ?? endyear ?? 0, endyear = endyear ?? startyear ?? 0;
                startyear = startyear == 0 ? endyear : startyear;
                endyear = endyear == 0 ? startyear : endyear;

                if (startyear == 0 || endyear == 0) {
                    budgets.length != 0 && budgets.clear();
                } else {
                    // sort the array, and then remove items not in-range
                    budgets.sort((a, b) => a.start?.getFullYear() || 0 - b.start?.getFullYear() || 0);

                    const exists = {}, todel = budgets.filter((val) => {
                        const year = val.start?.getFullYear() || 0;
                        if (exists[year]) return true;
                        exists[year] = true;

                        return year < startyear || year > endyear;
                    })

                    todel.length > 0 && budgets.destroy(todel)

                    // create budget if not exist
                    for (let idx = startyear; idx <= endyear; idx++) {
                        let pos = 0, nc = 0;

                        for (pos = 0, nc = budgets.length; pos < nc; pos++) {
                            const year = budgets[pos].start?.getFullYear();
                            if (year == idx) { pos = null; break; }
                            if (year > idx) break;
                        }

                        if (pos != null) {
                            budgets.create({ start: new Date(idx, 1, 1) }, pos)
                        }
                    }
                }
            }

            props.$onbreaking_ = false;
        }
    }

    @Editor.Collections(Budget)
    export class Budgets<TParent extends Project> extends Collection<Budget<TParent>, TParent, TPrj.IBudget> implements TPrj.IBudgets {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _budgets: TPrj.IBudget[] = [],
        ) {
            super(parent, []);
            this.recreate(_budgets);
        }

        construct(val: TPrj.IBudget): Budget<TParent> {
            return new Budget<TParent>(this.org, this.prj, this.parent, null, val);
        }
    }

    export class Plan extends Editor.Editor<Plan, TPrj.IPlan> implements TPrj.IPlan {
        @JSON.Key()
        @Editor.Field(Plan, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Plan, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Plan, Editor.Type.owned)
        description: string;

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            _plan: TPrj.IPlan = {}
        ) {
            super(_plan);
        }
    }

    @Editor.Collections(Plan)
    export class Plans extends Collection<Plan, Prj, TPrj.IPlan> implements TPrj.IPlans {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            plans: TPrj.IPlan[] = []
        ) {
            super(parent, []);
            this.recreate(plans)
        }

        construct(val: TPrj.IPlan): Plan {
            return new Plan(this.org, this.prj, this.parent, val);
        }
    }

    export class Project extends Editor.Editor<Project, TPrj.IProject> implements TPrj.IProject {
        @JSON.Key()
        @Editor.Field(Project, Editor.Type.guidid)
        id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        location: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        position: TPrj.IPosition;

        get positionXY(): string[] {
            return this.position?.[0]?.postion;
        }

        get positonDistrict(): string {
            return this.position?.[0]?.district;
        }

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.refinherit,
            Editor.RefInherit(function (this: Project): ProjectType<Prj>[] {
                return this.prj.projecttypes;   //获取实例projecttype全集
            }, function (this: Project, item: ProjectType<Prj>) {
                if (this.ispatched) return null; //打包项目没有projecttype
                return new ProjectType<Project>(this.org, this.prj, this, item);
            }, 'projecttype')
        ) projecttype: ProjectType<Project>;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owneds,
            Editor.Owneds(function (this: Project) {
                return new Budgets(this.org, this.prj, this);
            }, 'budgets')
        ) readonly budgets: Budgets<Project>;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned,
            Editor.Owned(function (this: Project, item: TPrj.IBudget) {
                return new Budget(this.org, this.prj, this, this.budgets, item);
            }, () => Budget, 'budget')
        ) readonly budget: Budget<Project>;

        @Editor.Field(Project, Editor.Type.refflattens,
            Editor.RefFlattens(function (this: Project, obj: Project | ProjectType<Project>): ProjectType<Project>[] {
                return obj instanceof Project ? [obj.projecttype] : (noop(obj?.workitems), null);
            }, 'workitems')
        ) readonly workitems: Workitem<Workset<Stage<ProjectType<Project>>>>[];

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.refowned,
            Editor.RefOwned(function (this: Project) {
                return this.prj.plans;
            }, () => Plan, 'plan')
        ) plan: Plan;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.refowned,
            Editor.RefOwned(function (this: Project) {
                return this.org.inspectors
            }, () => Org.Inspector, 'inspector')
        ) inspector: Org.Inspector;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.refowned,
            Editor.RefOwned(function (this: Project) {
                return this.org.actors;
            }, () => Org.Actor, 'actor')
        ) actor: Org.Actor;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        ispatched: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        processlock: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        cancelled: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        owncompany: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        owndept: string;

        // @JSON.Key()
        // @Editor.Field(Project, Editor.Type.refowned,
        //     Editor.RefOwned(function (this: Project) {
        //         return this.org.depts;
        //     }, () => Org.Dept, 'dept', function (this: Project): (() => Org.Dept) {
        //         return this.parent != this.prj.project ? () => this.parent?.dept : null;
        //     })
        // ) dept: Org.Dept;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Project) {
                return this.org.depts;
            }, function (this: Project) {
                return [] as Org.Dept[];
            }, 'dept')
        ) dept: Org.Dept[];

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        memo: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        isuniplan: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        uniplan: string;

        @JSON.Key()
        @Editor.Field(Project, [Editor.Type.owned, Editor.Value.Type.enum, Pool, Pool.input])
        pool: Pool;

        @Editor.Field(Project, Editor.Type.owned)
        readonly myproject: boolean;

        get exec(): TPrj.IPrjExec {
            const { $props_: { _project: { exec } = {} } } = this;
            if (exec) return exec;

            return this.resetExec(this._project?.exec);
        }

        get nextpool(): Pool | null {
            const { $props_: props } = this;
            return props.nextpool ?? null;
        }

        set nextpool(pool: Pool | null) {
            const { $props_: props } = this;
            props.nextpool = pool ?? null;

            if (!pool) {
                this.$cancel_('pool');
                return;
            }

            this.pool = pool;
        }

        get nextpools(): Pool[] | undefined {
            const { prj: { app } } = this, { prepareproject } = app;
            if (!prepareproject) return;

            const { exec: { keystages = {} } = {} } = this;
            const { auth: { me: { priviledges } } } = app;
            const prepool = this.$preval_('pool');

            const isDone = (pool: string): boolean => {
                if (_.has(keystages, '*')) return false;
                return !_.has(keystages, pool) || keystages[pool]
            }

            const actionMap: {
                [prepool in Pool]?: [
                    nextpool?: Pool,
                    condition?: boolean
                ][]
            } = {
                [Pool.input]: [
                    [Pool.meditate, !!priviledges.plan?.inputproject?.cud?.pool]
                ],
                [Pool.meditate]: [
                    [Pool.input, true],
                    [Pool.candidate, isDone('meditate')]
                ],
                [Pool.candidate]: [
                    [Pool.begun, isDone('candidate')],
                ],
                [Pool.begun]: [
                    [Pool.candidate, true],
                    [Pool.accept, isDone('begun')]
                ],
                [Pool.accept]: []
            }

            const nextpools = actionMap[prepool]?.map(([nextpool, condition]) => {
                if (condition) return nextpool;
            })?.filter(pool => !!pool);

            return (nextpools?.length ?? 0) > 0 ? nextpools : undefined;
        }

        get constrstatus(): TPrj.IWorkitem.Status {
            return this.exec?.constrstatus || TPrj.IWorkitem.Status.unknown;
        };

        get ispreproject(): boolean {
            var rst = false;
            rst = (this.constrtype?.indexOf(TPrj.ConstrType.projectpre) >= 0);
            return rst;
        }

        get formal(): boolean {
            return !this.ispreproject && !this.cancelled;
        }

        get constrtype(): TPrj.ConstrType[] {
            return this.exec?.constrtype || [TPrj.ConstrType.unknown];
        }

        get constrtypename(): string {
            const { prj: { app: { dict: { PrjConstrTypes: { indexed: constrTypes } } } } } = this;
            var rst = this.constrtype.map(val => constrTypes[val]?.title || "").join(",");
            return rst;
        }

        get constrstatusname(): string {
            const { constrstatus, prj: { app: { lang, dict: { AllStatus } } } } = this;

            if (constrstatus == TPrj.IWorkitem.Status.finished) {
                return lang.sysenums.prjfinished;
            }

            return AllStatus.indexed[constrstatus]?.title || "";
        }

        get execend(): Date {
            return this.exec?.end;
        }

        get fullName(): string {
            let fName: string = `${this.plan?.name || ""}/${this.name || ""}`;
            return fName;
        }

        get prjConsume(): number {
            var consume = 0;
            this.budgets.forEach(budget => {
                if (budget?.consume) {
                    consume += budget?.consume;
                }
            });

            return consume;
        }

        get prjLevel(): string {
            const { prj: { app: { dict: { enums: { PrjLevel: { PatchedProject, DetailProject } } } } } } = this;
            return this.ispatched ? PatchedProject : DetailProject;
        }

        getBudgetAmount(year: number | number[]): number {
            var amount = 0;
            this.budgets.forEach(budget => {
                if (typeof year == 'number') {
                    if (budget.start?.getFullYear() == year) {
                        if (budget?.amount) amount = budget?.amount;
                    }
                }

                if (Array.isArray(year) && year.length > 0) {
                    if (year.firstOf(budget.start?.getFullYear())) {
                        if (budget?.amount) amount += budget?.amount;
                    }
                }
            });

            return amount;
        }

        getBudgetConsume(year: number | number[]): number {
            var consume = 0;
            this.budgets.forEach(budget => {
                if (typeof year == 'number') {
                    if (budget.start?.getFullYear() == year) {
                        if (budget?.consume) consume = budget?.consume;
                    }
                }

                if (Array.isArray(year) && year.length > 0) {
                    if (year.firstOf(budget.start?.getFullYear())) {
                        if (budget?.consume) consume += budget?.consume;
                    }
                }
            });

            return consume;
        }

        getBudgetPercent(year: number): number {
            var percent = 0;
            this.budgets.forEach(budget => {
                if (budget.start?.getFullYear() == year) {
                    if (budget?.percent) percent = budget?.percent;
                }
            });

            return percent;
        }

        getBudgetTarget(year: number | number[]): string {
            var target = "";
            this.budgets.forEach(budget => {
                if (typeof year == 'number') {
                    if (budget?.start?.getFullYear() == year) {
                        target = budget?.target;
                    }
                }

                if (Array.isArray(year) && year.length > 0) {
                    if (budget?.start?.getFullYear() == year.last) {
                        target = budget?.target;
                    }
                }
            });

            return target;
        }

        getActorleaders(): string {
            const leaders = this.actor?.leaders?.map(v => {
                return v.name;
            }).toString();

            return leaders;
        }

        getActoractors(): string {
            const actors = this.actor?.actors?.map(v => {
                return v.name;
            }).toString();

            return actors;
        }

        getActoractorsdept(): string {
            const depts = this.actor?.actors?.map(v => {
                return v.dept?.name;
            }).toString();

            return depts;
        }

        getActordomains(): string {
            const domains = this.actor?.domains?.map(v => {
                return v.name;
            }).toString();

            return domains;
        }

        getActordomainsdept(): string {
            const depts = this.actor?.domains?.map(v => {
                return v.dept?.name;
            }).toString();

            return depts;
        }

        getActorsites(): string {
            const sites = this.actor?.sites?.map(v => {
                return v.name;
            }).toString();

            return sites;
        }

        getActorsitesdept(): string {
            const depts = this.actor?.sites?.map(v => {
                return v.dept?.name;
            }).toString();

            return depts;
        }

        getInspectormembers(): string {
            const inspectormembers = this.inspector?.kpimembers?.map(v => {
                return v.name;
            }).toString();

            return inspectormembers;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            public _project: TPrj.IProject = {}
        ) {
            super(_project);
        }

        $update_(value?: TPrj.IProject) {
            if (!value) return;

            const pool = value.pool as any, { pool: curpool } = this;
            value.pool = (_.isString(pool) ? Pool[pool] : pool) ?? curpool;

            // try to change project collection if pool changed
            if (this.resetCollection(value)) return;

            Project.UpdateIfHave(this, value, Project.ProjectUpdater);
            this.resetExec(value.exec);
            super.$update_(value);
        }

        resetExec(_exec?: TPrj.IPrjExec): TPrj.IPrjExec {
            const { $props_: props } = this;
            const project = (props._project = props._project || {});
            const exec = (project.exec = project.exec || {});
            if (!_exec) return exec;

            // reset the setting.
            this._project.exec = this._project.exec || {};
            _.merge(this._project.exec, _exec);

            // update the exec value
            Project.UpdateIfHave(exec, _exec, Project.ExecUpdater);
            return exec;
        }

        resetCollection(value: TPrj.IProject): boolean {
            const { pool = 0 } = value, prepool = this.$preval_('pool') ?? 0;
            if (pool == prepool) return false;

            const { poolmap } = Prj, { prj } = this;
            const curpoolmsk = (pool & Pool.poolmask), prepoolmsk = (prepool & Pool.poolmask);
            const curcoll = poolmap[curpoolmsk], precoll = poolmap[prepoolmsk];

            if (curpoolmsk == prepoolmsk) {
                prj[precoll]?.refresh?.();
                return false;
            }

            prj[precoll]?.remove(this), prj[precoll]?.refresh?.();
            if (Backface.Of(prj, curcoll)?.loaded) {
                prj[curcoll]?.create(value, 0), prj[curcoll]?.refresh?.();
            }

            return true;
        }

        static stdValue(prj: DeepPartial<Project>): DeepPartial<Project> {
            return Sys.stdEnumValue(prj, Project.EnumValue);
        }

        static readonly EnumValue = {
            pool: TPrj.Pool
        }

        static readonly ExecUpdater: {
            [P in keyof TPrj.IPrjExec]-?: (exec: TPrj.IPrjExec, val: any) => TPrj.IPrjExec[P]
        } = {
                constrtype: (exec: TPrj.IPrjExec, val: any) => (exec.constrtype = val?.map(v => TPrj.ConstrType[v] as any)),
                constrstatus: (exec: TPrj.IPrjExec, val) => (exec.constrstatus = TPrj.IWorkitem.Status[val] as any),
                begin: (exec: TPrj.IPrjExec, val) => (exec.begin = Editor.Value.toDate(val)),
                end: (exec: TPrj.IPrjExec, val) => (exec.end = Editor.Value.toDate(val)),
                keystages: (exec: TPrj.IPrjExec, val) => (exec.keystages = val),
                duration: (exec: TPrj.IPrjExec, val) => (exec.duration = val),
            }

        static readonly ProjectUpdater: {
            [P in keyof Project]?: (project: Project, val: any) => void
        } = {
                pool: (project: Project, val: any) => {
                    project.$setting_.pool = _.isString(val) ? Pool[val] : val;
                },
                myproject: (project: Project, val: any) => {
                    project.$setting_.myproject = val;
                }
            }
    }

    export class PilotProject extends Project {

    }

    export class InputProject extends Project {

    }

    @Editor.Collections(Project)
    export class Projects extends Collection<Project, Prj, TPrj.IProject> implements TPrj.IProjects, OnDestroy {
        protected _props = Property.Of<Projects & {
            pools: {
                [pool: string]: {
                    projects: Project[],
                    pool: Pool
                }
            }
        }>(this).values;

        get begun(): Project[] | undefined {
            const { _props: { pools: pools } } = this;
            return (pools['begun'] || (
                pools['begun'] = this.createPool(
                    Pool.begun
                )
            )).projects
        }

        get accept(): Project[] | undefined {
            const { _props: { pools } } = this;
            return (pools['accept'] || (
                pools['accept'] = this.createPool(
                    Pool.accept
                )
            ))?.projects
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            _projects: TPrj.IProject[] = []
        ) {
            super(parent, []);
            this.recreate(_projects);
            this._props.pools = {};
        }

        ngOnDestroy(): void {

        }

        construct(val: TPrj.IProject): Project {
            return new Project(this.org, this.prj, this.parent, val);
        }

        refresh() {
            Promise.resolve().then(() => {
                _.forEach(this._props.pools, (c => {
                    c.projects.reset(this.extractPool(c.pool));
                }))
            })
        }

        protected extractPool(pool: Pool): Project[] {
            return this.filter(p => p.pool == pool);
        }

        protected createPool(pool: Pool): Projects['_props']['pools'][string] | undefined {
            const { prj: { app: { prepareproject } } } = this;
            if (!prepareproject) return;

            return {
                projects: this.extractPool(pool),
                pool: pool
            }
        }
    }

    @Editor.Collections(PilotProject)
    export class PilotProjects extends Projects {
        get meditate(): Project[] | undefined {
            const { _props: { pools } } = this;
            return (pools['meditate'] || (
                pools['meditate'] = this.createPool(
                    Pool.meditate
                )
            ))?.projects
        }

        get candidate(): Project[] | undefined {
            const { _props: { pools } } = this;
            return (pools['candidate'] || (
                pools['candidate'] = this.createPool(
                    Pool.candidate
                )
            ))?.projects
        }

        construct(val: TPrj.IProject): PilotProject {
            return new PilotProject(this.org, this.prj, this.parent, val);
        }
    }

    @Editor.Collections(InputProject)
    export class InputProjects extends Projects {
        construct(val: TPrj.IProject): InputProject {
            return new InputProject(this.org, this.prj, this.parent, val);
        }
    }

    export class SearchFilter extends Editor.Editor<SearchFilter, TPrj.ISearchFilter> implements TPrj.ISearchFilter {
        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.guidid)
        id: Unique.guid;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.refowned,
            Editor.RefOwned(function (this: SearchFilter) {
                return this.org?.peoples;
            }, () => Org.People, 'people')
        ) people: Org.People;

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owned, Editor.Value.Type.date])
        date: Date;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.owned)
        nowyear: boolean;

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owned, Editor.Value.Type.date])
        start: Date;

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owned, Editor.Value.Type.date])
        end: Date;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: SearchFilter) {
                return this.prj.plans;
            }, function (this: SearchFilter) {
                return [] as Plan[];
            }, 'plan')
        ) readonly plan: Plan[];

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: SearchFilter) {
                return this.org.depts;
            }, function (this: SearchFilter) {
                return [] as Org.Dept[];
            }, 'dept')
        ) readonly dept: Org.Dept[];

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owneds, Editor.Value.Type.enum, TPrj.IWorkitem.Status],
            Editor.Owneds(function (this: SearchFilter) {
                return [];
            }, 'constrstatus'))
        constrstatus?: TPrj.IWorkitem.Status[];

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owneds, Editor.Value.Type.enum, TPrj.ConstrType], Editor.Owneds(function (this: SearchFilter) {
            return [];
        }, 'constrtype'))
        constrtype?: TPrj.ConstrType[];

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.refowneds, Editor.RefOwneds(function (this: SearchFilter) {
            return this.prj.workitems;
        }, function (this: SearchFilter) {
            return [] as Prj.Workitem<Prj>[];
        }, 'workitems'))
        readonly workitems: Prj.Workitem<Prj>[];

        get searchelements(): TPrj.ISearchElement[] {
            var p = [];
            if (!this.workitems || this.workitems.length <= 0) {
                this.prj.projecttypes.forEach(pt => {
                    pt.stages.forEach(st => {
                        let temp: TPrj.ISearchElement = p.firstOf(se => se.name == st.name);
                        if (!temp) p.push(temp = { name: st.name, ids: [], children: [] });
                        temp.ids.push(st.id);
                        st.workitems.forEach(wt => {
                            if (!temp.children.firstOf(child => child.id == wt.id)) { temp.children.push({ id: wt.id, name: wt.name }); }
                        });
                    });
                });
            } else {
                this.prj.projecttypes.forEach(pt => {
                    pt.stages.forEach(st => {
                        let temp: TPrj.ISearchElement = p.firstOf(se => se.name == st.name);
                        if (!temp) p.push(temp = { name: st.name, ids: [], children: [] });
                        temp.ids.push(st.id);
                        st.workitems.forEach(wt => {
                            if (!temp.children.firstOf(child => child.id == wt.id) && this.workitems.firstOf(ws => ws.id == wt.id)) { temp.children.push({ id: wt.id, name: wt.name }); }
                        });
                    });
                });
            }

            return p;
        };

        get stagecount(): number {
            let iLen: number = 0;
            iLen += this.searchelements ? this.searchelements.length : 0
            return iLen;
        }

        get workitemcount(): number {
            let iLen: number = 0;
            this.searchelements.forEach(se => {
                iLen += se.children?.length;
            });
            return iLen;
        }

        checkstage(id: string): string {
            var stage = this.searchelements.find(se => se.ids.includes(id));
            return stage ? stage.ids[0] : null;
        }

        checkworkitem(id: string): boolean {
            var ws = this.searchelements.reduce((res, v) => {
                res.push(v.children.map(p => p.id)); return res;
            }, []).find(wsid => wsid == id);
            return (!isNull(ws) && !isUndefined(ws));
        }

        get selectedYears(): number[] {
            const { prj: { app: { dict: { currentYear, rangeyearmin, rangeyearmax } } } } = this;

            var years = [];
            if (this.nowyear) {
                years = [currentYear, currentYear];
            } else {
                var ts = rangeyearmin, tn = rangeyearmax;
                if (this.start) ts = this.start.getFullYear();
                if (this.end) tn = this.end.getFullYear();
                if (this.start || this.end) years = [ts, tn];
            }

            return years;
        }

        get searchfilterparam(): object {
            const { prj: { app: { dict: { currentYear, rangeyearmin, rangeyearmax, AllStatus, PrjConstrTypes } } } } = this;

            var param = {};
            if (this.nowyear) {
                param["year"] = [currentYear, currentYear];
            } else {
                var ts = rangeyearmin, tn = rangeyearmax;
                if (this.start) ts = this.start.getFullYear();
                if (this.end) tn = this.end.getFullYear();
                if (this.start || this.end) param["year"] = [ts, tn];
            }

            if (this.dept) {
                param["owndept"] = this.plan.map(v => ({ id: v.id }));
            }

            if (this.plan) {
                param["plan"] = this.plan.map(v => ({ id: v.id }));
            }

            if (this.workitems) {
                param["workitem"] = param["workitem"] || {};
                param["workitem"]["id"] = this.workitems.map(v => v.id);
            }

            // if(this.constrstatus) {
            //     param["constrstatus"] = this.constrstatus.reduce((res,v)=>{
            //         res.push(AllStatus[v].key); return res;
            //     },[])
            // }            

            // if(this.constrtype) {
            //     param["constrtype"] = this.constrtype.reduce((res,v)=>{
            //         res.push(PrjConstrTypes[v].key); return res;
            //     },[])
            // }

            return param;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            _searchfilter: TPrj.ISearchFilter = {}
        ) {
            super(_searchfilter);
        }
    }

    @Editor.Collections(SearchFilter)
    export class SearchFilters extends Collection<SearchFilter, Prj, TPrj.ISearchFilter> implements TPrj.ISearchFilters {
        constructor(
            public org: Org,
            public prj: Prj,
            _searchfilters?: TPrj.ISearchFilter[]
        ) {
            super(prj, []);
            this.recreate(_searchfilters);
        }

        construct(val: TPrj.ISearchFilter): SearchFilter {
            return new SearchFilter(this.org, this.prj, val);
        }
    }

    export class SearchFiltersHost extends Editor.Editor<SearchFiltersHost, TPrj.IPrj> {
        constructor(
            public app: AppService
        ) {
            super(app.backface.prj);
        }

        @JSON.Key()
        @Backface.Entity(() => SearchFilter, ["prj", "search_filters", true])
        @Editor.Field(SearchFiltersHost, Editor.Type.owneds,
            Editor.Owneds(function (this: SearchFiltersHost) {
                const { app: { org, prj } } = this;
                return new SearchFilters(org, prj);
            }, 'searchFilters')
        ) readonly searchFilters: SearchFilters;
    }

    export class SearchElement implements TPrj.ISearchElement {
        name: string;
        ids: string[];
        children: { id: string, name: string }[];
        checkstage(id: string): boolean {
            return this.ids.includes(id);
        }

        checkworkitem(id: string): boolean {
            var ws = this.children.find(ws => ws.id == id);
            return (!isNull(ws) && !isUndefined(ws));
        }
    }

}

export namespace Prj {
    export type ProjProjectType = Prj.ProjectType<Prj.Project>;
    export type ProjStage = Prj.Stage<ProjProjectType>;
    export type ProjWorkset = Prj.Workset<ProjStage>;
    export type ProjWorkitem = Prj.Workitem<Project | ProjWorkset>;
    export type ProjCheckpoint = Prj.Checkpoint<ProjWorkitem>;

    export type PrjProjectType = Prj.ProjectType<Prj>;
    export type PrjStage = Prj.Stage<Prj | PrjProjectType>;
    export type PrjWorkset = Prj.Workset<Prj | PrjStage>;
    export type PrjWorkitem = Prj.Workitem<Prj | PrjWorkset>;
    export type PrjCheckpoint = Prj.Checkpoint<Prj | PrjWorkitem>;

    export type ProjStages = Prj.Stages<ProjProjectType>
    export type PrjStages = Prj.Stages<Prj | PrjProjectType>;

    export type ProjWorksets = Prj.Worksets<ProjStage>;
    export type PrjWorksets = Prj.Worksets<Prj | PrjStage>;

    export type ProjWorkitems = Prj.Workitem<ProjWorkset>;
    export type PrjWorkitems = Prj.Workitems<Prj | PrjWorkset>;

    export type ProjCheckpoints = Prj.Checkpoints<ProjWorkitem>;
    export type PrjCheckpoints = Prj.Checkpoints<Prj | PrjWorkitem>;

    export interface SumProjectData {
        id?: string,
        name?: string,
        pool?: string,
        owncompany?: string,
        owndept?: string,
        location?: string,
        positonDistrict?: string,
        description?: string,
        constrtype?: string,
        constrstatus?: string,
        memo?: string,

        planname?: string,

        budgetstartend?: string,
        budgetamount?: string,
        budgetyearamount?: string,
        budgetyeartarget?: string,
        budgetyearconsume?: string,
        budgetyearpercent?: string,

        actorheader?: string,

        actorleaders?: string,
        actorleadersdept?: string,

        actoractors?: string,
        actoractorsdept?: string,

        actordomains?: string,
        actordomainsdept?: string,

        actorsites?: string,
        actorsitesdept?: string,
        actorprjowner?: string,

        inspectorkpileader?: string,
        inspectorkpimembers?: string,

        progresscomment?: string,
        problems?: string
    };
}