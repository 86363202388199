import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EventEmitter, Type } from '@angular/core';
import * as rxop from "rxjs/operators";
import { isBoolean } from 'lodash';
import * as _ from 'lodash';
import * as rx from 'rxjs';

import { Exec as TExec, Sys as TSys, Prj as TPrj } from './types';
import { Collection } from '../../../utils/libs/collection';
import { _JSON } from '../../../utils/libs/polyfill/json';
import { Property } from "../../../utils/libs/property";
import { Editor } from '../../../utils/libs/editor';
import { Unique } from '../../../utils/libs/unique';
import { AppService } from '../app.service';
import { Backface } from './config';
import { Org } from "./org";
import { Prj } from './prj';

export class Exec extends Editor.Editor<Exec, TExec.IExec> implements TExec.IExec {
    @JSON.Key()
    @Backface.Entity(() => Exec.ResourceType, "exec")
    @Editor.Field(Exec, Editor.Type.owneds,
        Editor.Owneds(function (this: Exec) {
            return new Exec.ResourceTypes(this.app);
        }, 'resource_types')
    ) readonly resource_types: Exec.ResourceTypes;

    @Backface.Entity(() => Exec.Problem, "exec")
    readonly problems?: Exec.Problem[];

    @Backface.Entity(() => Exec.Result, "exec")
    readonly results?: Exec.Results;

    /*
    @JSON.Key()
    @Backface.Entity(() => Exec.Result, "exec")
    @Editor.Field(Exec, Editor.Type.owneds,
        Editor.Owneds(function (this: Exec) {
            return new Exec.Results(this.org, this.prj, this);
        }, 'results')
    ) readonly results: Exec.Results;

    @Backface.Entity(() => Exec.Problem, "exec")
    @Editor.Field(Exec, Editor.Type.refflattens,
        Editor.RefFlattens(function (this: Exec, obj: Exec | Exec.Result): Exec.Result[] {
            if (obj instanceof Exec) return obj.results;
            if (!obj) return;

            const { value } = obj;
            value?.$fields_?.forEach(
                f => value[f.key]
            );

            return null;
        }, 'problems')
    ) readonly problems: Exec.Problem[];
    */

    constructor(
        public app: AppService,
        setting?: TExec.IExec
    ) {
        super(setting);
    }
}

export namespace Exec {
    export class Result<T extends Result<T, TI> = Result<never, TExec.IResult>, TI extends TExec.IResult = TExec.IResult> extends Editor.Editor<T, TI> implements TExec.IResult {
        @JSON.Key()
        @Editor.Field(Result, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.refowned,
            Editor.RefOwned(function (this: Result) {
                return this.app.prj.project;
            }, () => Prj.Project, 'project')
        ) project: Prj.Project;

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.refowned,
            Editor.RefOwned(function (this: Result) {
                return this.projecttype?.stages;
            }, () => Prj.Stage, 'stage', function (this: Result) {
                return () => {
                    const workitem = this.workitem;
                    const workset = workitem?.parent;
                    const parent = workset?.parent;
                    if (parent instanceof Prj.Stage) {
                        return parent;
                    }
                }
            })
        ) stage: Prj.ProjStage;

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.refowned,
            Editor.RefOwned(function (this: Result) {
                return this.stage?.worksets;
            }, () => Prj.Workset, 'workset', function (this: Result) {
                return () => {
                    const workitem = this.workitem;
                    const parent = workitem?.parent;
                    if (parent instanceof Prj.Workset) {
                        return parent;
                    }
                }
            })
        ) workset: Prj.ProjWorkset;

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.refowned,
            Editor.RefOwned(function (this: Result) {
                const { _result: { projecttype: { id: ptid } = {}, stage: { id: sid } = {} } } = this;
                const { _result: { workset: { id: wsetid } = {}, workitem = {} } } = this;
                const { app: { prj: { workitems: gworkitems } } } = this;
                if (workitem instanceof Prj.Workitem) return [workitem];

                if (!workitem.type && workitem.id) {
                    workitem.type = gworkitems.find(
                        w => w.id == workitem.id
                    )?.type
                }

                if (workitem.type == TPrj.IWorkitem.Type.supervise) {
                    return gworkitems as any;
                }

                if (!ptid) {
                    return [];
                }

                return this.projecttype?.workitems;
            }, () => Prj.Workitem, 'workitem')
        ) workitem: Prj.ProjWorkitem;

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.owned,
            Editor.Owned(function (this: Result, item: { [key: string]: any }) {
                return new Editor.Dynamic(item, this, this.workitem?.checkpoints, Exec.FieldTypes);
            }, () => Editor.Dynamic, 'value')
        ) value: Editor.Dynamic<Result>;

        @JSON.Key()
        @Editor.Field(Result, [Editor.Type.owned, Editor.Value.Type.date])
        date: Date;

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.refowned,
            Editor.RefOwned(function (this: Result) {
                return this.app?.org?.peoples;
            }, () => Org.People, 'people')
        ) people: Org.People;

        @JSON.Key()
        @Editor.Field(Result, [Editor.Type.owned, Editor.Value.Type.enum, TSys.Result, TSys.Result.none])
        result: TSys.Result;

        @JSON.Key()
        @Editor.Field(Result, [Editor.Type.owned, Editor.Value.Type.enum, TSys.Reason, TSys.Reason.none])
        reason: TSys.Reason;

        @JSON.Key()
        get projecttype(): Prj.ProjProjectType {
            return this.project?.projecttype;
        }

        get pics(): Pics {
            return this.$props_.pics;
        }

        set pics(val: Pics) {
            this.$props_.pics = val;
        }

        get files(): XFiles {
            return this.$props_.files;
        }

        set files(val: XFiles) {
            this.$props_.files = val;
        }

        constructor(
            public app: AppService,
            public _result: TExec.IResult
        ) {
            super(_result as TI);

            const { value } = this, { consumeByProject } = app;
            value.$exclude_['yearlyinvested'] = consumeByProject;
            value.$json.exclude['yearlyinvested'] = consumeByProject;
            value.$json.exclude['notused'] = true;
            value.$exclude_['notused'] = true;
        }

        $save_(state?: { drilling?: boolean; }): boolean | rx.Observable<boolean> {
            const { $eprops_: eprops, $props_: props } = this, lsaving = "lastsaving";
            if (eprops.$saving_) return props[lsaving];

            // wait all uploading pic/file to finish
            const uploads = [...(this.pics ?? []), ...(this.files ?? [])].map(
                (xfile) => xfile.uploaded
            ).filter(
                e => e
            );

            if (uploads?.length > 0) {
                eprops.$saving_ = true;

                const _ret = (props[lsaving] = rx.zip(...uploads).pipe(
                    rxop.concatMap((): rx.Observable<boolean> => {
                        const ret = super.$save_(state);
                        return isBoolean(ret) ? rx.of(ret) : ret;
                    }),
                    rxop.catchError((error: any): rx.Observable<boolean> => {
                        eprops.$saving_ = false;
                        return rx.of(false);
                    }),
                    rxop.finalize(() => {
                        _sub.unsubscribe();
                    }),
                    rxop.shareReplay(1)
                )), _sub = _ret.subscribe();

                return _ret;
            }

            return (props[lsaving] = super.$save_(state));
        }

        $update_(value?: TI) {
            if (!value) return;

            super.$update_(value);
            const { project } = this;
            project?.$update_(value.project);
        }
    }

    export class Audit extends Editor.Editor<Audit, TExec.IAudit> implements TExec.IAudit {
        @JSON.Key()
        @Editor.Field(Audit, Editor.Type.owned)
        comment: string;

        @JSON.Key()
        @Editor.Field(Audit, [Editor.Type.owned, Editor.Value.Type.date])
        date: Date;

        @JSON.Key()
        @Editor.Field(Audit, [Editor.Type.owned, Editor.Value.Type.enum, TExec.IResult.Audit.Status, TExec.IResult.Audit.Status.auditting])
        status: TExec.IResult.Audit.Status;

        @JSON.Key()
        @Editor.Field(Audit, Editor.Type.refowned,
            Editor.RefOwned(function (this: Audit) {
                return this.result.app?.org?.peoples;
            }, () => Org.People, 'people')
        ) people: Org.People;

        constructor(
            public result: AuditResult,
            public _audit: TExec.IAudit
        ) {
            super(_audit);
        }
    }

    export class AuditResult extends Result<AuditResult, TExec.IAuditResult> implements TExec.IAuditResult {
        private _props = Property.Of(this).values;

        @JSON.Key()
        @Editor.Field(AuditResult, Editor.Type.owned,
            Editor.Owned(function (this: AuditResult, item: TExec.IAudit) {
                return new Audit(this, item);
            }, () => Audit, 'audit')
        ) readonly audit: Audit;

        get status(): TExec.IResult.Audit.Status {
            return this._props.status
        }

        get callback(): boolean {
            const { IResult: { Audit: { Status: { callback } } } } = TExec;
            return this.audit.status == callback;
        }

        set callback(val: boolean) {
            const { IResult: { Audit: { Status: { callback } } } } = TExec;
            this.audit.status = val ? callback : this.status;
        }

        constructor(
            public app: AppService,
            public _result: TExec.IAuditResult
        ) {
            super(app, _result);

            const { audit: { status } = {} } = this;
            const { _props: props } = this;
            props.status = status;
        }
    }

    @Editor.Collections(Result)
    export class Results extends Collection<Result, AppService, TExec.IResult> implements TExec.IResults {
        constructor(
            public app: AppService,
            results: TExec.IResult[] = []
        ) {
            super(app, []);
            this.recreate(results);
        }

        construct(val: Result | TExec.IResult): Result {
            return val instanceof Result ? val : new Result(this.app, val);
        }

        _onAddRemoved({ added, removed }: { added: Result[]; removed: Result[] }) {
            super._onAddRemoved?.({ added, removed });

            (removed || []).forEach(r => {
                const workitem = r.workitem;
                workitem && this.perworkitem(workitem, true).remove(r);
            });

            (added || []).forEach(r => {
                const workitem = r.workitem;
                workitem && this.perworkitem(workitem, true).add(r);
            });
        }

        perworkitem(item: Prj.ProjWorkitem, needcreate: boolean = false): Exec.Result[] {
            const cache = Property.Of<{
                map: Map<
                    Prj.ProjWorkitem,
                    Exec.Result[]
                >
            }>(this).values;

            const map = (cache.map = cache.map || new Map());
            if (!map.has(item) && needcreate) map.set(item, []);
            return map.get(item);
        }
    }
}

export namespace Exec {

    export class ResourceType extends Editor.Editor<ResourceType, TExec.IResourceType> implements TExec.IResourceType {
        @Editor.Field(ResourceType, Editor.Type.owned)
        readonly id: number;

        @Editor.Field(ResourceType, Editor.Type.owned)
        readonly mime_type: string;

        @Editor.Field(ResourceType, Editor.Type.owned)
        readonly content_type: string;

        constructor(
            _resource_type: TExec.IResourceType = {}
        ) {
            super(_resource_type);
        }
    }

    @Editor.Collections(ResourceType)
    export class ResourceTypes extends Collection<ResourceType, AppService, TExec.IResourceType> implements TExec.IResourceTypes {
        constructor(
            public app: AppService,
            _resource_types: TExec.IResourceType[] = []
        ) {
            super(app, []);
            this.recreate(_resource_types);
        }

        construct(val: ResourceType | TExec.IResourceType): ResourceType {
            return val instanceof ResourceType ? val : new ResourceType(val);
        }
    }

    export class Resource extends Editor.Editor<Resource, TExec.IResource> implements TExec.IResource {
        @JSON.Key()
        @Editor.Field(Resource, Editor.Type.owned)
        readonly id: Unique.guid;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly digest: string;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly url: string;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly source: string;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly size: number;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly duration: number;

        @Editor.Field(Resource, Editor.Type.refowned,
            Editor.RefOwned(function (this: Resource) {
                return this.parent?.parent?.app?.exec?.resource_types;
            }, () => ResourceType, 'resource_type')
        ) readonly resource_type: ResourceType;

        constructor(
            public parent: Pic | XFile,
            _resource: TExec.IResource = {}
        ) {
            super(_resource)
        }
    }

    const fileItemHacks = ['_prepareToUploading', 'onBuildForm', 'onComplete', 'onProgress', 'onSuccess', 'onCancel', 'onError'];
    export class Pic extends Editor.Editor<Pic, TExec.IPic> implements TExec.IPic {
        static readonly canupload: string = '__canupload';
        static readonly uploaded: string = '__uploaded';

        @JSON.Key()
        @Editor.Field(Pic, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Pic, Editor.Type.owned)
        comment: string;

        @JSON.Key()
        @Editor.Field(Pic, Editor.Type.owned,
            Editor.Owned(function (this: Pic, item: TExec.IResource) {
                return item && (new Resource(this, item))
            }, () => Resource, 'resource')
        ) resource: Resource;

        get fileItem(): FileItem {
            const { $props_: props } = this;
            return props.fileItem;
        }

        set fileItem(val: FileItem) {
            const { $props_: props, $props_: { fileItem } } = this;

            if (val != fileItem && fileItem) {
                fileItemHacks.forEach(key => {
                    fileItem[key] = props[key];
                })

                fileItem[Pic.uploaded] = null;
                props.fileItem = null;
                props.url = null;
            }

            if (!val) return;

            const file = val._file;
            const windowURL = window.URL || window.webkitURL;
            props.url = windowURL?.createObjectURL?.(file);
            props.fileItem = val;

            val[Pic.uploaded] = new EventEmitter();
            fileItemHacks.forEach(key => {
                props[key] = val[key];
                val[key] = this[key];
            })
        }

        get url(): string {
            const { resource, $props_: props } = this;
            if (resource) {//location.origin + "/" + 
                return resource.url || resource.source;
            }

            return props.url;
        }

        get content_type(): string {
            return this.fileItem?.file.type.split('/')[0] || this.resource?.resource_type?.content_type;
        }

        get mime_type(): string {
            return this.fileItem?.file.type.split('/')[1] || this.resource?.resource_type?.mime_type;
        }

        get uploader(): FileUploader {
            return this.pics.uploader;
        }

        get uploaded(): EventEmitter<void> {
            return this.fileItem?.[Pic.uploaded];
        }

        constructor(
            public parent: Result,
            public pics: Pics,
            _pic: TExec.IPic = {}
        ) {
            super(_pic);

            fileItemHacks.forEach(key => {
                this[key] = this[key].bind(this);
            })

            this.fileItem = _pic.fileItem;
        }

        onLoaded(ev: Event) {
            const { fileItem } = this;
            if (!fileItem) { return; }

            if (ev.currentTarget instanceof HTMLVideoElement) {
                fileItem.formData = {
                    duration: ev.currentTarget.duration
                }
            }

            // start to upload
            this.fileItem[Pic.canupload] = true;
            this.pics.uploader.uploadAll();
        }

        onBuildForm(form: FormData): any {
            const { $props_: props, fileItem, fileItem: { formData } } = this;
            if (!(form && formData)) return;

            _.forEach(formData, (v, k) => {
                form.append(k, v);
            })

            return props.onBuildForm?.call(fileItem, form);
        }

        onSuccess(response: string | HttpResponse<any>, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            // successfully uploaded.
            this.resource = (response as HttpResponse<any>)?.body;
            this.uploaded?.emit();

            // clear file item related.
            this.fileItem[Pic.uploaded] = null;
            this.fileItem = null;

            return props.onSuccess?.call(fileItem, response, status, headers);
        }

        onError(response: string | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onError?.call(fileItem, response, status, headers);
        }

        onComplete(response: string | HttpResponse<any> | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onComplete?.call(fileItem, response, status, headers);
        }

        onCancel(response: string, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onCancel?.call(fileItem, response, status, headers);
        }

        onProgress(progress: number): any {
            const { $props_: props, fileItem } = this;

            return props.onProgress?.call(fileItem, progress);
        }

        _prepareToUploading(): void {
            const { $props_: props, fileItem } = this;
            if (!fileItem?.[Pic.canupload]) return;

            props._prepareToUploading.call(fileItem);
        }
    }

    @Editor.Collections(Pic)
    export class Pics extends Collection<Pic, Result, TExec.IPic> implements TExec.IPics {
        private _props = Property.Of(this).values;

        get uploader(): FileUploader {
            const { _props: props } = this;
            let uploader: FileUploader;

            return props.uploader = props.uploader || (uploader = props.uploader =
                new FileUploader({
                    itemAlias: "uploadedfile",
                    url: "/resource/upload",
                    removeAfterUpload: true,
                    autoUpload: false,
                    method: "POST",
                    filters: [{
                        name: 'media_filter',
                        fn: (
                            item?: FileLikeObject,
                            options?: FileUploaderOptions
                        ): boolean => {
                            if (this.parent.$saving_) {
                                return false;
                            }

                            return !this.exceedamount;
                        }
                    }]
                }),
                uploader.onAfterAddingFile = (item: FileItem): void => {
                    this.create({ fileItem: item });
                },
                uploader
            )
        }

        get exceedamount(): boolean {
            const { parent: { app: { lang } } } = this;
            const { general: { mediamaxamount = 12 } = {} } = lang;
            return this.length >= Number.parseInt(mediamaxamount);
        }

        constructor(
            public parent: Result,
            _pics: TExec.IPic[] = []
        ) {
            super(parent, []);
            parent.pics = this;
            this.recreate(_pics);
        }

        construct(val: Pic | TExec.IPic): Pic {
            return val instanceof Pic ? val : new Pic(this.parent, this, val);
        }

        _onAddRemoved({ removed, added }: { removed: Pic[]; added: Pic[]; }) {
            super._onAddRemoved?.({ added, removed });

            const { uploader } = this;
            removed?.forEach(f => {
                const { fileItem } = f;
                fileItem && uploader.cancelItem(fileItem);
                fileItem && uploader.removeFromQueue(fileItem)
            });
        }
    }

    export class XFile extends Editor.Editor<XFile, TExec.IXFile> implements TExec.IXFile {
        static readonly canupload: string = '__canupload';
        static readonly uploaded: string = '__uploaded';

        @JSON.Key()
        @Editor.Field(XFile, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(XFile, Editor.Type.owned)
        comment: string;

        @JSON.Key()
        @Editor.Field(XFile, Editor.Type.owned,
            Editor.Owned(function (this: XFile, item: TExec.IResource) {
                return item && (new Resource(this, item))
            }, () => Resource, 'resource')
        ) resource: Resource;

        get fileItem(): FileItem {
            const { $props_: props } = this;
            return props.fileItem;
        }

        set fileItem(val: FileItem) {
            const { $props_: props, $props_: { fileItem } } = this;

            if (val != fileItem && fileItem) {
                fileItemHacks.forEach(key => {
                    fileItem[key] = props[key];
                })

                fileItem[XFile.uploaded] = null;
                props.fileItem = null;
                props.url = null;
            }

            if (!val) return;

            const file = val._file;
            const windowURL = window.URL || window.webkitURL;
            props.url = windowURL?.createObjectURL?.(file);
            props.fileItem = val;

            val[XFile.uploaded] = new EventEmitter();
            fileItemHacks.forEach(key => {
                props[key] = val[key];
                val[key] = this[key];
            })
        }

        get url(): string {
            const { resource, $props_: props } = this;
            if (resource) {//location.origin + "/" + 
                return resource.url || resource.source;
            }

            return props.url;
        }

        get content_type(): string {
            return this.fileItem?.file.type.split('/')[0] || this.resource?.resource_type?.content_type;
        }

        get mime_type(): string {
            return this.fileItem?.file.type.split('/')[1] || this.resource?.resource_type?.mime_type;
        }

        get uploader(): FileUploader {
            return this.files.uploader;
        }

        get uploaded(): EventEmitter<void> {
            return this.fileItem?.[XFile.uploaded];
        }

        constructor(
            public parent: Result,
            public files: XFiles,
            _file: TExec.IXFile = {}
        ) {
            super(_file);

            fileItemHacks.forEach(key => {
                this[key] = this[key].bind(this);
            })

            this.fileItem = _file.fileItem;
        }

        onLoaded(ev?: Event) {
            const { fileItem } = this;
            if (!fileItem) { return; }

            if (ev?.currentTarget instanceof HTMLVideoElement) {
                fileItem.formData = {
                    duration: ev.currentTarget.duration
                }
            }

            // start to upload
            this.fileItem[XFile.canupload] = true;
            this.files.uploader.uploadAll();
        }

        onBuildForm(form: FormData): any {
            const { $props_: props, fileItem, fileItem: { formData } } = this;
            if (!(form && formData)) return;

            _.forEach(formData, (v, k) => {
                form.append(k, v);
            })

            return props.onBuildForm?.call(fileItem, form);
        }

        onSuccess(response: string | HttpResponse<any>, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            // successfully uploaded.
            this.resource = (response as HttpResponse<any>)?.body;
            this.uploaded?.emit();

            // clear file item related.
            this.fileItem[XFile.uploaded] = null;
            this.fileItem = null;

            return props.onSuccess?.call(fileItem, response, status, headers);
        }

        onError(response: string | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onError?.call(fileItem, response, status, headers);
        }

        onComplete(response: string | HttpResponse<any> | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onComplete?.call(fileItem, response, status, headers);
        }

        onCancel(response: string, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onCancel?.call(fileItem, response, status, headers);
        }

        onProgress(progress: number): any {
            const { $props_: props, fileItem } = this;

            return props.onProgress?.call(fileItem, progress);
        }

        _prepareToUploading(): void {
            const { $props_: props, fileItem } = this;
            if (!fileItem?.[XFile.canupload]) return;

            props._prepareToUploading.call(fileItem);
        }
    }

    @Editor.Collections(XFile)
    export class XFiles extends Collection<XFile, Result, TExec.IXFile> implements TExec.IXFiles {
        private _props = Property.Of(this).values;

        get uploader(): FileUploader {
            const { _props: props } = this;
            let uploader: FileUploader;

            return props.uploader = props.uploader || (uploader = props.uploader =
                new FileUploader({
                    itemAlias: "uploadedfile",
                    url: "/resource/upload",
                    removeAfterUpload: true,
                    autoUpload: false,
                    method: "POST",
                    filters: [{
                        name: 'media_filter',
                        fn: (
                            item?: FileLikeObject,
                            options?: FileUploaderOptions
                        ): boolean => {
                            if (this.parent.$saving_) {
                                return false;
                            }

                            return !this.exceedamount;
                        }
                    }]
                }),
                uploader.onAfterAddingFile = (item: FileItem): void => {
                    const xfile = this.create({ fileItem: item });
                    xfile.onLoaded();
                },
                uploader
            )
        }

        get exceedamount(): boolean {
            const { parent: { app: { lang } } } = this;
            const { general: { mediamaxamount = 12 } } = lang;
            return this.length >= Number.parseInt(mediamaxamount);
        }

        constructor(
            public parent: Result,
            _files: TExec.IXFile[] = []
        ) {
            super(parent, []);
            parent.files = this;
            this.recreate(_files);
        }

        construct(val: XFile | TExec.IXFile): XFile {
            return val instanceof XFile ? val : new XFile(this.parent, this, val);
        }

        _onAddRemoved({ removed, added }: { removed: XFile[]; added: XFile[]; }) {
            super._onAddRemoved?.({ added, removed });

            const { uploader } = this;
            removed?.forEach(f => {
                const { fileItem } = f;
                fileItem && uploader.cancelItem(fileItem);
                fileItem && uploader.removeFromQueue(fileItem)
            });
        }
    }

    export class Problem extends Editor.Editor<Problem, TExec.IProblem> implements TExec.IProblem {
        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.numberid)
        auditid: number;

        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.owned)
        problem: string;

        @JSON.Key()
        @Editor.Field(Problem, [Editor.Type.owned, Editor.Value.Type.date])
        date: Date;

        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.owned)
        fixed: boolean;

        get workitem(): Prj.ProjWorkitem {
            return this.parent.workitem;
        }

        get project(): Prj.Project {
            return this.parent.project;
        }

        constructor(
            public parent: Result,
            _problem: TExec.IProblem = {}
        ) {
            super((_problem.date = _problem.date || parent.date || new Date(), _problem));
        }
    }

    @Editor.Collections(Problem)
    export class Problems extends Collection<Problem, Result, TExec.IProblem> implements TExec.IProblems {
        constructor(
            public parent: Result,
            _problems: TExec.IProblem[] = []
        ) {
            super(parent, []);
            this.recreate(_problems);
        }

        construct(val: Problem | TExec.IProblem): Problem {
            return val instanceof Problem ? val : new Problem(this.parent, val);
        }

        _onAddRemoved({ added, removed }: { added: Problem[]; removed: Problem[] }) {
            super._onAddRemoved?.({ added, removed });

            const { parent: { app: { exec: { problems } } } } = this;
            removed && problems?.remove(removed);
            added && problems?.add(added, 0);
        }
    }

    export const FieldTypes: {
        [P in Editor.Value.Type]?: Type<any>
    } = {
        [Editor.Value.Type.pic]: Pic,
        [Editor.Value.Type.pics]: Pics,
        [Editor.Value.Type.file]: XFile,
        [Editor.Value.Type.files]: XFiles,
        [Editor.Value.Type.problem]: Problem,
        [Editor.Value.Type.problems]: Problems
    }
}
