import { DeepPartial } from "../../../utils/libs/types/mixins";
import { Collection } from "../../../utils/libs/collection";
import { _JSON } from "../../../utils/libs/polyfill/json";
import { Editor } from '../../../utils/libs/editor';
import { Unique } from '../../../utils/libs/unique';
import { AppService } from "../app.service";
import { Org as TOrg } from './types';
import { Backface } from './config';

export class Org extends Editor.Editor<Org, TOrg.IOrg> {
    @JSON.Key()
    @Backface.Entity(() => Org.Priviledge, "org")
    @Editor.Field(Org, Editor.Type.owned,
        Editor.Owned(function (this: Org, item: TOrg.IPriviledge) {
            return new Org.Priviledge(this, null, item)
        }, () => Org.Priviledge, 'priviledge')
    ) readonly priviledge: Org.Priviledge;

    @JSON.Key()
    @Backface.Entity(() => Org.Dept, "org")
    @Editor.Field(Org, Editor.Type.owned,
        Editor.Owned(function (this: Org, item: TOrg.IDept) {
            return new Org.Dept(this, null, item);
        }, () => Org.Dept, 'dept')
    ) readonly dept: Org.Dept;

    @JSON.Key()
    @Backface.Entity(() => Org.People, "org")
    @Editor.Field(Org, Editor.Type.owneds,
        Editor.Owneds(function (this: Org): Org.Peoples {
            return new Org.Peoples(this);
        }, 'peoples')
    ) readonly peoples: Org.Peoples;

    @JSON.Key()
    @Backface.Entity(() => Org.Role, "org")
    @Editor.Field(Org, Editor.Type.owned,
        Editor.Owned(function (this: Org, item: TOrg.IRole) {
            return new Org.Role(this, null, item);
        }, () => Org.Role, 'role')
    ) readonly role: Org.Role;

    @JSON.Key()
    @Backface.Entity(() => Org.Actor, "org")
    @Editor.Field(Org, Editor.Type.owneds,
        Editor.Owneds(function (this: Org) {
            return new Org.Actors(this);
        }, 'actors')
    ) readonly actors: Org.Actors;

    @JSON.Key()
    @Backface.Entity(() => Org.Inspector, "org")
    @Editor.Field(Org, Editor.Type.owneds,
        Editor.Owneds(function (this: Org) {
            return new Org.Inspectors(this);
        }, 'inspectors')
    ) readonly inspectors: Org.Inspectors;

    @Editor.Field(Org, Editor.Type.refflattens,
        Editor.RefFlattens(function (this: Org, item: TOrg.IPriviledge) {
            return new Org.Priviledge(this, this.priviledge, item);
        }, function (this: Org, item: Org.Priviledge): Org.Priviledge[] {
            return item?.parent?.priviledges;
        }, function (this: Org, obj: Org | Org.Priviledge): Org.Priviledge[] {
            return obj instanceof Org ? obj.priviledge?.priviledges : obj.priviledges;
        }, 'priviledges')
    ) readonly priviledges: Org.Priviledge[];

    @Editor.Field(Org, Editor.Type.refflattens,
        Editor.RefFlattens(function (this: Org, item: TOrg.IRole) {
            return new Org.Role(this, this.role, item);
        }, function (this: Org, item: Org.Role): Org.Role[] {
            return item?.parent?.roles;
        }, function (this: Org, obj: Org | Org.Role): Org.Role[] {
            return obj instanceof Org ? obj.role?.roles : obj.roles;
        }, 'roles')
    ) readonly roles: Org.Role[];

    @Editor.Field(Org, Editor.Type.refflattens,
        Editor.RefFlattens(function (this: Org, item: TOrg.IDept) {
            return new Org.Dept(this, this.dept, item);
        }, function (this: Org, item: Org.Dept): Org.Dept[] {
            return item?.parent?.depts;
        }, function (this: Org, obj: Org | Org.Dept): Org.Dept[] {
            return obj instanceof Org ? obj.dept?.depts : obj.depts;
        }, 'depts')
    ) readonly depts: Org.Dept[];

    constructor(app: AppService, setting?: TOrg.IOrg) {
        super(setting)
    }
}

export namespace Org {
    export class Dept extends Editor.Editor<Dept, TOrg.IDept> implements TOrg.IDept {
        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owned)
        location: string;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Dept) {
                return this.org.peoples;
            }, function (this: Dept) {
                return [] as People[]
            }, 'peoples')
        ) readonly peoples: People[];

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owneds,
            Editor.Owneds(function (this: Dept) {
                return new Depts(this.org, this);
            }, 'depts')
        ) readonly depts: Depts;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.refparent,
            Editor.RefParent(function (this: Dept) {
                return this.org.dept;
            }, function (this: Dept, parent: Dept): Dept[] {
                return parent?.depts;
            }, () => Dept, 'parent', function (this: Dept): Dept {
                return this.org.dept;
            })
        ) parent: Dept;

        constructor(
            public org: Org,
            parent: Dept,
            _dept: TOrg.IDept = {}
        ) {
            super((_dept.parent = parent, _dept));
        }

        firstOf(dept: DeepPartial<Org.Dept> | ((item: Dept) => boolean)): Dept {
            if (this.match(dept)) {
                return this;
            }

            return this.depts?.firstOf(dept);
        }
    }

    @Editor.Collections(Dept)
    export class Depts extends Collection<Dept, Dept, TOrg.IDept> implements TOrg.IDepts {
        constructor(
            public org: Org,
            public parent: Dept,
            _depts: TOrg.IDept[] = []
        ) {
            super(parent, []);
            this.recreate(_depts);
        }

        construct(val: TOrg.IDept): Dept {
            return new Dept(this.org, this.parent, val);
        }

        _onCreateDestroied({ created, destroied }: { created: Dept[]; destroied: Dept[]; }) {
            super._onCreateDestroied?.({ created, destroied });

            const { depts } = this.org;
            destroied && depts.remove(destroied);
            created && depts.add(created, 0);
        }
    }

    export class Priviledge extends Editor.Editor<Priviledge, TOrg.IPriviledge> implements TOrg.IPriviledge {
        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.numberid)
        readonly id: number;

        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.owned)
        prop: _JSON.Type;

        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.owneds,
            Editor.Owneds(function (this: Priviledge) {
                return new Priviledges(this.org, this);
            }, 'priviledges')
        ) readonly priviledges: Priviledges;

        constructor(
            public org: Org,
            public parent: Priviledge,
            _priviledge: TOrg.IPriviledge = {}
        ) {
            super({
                ..._priviledge,
                name: !_priviledge.id ? 'priviledge.' : `${parent.name}${_priviledge.name}${_priviledge.priviledges ? '.' : ''}`
            })
        }

        firstOf(privildege: DeepPartial<Org.Priviledge> | ((item: Priviledge) => boolean)): Priviledge {
            if (this.match(privildege)) {
                return this;
            }

            return this.priviledges?.firstOf(privildege);
        }
    }

    @Editor.Collections(Priviledge)
    export class Priviledges extends Collection<Priviledge, Priviledge, TOrg.IPriviledge> implements TOrg.IPriviledges {
        constructor(
            public org: Org,
            public parent: Priviledge,
            _priviledges: TOrg.IPriviledge[] = []
        ) {
            super(parent, []);
            this.recreate(_priviledges);
        }

        construct(val: TOrg.IPriviledge): Priviledge {
            return new Priviledge(this.org, this.parent, val);
        }

        _onCreateDestroied({ created, destroied }: { created: Priviledge[]; destroied: Priviledge[]; }) {
            super._onCreateDestroied?.({ created, destroied });

            const { priviledges: priviledges } = this.org;
            destroied && priviledges.remove(destroied);
            created && priviledges.add(created, 0);
        }
    }

    export class Role extends Editor.Editor<Role, TOrg.IRole> implements TOrg.IRole {
        @JSON.Key()
        @Editor.Field(Role, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Role) {
                return this.org.priviledge;
            }, function (this: Role) {
                return [] as Priviledge[]
            }, 'priviledges')
        ) readonly priviledges: Priviledge[];

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.owneds,
            Editor.Owneds(function (this: Role) {
                return new Roles(this.org, this);
            }, 'roles')
        ) readonly roles: Roles;

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.refparent,
            Editor.RefParent(function (this: Role) {
                return this.org.role;
            }, function (this: Role, parent: Role): Role[] {
                return parent?.roles;
            }, () => Role, 'parent', function (this: Role): Role {
                return this.org.role;
            })
        ) parent: Role;

        constructor(
            public org: Org,
            parent: Role,
            _role: TOrg.IRole = {}
        ) {
            super((_role.parent = parent, _role));
        }

        firstOf(role: DeepPartial<Org.Role> | ((item: Role) => boolean)): Role {
            if (this.match(role)) {
                return this;
            }

            return this.roles?.firstOf(role);
        }
    }

    @Editor.Collections(Role)
    export class Roles extends Collection<Role, Role, TOrg.IRole> implements TOrg.IRoles {
        constructor(
            public org: Org,
            public parent: Role,
            _roles?: TOrg.IRole[]
        ) {
            super(parent, []);
            this.recreate(_roles);
        }

        construct(val: TOrg.IRole): Role {
            return new Role(this.org, this.parent, val);
        }

        _onCreateDestroied({ created, destroied }: { created: Role[]; destroied: Role[]; }) {
            super._onCreateDestroied?.({ created, destroied });

            const { roles: roles } = this.parent.org;
            destroied && roles.remove(destroied);
            created && roles.add(created, 0);
        }
    }

    export class People extends Editor.Editor<People, TOrg.IPeople> implements TOrg.IPeople {
        @JSON.Key()
        @Editor.Field(People, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        mobile: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        email: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        disabled: boolean;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        password: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: People) {
                return this.org.role;
            }, function (this: People) {
                return [] as Role[]
            }, 'roles')
        ) readonly roles: Role[];

        @JSON.Key()
        @Editor.Field(People, Editor.Type.refowned,
            Editor.RefOwned(function (this: People) {
                return this.org.dept;
            }, () => Dept, 'dept')
        ) dept: Dept;

        constructor(
            public org: Org,
            _people: TOrg.IPeople = {}
        ) {
            super(_people);
        }
    }

    @Editor.Collections(People)
    export class Peoples extends Collection<People, Org, TOrg.IPeople> implements TOrg.IPeoples {
        constructor(
            public org: Org,
            _peoples?: TOrg.IPeople[]
        ) {
            super(org, []);
            this.recreate(_peoples);
        }

        construct(val: TOrg.IPeople): People {
            return new People(this.org, val);
        }
    }

    export class Actor extends Editor.Editor<Actor, TOrg.IActor> implements TOrg.IActor {
        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.refowned,
            Editor.RefOwned(function (this: Actor) {
                return this.org.peoples;
            }, () => People, 'header')
        ) header: People;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.refowned,
            Editor.RefOwned(function (this: Actor) {
                return this.org.peoples;
            }, () => People, 'prjowner')
        ) prjowner: People;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Actor) {
                return this.org.peoples;
            }, function (this: Actor) {
                return [] as People[];
            }, 'leaders')
        ) readonly leaders: People[];

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Actor) {
                return this.org.peoples;
            }, function (this: Actor) {
                return [] as People[];
            }, 'actors')
        ) readonly actors: People[];

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Actor) {
                return this.org.peoples;
            }, function (this: Actor) {
                return [] as People[];
            }, 'domains')
        ) readonly domains: People[];

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Actor) {
                return this.org.peoples;
            }, function (this: Actor) {
                return [] as People[];
            }, 'sites')
        ) readonly sites: People[];

        constructor(
            public org: Org,
            _actor: TOrg.IActor = {}
        ) {
            super(_actor);
        }
    }

    @Editor.Collections(Actor)
    export class Actors extends Collection<Actor, Org, TOrg.IActor> implements TOrg.IActors {
        constructor(
            public org: Org,
            _actors?: TOrg.IActor[]
        ) {
            super(org, []);
            this.recreate(_actors);
        }

        construct(val: TOrg.IActor): Actor {
            return new Actor(this.org, val);
        }
    }

    export class Inspector extends Editor.Editor<Inspector, TOrg.IInspector> implements TOrg.IInspector {
        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.guidid)
        readonly id: Unique.guid;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.owned)
        name: string;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.owned)
        description: string;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.refowned,
            Editor.RefOwned(function (this: Inspector) {
                return this.org.peoples;
            }, () => People, 'kpileader')
        ) kpileader: People;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.refowneds,
            Editor.RefOwneds(function (this: Inspector) {
                return this.org.peoples;
            }, function (this: Inspector) {
                return [] as People[]
            }, 'kpimembers')
        ) readonly kpimembers: People[];

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.refowned,
            Editor.RefOwned(function (this: Inspector) {
                return this.org.peoples;
            }, () => People, 'cdtorleader')
        ) cdtorleader: People;

        constructor(
            public org: Org,
            _inspector: TOrg.IInspector = {}
        ) {
            super(_inspector);
        }
    }

    @Editor.Collections(Inspector)
    export class Inspectors extends Collection<Inspector, Org, TOrg.IInspector> implements TOrg.IInspectors {
        constructor(
            public org: Org,
            _inspectors?: TOrg.IInspector[]
        ) {
            super(org, []);
            this.recreate(_inspectors);
        }

        construct(val: TOrg.IInspector): Inspector {
            return new Inspector(this.org, val);
        }
    }
}