import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Sys as TSys } from "../../../application/service/backface/types";
import { AppService } from "../../../application/service/app.service";
import { Prj } from "../../../application/service/backface/prj";
import { GovEditor } from "../model/form.editting";
import { Property } from "../../libs/property";
import { Editor } from "../../libs/editor";
import { Async } from "../../libs/async";
import { SysService } from "src/app/application";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

type IEditting = GovEditor.IEditting;
const Editting = GovEditor.Editting;

@Component({
    selector: "gov-advfilter, [gov-advfilter]",
    templateUrl: "./gov.advfilter.component.html",
    styleUrls: ["./gov.advfilter.component.scss"]
})
export class GovAdvfilterComponent implements OnInit, AfterViewInit {
    private _props = Property.Of(this).values;

    get currentmodule(): TSys.IDatasetModule {
        let { app: { datasources }, searchFilter, _props: props, _props: { currentmodule } } = this;
        if (currentmodule) return currentmodule;

        currentmodule = (props.currentmodule = datasources.advfilter);
        this.searchFilter = searchFilter;
        return currentmodule;
    }

    get searchFilters(): Prj.SearchFilters {
        return this._props.searchFilters;
    }

    get searchFilter(): Prj.SearchFilter {
        return this._props.searchFilter;
    }

    set searchFilter(val: Prj.SearchFilter) {
        const { sys, currentmodule, _props: props, _props: { searchFilter } } = this;
        if (searchFilter == val || !currentmodule) return;

        const toexchange = () => {
            props.searchFilter = val;
            const boundeditting = Editting.getBound(currentmodule).editting;
            Editting.set(currentmodule, boundeditting.reset({
                form: {
                    editor: val as any as Editor.Editor<any>,
                    headers: currentmodule.headers,
                    noactionbar: true
                },
                source: val,
            }));
        }

        if (this.isChanged) {
            sys.prompt('save', null, () => {
                this.cancel();
                toexchange();
            })
        } else {
            toexchange();
        }
    }

    get isOwnded(): boolean {
        const { app: { auth: { me } }, searchFilter } = this;
        return (searchFilter?.people?.id || me?.id) == me?.id;
    }

    get isChanged(): boolean {
        const { searchFilter } = this;
        return searchFilter?.$changed_();
    }

    get isNew(): boolean {
        const { searchFilter } = this;
        return searchFilter?.$forcreate;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        protected data: {
            searchFilters: Prj.SearchFilters,
            searchFilter: Prj.SearchFilter,
        },
        protected dialogRef: MatDialogRef<any, any>,
        protected httpClient: HttpClient,
        public app: AppService,
        public sys: SysService
    ) {
        const { _props: props, app: { defaultfiler } } = this;
        const { data: { searchFilter, searchFilters } } = this;
        props.searchFilters = searchFilters;

        this.searchFilter = (searchFilter == defaultfiler ? undefined : searchFilter);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    create() {
        const { searchFilters, sys, app: { auth: { me } } } = this;

        const tocreate = () => {
            this.searchFilter = searchFilters.createnew({
                people: { id: me?.id },
                date: new Date()
            }, 0);
        }

        if (this.isChanged) {
            // prompt whether need to save the changes.
            sys.prompt('save', null, () => {
                this.cancel();
                tocreate();
            });
        } else {
            tocreate();
        }
    }

    save() {
        const { searchFilter } = this;
        searchFilter?.$save_();
    }

    cancel() {
        const { searchFilter, isNew } = this;
        searchFilter?.$cancel_();

        if (isNew) {
            const { searchFilters: { 0: first } } = this;
            this.searchFilter = first;
        }
    }

    delete() {
        const { searchFilters, searchFilter, sys } = this;
        if (!searchFilters) return;

        const todelete = () => {
            const ret = searchFilters?.destroy(searchFilter);

            if (ret instanceof Observable) {
                const sub = ret.pipe(finalize(() => {
                    sub?.unsubscribe();
                })).subscribe((value: any): void => {
                    const { searchFilters: { 0: first } } = this;
                    this.searchFilter = first;
                }, (error: any): void => {
                })
            } else {
                const { searchFilters: { 0: first } } = this;
                this.searchFilter = first;
            }
        }

        sys.prompt('delete', todelete, null, {
            count: 1
        });
    }

    close() {
        const { dialogRef, sys } = this;

        const toclose = () => {
            dialogRef.close(this.searchFilter);
        }

        if (this.isChanged) {
            // prompt whether need to save the changes.
            sys.prompt('save', null, () => {
                this.cancel();
                toclose();
            });
        } else {
            toclose();
        }
    }

    static open(dialog: MatDialog, data: {} = {}): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: false,
            restoreFocus: true,
            maxHeight: '90vh',
            minHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            height: '90vh',
            width: '40vw',
            data: {
                ...data
            }
        }

        return dialog.open(GovAdvfilterComponent, config);
    }

}