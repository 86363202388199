import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';

import { AppService } from '../../service/app.service';
import { SysService } from '../../service/sys.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: "./frame.component.html",
    styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {

    get slidemode(): 'over' | 'side' | 'push' {
        return localStorage.NaviSlideMode || 'side'
    }

    set slidemode(val: 'over' | 'side' | 'push') {
        localStorage.NaviSlideMode = val;
    }

    get slideopened(): boolean {
        localStorage.NaviSlideOpened = localStorage.NaviSlideOpened || 'true';

        try {
            return JSON.parse(localStorage.NaviSlideOpened);
        } catch (e) { }

        return true;
    }

    set slideopened(val: boolean) {
        localStorage.NaviSlideOpened = val;
    }

    constructor(
        public route: ActivatedRoute,
        public sys: SysService,
        public app: AppService
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

}
