<ng-template #people let-title="title" let-peoples="peoples">
  <div class="d-flex flex-column align-items-stretch justify-content-start">
    <div class="card card-chart" style="width: 240px; margin: 8px; background-color: wheat">
      <div class="card-body">
        <h3 class="card-title" style="text-align: center; margin-top: 8px">
          {{ title | translate }}
        </h3>
      </div>
    </div>
    <ng-container *ngFor="let people of app._.isArray(peoples) ? peoples : [peoples]">
      <div class="card card-chart" style="width: 240px; margin: 10px; background-color: lightblue">
        <div class="card-body">
          <table>
            <tr>
              <td>
                <h4 class="card-title">{{ "people.name" | translate }}:</h4>
              </td>
              <td>
                <h4 class="card-title">{{ people?.name }}</h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4 class="card-title">{{ "people.mobile" | translate }}:</h4>
              </td>
              <td>
                <h4 class="card-title">{{ people?.mobile }}</h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4 class="card-title">{{ "people.email" | translate }}:</h4>
              </td>
              <td>
                <h4 class="card-title">{{ people?.email }}</h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4 class="card-title">{{ "people.dept" | translate }}:</h4>
              </td>
              <td>
                <h4 class="card-title">{{ people?.dept?.name }}</h4>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #team let-team="team" let-peoples="peoples">
  <div class="h-100 w-100 d-flex flex-row align-items-stretch justify-content-start flex-none">
    <ng-container *ngFor="let group of peoples">
      <ng-container *ngTemplateOutlet="
          people;
          context: {
            title: team + '.' + group,
            peoples: project[team][group]
          }
        "></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template id="project.actor">
  <ng-container *ngTemplateOutlet="
      team;
      context: {
        team: 'actor',
        peoples: ['header', 'leaders', 'actors', 'domains', 'sites', 'prjowner']
      }
    "></ng-container>
</ng-template>

<ng-template id="project.inspector">
  <ng-container *ngTemplateOutlet="
      team;
      context: {
        team: 'inspector',
        peoples: ['kpileader', 'kpimembers', 'cdtorleader']
      }
    "></ng-container>
</ng-template>

<ng-template id="project.summery">
  <div class="w-100 d-flex flex-column align-items-stretch justify-content-start">
    <div class="example-action-buttons">
      <button mat-button (click)="accordion.openAll()">
        {{ "projectdetail.expend" | translate }}
      </button>
      <button mat-button (click)="accordion.closeAll()">
        {{ "projectdetail.fold" | translate }}
      </button>
    </div>
    <mat-accordion #accordion="matAccordion" class="example-headers-align" multi>
      <mat-expansion-panel class="card-frame">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "projectdetail.basic" | translate }}
          </mat-panel-title>
          <mat-panel-description>
            {{ "projectdetail.basichint" | translate }}
            <mat-icon>account_circle</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <gov-splitter class="min-h-5 d-flex flex-row align-items-stretch justify-content-start flex-fill"
          [direction]="'horizontal'">
          <div gov-splitter-panel [size]="50">
            <table>
              <tr>
                <td>{{ "projecttype.name" | translate }}：</td>
                <td>{{ project?.projecttype?.name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "general.myproject" | translate }}：</td>
                <td>{{ project?.myproject ? "是" : "否" }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.isuniplan" | translate }}：</td>
                <td>{{ project?.isuniplan ? "是" : "否" }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.uniplan" | translate }}：</td>
                <td>{{ project?.uniplan }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.cancelled" | translate }}：</td>
                <td>{{ project?.cancelled ? "是" : "否" }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.prjlevel" | translate }}：</td>
                <td>{{ project?.prjLevel }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.constrtype" | translate }}：</td>
                <td>{{ project?.constrtypename }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.constrstatus" | translate }}：</td>
                <td>{{ project?.constrstatusname| translate }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "budget.start" | translate }}：</td>
                <td>
                  {{ project?.budget?.start | date : app.sys.dateFormat }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "budget.end" | translate }}：</td>
                <td>
                  {{ project?.budget?.end | date : app.sys.dateFormat }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.owncompany" | translate }}：</td>
                <td>{{ project?.owncompany }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.owndept" | translate }}：</td>
                <td>{{ project?.owndept }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.actor" | translate }}：</td>
                <td>{{ project?.actor?.name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.inspector" | translate }}：</td>
                <td>{{ project?.inspector?.name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.location" | translate }}：</td>
                <td>{{ project?.location }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.position" | translate }}：</td>
                <td>
                  {{ project?.positonDistrict }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{{ "project.memo" | translate }}：</td>
                <td>{{ project?.memo }}</td>
                <td></td>
              </tr>
            </table>
          </div>
          <div gov-splitter-panel [size]="50" [max]="50" [min]="50">
            <mat-form-field floatLabel="always" appearance="standard" class="w-100 h-100">
              <mat-label class="m-1 d-inline-block">{{ "project.description" | translate }}：</mat-label>
              <textarea matInput class="p-1" [mat-autosize]="true"
                [readonly]="true">{{ project?.description }}</textarea>
            </mat-form-field>
          </div>
        </gov-splitter>
      </mat-expansion-panel>
      <mat-expansion-panel class="card-frame">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "projectdetail.progress" | translate }}
          </mat-panel-title>
          <mat-panel-description>
            {{ "projectdetail.progresshint" | translate }}
            <mat-icon>domain</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <gov-results class="min-h-5 d-flex flex-row align-items-stretch justify-content-start flex-fill"
          [resultdata]="lastResultData">
        </gov-results>
      </mat-expansion-panel>
      <mat-expansion-panel class="card-frame">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "projectdetail.budget" | translate }}
          </mat-panel-title>
          <mat-panel-description>
            {{ "projectdetail.budgethint" | translate }}
            <mat-icon>map</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-card>
          <mat-card-content>
            <mat-label>
              {{ "budget.amount" | translate }}({{
              "general.bunit" | translate
              }})：{{
              project?.budget.amount | number : "1.0-0"
              }}&nbsp;&nbsp;&nbsp;&nbsp;{{ "budget.consume" | translate }}({{
              "general.bunit" | translate
              }})：{{ project?.budget.consume | number : "1.0-0" }}
            </mat-label>
            <section class="example-section">
              <mat-progress-bar class="example-margin" [color]="'primary'" [mode]="'determinate'"
                [value]="data.object.budget.percent">
              </mat-progress-bar>
              <div style="position: absolute; padding: 10px; color: white">
                {{ project?.budget.percent }}%
              </div>
            </section>
          </mat-card-content>
        </mat-card>
        <div echarts class="vh-30 w-100 d-inline-flex" (chartClick)="Report.onClickCharBar($event)"
          [options]="budgetoption"></div>
      </mat-expansion-panel>
      <ng-container *ngIf="!data.object.ispatched">
        <mat-expansion-panel class="card-frame">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "projectdetail.yearmonthcost" | translate }}
            </mat-panel-title>
            <mat-panel-description>
              {{ "projectdetail.yearmonthcosthint" | translate }}
              <mat-icon>date_range</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
            <div class="my-auto d-flex flex-row align-items-stretch justify-content-end flex-none">
              <div class="my-auto mr-2">
                <mat-label>{{ "general.annual" | translate }}：</mat-label>
              </div>
              <div class="my-auto mr-2">
                <select style="width: 100px; text-align: center" [(ngModel)]="selectedYearCost">
                  <option *ngFor="
                      let year of app._.range(
                        app.dict.rangeyearmin,
                        app.dict.currentYear + 1
                      )
                    " value="{{ year }}">
                    {{ year }}年
                  </option>
                </select>
              </div>
            </div>
            <div id="prjyearmonthcost" echarts class="vh-30 w-100 d-inline-flex" 
              [options]="yearmonthcostchartParam.option"></div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="card-frame">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "projectdetail.stage" | translate }}
            </mat-panel-title>
            <mat-panel-description>
              {{ "projectdetail.stagehint" | translate }}
              <mat-icon>date_range</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
            <gov-statusbar [(statuscount)]="prjstatuscount"></gov-statusbar>
            <div class="h-100 w-100 flex-fill" [gov-table]="stageSummery"
              (onWorkItemDetailsTemplate)="onWorkItemDetails($event)"></div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="card-frame">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "projectdetail.supervise" | translate }}
            </mat-panel-title>
            <mat-panel-description>
              {{ "projectdetail.supervisehint" | translate }}
              <mat-icon>date_range</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
            <div class="my-auto d-flex flex-row align-items-stretch justify-content-end flex-none">
              <div class="my-auto mr-2">
                <mat-label>{{ "general.annual" | translate }}：</mat-label>
              </div>
              <div class="my-auto mr-2">
                <select style="width: 100px; text-align: center" [(ngModel)]="selectedYear">
                  <option *ngFor="
                      let year of app._.range(
                        app.dict.rangeyearmin,
                        app.dict.currentYear + 1
                      )
                    " value="{{ year }}">
                    {{ year }}年
                  </option>
                </select>
              </div>
            </div>
            <div id="prjsuperviseoption" echarts class="vh-30 w-100 d-inline-flex" (chartClick)="onClickCharBar($event)"
              [options]="checkoption"></div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</ng-template>

<div class="h-100 w-100 m-0 p-0 d-flex flex-column align-items-stretch justify-content-start">
  <div mat-dialog-title class="d-flex flex-row flex-nowrap justify-content-start align-items-center">
    <div class="flex-fill">{{ project?.fullName }}</div>
    <label class="btn btn-sm" mat-dialog-close>
      <i class="fa fa-close fa-fw"></i>
    </label>
  </div>

  <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

  <mat-dialog-content class="mat-typography position-relative px-0 flex-fill">
    <div class="h-100 w-100" [gov-editor]="modules" [toeditting]="editting"
      (onDiagramImported)="handlers.projectexec.onDiagramImported($event)"
      (onDiagramDestroied)="handlers.projectexec.onDiagramDestroied($event)" (onCurrentModule)="onCurrentModule($event)"
      (onCommandTemplate)="
        $event.config.disableClose = false;
        $event.template = component
      "></div>
  </mat-dialog-content>
</div>