import { Component, OnInit, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { TemplateService } from '../../utils/view/template.directive';
import { AppService } from "../../application/service/app.service";

@Component({
    selector: 'systemset.component',
    templateUrl: "./systemset.component.html",
    styleUrls: ['./systemset.component.scss'],
    providers: [
        TemplateService
    ]
})
export class SystemsetComponent implements AfterViewInit, OnInit {
    constructor(public app: AppService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
    }
    
    static open(dialog: MatDialog): MatDialogRef<any, any> {
        const config: MatDialogConfig = {
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '50vh',
            minHeight: '50vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            height: '40vh',
            width: '40vw'
        }

        return dialog.open(SystemsetComponent, config);
    }
}
