<div *ngIf="toolbar" class="my-auto d-flex flex-row align-items-center justify-content-end flex-none">
  <mat-form-field *ngIf="app._.hasIn(toolbar, 'searcher') && !noinputtxt" class="mt-auto mx-1 mb-1">
    <input matInput [(ngModel)]="inputtxt" placeholder="{{ 'general.searchhint' | translate }}" />
  </mat-form-field>
  <mat-form-field *ngIf="app._.hasIn(toolbar, 'searcher') && filterdata" class="mt-auto mx-1 mb-1"
    style="width: 100px;">
    <mat-select [(value)]="optionvalue">
      <ng-container *ngFor="let item of filterdata">
        <mat-option [value]="item">{{ item?.title | translate }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button *ngIf="toolbar?.create" color="primary" class="mt-auto mx-1 mb-1" (click)="toolbar?.create()">
    <i class="material-icons">add</i>{{ "general.create" | translate }}
  </button>
  <button mat-flat-button *ngIf="toolbar?.delete" color="primary" class="mt-auto mx-1 mb-1" (click)="toolbar?.delete()"
    [disabled]="!toolbar?.selection?.hasValue()">
    <i class="material-icons">clear</i>{{ "general.delete" | translate }}
  </button>
  <div *ngIf="app._.hasIn(toolbar, 'selectedYear')" class="mt-auto mx-1 mb-1">
    <mat-label>{{ "general.annual" | translate }}：</mat-label>
    <select style="width: 100px; text-align: center" [(ngModel)]="toolbar.selectedYear">
      <option *ngFor="
          let year of app._.range(
            toolbar.minYear || app.dict.rangeyearmin,
            (toolbar.maxYear || app.dict.rangeyearmax) + 1
          )
        " value="{{ year }}">
        {{ year }}{{'general.year' | translate}}
      </option>
    </select>
  </div>
  <div *ngIf="app._.hasIn(toolbar, 'selectedProcess')" class="mt-auto mx-1 mb-1">
    <mat-label>{{ "navigation.process" | translate }}：</mat-label>
    <select style="width: 130px; text-align: center" [(ngModel)]="toolbar.selectedProcess">
      <option *ngFor="
          let process of app.prj.projecttypes
        " value="{{ process.id }}">
        {{ process.name }}
      </option>
    </select>
  </div>
  <div *ngIf="toolbar.exportas || toolbar.advfilter" class="mt-auto mx-1 mb-1 d-flex flex-row align-items-stretch">
    <ng-container *ngIf="isTemplateRef(toolbar?.advfilter)">
      <ng-container *ngTemplateOutlet="toolbar?.advfilter"></ng-container>
    </ng-container>

    <button mat-flat-button color="primary" *ngIf="!isTemplateRef(toolbar?.advfilter) && isFunction(toolbar?.advfilter)"
      (click)="advfilterex()">
      <i class="material-icons">format_list_bulleted</i>{{ "general.advfilter" | translate }}
    </button>

    <button mat-flat-button color="primary" *ngIf="toolbar?.exportas" (click)="toolbar?.exportas('xlsx')">
      <i class="material-icons">archive</i>{{ "summary.exportexcel" | translate }}
    </button>

    <button mat-flat-button color="primary" *ngIf="false" (click)="toolbar?.exportas('pdf')">
      <i class="material-icons">save_alt</i>{{ "summary.exportpdf" | translate }}
    </button>
  </div>
</div>