
import { isNumber, isString } from 'lodash';
import { __extends } from 'tslib';
import { types } from 'util';
import "reflect-metadata";

export function Upfirst(str: string) {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}

export function Capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getUnitFromValue(value) {
    return value.replace(parseFloat(value), '')
}

export function isArrayLike(obj) {
    var length = obj?.['length'];
    return isNumber(length) && length >= 0 && length <= Math.pow(2, 53) - 1;
}

export function isEmpty(obj) {
    if (obj == null) {
        return true;
    }

    if (isArrayLike(obj) && (Array.isArray(obj) || isString(obj) || types.isArgumentsObject(obj))) {
        return obj.length === 0;
    }

    return Object.keys(obj).length === 0;
}

export function has(obj: Object, key: string): boolean {
    return obj?.hasOwnProperty(key);
}

export function normalizeFloat(value: string | number, step: number = 1, valueDef: number = 0): number {
    var _value = (typeof value === 'string') ? parseFloat(value) : value;

    if (isNaN(_value)) {
        return valueDef;
    }

    let stepDecimals = 0;
    if (Math.floor(_value) !== _value) {
        const side = step.toString().split('.')[1];
        stepDecimals = side ? side.length : 0;
    }

    return stepDecimals ? parseFloat(_value.toFixed(stepDecimals)) : _value;
};

export function toArray<T>(argsin: ArrayLike<T>, start?: number, end?: number): T[] {
    var slice = Array.prototype.slice;
    return slice.call(argsin, start, end);
}