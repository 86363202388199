import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Directive, EmbeddedViewRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable, of, timer } from "rxjs";
import * as rxops from "rxjs/operators";
import { has } from "lodash";
import * as _ from "lodash";

import { Unique } from "../../../app/utils/libs/unique";
import { Property } from "../../utils/libs/property";
import { VersioinService } from "./version.service";
import { MsgService } from "./msg.service";
import { Server } from "../types";

function getResponseHeader(xhr: XMLHttpRequest, header: string): string | null {
    if (RegExp(`^${header}:`, 'mi').test(xhr.getAllResponseHeaders())) {
        return xhr.getResponseHeader(header);
    }

    return null;
}

function getResponseUrl(xhr: XMLHttpRequest): string | null {
    if ('responseURL' in xhr && xhr.responseURL) {
        return xhr.responseURL;
    }

    return getResponseHeader(xhr, 'X-Request-URL');
}

export namespace HTTP {
    export namespace Header {
        export const Force = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache');
    }
}

declare global {
    interface XMLHttpRequest {
        url: string | URL;
        httpResponse: HttpResponse<any>;
        httpErrorResponse: HttpErrorResponse;
    }
}

interface XHRProps extends XMLHttpRequest {
    listeners: {
        [P in 'load' | 'error' | 'abort']?: (
            this: XMLHttpRequest,
            ev: ProgressEvent<EventTarget>
        ) => any
    }
}

const XHRID = '_xhrid';

@Injectable({ providedIn: 'root' })
export class HttpService implements OnDestroy, HttpInterceptor {
    private _props = Property.Of<{
        xhrmap: Map<string, XMLHttpRequest>,
        reqing: boolean[],
    } & HttpService>(this).values;

    get token(): string {
        return this._props.token;
    }

    set token(val: string) {
        this._props.token = val;
    }

    get requesting(): boolean {
        return (this._props.reqing?.length || 0) > 0;
    }

    get onRequesting(): EventEmitter<boolean> {
        return (this._props.onRequesting = this._props.onRequesting || new EventEmitter());
    }

    constructor(
        private version: VersioinService,
        private msg: MsgService
    ) {
        // Hack the XMLHttpRequest to process the token like HttpClient
        const { _props } = this, thishttp = this;
        const xhrprototype = XMLHttpRequest.prototype;
        const _xhrprototype = Property.Of(xhrprototype).values;

        const xhrmap = (_props.xhrmap = _props.xhrmap || new Map());
        const reqing = (_props.reqing = _props.reqing || []);
        const pushreqing = () => {
            const isreqing = this.requesting;
            reqing.push(true);

            if (!isreqing) {
                this.onRequesting.emit(true);
            }
        }

        const popreqing = () => {
            reqing.pop();

            if (!this.requesting) {
                this.onRequesting.emit(false);
            }
        }

        const handleError = (err: HttpErrorResponse) => {
            const error: Server.Status.Error = err.error;
            if (error.handled) return;
            msg.emitRequestError(error);
        }

        _xhrprototype.response = Object.getOwnPropertyDescriptor(xhrprototype, 'response');
        _xhrprototype.getAllResponseHeaders = xhrprototype.getAllResponseHeaders;
        _xhrprototype.send = xhrprototype.send;
        _xhrprototype.open = xhrprototype.open;

        xhrprototype.send = function (this: XMLHttpRequest, body?: Document | null): void {
            const xhrprops = Property.Of<XHRProps>(this).values;
            xhrprops.listeners = {};

            // call xhrsend to initialize token header
            const requrl = new URL(this.url?.toString(), location.origin);
            const mysite = location.host === requrl.host;

            // util methods
            let headerResponse: HttpHeaderResponse | null = null;
            const partialFromXhr = (): HttpHeaderResponse => {
                if (headerResponse !== null) {
                    return headerResponse;
                }

                // Read status and normalize an IE9 bug (http://bugs.jquery.com/ticket/1450).
                const status: number = this.status === 1223 ? 204 : this.status;
                const statusText = this.statusText || 'OK';

                // Parse headers from XMLHttpRequest - this step is lazy.
                const headers = new HttpHeaders(this.getAllResponseHeaders());

                // build the HttpHeaderResponse and memoize it.
                // Read the response URL from the XMLHttpResponse instance and fall back on the request URL.
                headerResponse = new HttpHeaderResponse({ headers, status, statusText, url: getResponseUrl(this) || this.url?.toString() });
                return headerResponse;
            };

            const onLoad = (): HttpResponse<any> | HttpErrorResponse => {
                // Read response state from the memoized partial data.
                let { headers, status, statusText, url } = partialFromXhr();

                // The body will be read out if present.
                let body: any | null = null;

                if (status !== 204) {
                    // Use XMLHttpRequest.response if set, responseText otherwise.
                    body = (typeof this.response === 'undefined') ? this.responseText : this.response;
                }

                // Normalize another potential bug (this one comes from CORS).
                if (status === 0) {
                    status = !!body ? 200 : 0;
                }

                // ok determines whether the response will be transmitted on the event or
                // error channel. Unsuccessful status codes (not 2xx) will always be errors,
                // but a successful status code can still result in an error if the user
                // asked for JSON data and the body cannot be parsed as such.
                let ok = status >= 200 && status < 300;

                // Check whether the body needs to be parsed as JSON (in many cases the browser will have done that already).
                if (/application\/json/i.test(headers.get("content-type")) && typeof body === 'string') {
                    // Save the original body, before attempting XSSI prefix stripping.
                    const originalBody = body, XSSI_PREFIX = /^\)\]\}',?\n/;
                    body = body.replace(XSSI_PREFIX, '');

                    try {
                        // Attempt the parse. If it fails, a parse error should be delivered to the user.
                        body = body !== '' ? JSON.parse(body) : null;
                    } catch (error) {
                        // Since the JSON.parse failed, it's reasonable to assume this might not have been a
                        // JSON response. Restore the original body (including any XSSI prefix) to deliver
                        // a better error response.
                        body = originalBody;

                        // If this was an error request to begin with, leave it as a string, it probably
                        // just isn't JSON. Otherwise, deliver the parsing error to the user.
                        if (ok) {
                            // Even though the response status was 2xx, this is still an error.
                            ok = false;

                            // The parse error contains the text of the body that failed to parse.
                            body = {
                                error,
                                text: body
                            };
                        }
                    }
                }

                if (ok) {
                    // A successful response is delivered on the event stream.
                    return new HttpResponse({
                        url: url || undefined,
                        statusText,
                        headers,
                        status,
                        body,
                    });
                }

                // An unsuccessful request is delivered on the error channel.
                return new HttpErrorResponse({
                    // The error in this case is the response body (error from the server).
                    url: url || undefined,
                    error: body,
                    statusText,
                    headers,
                    status,
                });
            };

            const onError = (error: ProgressEvent): HttpErrorResponse => {
                const { url } = partialFromXhr();
                return new HttpErrorResponse({
                    statusText: this.statusText || 'Unknown Error',
                    status: this.status || 0,
                    url: url || undefined,
                    error,
                });
            };

            const onEnd = (xhr: XMLHttpRequest): void => {
                xhrmap.delete(Unique.numbered(xhr));
                popreqing(), thishttp.updateToken(xhr);

                _.forEach(xhrprops.listeners, (func, key) => {
                    xhr.removeEventListener(key, func);
                })
            }

            // listen on the events for reliable way to gurantee our process will be executed.
            xhrprops.listeners.load = function (this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) {
                if (mysite) {
                    const resp = thishttp.checkResponse(this, onLoad);
                    if (resp instanceof HttpErrorResponse) {
                        handleError(resp);
                    }
                }

                onEnd(this);
            }

            xhrprops.listeners.error = function (this: XMLHttpRequest, ev: ProgressEvent) {
                if (mysite) {
                    const resp = thishttp.checkResponse(this, onError.bind(this, ev));
                    if (resp instanceof HttpErrorResponse) {
                        handleError(resp);
                    }
                }

                onEnd(this);
            }

            xhrprops.listeners.abort = function (this: XMLHttpRequest, ev: ProgressEvent) {
                onEnd(this);
            }

            _.forEach(xhrprops.listeners, (func, key) => {
                this.addEventListener(key, func);
            })

            // hook the related api.
            xhrprops.onload = this.onload;
            this.onload = function (this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) {
                thishttp.checkResponse(this, onLoad);
                return xhrprops.onload?.call(this, ev)
            }

            xhrprops.onerror = this.onerror;
            this.onerror = function (this: XMLHttpRequest, ev: ProgressEvent) {
                thishttp.checkResponse(this, onError.bind(this, ev));
                return xhrprops.onerror?.call(this, ev)
            }

            // mark requesting state
            pushreqing();

            // call real send
            try {
                _xhrprototype.send.call(this, body);
            } catch (e) {
                // in sync mode, but access backend failed
                xhrprops.listeners.error.call(this, e);
                throw e;
            }
        }

        xhrprototype.open = function (this: XMLHttpRequest, method: string, _url: string | URL, async?: boolean, username?: string | null, password?: string | null): void {
            const { token, version: { server: { version } = {} } } = thishttp;
            const requrl = new URL(_url?.toString(), location.origin);
            const mysite = location.host === requrl.host;

            let url: string = null;
            if (mysite && method.toLocaleLowerCase() == "get" && version) {
                const __url = _url?.toString() || '';

                if (!/\s*\?\s*version\s*=\s*/.test(__url)) {
                    if (/\?/.test(__url)) {
                        url = __url + ";version=" + version;
                    } else {
                        url = __url + "?version=" + version;
                    }
                }
            }

            // open the request
            if (async !== undefined) {
                _xhrprototype.open.call(this, method, url || _url, async, username, password);
            } else {
                _xhrprototype.open.call(this, method, url || _url);
            }

            // add AuthToken to the req
            mysite && token && this.setRequestHeader("AuthToken", token);
            if (localStorage.Access) this.setRequestHeader("Access", localStorage.Access);

            // register the XMLHttpRequest with unique id
            const uid = Unique.numbered(this);

            if (!xhrmap.has(uid)) {
                xhrmap.set(uid, this);
            }

            // just record the request parameters
            this.url = url || _url;
        }

        xhrprototype.getAllResponseHeaders = function (this: XMLHttpRequest): string {
            const headers = _xhrprototype.getAllResponseHeaders.call(this);
            return `${headers}\n${XHRID}:${Unique.numbered(this)}`;
        }

        Object.defineProperty(xhrprototype, 'response', {
            ...(_xhrprototype.response || {}), get: function (this: XMLHttpRequest) {
                return this.httpResponse || this.httpErrorResponse || _xhrprototype.response?.get?.call(this);
            }
        })
    }

    ngOnDestroy() {

    }

    updateToken(from: XMLHttpRequest | HttpResponse<any> | HttpErrorResponse) {
        const resptoken = (
            from instanceof XMLHttpRequest ?
                getResponseHeader(from, 'AuthToken') :
                from.headers.get('AuthToken')
        );
        if (!resptoken) return;

        const url = from.url instanceof URL ? from.url.toString() : from.url;
        const requrl = new URL(url, location.origin);
        if (location.host !== requrl.host) return;

        this.token = resptoken;
        this.msg.emitMeChanged({
            token: resptoken
        }, null);
    }

    checkResponse(resp: XMLHttpRequest | HttpResponse<any> | HttpErrorResponse, maker?: (() => HttpResponse<any> | HttpErrorResponse)): HttpResponse<any> | HttpErrorResponse {
        let xhr: XMLHttpRequest = null;

        if (resp instanceof XMLHttpRequest) {
            const response = (resp?.httpResponse || resp?.httpErrorResponse)
            if (response) return response;
            xhr = resp, resp = maker?.();
        } else {
            const xhrid = resp?.headers?.get(XHRID);
            xhr = this._props.xhrmap.get(xhrid);
        }

        if (!(resp instanceof HttpResponse || resp instanceof HttpErrorResponse)) {
            return resp;
        }

        // whether has bound the response object? 
        const response = (xhr?.httpResponse || xhr?.httpErrorResponse)
        if (response) return response;

        // not yet bound the response object
        if (resp instanceof HttpResponse) {
            if (has(resp.body, "status.succeed")) {
                if (resp.body.status.succeed) {
                    resp = resp.clone({
                        body: resp.body.values || true
                    });
                } else {
                    resp = new HttpErrorResponse({
                        ...resp, error: resp.body.status
                    });
                }
            }
        }

        if (resp instanceof HttpErrorResponse && (!has(resp.error, 'errcode') || !has(resp.error, 'errkey'))) {
            // 1.  NetworkError: DOMException // sync access, server stop liked error
            // {
            //      stack: "Error: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'http://localhost/entity/project/retrieve'.\n    at http://localhost/polyfills.js:93519:31\n    at proto.<computed> (http://localhost/polyfills.js:91393:18)\n    at HttpService.xhrprototype.send (http://localhost/main.js:10075:31)\n    at push.rnna.Accessor.bysync (http://localhost/main.js:19764:21)\n    at push.rnna.Accessor.get (http://localhost/main.js:19753:25)\n    at Handler.get (http://localhost/main.js:19804:56)\n    at Prj.get (http://localhost/main.js:1608:54)\n    at desc.get [as project] (http://localhost/main.js:2264:42)\n    at Prj.<anonymous> (http://localhost/main.js:17437:51)\n    at Prj.get (http://localhost/main.js:2138:46)"
            //      code: 19
            //      message: "Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'http://localhost/entity/project/retrieve'."
            //      name: "NetworkError"
            // }
            //
            // 2. ProgressEvent // async access, server stop liked error
            // {
            //      type: "error"
            //      ...: ..
            // }
            //  
            // 3. string // server response http error code, such as 500
            //

            let error: Server.Status.Error = null;
            if (resp.error instanceof ProgressEvent || resp.error instanceof DOMException) {
                error = {
                    errkey: "error.request.network",
                    succeed: false,
                    errcode: 2004,
                }
            } else {
                error = {
                    errkey: "error.request.server",
                    errmsg: resp.statusText,
                    succeed: false,
                    errcode: resp.status,
                }
            }

            resp = new HttpErrorResponse({
                statusText: resp.statusText,
                headers: resp.headers,
                status: resp.status,
                url: resp.url,
                error: error,
            })
        }

        xhr && (
            resp instanceof HttpErrorResponse ?
                xhr.httpErrorResponse = resp :
                xhr.httpResponse = resp
        );

        return resp;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = new URL(req.url, location.origin);
        if (location.host !== url.host) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            rxops.catchError((resp: HttpEvent<any>) => {
                return of(resp)
            }),
            rxops.map((resp: HttpEvent<any>): HttpEvent<any> => {
                if (resp instanceof HttpResponse || resp instanceof HttpErrorResponse) {
                    this.updateToken(resp);

                    resp = this.checkResponse(resp) as HttpEvent<any>;
                    if (resp instanceof HttpErrorResponse) {
                        throw resp.error;
                    }
                }

                return resp;
            })
        );
    }
}

@Directive({
    selector: '[rqOverlay]',
    exportAs: 'RqOverlay'
}) export class RqOverlayDirective implements OnInit {
    private _props = Property.Of<{
        view: EmbeddedViewRef<any>
    }>(this).values;

    @Input('delay')
    delay: number;

    constructor(
        protected httpService: HttpService,
        protected overlay: TemplateRef<any>,
        protected container: ViewContainerRef,
    ) {
        this.httpService.onRequesting.subscribe((requesting) => {
            if (requesting) {
                const subscription = timer(this.delay || 500).subscribe(() => {
                    subscription.unsubscribe();
                    if (this.httpService.requesting) {
                        this.showOverlay();
                    }
                })
            } else {
                this.hideOverlay();
            }
        })
    }

    ngOnInit(): void {

    }

    showOverlay() {
        const { _props: props } = this;
        if (props.view) return;

        const { container, overlay } = this;
        props.view = container.createEmbeddedView(overlay);
    }

    hideOverlay() {
        const { _props: props } = this;
        if (!props.view) return;

        props.view.destroy();
        props.view = null;
    }
}