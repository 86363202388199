import { FromEventTarget, EventListenerOptions } from 'rxjs/internal/observable/fromEvent';
import { Subscription, fromEvent, Observable, merge, ObservableInput } from 'rxjs';
import { isUndefined, castArray } from 'lodash';

import { DeepPartial, NameOf, TypeValue } from './types/mixins';
import { Property } from './property';

abstract class _Differ {
    abstract clear();
    abstract check();
}

function isDifferMap(o: Differ.Types | Differ.Map): o is Differ.Map {
    if (!o || o instanceof _Differ) return false;
    return true;
}

function _clear(d: Differ.Types) {
    d?.clear();
}

function _check(d: Differ.Types) {
    d?.check();
}

function _through(settings: Differ.Types | Differ.Map, dfn: (d: Differ.Types) => void) {
    if (!settings) {
        return;
    }

    if (!isDifferMap(settings)) {
        dfn(settings);
        return;
    }

    for (var _k in settings) {
        let _differ: Differ.Types | Differ.Map = settings[_k];

        if (isDifferMap(_differ)) {
            _through(_differ, dfn);
            continue;
        }

        dfn(_differ);
    }
}

function _extend(src: Differ.Map, target: Differ.Map) {
    for (var k in src) {
        let _target: Differ.Types | Differ.Map = target[k];
        let _src: Differ.Types | Differ.Map = src[k];

        if (_src !== _target) {
            // unset the differ
            _through(_target, _clear);
            delete target[k];

            if (_src) {
                // reset the differ
                target[k] = _src;
            }

            continue;
        }

        if (isDifferMap(_src) && isDifferMap(_target)) {
            _extend(_src, _target);
        }
    }
}

export class Differ<Map extends Differ.Map = Differ.Map> {
    static create<Map extends Differ.Map>(settings: DeepPartial<Map, _Differ> = {}): Differ<Map> {
        let differ: Differ<Map> = new Differ<Map>();
        return differ.extend(settings);
    };

    private _props = Property.Of<Differ & {
        map: DeepPartial<Map, _Differ>
    }>(this).values;

    constructor() {
        const { _props: props } = this;
        props.map = {};
    }

    extend(settings: DeepPartial<Map, _Differ>): Differ<Map> {
        const { _props: props } = this;
        _extend(settings as Differ.Map, props.map as Differ.Map);
        return this;
    }

    check(): Differ<Map> {
        const { _props: props } = this;
        _through(props.map as Differ.Map, _check);
        return this;
    }

    clear(): Differ<Map> {
        const { _props: props } = this;
        _through(props.map as Differ.Map, _clear);
        props.map = {};
        return this;
    }
};

export namespace Differ {

    export type Types = Differ.Value<any> | Differ.Shallow<any> | Differ.Event<any>;

    export interface Map {
        [key: string]: Types | Map;
    }

    /**
     * A value difference checker.
     */
    export class Value<T = any> extends _Differ {
        private _handler: (diff?: Value.Diffed<T>) => void;
        private _cleared: boolean;
        private _valf: () => T;
        private _val: T;

        private value(val?: T): T {
            if (isUndefined(val) && this._valf) {
                return this._valf();
            }

            return val;
        }

        constructor(val: T | (() => T), handler: (diff?: Value.Diffed<T>) => void, thisArg?: any) {
            super();

            if (typeof val === 'function') {
                this._valf = val as (() => T);
                val = this.value();
            }

            this._handler = thisArg ? handler.bind(thisArg) : handler;
            this._cleared = false;
            this._val = val;
        }

        static create<T>(val: T | (() => T), handler: (diff?: Value.Diffed<T>) => void, thisArg?: any): Value<T> {
            return new Value(val, handler, thisArg);
        }

        clear() {
            this._cleared = true;
        }

        check(val?: T): Value.Diffed<T> {
            if (this._cleared) return null;
            val = this.value(val);

            if (this._val != val) {
                let diff = {
                    current: val,
                    previous: this._val
                }

                this._handler(diff);
                this._val = val;
                return diff;
            }

            return null;
        }
    }

    export namespace Value {
        export interface Diffed<T> {
            current: T,
            previous: T
        }
    }

    /**
     * A shadow difference checker.
     */
    export function shallow<T = Object | Array<any>>(objOrig: T, objNew: T): Differ.Shallow.Diffed<T> {
        const result: {
            changed: boolean,
            result: Differ.Shallow.Diffed<T>
        } = Array.from(new Set([
            ...Object.keys(objOrig),
            ...Object.keys(objNew)
        ])).reduce((res, key) => {
            const hasold = objOrig.hasOwnProperty(key);
            const hasnew = objNew.hasOwnProperty(key);

            if (hasold && hasnew) {
                const vold = objOrig[key];
                const vnew = objNew[key];

                if (vold != vnew) {
                    res.result.previous[key] = vold;
                    res.result.current[key] = vnew;
                    res.changed = true;
                }
            } else if (hasold) {
                res.result.previous[key] = objOrig[key];
                res.changed = true;
            } else {
                res.result.current[key] = objNew[key];
                res.changed = true;
            }

            return res;
        }, {
            changed: false as boolean,
            result: {
                previous: {},
                current: {}
            }
        });

        return result.changed ? result.result : null;
    }

    export class Shallow<T = Object> extends _Differ {
        private _handler: (diff?: Shallow.Diffed<T>) => void;
        private _cleared: boolean;
        private _valf: () => T;
        private _val: T;

        private value(val?: T): T {
            if (isUndefined(val) && this._valf) {
                return this._valf();
            }

            return val;
        }

        constructor(val: T | (() => T), handler: ((diff?: Shallow.Diffed<T>) => void), thisArg?: any) {
            super();

            if (typeof val === 'function') {
                this._valf = val as (() => T);
                val = this.value();
            }

            this._handler = thisArg ? handler.bind(thisArg) : handler;
            this._val = { ...val };
            this._cleared = false;
        }

        static create<T>(val: T | (() => T), handler: ((diff?: Shallow.Diffed<T>) => void), thisArg?: any): Shallow<T> {
            return new Shallow(val, handler, thisArg);
        }

        clear() {
            this._cleared = true;
        }

        check(val?: T): Shallow.Diffed<T> {
            if (this._cleared) return null;
            val = this.value(val);

            if (isUndefined(val)) {
                return null;
            }

            let diff = shallow(this._val, val);
            this._val = { ...val };

            if (diff) {
                this._handler(diff);
            }

            return diff;
        }
    }

    export namespace Shallow {
        export interface Diffed<T> {
            current: {
                [K in NameOf<T, Function>]?: T[K];
            },
            previous: {
                [K in NameOf<T, Function>]?: T[K];
            }
        }
    }

    /**
     * A event notifier checker.
     */
    export class Event<T = any> extends _Differ {
        private _subscription: Subscription;

        static fromEvent<Event>(target: FromEventTarget<Event> | FromEventTarget<Event>[], eventName: string | (string[]), options?: EventListenerOptions): Observable<Event> {
            const eventnames = castArray<string>(eventName).filter(en => !!en).reduce((res: string[], en: string) => {
                return [...res, ...en.split(' ')];
            }, []), os: ObservableInput<Event>[] = [];

            castArray<FromEventTarget<Event>>(target).filter(tgt => !!tgt).forEach((tgt) => {
                eventnames.forEach((evt) => {
                    os.push(fromEvent(tgt, evt, options))
                })
            });

            return merge(...os);
        }

        static create<TE extends Observable<any>>(event: TE, handler: (val: TypeValue<TE>) => void, thisArg?: any): Event<TypeValue<TE>> {
            return new Event(event, handler, thisArg);
        }

        constructor(event: Observable<T>, handler: (val: T) => void, thisArg?: any) {
            super();

            handler = thisArg ? handler.bind(thisArg) : handler;
            this._subscription = event.subscribe(handler);
        }

        clear() {
            if (this._subscription) {
                this._subscription.unsubscribe();
                this._subscription = null;
            }
        }

        check() {

        }
    }
}
